import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { matchRules } from '@topo-io/utils';
import { guestSectionCommentsRoutes } from '@/config';

export const useIsGuestSectionComments = () => {
  const {  asPath } = useRouter();
  const isSectionComments = useMemo(() => matchRules(asPath, guestSectionCommentsRoutes), [asPath]);
  return isSectionComments;
};
