import { Trans, t } from '@lingui/macro';
import { Box, Button, CustomThemeOverride, Flex, useToast } from '@topo-io/design-system';
import { ThemeDocument, useCreateThemeMutation, useUpdateThemeMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { useBrandingColors } from '@/components/branding/hooks';
import { BrandingForm } from './branding-form';
import { BrandingSettingsInfoBox } from './branding-settings-info-box';

export const BrandingSettings = () => {
  const { successToast, errorToast } = useToast();
  const {
    theme,
    primaryColorsPalette,
    setContrastTextColor,
    setPrimaryColor,
    reset,
    FALLBACK_PRIMARY_COLOR,
    FALLBACK_CONTRAST_TEXT_COLOR,
  } = useBrandingColors();
  const [createThemeMutation, { loading: isCreateThemeLoading }] = useCreateThemeMutation({
    refetchQueries: [ThemeDocument],
  });
  const [updateThemeMutation, { loading: isUpdateThemeLoading }] = useUpdateThemeMutation({
    refetchQueries: [ThemeDocument],
  });

  const { id, primaryColor, contrastTextColor } = theme;

  const handleSubmit = async () => {
    try {
      if (isNil(id)) {
        await createThemeMutation({
          variables: {
            input: {
              primaryColor,
              contrastTextColor,
            },
          },
        });
      } else {
        await updateThemeMutation({
          variables: {
            input: {
              id,
              // Hack, remove this after fixing the generate color palette function
              primaryColor: primaryColor === FALLBACK_PRIMARY_COLOR ? undefined : primaryColor,
              contrastTextColor:
                contrastTextColor === FALLBACK_CONTRAST_TEXT_COLOR ? undefined : contrastTextColor,
            },
          },
        });
      }
      successToast({
        title: t`Brand colors updated successfully`,
      });
    } catch (e) {
      errorToast({
        title: t`An error occurred while updating brand colors`,
      });
    }
  };

  return (
    <Box borderRadius="md" bgColor="white" padding="4">
      <BrandingSettingsInfoBox>
        <Trans>Changes you make will impact all workspaces for everyone in your company.</Trans>
      </BrandingSettingsInfoBox>

      <Box mt="6">
        <CustomThemeOverride colors={primaryColorsPalette} contrastColor={contrastTextColor}>
          <BrandingForm
            primaryColor={primaryColor}
            contrastTextColor={contrastTextColor}
            onContrastTextColorChange={setContrastTextColor}
            onPrimaryColorChange={setPrimaryColor}
            onReset={() => reset()}
          />
        </CustomThemeOverride>
        <Flex justifyContent="flex-end" mt="8">
          <Button isLoading={isCreateThemeLoading || isUpdateThemeLoading} onClick={handleSubmit}>
            <Trans>Save</Trans>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
