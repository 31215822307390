import { useCallback } from 'react';
import type { SectionTemplate } from '@topo-io/graphql';
import {
  FILES_USAGES,
  useCreateSectionFromTemplateMutation,
  useCreateSectionMutation,
} from '@topo-io/graphql';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { isNil } from '@topo-io/utils';
import { addSectionToSections } from '@/components/section/utils/adapters';
import { getCacheSections } from '@/components/section/utils/cache-utils';

export const useCreateSection = ({
  workspaceTabId,
  sections,
}: {
  workspaceTabId: string | undefined;
  sections: SectionJSON[];
}) => {
  const [createSectionMutation] = useCreateSectionMutation({
    update: (cache, { data }) => {
      const updatedSections = data?.createSection;
      if (isNil(updatedSections)) {
        return;
      }
      cache.modify(getCacheSections(updatedSections));
    },
  });

  const [createSectionFromTemplateMutation] = useCreateSectionFromTemplateMutation({
    update: (cache, { data }) => {
      const updatedSections = data?.createSectionFromTemplate;
      if (isNil(updatedSections)) {
        return;
      }
      cache.modify(getCacheSections(updatedSections));
    },
    refetchQueries: [FILES_USAGES],
  });

  const createSection = useCallback(
    ({ index }: { index: number }) => {
      const { nextSectionsState, createdSection } = addSectionToSections({
        index,
        sections,
      });
      if (isNil(workspaceTabId)) {
        return;
      }
      void createSectionMutation({
        variables: {
          input: {
            workspaceTabId,
            position: index,
            title: createdSection.title,
            content: createdSection.content,
          },
        },
        optimisticResponse: {
          createSection: { content: nextSectionsState },
        },
      });
    },
    [createSectionMutation, sections, workspaceTabId]
  );

  const createSectionFromTemplate = useCallback(
    ({
      index,
      sectionTemplate,
    }: {
      index: number;
      sectionTemplate: Pick<SectionTemplate, 'id' | 'title' | 'content'>;
    }) => {
      const { nextSectionsState } = addSectionToSections({
        index,
        sections,
        sectionTemplate,
      });
      if (isNil(workspaceTabId)) {
        return;
      }
      void createSectionFromTemplateMutation({
        variables: {
          input: {
            workspaceTabId,
            position: index,
            sectionTemplateId: sectionTemplate.id,
          },
        },
        optimisticResponse: {
          createSectionFromTemplate: {
            content: nextSectionsState,
          },
        },
      });
    },
    [createSectionFromTemplateMutation, sections, workspaceTabId]
  );

  return { createSection, createSectionFromTemplate };
};
