import Link from 'next/link';
import { Box, Flex, H3 } from '@topo-io/design-system';
import { baseUrl } from '@/config/routes';

interface NotFoundProps {
  resource?: string;
}

export const NotFound = ({ resource }: NotFoundProps) => {
  return (
    <Flex w="full" h="full" alignItems="center" justifyContent="center">
      <Box overflowY="auto">
        <H3>This {resource ?? 'page'} does not exist.</H3>
        <Box color="primary.500">
          <Link href={baseUrl()}>Go back to the homepage</Link>
        </Box>
      </Box>
    </Flex>
  );
};
