import { Trans, t } from '@lingui/macro';
import type { FieldValues } from '@saas-ui/forms';
import { Field, FormLayout, CheckboxField } from '@saas-ui/forms';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { ItemType } from '@topo-io/design-system';
import {
  Button,
  DropdownButton,
  DropdownItem,
  Flex,
  Input,
  useToast,
} from '@topo-io/design-system';
import type { CRMAccount } from '@topo-io/graphql';
import { isBlank, isNil, isNotNil } from '@topo-io/utils';
import { BackButton, CRMAccountsField } from '@/components/common';
import { CompanyField } from '@/components/common/fields/company-field';
import type {
  EditingMember,
  FormInviteMember,
} from '@/components/workspace/common/share-workspace-modal/invite-members-tab/types';
import { useInviteMembersSubmit } from '@/components/workspace/common/share-workspace-modal/invite-members-tab/use-invite-members-submit';
import { useWorkspaceContext, useWorkspace } from '@/components/workspace/hooks';
import { useSubscription } from '@/hooks/use-subscription';

const sendInvitationI18n = () => t`Send invitation`;
const inviteMemberI18n = () => t`Add member`;

interface AddDetailsStepProps {
  form: FormInviteMember;
  workspaceId: string;
  editingMember: EditingMember | undefined;
  goBack: () => void;
  goToStart: () => void;
  goToNextStep: () => void;
}

const SendInvitationDropdownButton = ({
  form,
  sendInvitation,
  personalizeInvitation,
}: {
  form: AddDetailsStepProps['form'];
  sendInvitation: (formPayload: FieldValues) => void;
  personalizeInvitation: () => void;
}) => {
  const formValues = form.getValues();
  const shouldSendInvitationEmail = 'withEmail' in formValues ? formValues.withEmail : false;

  const [buttonLabel, setButtonLabel] = useState(sendInvitationI18n());

  useEffect(() => {
    if (shouldSendInvitationEmail) {
      setButtonLabel(sendInvitationI18n());
    } else {
      setButtonLabel(inviteMemberI18n());
    }
  }, [shouldSendInvitationEmail]);

  const handleFormSubmission = () => {
    void form.handleSubmit(sendInvitation)();
  };

  return (
    <DropdownButton type="submit" onClick={handleFormSubmission} label={buttonLabel}>
      <DropdownItem onClick={handleFormSubmission}>{buttonLabel}</DropdownItem>
      <DropdownItem onClick={personalizeInvitation}>
        <Trans>Personalize invitation</Trans>
      </DropdownItem>
    </DropdownButton>
  );
};

const EditSubmitButton = ({ onSubmit }: { onSubmit: () => void }) => (
  <Button type="submit" onClick={onSubmit}>
    <Trans>Edit Member</Trans>
  </Button>
);

export const AddDetailsStep = ({
  form,
  workspaceId,
  editingMember,
  goToStart,
  goBack,
  goToNextStep,
}: AddDetailsStepProps): JSX.Element => {
  const { id, isWorkspace, isPreview, isGuest } = useWorkspaceContext();
  const { workspace } = useWorkspace(id!, { skip: isNil(id) || !isWorkspace });
  const { successToast, errorToast } = useToast();
  const formValues = form.getValues();
  const { hasCRMFeatures } = useSubscription();

  const getSuccessMessage = () => {
    const formValues = form.getValues();
    const shouldDisplayEmailMessage = 'withEmail' in formValues ? formValues.withEmail : false;

    if (shouldDisplayEmailMessage) {
      return t`Email sent`;
    }
    if (editingMember) {
      return t`Information Saved`;
    }
    return t`Member added`;
  };

  const onSubmitSuccess = () => {
    successToast({ title: getSuccessMessage() });
    form.reset(
      {},
      {
        keepValues: false,
      }
    );
  };
  const onSubmitFailure = () => {
    errorToast({ title: 'Something went wrong' });
  };

  const handleCrmAccountClear = () => {
    form.setValue('CRMId', undefined);
    form.setValue('CRMAccount', undefined);
    form.setValue('clearCRMData', true);
  };

  const handleCrmAccountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedAccount = event.target.value as unknown as ItemType | undefined;
    const shouldClearCrmAccount = isNil(selectedAccount) || isBlank(selectedAccount.label);

    if (shouldClearCrmAccount) {
      handleCrmAccountClear();
    } else {
      form.setValue('CRMAccount.name', selectedAccount.label);
      form.setValue('CRMAccount.crmId', selectedAccount.key);
    }
  };

  const { sendInvitation, updateMember, setWithEmail } = useInviteMembersSubmit({
    workspaceId,
    editingMemberId: editingMember?.id,
    onSubmitSuccess,
    onSubmitFailure,
    onSubmitCompleted: goToStart,
  });

  const hasCRMValidation = isNotNil(formValues.CRMId);
  const shouldDisplayCrmFields =
    isNotNil(workspace?.CRMAssociation?.CRMAccount.id) && !isPreview && !isGuest && hasCRMFeatures;

  let defaultCrmAccount: CRMAccount | undefined = undefined;

  const crmAccountFromForm = form.getValues('CRMAccount');
  if (isNotNil(crmAccountFromForm)) {
    defaultCrmAccount = {
      id: crmAccountFromForm.crmId,
      name: crmAccountFromForm.name,
      mergeId: crmAccountFromForm.crmId,
    };
  } else if (isNotNil(editingMember) && isNotNil(editingMember.CRMAccount)) {
    defaultCrmAccount = {
      id: editingMember.CRMAccount.crmId,
      name: editingMember?.CRMAccount.name,
      mergeId: editingMember.CRMAccount.crmId,
    };
  }

  const submitUpdateMember = useCallback(() => {
    void form.handleSubmit(updateMember)();
  }, [form, updateMember]);

  return (
    <>
      <BackButton onClick={goBack} text={t`go back`} />
      <FormLayout my="2">
        <Field
          type="email"
          name="email"
          label={t`Email`}
          placeholder={t`your-email@example.com`}
          isRequired={!editingMember || hasCRMValidation}
          isDisabled={Boolean(editingMember)}
          defaultValue={editingMember?.email}
        />
      </FormLayout>
      <FormLayout templateColumns="1fr 1fr" gap="2" mb="2">
        <Field
          name="firstName"
          label={t`First name`}
          placeholder={t`John`}
          defaultValue={editingMember?.firstName}
          isRequired={hasCRMValidation}
        />
        <Field
          name="lastName"
          label={t`Last name`}
          placeholder={t`Doe`}
          defaultValue={editingMember?.lastName}
          isRequired={hasCRMValidation}
        />
      </FormLayout>
      <FormLayout mb="2">
        <Field
          name="jobTitle"
          label={t`Job title`}
          placeholder={t`CEO`}
          defaultValue={editingMember?.jobTitle}
        />
      </FormLayout>
      <FormLayout mb={shouldDisplayCrmFields ? '2' : '4'}>
        <CompanyField
          name="company"
          label={t`Company`}
          placeholder={t`Acme`}
          defaultValue={editingMember?.company?.name}
        />
      </FormLayout>
      {shouldDisplayCrmFields && (
        <>
          <FormLayout mb="4">
            <CRMAccountsField
              name="_CRMAccount"
              label={t`CRM Account sync`}
              defaultCRMAccount={defaultCrmAccount}
              onChange={handleCrmAccountChange}
              onClear={handleCrmAccountClear}
              clearable
            />
          </FormLayout>
          <Input type="hidden" name="clearCRMData" value={'false'} />
        </>
      )}
      {!editingMember && (
        <CheckboxField
          defaultChecked={true}
          name="withEmail"
          label={t`Send email`}
          mr="2"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setWithEmail(event.target.checked);
          }}
        />
      )}
      <Flex justifyContent="end">
        {editingMember ? (
          <EditSubmitButton onSubmit={submitUpdateMember} />
        ) : (
          <SendInvitationDropdownButton
            form={form}
            sendInvitation={sendInvitation}
            personalizeInvitation={() => goToNextStep()}
          />
        )}
      </Flex>
    </>
  );
};
