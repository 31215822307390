import { memo, useState } from 'react';
import { Block, Box, HStack, Spacer } from '@topo-io/design-system';
import { SectionEditor } from '@/components/rich-text-editor/section-editor';
import { useDebouncedUpdateSectionContent } from './hooks/use-debounced-update-section-content';
import { SectionTitle } from './section-title';
import type { BasicSectionProps } from './types';

type SectionSimpleEditorModeProps = BasicSectionProps & {
  isOwner: boolean;
};

export const SectionSimpleEditorMode = memo((props: SectionSimpleEditorModeProps) => {
  const {
    id,
    sectionContent,
    sectionTitle,
    updateSectionTitle,
    updateSectionContent,
    sectionContentId,
    isOwner,
  } = props;
  const [shouldFocusContent, setShouldFocusContent] = useState(false);

  const setContent = useDebouncedUpdateSectionContent({
    id,
    updateSectionContent,
  });

  const handleEnterKeyPress = () => {
    // Resets focus immediately to allow jumping to content behavior to be repeated multiple times on hitting Enter
    setShouldFocusContent(true);
    setTimeout(() => {
      setShouldFocusContent(false);
    }, 50);
  };

  return (
    <Box position="relative" maxH="100%">
      <Block maxH="100%" mt="4">
        <HStack alignContent="center" mb="4" overflowY="scroll" maxH="100%">
          <SectionTitle
            id={id}
            readOnly={!isOwner}
            title={sectionTitle}
            updateSectionTitle={updateSectionTitle}
            onEnterKeyPress={handleEnterKeyPress}
          />
          <Spacer />
        </HStack>
        <SectionEditor
          sectionId={id}
          readOnly={!isOwner}
          initialContent={sectionContent}
          setContent={setContent}
          shouldFocusContent={shouldFocusContent}
          sectionContentId={sectionContentId}
        />
      </Block>
    </Box>
  );
});

SectionSimpleEditorMode.displayName = 'SectionSimpleEditorMode';
