import { useRef } from 'react';
import type { FC } from 'react';
import { Box, HStack, Icon, Tooltip } from '@topo-io/design-system';
import { useIsTextOverflow } from '@topo-io/hooks';

export type SectionTemplateItem = any; // FIXME: Algolia

const ItemHighlightedTitle: FC<{ item: SectionTemplateItem }> = ({ item }) => {
  return (
    // <Highlight attribute="title" hit={item} />
    // FIXME: Algolia
    <>{item.title}</>
  );
};

const dragActiveStyle = {
  borderColor: 'primary.500',
  color: 'primary.500',
  transform: 'rotate(-4deg)',
};

export const TemplateLibraryItem: FC<{
  title: string;
  item: SectionTemplateItem;
  onClick: () => void;
  isActive: boolean;
}> = ({ title, item, onClick, isActive }) => {
  const boxRef = useRef(null);
  const isOverflowing = useIsTextOverflow({ containerRef: boxRef, text: title });

  return (
    <HStack
      marginY="2"
      h="40px"
      w="full"
      backgroundColor="gray.10"
      borderRadius="md"
      borderWidth="1px"
      p="2"
      onClick={onClick}
      {...(isActive && dragActiveStyle)}
    >
      <Box minW="fit-content">
        <Icon icon="DotsGrid" mr="2px" w="12px" h="12px" />
      </Box>
      <Box
        ref={boxRef}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        userSelect="none"
      >
        {isOverflowing ? (
          <Tooltip label={title} placement="top">
            <Box>
              {item.title}
              {/* FIXME: Algolia */}
              {/* <Highlight
                attribute="title"
                hit={item}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              /> */}
            </Box>
          </Tooltip>
        ) : (
          <ItemHighlightedTitle item={item} />
        )}
      </Box>
    </HStack>
  );
};
