import { Trans, t } from '@lingui/macro';
import type { Dispatch, SetStateAction } from 'react';
import { useRef, useState } from 'react';
import {
  Box,
  DragHandle,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  InlineInput,
  useMediaQuery,
  appTheme,
} from '@topo-io/design-system';
import { useDebouncedCallback } from '@topo-io/hooks';
import { getLastPosition, isNil, isNotEmptyArray } from '@topo-io/utils';
import { StepStatus } from '@/components/roadmap/step-status';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { useSavingStatus } from '@/state/hooks/use-saving-status';
import {
  useCreateOnKeypress,
  useCreateTaskMutationWithCache,
  useDeleteOnKeypress,
  useUpdateStepMutationWithCache,
  useRoadmapContext,
} from './hooks';
import { useDeleteStepMutationWithCache } from './hooks/use-delete-step-mutation-with-cache';
import { TaskProgress } from './roadmap-task-drawer/task-progress';
import { StepStatusIcon } from './step-status-icon';
import type { RoadmapStep } from './types';

const UPDATE_TIMER = 2000;

export const StepHeader = ({
  step,
  isOpen,
  setIsOpen,
  isHovering,
}: {
  step: RoadmapStep;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isHovering: boolean;
}) => {
  const { showNotSavedStatus } = useSavingStatus();
  const { name, tasks, id, isVisible } = step;
  const [isLargerThanSmBreakpoint] = useMediaQuery(`(min-width: ${appTheme.breakpoints?.sm ?? 0})`);
  const { id: workspaceId, isPreview } = useWorkspaceContext();
  const { canEditRoadmap } = useRoadmapContext();
  const { updateStepWithCache } = useUpdateStepMutationWithCache();
  const { deleteStepWithCache } = useDeleteStepMutationWithCache();
  const { createTaskWithCache } = useCreateTaskMutationWithCache();

  const [newName, setNewName] = useState(step.name ?? '');
  const onDelete = () => deleteStepWithCache({ stepId: step.id });
  const onCreate = () => {
    createTaskWithCache({
      stepId: step.id,
      position: getLastPosition(tasks),
    });
    setIsOpen(true);
  };
  const inputRef = useRef(null);
  useDeleteOnKeypress({
    key: 'Backspace',
    target: newName,
    onKeypress: onDelete,
    ref: inputRef,
  });
  useCreateOnKeypress({
    key: 'Enter',
    onKeypress: onCreate,
    ref: inputRef,
  });
  const updateTitle = useDebouncedCallback(() => {
    if (isNil(workspaceId)) {
      return;
    }
    updateStepWithCache({
      stepData: {
        workspaceId,
        name: newName,
      },
      stepId: step.id,
    });
  }, UPDATE_TIMER);

  const visibleTasks = tasks.filter((task) => task.isVisible);
  const totalTasks = visibleTasks.length;
  const completedTasks = visibleTasks.filter((task) => task.done).length;
  const toggle = () => setIsOpen((state) => !state);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    showNotSavedStatus();
    setNewName(e.target.value);
    void updateTitle();
  };

  const handleDelete = () => {
    deleteStepWithCache({ stepId: id });
  };

  const toggleVisibility = () => {
    if (isNil(workspaceId)) {
      return;
    }

    updateStepWithCache({
      stepData: {
        workspaceId,
        isVisible: !step.isVisible,
      },
      stepId: step.id,
    });
  };
  const isStepHidden = !step.isVisible;

  return (
    <HStack borderRadius="md" backgroundColor="gray.10" py="1" px="4" mb="0" minH="48px">
      <HStack w="full" justifyContent="space-between" position="relative">
        <HStack w="full">
          {canEditRoadmap && <DragHandle isHovering={isHovering} />}
          <InlineInput
            as="h5"
            defaultValue={name}
            placeholder={t`Title`}
            onChange={onChange}
            readOnly={!canEditRoadmap}
            onClick={!canEditRoadmap ? toggle : () => null}
            cursor={!canEditRoadmap ? 'pointer' : 'text'}
            ref={inputRef}
            data-testid="map-title"
          />
        </HStack>
        <HStack onClick={toggle} cursor="pointer" {...(isStepHidden && { opacity: '0.5' })}>
          {isLargerThanSmBreakpoint ? <StepStatus step={step} /> : <StepStatusIcon step={step} />}
          {isNotEmptyArray(step.tasks) && (
            <>
              {isLargerThanSmBreakpoint ? (
                <TaskProgress
                  completedCount={completedTasks}
                  count={totalTasks}
                  backgroundColor="gray.30"
                  w={8}
                  h={2}
                />
              ) : (
                <Box fontSize="sm" userSelect="none">{`${completedTasks}/${totalTasks}`}</Box>
              )}
            </>
          )}
          <Icon color="gray.500" icon={isOpen ? 'chevron-down' : 'chevron-up'} />
        </HStack>
        {canEditRoadmap && (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<Icon icon="more-horizontal" />}
              variant="ghost"
              colorScheme="gray"
            />
            <MenuList>
              {!isPreview && (
                <>
                  {isVisible ? (
                    <MenuItem
                      icon={<Icon icon="eye-off" />}
                      color="gray.900"
                      onClick={toggleVisibility}
                    >
                      <Trans>Hide step</Trans>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      icon={<Icon icon="eye" />}
                      color="gray.900"
                      onClick={toggleVisibility}
                    >
                      <Trans>Show step</Trans>
                    </MenuItem>
                  )}
                </>
              )}
              <MenuItem icon={<Icon icon="trash" />} color="red.500" onClick={handleDelete}>
                <Trans>Delete step</Trans>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>
    </HStack>
  );
};
