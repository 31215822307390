export const scrollToSection = (id: string) => {
  setTimeout(() => {
    if (!window || !document) {
      return;
    }

    // eslint-disable-next-line @topo-io/no-dom-manual-manipulation
    const section = document.querySelector(`#section-${id}`);
    if (!section) {
      return;
    }

    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, 10);
};
