import type { RoadmapContentQuery } from '@topo-io/graphql';
import {
  TaskStatus,
  RoadmapContentDocument,
  RoadmapType,
  useUpdateTaskMutation,
} from '@topo-io/graphql';
import { deepClone, isNil, omit } from '@topo-io/utils';
import type { RoadmapTask } from '@/components/roadmap/types';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';

interface UpdateTaskPositionMutationWithCacheProps {
  updatedTask: RoadmapTask;
  newPosition: number;
  stepId: string;
}

const DELTA = 0.5;

export const useUpdateTaskPositionMutationWithCache = () => {
  const [updateTask] = useUpdateTaskMutation();
  const { id, isWorkspace } = useWorkspaceContext();

  const updateTaskPositionWithCache = ({
    updatedTask,
    newPosition,
    stepId,
  }: UpdateTaskPositionMutationWithCacheProps) => {
    const payload = omit(updatedTask, [
      'id',
      'assignees',
      'subtasksCount',
      'completedSubtasksCount',
      'commentCount',
    ]);
    void updateTask({
      variables: {
        taskData: {
          ...payload,
          position: newPosition,
          stepId,
        },
        taskId: updatedTask.id,
      },
      optimisticResponse: {
        updateTask: {
          __typename: 'Task',
          id: '0',
          done: false,
          assignees: [],
          status: TaskStatus.NOT_STARTED,
          isVisible: true,
          actions: [],
        },
      },
      update: (cache) => {
        const data = cache.readQuery<RoadmapContentQuery>({
          query: RoadmapContentDocument,
          variables: {
            input: {
              workspaceId: id,
              type: isWorkspace ? RoadmapType.Workspace : RoadmapType.WorkspaceTemplate,
            },
          },
        });
        if (isNil(data)) {
          return;
        }
        const { roadmap } = data;
        const clonedRoadmap = deepClone(roadmap);
        const modifiedStep = clonedRoadmap.steps.find((step) => step.id === stepId);
        if (isNil(modifiedStep)) {
          return;
        }
        const modifiedTask = modifiedStep.tasks.find((task) => task.id === updatedTask.id);
        if (isNil(modifiedTask)) {
          return;
        }
        const previousPosition = modifiedTask.position;
        modifiedTask.position =
          previousPosition > newPosition ? newPosition - DELTA : newPosition + DELTA;
        modifiedStep.tasks = modifiedStep.tasks.sort((a, b) => a.position - b.position);
        modifiedStep.tasks = modifiedStep.tasks.map((task, index) => ({
          ...task,
          position: index,
        }));
        cache.writeQuery({
          query: RoadmapContentDocument,
          variables: {
            input: {
              workspaceId: id,
              type: isWorkspace ? RoadmapType.Workspace : RoadmapType.WorkspaceTemplate,
            },
          },
          data: {
            roadmap: { ...clonedRoadmap },
          },
        });
      },
    });
  };
  return { updateTaskPositionWithCache };
};
