import type { InviteMemberInput } from '@topo-io/graphql';
import { useInviteMemberMutation, MEMBERS_FRAGMENT } from '@topo-io/graphql';

export const useInviteMember = ({
  workspaceId,
  onSubmitSuccess,
  onSubmitFailure,
  onSubmitCompleted,
}: {
  workspaceId: string;
  onSubmitSuccess: () => void;
  onSubmitFailure: () => void;
  onSubmitCompleted: () => void;
}): {
  inviteMember: (input: InviteMemberInput) => Promise<void>;
} => {
  const [inviteMemberMutation] = useInviteMemberMutation({
    update: (cache, { data }) => {
      const inviteMember = data?.inviteMember;
      cache.modify({
        fields: {
          members(existingMembers = []) {
            const newMemberRef = cache.writeFragment({
              data: inviteMember,
              fragment: MEMBERS_FRAGMENT,
            });
            return [...existingMembers, newMemberRef];
          },
        },
      });
    },
  });

  const inviteMember = async ({
    withEmail = true,
    company,
    ...workspaceProps
  }: InviteMemberInput) => {
    await inviteMemberMutation({
      variables: {
        ...workspaceProps,
        company: company?.name ? company : undefined,
        workspaceId,
        withEmail,
      },
      onError: onSubmitFailure,
      onCompleted: onSubmitSuccess,
    });
    onSubmitCompleted();
  };

  return {
    inviteMember,
  };
};
