import type { AxisTickProps } from '@nivo/axes';
import type { BarDatum } from '@nivo/bar';
import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Flex, Text, Tooltip, useToken } from '@topo-io/design-system';
import { ZERO, isNil, truncate, formatAnalyticsDuration } from '@topo-io/utils';
import { AnalyticsTooltip } from '@/components/analytics/common';
import type { GuestsMapping, SectionsMapping } from '@/components/analytics/types';
import { getGuestOrMergedAnonymousUser, getSectionTitle } from '@/components/analytics/utils';
import { useLocale } from '@/hooks';

const ResponsiveBar = dynamic(() => import('@nivo/bar').then((m) => m.ResponsiveBar), {
  ssr: false,
});

const TEXT_WIDTH = 160;
const MAX_TITLE_LENGTH = 20;

interface Props {
  data: BarDatum[] | undefined;
  keys: string[] | undefined;
  sectionsMapping: SectionsMapping | null;
  guestsMapping: GuestsMapping | null;
}

export const MostViewedSectionsGraph: FC<Props> = ({
  data,
  keys,
  sectionsMapping,
  guestsMapping,
}) => {
  const locale = useLocale();
  const colors = useToken('colors', ['primary.50', 'primary.100', 'primary.200', 'primary.300']);

  const renderTick = useCallback(
    (tick: AxisTickProps<string>) => {
      const { x, y, value } = tick;
      const title = getSectionTitle(sectionsMapping, value);
      if (title.length > MAX_TITLE_LENGTH) {
        return (
          <g transform={`translate(${x - TEXT_WIDTH},${y})`}>
            <Tooltip
              as="span"
              label={
                <text textAnchor="left" dominantBaseline="middle">
                  {title}
                </text>
              }
              placement="top"
            >
              <text textAnchor="left" dominantBaseline="middle">
                {truncate(title, MAX_TITLE_LENGTH)}
              </text>
            </Tooltip>
          </g>
        );
      }

      return (
        <g transform={`translate(${x - TEXT_WIDTH},${y})`}>
          <text textAnchor="left" dominantBaseline="middle">
            {title}
          </text>
        </g>
      );
    },
    [sectionsMapping]
  );

  if (isNil(data)) {
    return null;
  }

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      margin={{ top: 30, right: 0, bottom: 50, left: TEXT_WIDTH }}
      indexBy="sectionId"
      layout="horizontal"
      colors={colors}
      enableLabel={false}
      enableGridX={false}
      enableGridY={false}
      axisLeft={{
        renderTick,
      }}
      minValue={0}
      maxValue="auto"
      axisBottom={{
        tickValues: 2,
        format: (value: number) => {
          if (value === ZERO) {
            return '0';
          }
          return formatAnalyticsDuration(value, locale);
        },
      }}
      tooltip={({ id: userId, value }) => {
        return (
          <AnalyticsTooltip
            label={
              <Flex direction="column" alignItems="center" justifyContent="center">
                <Text>
                  {
                    getGuestOrMergedAnonymousUser({
                      guestsMapping,
                      userId: userId as string,
                    }).displayName
                  }
                </Text>
                <Text>{formatAnalyticsDuration(value, locale)}</Text>
              </Flex>
            }
          />
        );
      }}
    />
  );
};
