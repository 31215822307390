import { Trans } from '@lingui/macro';
import { Box, HStack, Icon } from '@topo-io/design-system';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';

export const BlankSectionButton = () => {
  const { tabId } = useWorkspaceTabs();
  const { onClickAddSection } = useContent(tabId);
  return (
    <HStack
      h="40px"
      w="full"
      backgroundColor="gray.10"
      borderRadius="md"
      borderWidth="1px"
      onClick={onClickAddSection}
      mt="2"
    >
      <Box p="2">
        <Icon icon="DotsGrid" mr="-5px" w="12px" h="12px" />
      </Box>
      <Box>
        <Trans>Add blank section</Trans>
      </Box>
    </HStack>
  );
};
