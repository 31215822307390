import { Trans } from '@lingui/macro';
import { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, FileUploadModal, FilesPreview, H5, Icon } from '@topo-io/design-system';
import type { UploadedFile } from '@topo-io/design-system';
import type { TaskQuery } from '@topo-io/graphql';
import { useFilesUsagesQuery } from '@topo-io/graphql';
import { useRoadmapContext } from '@/components/roadmap/hooks';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';

interface TaskFilesProps {
  task: TaskQuery['task'];
}

export const TaskFiles: FC<TaskFilesProps> = ({ task }) => {
  const { isGuest } = useWorkspaceContext();
  const { data } = useFilesUsagesQuery({
    variables: { input: { taskId: task.id } },
  });
  const { canEditRoadmap } = useRoadmapContext();
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const { createFile, createFileUsage, deleteFile } = useTaskContext();

  const filesPreviews = (data?.filesUsages ?? []).map(
    ({ id: fileId, url, type, name, size, owner }) => ({
      fileId,
      url,
      type,
      name,
      size,
      owner,
    })
  );

  const handleUploadCompleted = (uploadedFile: UploadedFile) => {
    void createFileUsage(uploadedFile.fileId);
    setIsFileUploadModalOpen(false);
  };

  return (
    <Box my={6}>
      <H5 mb={2}>
        <Trans>Files ({filesPreviews.length})</Trans>
      </H5>
      <Box width="full">
        <FilesPreview
          files={filesPreviews}
          editable={canEditRoadmap}
          deleteFile={deleteFile}
          withEmptyState={isGuest}
          hasDescription
        />
        {(canEditRoadmap || isGuest) && (
          <>
            <Button
              leftIcon={<Icon icon="plus-circle" color="primary.500" />}
              variant="ghost"
              w="full"
              px="0"
              colorScheme="grey"
              justifyContent="left"
              fontWeight="normal"
              borderRadius="sm"
              onClick={() => setIsFileUploadModalOpen(true)}
            >
              <Trans>Add file</Trans>
            </Button>
            <FileUploadModal
              isOpen={isFileUploadModalOpen}
              onClose={() => setIsFileUploadModalOpen(false)}
              onUploadCompleted={handleUploadCompleted}
              accept="file"
              createFile={createFile}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
