import { Plural, Trans } from '@lingui/macro';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { TOOLBAR_SECTION_Z_INDEX } from '@topo-io/constants';
import { Button, HStack } from '@topo-io/design-system';
import { isPositive } from '@topo-io/utils';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';
import { sectionCommentsUrl } from '@/config';
import { useQueryParamId, useUser } from '@/hooks';

interface SectionCtaCommentProps {
  sectionId: string;
  numberOfComments: number;
}

export const SectionCtaComment: FC<SectionCtaCommentProps> = ({ sectionId, numberOfComments }) => {
  const workspaceId = useQueryParamId();
  const { isIdentifiedUser, user } = useUser();
  const { isPreview, isGuest } = useWorkspaceContext();
  const { tab } = useWorkspaceTabs();

  const { replace } = useRouter();

  if (!isIdentifiedUser(user)) {
    return null;
  }

  const onClick = () => {
    void replace(
      sectionCommentsUrl({
        workspaceId,
        sectionId,
        mode: { isPreview, isGuest },
        tabSlug: tab.slug,
      }),
      undefined,
      {
        shallow: true,
      }
    );
  };

  const iconAndMessageMapper = {
    positive: {
      message: <Plural value={numberOfComments} one="# comment" other="# comments" />,
    },
    none: {
      message: <Trans>Add comment</Trans>,
    },
  };

  const { message } = isPositive(numberOfComments)
    ? iconAndMessageMapper.positive
    : iconAndMessageMapper.none;

  return (
    <HStack>
      <Button
        zIndex={TOOLBAR_SECTION_Z_INDEX}
        aria-label="redirect-comment-link"
        onClick={onClick}
        cursor="pointer"
        fontWeight="500"
        color="gray.500"
        w="fit-content"
        ml="auto"
        mr="0"
        py="2"
        size="sm"
        fontSize="md"
        variant="outline"
        colorScheme="gray"
        borderColor="gray.30"
        bgColor="white"
      >
        {message}
      </Button>
    </HStack>
  );
};
