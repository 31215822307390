import { useMemo } from 'react';
import { IntegrationName, useIntegrationConnectionNameQuery } from '@topo-io/graphql';
import { getIntegrationIcon } from '@/components/integrations/integration-name.mapper';

export const useIntegrations = () => {
  const { data, loading } = useIntegrationConnectionNameQuery();

  const integrationConnectionName = data?.integrationConnectionName ?? undefined;

  const isConnectedToOneIntegration = useMemo(
    () => Boolean(data?.integrationConnectionName) ?? false,
    [data?.integrationConnectionName]
  );

  const integrationIcon = useMemo(
    () => (integrationConnectionName ? getIntegrationIcon(integrationConnectionName) : undefined),
    [integrationConnectionName]
  );

  const isCloseDateUpdateAvailable = integrationConnectionName === IntegrationName.HUBSPOT;

  return {
    integrationConnectionName,
    isConnectedToOneIntegration,
    integrationIcon,
    isCloseDateUpdateAvailable,
    loading,
  };
};
