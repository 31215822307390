import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import type { SectionTemplate } from '@topo-io/graphql';
import type { ContentJSON, SectionJSON } from '@topo-io/rich-text-editor';
import { isFirstElement, isIndexFound, isIndexNotFound, isLastElement } from '@topo-io/utils';
import {
  extractValueFromIndex,
  getNewPosition,
  insertValueAtIndex,
  removeValueAtIndex,
} from './operations';

const createBlock = (type: string, properties = {}, content: ContentJSON[] = []): SectionJSON => {
  const id = uuidv4();
  const workspaceTabId = uuidv4();
  const title = '';
  const show = true;
  return {
    id,
    workspaceTabId,
    type,
    title,
    content,
    show,
    position: 0,
    numberOfComments: 0,
    ...properties,
  };
};

export const createInitialSectionContent = (othersContent = {}) =>
  createBlock('doc', {}, [{ type: 'paragraph', ...othersContent }]);

export const addSectionToSections = ({
  index,
  sections,
  sectionTemplate,
}: {
  index: number;
  sections: SectionJSON[];
  sectionTemplate?: Pick<SectionTemplate, 'id' | 'title' | 'content'>;
}) => {
  const content = createInitialSectionContent(sectionTemplate?.content);
  const section = createBlock('section', { title: sectionTemplate?.title ?? '' }, [content]);
  const nextSectionsState = produce(sections, (draft: SectionJSON[]) => {
    if (!index) {
      draft.push(section);
    } else {
      insertValueAtIndex(draft, index, section);
    }
  });

  return { nextSectionsState, createdSection: section };
};

export const removeSectionFromSections = ({
  sectionId,
  sections,
}: {
  sectionId: string;
  sections: SectionJSON[];
}) => {
  const nextSectionsState = produce(sections, (draft) => {
    const index = draft.findIndex((e) => e.id === sectionId);
    if (isIndexFound(index)) {
      removeValueAtIndex(draft, index);
    }
  });

  return { nextSectionsState };
};

export const updateSectionContentOfSections = ({
  sectionId,
  sectionContent,
  sections,
}: {
  sectionId: string;
  sectionContent: ContentJSON;
  sections: SectionJSON[];
}) => {
  const nextSectionsState = produce(sections, (draft) => {
    const index = draft.findIndex((e) => e.id === sectionId);
    if (isIndexNotFound(index)) {
      return;
    }

    if (!Array.isArray(draft[index].content)) {
      draft[index].content = [];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    draft[index].content[0] = sectionContent;
  });

  return { nextSectionsState };
};

export const updateSectionTitleOfSections = ({
  sectionId,
  title,
  sections,
}: {
  sectionId: string;
  title: string;
  sections: SectionJSON[];
}) => {
  const nextSectionsState = produce(sections, (draft: SectionJSON[]) => {
    const index = draft.findIndex((e) => e.id === sectionId);
    if (index in draft) {
      draft[index].title = title;
    } else {
      console.warn(`failed to set title on ${sectionId} to ${title}`);
    }
  });

  return { nextSectionsState };
};

export const moveSectionPositionOfSections = ({
  index,
  direction,
  sections,
}: {
  index: number;
  direction: 'up' | 'down';
  sections: SectionJSON[];
}) => {
  const nextSectionsState = produce(sections, (draft) => {
    if (isFirstElement(index) && direction === 'up') {
      return;
    }

    if (isLastElement(sections, index) && direction === 'down') {
      return;
    }

    const newPosition = getNewPosition(index, direction);
    const valueToMove = extractValueFromIndex(draft, index);
    insertValueAtIndex(draft, newPosition, valueToMove);
  });

  return { nextSectionsState };
};

export const moveSectionPositionOfSectionsFromDnD = ({
  index,
  direction,
  sections,
}: {
  index: number;
  direction: 'up' | 'down';
  sections: SectionJSON[];
}) => {
  const nextSectionsState = produce(sections, (draft) => {
    if (isFirstElement(index) && direction === 'up') {
      return;
    }

    if (isLastElement(sections, index) && direction === 'down') {
      return;
    }

    const valueToMove = extractValueFromIndex(draft, index);
    insertValueAtIndex(draft, index, valueToMove);
  });

  return { nextSectionsState };
};
