import { produce } from 'immer';
import type { WritableDraft } from 'immer/dist/internal';
import type { Step } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { replaceAtPosition } from './operations';

const getCurrentStepAndIndex = (draft: WritableDraft<Partial<Step>>[], currentStepId: string) => {
  const currentStepIndex = draft.findIndex((step) => step.id === currentStepId);
  const currentStep = draft[currentStepIndex];

  return { currentStepIndex, currentStep };
};

export const addStepToRoadmap = ({
  state,
  workspaceId,
}: {
  state: Partial<Step>[];
  workspaceId: string;
}) => {
  return produce(state, (draft) => {
    draft.push({ workspaceId });
  });
};

export const removeStepFromRoadmap = ({
  state,
  stepId,
}: {
  state: Partial<Step>[];
  stepId: string;
}) => {
  return produce(state, (draft) => {
    const { currentStep, currentStepIndex } = getCurrentStepAndIndex(draft, stepId);
    if (isNil(currentStep)) {
      return;
    }
    draft.splice(currentStepIndex, 1);
  });
};

export const updateStepInRoadmap = ({
  state,
  stepId,
  updateStepData,
}: {
  state: Step[];
  stepId: string;
  updateStepData: Partial<Step>;
}) => {
  return produce(state, (draft) => {
    const { currentStep, currentStepIndex } = getCurrentStepAndIndex(draft, stepId);
    if (isNil(currentStep)) {
      return;
    }
    replaceAtPosition(draft, currentStepIndex, { ...currentStep, ...updateStepData });
  });
};
