import { useCallback } from 'react';
import type { SectionTemplate } from '@topo-io/graphql';
import {
  useCreateSection,
  useDeleteSection,
  useDesynchronizeSection,
  useWorkspaceTabSections,
  useUpdateSection,
} from '@/components/section/hooks';

export const useContent = (workspaceTabId: string | undefined) => {
  const { sections } = useWorkspaceTabSections({ workspaceTabId });
  const { createSection, createSectionFromTemplate } = useCreateSection({
    workspaceTabId,
    sections,
  });
  const { deleteSection } = useDeleteSection({ workspaceTabId, sections });
  const {
    updateSectionContent,
    updateSectionTitle,
    moveSection,
    moveSectionFromDnD,
    moveSectionToAnotherTab,
    updateSectionVisibility,
  } = useUpdateSection({
    workspaceTabId,
    sections,
  });
  const { desynchronizeSection } = useDesynchronizeSection({
    workspaceTabId,
  });

  const addSection = (index: number) => {
    createSection({ index });
  };

  const addSectionAtLastPosition = useCallback(() => {
    const position = sections.length;
    createSection({ index: position });
    scrollToLastSection();
  }, [createSection, sections.length]);

  const addSectionTemplateAtLastPosition = useCallback(
    (sectionTemplate: Pick<SectionTemplate, 'id' | 'title' | 'content'>) => {
      const position = sections.length;
      createSectionFromTemplate({
        index: position,
        sectionTemplate,
      });
      scrollToLastSection();
    },
    [createSectionFromTemplate, sections.length]
  );

  const addSectionTemplateAtIndex = useCallback(
    (sectionTemplate: Pick<SectionTemplate, 'id' | 'title' | 'content'>, index: number) => {
      createSectionFromTemplate({
        index,
        sectionTemplate,
      });
    },
    [createSectionFromTemplate]
  );
  const toggleSectionVisibility = useCallback(
    ({ sectionId, show }) => {
      updateSectionVisibility({ sectionId, show: !show });
    },
    [updateSectionVisibility]
  );

  const onClickAddSection = () => addSectionAtLastPosition();
  const onClickAddSectionFromTemplate = (sectionTemplate) => () => {
    addSectionTemplateAtLastPosition(sectionTemplate);
  };

  return {
    addSection,
    addSectionTemplateAtIndex,
    onClickAddSection,
    onClickAddSectionFromTemplate,
    removeSection: deleteSection,
    moveSection,
    moveSectionToAnotherTab,
    moveSectionFromDnD,
    desynchronizeSection,
    updateSectionTitle,
    updateSectionContent,
    toggleSectionVisibility,
  };
};

const scrollToLastSection = () => {
  setTimeout(() => {
    if (!window || !document) {
      return;
    }

    // eslint-disable-next-line @topo-io/no-dom-manual-manipulation
    const lastButton = document.querySelector('.all-sections');
    if (!lastButton) {
      return;
    }

    lastButton.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }, 10);
};
