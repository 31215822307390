import type { FC } from 'react';
import { useState } from 'react';
import { Box } from '@topo-io/design-system';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { isNil, hash, isNotNil } from '@topo-io/utils';
import { SectionWorkspaceEditorMode, SectionDividerWithAddButton } from '@/components/section';
import { NewSectionPlaceholder } from '@/components/section/components/section-placeholder/new-section-placeholder';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';

interface WorkspaceContentEditorModeProps {
  content: SectionJSON[];
}

const nextIndex = (index: number) => index + 1;

export const WorkspaceContentEditorMode: FC<WorkspaceContentEditorModeProps> = ({ content }) => {
  const { tabId } = useWorkspaceTabs();
  const [isDragging, setIsDragging] = useState(false);
  const { updateSectionTitle, updateSectionContent } = useContent(tabId);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  if (content.length === 0) {
    return (
      <>
        <NewSectionPlaceholder />
        <SectionDividerWithAddButton
          atIndex={0}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        />
      </>
    );
  }

  return (
    <Box position="relative" top={isDragging ? 'unset' : '-32px'}>
      <SectionDividerWithAddButton
        atIndex={0}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      />
      {content.map((section, index) => {
        if (isNil(section.content)) {
          return <></>;
        }

        // Force the re-rendering of the section if its content changes after the initial render and
        // if the content is from a template.
        const key = isNotNil(section.templateId) ? hash(section.content[0]) : section.id;

        return (
          <div key={section.id}>
            <SectionWorkspaceEditorMode
              id={section.id}
              templateId={section.templateId}
              sectionContent={section.content[0]}
              sectionContentId={section.sectionContentId}
              sectionTitle={section.title}
              index={index}
              updateSectionTitle={updateSectionTitle}
              updateSectionContent={updateSectionContent}
              show={section.show}
              numberOfComments={section.numberOfComments}
              key={key}
            />
            <SectionDividerWithAddButton
              atIndex={nextIndex(index)}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            />
          </div>
        );
      })}
    </Box>
  );
};
