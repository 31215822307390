import { t } from '@lingui/macro';
import { useState } from 'react';
import type { ReactNode } from 'react';
import type { UploadedFile } from '@topo-io/design-system';
import { useToast } from '@topo-io/design-system';
import {
  FileUsageUsage,
  FILES_USAGES,
  useCreateFileMutation,
  useCreateFileUsageMutation,
} from '@topo-io/graphql';
import { RichTextEditorProvider } from '@topo-io/rich-text-editor';
import { getEditorTranslations } from '@/components/rich-text-editor/utils/translations';

export const CommentEditorProvider = ({ children }: { children: ReactNode }) => {
  const { errorToast } = useToast();
  const [filesUploaded, setFilesUploaded] = useState<UploadedFile[]>([]);
  const [createFileMutation] = useCreateFileMutation();
  const [createFileUsageMutation] = useCreateFileUsageMutation({
    refetchQueries: [FILES_USAGES],
  });

  const createFile = async ({
    ext,
    type,
    checksum,
    name,
    size,
  }: {
    ext: string;
    type: string;
    checksum: string;
    name: string;
    size: number;
  }): Promise<UploadedFile | undefined> => {
    const { data, errors } = await createFileMutation({
      variables: {
        input: {
          type,
          checksum,
          ext,
          name,
          size,
        },
      },
    });

    if (errors) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }

    if (data) {
      setFilesUploaded([...filesUploaded, data.createFile]);
      return { ...data.createFile };
    }

    return;
  };

  const deleteFileUsage = async (id: string): Promise<void> => {
    setFilesUploaded(filesUploaded.filter((file) => file.fileId !== id));

    return Promise.resolve();
  };

  const createFileUsage = async (
    fileId: string,
    sectionCommentId: string | undefined
  ): Promise<string | undefined> => {
    if (!sectionCommentId) {
      console.warn('sectionCommentId needs to be provided for file usage creation');
      return;
    }

    const { data, errors } = await createFileUsageMutation({
      variables: {
        input: {
          fileId,
          relatedId: sectionCommentId,
          relatedType: 'comment',
          usage: FileUsageUsage.COMMENT,
        },
      },
    });

    if (errors) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }

    return data?.createFileUsage.id;
  };

  return (
    <RichTextEditorProvider
      pendingFiles={filesUploaded}
      createFile={createFile}
      deleteFile={deleteFileUsage}
      linkFileToRecord={createFileUsage}
      translations={getEditorTranslations()}
    >
      {children}
    </RichTextEditorProvider>
  );
};
