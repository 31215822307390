import type { FC } from 'react';
import { useState } from 'react';
import { Box, useDisclosure } from '@topo-io/design-system';
import {
  FILES_USAGES,
  useDeleteFileUsageMutation,
  useUpdateFileUsageMutation,
} from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import type { Cover } from '@/components/workspace/overview/cover/cover-types';
import { useQueryParamId } from '@/hooks';
import { CoverImage } from './cover-image';

interface CoverContainerProps {
  cover?: Cover;
  hasCoverError?: boolean;
  onCoverError?: () => void;
}

export const CoverContainer: FC<CoverContainerProps> = ({ cover, hasCoverError, onCoverError }) => {
  const { isWorkspace, isTemplate } = useWorkspaceContext();
  const queryParamId = useQueryParamId();

  const [isHovering, setIsHovering] = useState(false);
  const {
    isOpen: isFileUploadModalOpen,
    onOpen: onFileUploadModalOpen,
    onClose: onFileUploadModalClose,
    ...otherDisclosureMethods
  } = useDisclosure();

  const fileUploadModalDisclosure: ReturnType<typeof useDisclosure> = {
    isOpen: isFileUploadModalOpen,
    onOpen: onFileUploadModalOpen,
    onClose: () => {
      onFileUploadModalClose();
      setIsHovering(false);
    },
    ...otherDisclosureMethods,
  };

  const [updateFileUsageMutation] = useUpdateFileUsageMutation();
  const [deleteFileUsageMutation] = useDeleteFileUsageMutation();

  const handleSavePosition = async (newPosition: number) => {
    if (cover?.id) {
      await updateFileUsageMutation({
        variables: {
          id: cover.id,
          data: {
            metadata: {
              positionY: newPosition,
            },
          },
        },
      });
    }
  };

  const handleRemove = async () => {
    if (cover?.id) {
      await deleteFileUsageMutation({
        variables: {
          id: cover.id,
        },
        refetchQueries: [
          {
            query: FILES_USAGES,
            variables: {
              input: {
                ...(isWorkspace ? { workspaceId: queryParamId } : {}),
                ...(isTemplate ? { workspaceTemplateId: queryParamId } : {}),
              },
            },
          },
        ],
      });
    }
  };

  if (isNil(cover) || isNil(cover?.url) || hasCoverError) {
    return <></>;
  }

  return (
    <Box
      w="100%"
      mb="8"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        if (!fileUploadModalDisclosure.isOpen) {
          setIsHovering(false);
        }
      }}
      position="relative"
      aria-label="Workspace cover"
    >
      <CoverImage
        isHovering={isHovering}
        cover={cover}
        fileUploadModalDisclosure={fileUploadModalDisclosure}
        onSavePosition={handleSavePosition}
        onRemove={handleRemove}
        onReplace={handleRemove}
        onCoverError={onCoverError}
      />
    </Box>
  );
};
