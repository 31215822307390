import { t } from '@lingui/macro';
import type { ChangeEvent, FC } from 'react';
import type { AutoResizeOptions } from '@topo-io/design-system';
import { appTheme, useMediaQuery } from '@topo-io/design-system';
import { HStack, Icon, SavingStatus, InlineInput } from '@topo-io/design-system';
import type { TaskQuery } from '@topo-io/graphql';
import { isPositive } from '@topo-io/utils';
import { useDeleteTaskMutationWithCache } from '@/components/roadmap/hooks/use-delete-task-mutation-with-cache'; // Avoiding circular dep by directly importing the file defining the hook.
import { useRoadmapContext } from '@/components/roadmap/hooks/use-roadmap-context'; // Avoiding circular dep by directly importing the file defining the hook.
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context'; // Avoiding circular dep by directly importing the file defining the hook.
import { useSavingStatus } from '@/state/hooks/use-saving-status';
import { TaskDeletionButton } from './task-deletion-confirmation-modal';
import { TaskProgress } from './task-progress';

interface TaskHeaderProps {
  task: TaskQuery['task'];
  onClose: () => void;
}

const AUTO_RESIZE_OPTION_WITH_PROGRESS: AutoResizeOptions = {
  minCharacter: 10,
  maxCharacter: 20,
};

const AUTO_RESIZE_OPTION_WITHOUT_PROGRESS: AutoResizeOptions = {
  minCharacter: 10,
  maxCharacter: 25,
};

export const TaskHeader: FC<TaskHeaderProps> = ({ task, onClose: handleCloseDrawer }) => {
  const { showNotSavedStatus } = useSavingStatus();
  const { canEditRoadmap } = useRoadmapContext();
  const { debouncedUpdateTask } = useTaskContext();
  const { status, show: savingIsShown } = useSavingStatus();
  const { deleteTaskWithCache } = useDeleteTaskMutationWithCache(handleCloseDrawer);
  const [isLargerThanSmBreakpoint] = useMediaQuery(`(min-width: ${appTheme.breakpoints?.sm ?? 0})`);

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    showNotSavedStatus();
    debouncedUpdateTask({
      ...task,
      name: evt.target.value,
    });
  };

  const handleDeleteTask = () => {
    deleteTaskWithCache({ stepId: task.stepId, taskId: task.id });
  };

  const hasSubtasks = isPositive(task.subtasksCount);
  const autoResizeOptions = hasSubtasks
    ? AUTO_RESIZE_OPTION_WITH_PROGRESS
    : AUTO_RESIZE_OPTION_WITHOUT_PROGRESS;

  // Force re-render when fetch is done or when toggle the display of the progress
  const inputKey = `${task?.id}-${hasSubtasks}`;

  return (
    <HStack justifyContent="space-between" px={6} pb={2}>
      <HStack>
        <InlineInput
          as="h4"
          defaultValue={task?.name ?? ''}
          placeholder={t`Task name`}
          readOnly={!canEditRoadmap}
          onChange={handleOnChange}
          autoComplete="off"
          data-form-type="other"
          data-testid="task-title"
          autoFocus={false}
          width="auto"
          pr={2}
          withAutoResize={isLargerThanSmBreakpoint}
          autoResizeOption={autoResizeOptions}
          key={inputKey}
        />
      </HStack>
      <HStack gap={2}>
        {hasSubtasks && (
          <TaskProgress count={task.subtasksCount} completedCount={task.completedSubtasksCount} />
        )}
        {savingIsShown && isLargerThanSmBreakpoint && <SavingStatus status={status} />}
        {canEditRoadmap && <TaskDeletionButton onDeletion={handleDeleteTask} />}
        <Icon
          icon="x-circle"
          cursor="pointer"
          h={6}
          w={6}
          onClick={handleCloseDrawer}
          aria-label="Close task drawer button"
          strokeWidth={1.5}
        />
      </HStack>
    </HStack>
  );
};
