import type { FC } from 'react';
import { Flex, SkeletonCircle } from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';
import { getEngagementScoreStyle } from '@/components/engagement-score/utils';

interface EngagementScoreCircleProps {
  isScoreLoading: boolean;
  score?: number;
  size?: 'small' | 'big';
  withHoverStyleByDefault?: boolean;
}

export const EngagementScoreCircle: FC<EngagementScoreCircleProps> = ({
  isScoreLoading,
  score,
  size = 'small',
  withHoverStyleByDefault,
}) => {
  const circleSize = size === 'small' ? '8' : '14';
  const fontSize = size === 'small' ? 'sm' : 'xl';

  if (isScoreLoading) {
    return <SkeletonCircle data-testid="engagement-score-skeleton" size={circleSize} />;
  }

  if (isNil(score)) {
    return null;
  }
  const { backgroundColor, color, hover } = getEngagementScoreStyle(score);

  return (
    <Flex
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      width={circleSize}
      height={circleSize}
      fontSize={fontSize}
      borderRadius="50%"
      backgroundColor={withHoverStyleByDefault ? hover.backgroundColor : backgroundColor}
      color={withHoverStyleByDefault ? hover.color : color}
      _hover={hover}
      fontWeight="600"
      data-testid="engagement-score-circle"
    >
      {score}
    </Flex>
  );
};
