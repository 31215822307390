import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Avatar, CustomSwitch, Flex, FormControl, FormLabel, Text } from '@topo-io/design-system';
import type { MembersQuery } from '@topo-io/graphql';
import { getDisplayName } from '@topo-io/utils';

interface MemberTabAccessProps {
  member: MembersQuery['members'][0];
  isWorkspaceOwner: boolean;
  tabAccess: Set<string>;
  onMemberAccessChange: (userProfileId: string, checked: boolean) => void;
}

export const MemberTabAccess: FC<MemberTabAccessProps> = ({
  member,
  isWorkspaceOwner,
  tabAccess,
  onMemberAccessChange,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const displayName = getDisplayName(member, member.email);
  const hasFirstNameOrLastName = member.firstName ?? member.lastName;
  const elementId = `member-${member.userProfileId}`;

  useEffect(() => {
    setIsChecked(tabAccess.has(member.userProfileId) || isWorkspaceOwner);
  }, [tabAccess, member.userProfileId, isWorkspaceOwner]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onMemberAccessChange(member.userProfileId, checked);
  };

  return (
    <FormControl display="flex" alignItems="center" justifyContent="space-between">
      <FormLabel htmlFor={elementId} mb="0">
        <Flex alignItems="center" gap="2">
          <Avatar name={displayName} src={member.picture} size="sm" />
          <Text fontWeight="medium">{displayName}</Text>
          {hasFirstNameOrLastName && <Text color="gray.100">({member.email})</Text>}
        </Flex>
      </FormLabel>
      <CustomSwitch
        id={elementId}
        data-testid={elementId}
        isChecked={isChecked}
        isDisabled={isWorkspaceOwner}
        onChange={handleChange}
      />
    </FormControl>
  );
};
