import { t } from '@lingui/macro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AISectionButton } from '@/components/ai';
import { BlankSectionButton } from './blank-section-button';
import { DraggableItem } from './draggable-item';
import type { SectionTemplateItem } from './template-library-item';

export const TemplateLibraryButton = () => {
  const { topoGpt } = useFlags();

  const data: SectionTemplateItem = {
    id: 'blank-section',
    content: '',
    title: t`Blank section`,
  };

  return (
    <>
      {topoGpt && <AISectionButton />}
      <DraggableItem key={`blank-section`} id="blank-item" data={{ sectionTemplate: data }}>
        <BlankSectionButton />
      </DraggableItem>
    </>
  );
};
