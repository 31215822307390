interface WorkspacesUrlBuilderProps {
  isWorkspace?: boolean;
  isPreview?: boolean;
  isGuest?: boolean;
  tabSlug?: string;
}

type WorkspaceTabUrlFn = (id: string, props?: WorkspacesUrlBuilderProps) => string;

export const workspaceTabUrl: WorkspaceTabUrlFn = (
  id,
  { isWorkspace = true, isPreview = false, isGuest = false, tabSlug } = {}
) => {
  const object = isWorkspace ? 'workspaces' : 'templates';

  let prefix;
  if (isGuest) {
    prefix = 'w';
  } else if (isPreview) {
    prefix = `${object}/preview`;
  } else {
    prefix = object;
  }

  const uri = [prefix, id, tabSlug].filter(Boolean).join('/');
  return `/${uri}`;
};
