import type { TaskQuery } from '@topo-io/graphql';
import { useTaskQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';

export const useTask = (
  taskId?: string
): { isLoading: boolean; task: TaskQuery['task'] | undefined } => {
  const { data, loading: isLoading } = useTaskQuery({
    variables: { id: taskId! },
    skip: isNil(taskId),
  });

  const task = data?.task;

  return {
    task,
    isLoading,
  };
};
