import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { Trans } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';
import { Box, DropzoneArea, Flex } from '@topo-io/design-system';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { getFirstElement } from '@topo-io/utils';
import {
  useDragSectionTemplate,
  useDropSectionTemplate,
} from '@/components/section/hooks/use-drag-and-drop-section-template';
import { scrollToSection } from '@/components/section/utils';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { SectionsSummaryInfoBox } from '@/components/workspace/overview/sections-summary-info-box';
import { SectionLibraryItem } from './section-library-item';

interface SectionLibraryListProps {
  sections: SectionJSON[];
}

interface AddSectionProps {
  atIndex: number;
}

const NEXT_INDEX = 1;

const DraggableItem: FC<{
  id: string;
  data: { section: SectionJSON; index: number };
  children: ReactNode;
}> = ({ id, data, children }) => {
  const { attributes, listeners, setNodeRef } = useDragSectionTemplate({ id, data });

  return (
    <Box ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </Box>
  );
};

export const Droppable: FC<{
  id: number;
  data: Record<string, unknown>;
  children: ReactNode;
}> = ({ id, children, data }) => {
  const { isOver, setNodeRef } = useDropSectionTemplate({
    id,
    data,
  });
  const { active } = useDndContext();

  const isNotPreviousOrNextDraggable = useCallback(() => {
    const draggableIndex = active?.data?.current?.index;

    if (id === draggableIndex + NEXT_INDEX || draggableIndex === id) {
      return true;
    }
    return false;
  }, [active, id]);

  return (
    <Box ref={setNodeRef}>
      <AnimatePresence>
        {active && isOver && !isNotPreviousOrNextDraggable() ? (
          <DropzoneArea
            as={motion.div}
            key={`droppable-${id}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            p={3}
            my={2}
          >
            <Trans>Drag & drop here</Trans>
          </DropzoneArea>
        ) : (
          children
        )}
      </AnimatePresence>
    </Box>
  );
};

const DroppableZone: FC<AddSectionProps> = ({ atIndex }: { atIndex: number }) => {
  return (
    <Droppable id={atIndex} data={{ atIndex }}>
      <Box data-testid={`section-divider-${atIndex}`} h="2" w="full"></Box>
    </Droppable>
  );
};

const nextIndex = (index: number): number => index + NEXT_INDEX;

export const SectionLibraryList: FC<SectionLibraryListProps> = ({ sections }) => {
  const { active } = useDndContext();
  const { visibleSections } = useWorkspaceContext();
  const activeSection = getFirstElement(visibleSections);

  return (
    <Box w="full" h="full" py="2">
      <DroppableZone atIndex={0} />
      <Flex h="full" direction="column" justifyContent="space-between">
        <Box>
          {sections.map((section, index) => (
            <Box key={`dnd-section-lib-${section.id}`}>
              <DraggableItem key={section.id} id={section.id} data={{ section, index }}>
                <SectionLibraryItem
                  sectionId={section.id}
                  title={section.title}
                  show={section.show}
                  isActive={activeSection === section.id}
                  onClick={() => scrollToSection(section.id)}
                />
              </DraggableItem>
              <DroppableZone atIndex={nextIndex(index)} />
            </Box>
          ))}
        </Box>
        <SectionsSummaryInfoBox />
      </Flex>

      <DragOverlay>
        {active ? (
          <SectionLibraryItem
            sectionId={active?.data?.current?.section?.id}
            title={active?.data?.current?.section?.title}
            show={active?.data?.current?.section?.show}
            onClick={() => undefined}
            isActive={true}
            isDragging
          />
        ) : null}
      </DragOverlay>
    </Box>
  );
};
