import { Trans } from '@lingui/macro';
import { Box, Flex, SimpleModal, Spinner } from '@topo-io/design-system';

interface AISectionLoadingProps {
  loading: boolean;
}

export const AISectionLoading = ({ loading }: AISectionLoadingProps): JSX.Element => {
  return (
    <SimpleModal isOpen={loading} onClose={() => {}} skipCloseButton isCentered>
      <Box textAlign="center">
        <Box color="gray.200" fontSize="12px" py="4">
          <Trans>Creating section with AI</Trans>
        </Box>
        <Flex pb="4" alignItems="center" justifyContent="center">
          <Spinner mr="14px" thickness="4px" speed="1s" emptyColor="gray.40" color="primary.500" />
          <Trans>We are currently generating your section</Trans>
        </Flex>
      </Box>
    </SimpleModal>
  );
};
