import type { ChangeEvent, FC } from 'react';
import { useCallback, useState } from 'react';
import { Box, Icon, Radio, RadioGroup, Spacer, Text } from '@topo-io/design-system';
import type { SectionTemplate } from '@topo-io/graphql';
import { useDeleteSectionTemplateMutation, DeleteTemplateType } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { FormModal } from '@/components/common';

interface DeleteSectionTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  sectionTemplateId: string;
  onSuccess?: () => void;
}

export const DeleteSectionTemplateModal: FC<DeleteSectionTemplateModalProps> = ({
  sectionTemplateId,
  onClose,
  isOpen,
  onSuccess,
}) => {
  const [deleteSectionTemplateMutation] = useDeleteSectionTemplateMutation({
    update: (cache) => {
      cache.modify({
        fields: {
          sectionTemplates(existingSectionTemplates: SectionTemplate[] = [], { readField }) {
            return existingSectionTemplates.filter(
              (template) => readField('id', template) !== sectionTemplateId
            );
          },
        },
      });
    },
  });

  const defaultValue = DeleteTemplateType.KEEP_LINKED_SECTIONS;
  const [deleteTemplateType, setDeleteTemplateType] = useState(defaultValue);
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDeleteTemplateType(e.target.value as DeleteTemplateType);
  }, []);

  const onSubmit = useCallback(async () => {
    const { errors } = await deleteSectionTemplateMutation({
      variables: { input: { id: sectionTemplateId, deleteType: deleteTemplateType } },
    });

    if (isNil(errors)) {
      onSuccess?.();
    }

    onClose();
  }, [deleteSectionTemplateMutation, deleteTemplateType, onClose, onSuccess, sectionTemplateId]);
  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete section template?"
      onSubmit={onSubmit}
    >
      By deleting this synced section, some workspaces will be impacted.
      <Spacer />
      What do you want to do?
      <RadioGroup defaultValue={defaultValue} display="flex" flexDirection="column" rowGap="5">
        <Radio
          onChange={onChange}
          value={DeleteTemplateType.KEEP_LINKED_SECTIONS}
          alignItems="start"
        >
          <Box marginTop="-4px" display="flex" justifyContent="space-between">
            Keep it as editable section
            <Icon icon="edit-3" />
          </Box>
          <Text marginTop="1" color="gray.500">
            This template will be deleted, but all the sections using this template in a workspace
            will remain
          </Text>
        </Radio>
        <Radio onChange={onChange} value={DeleteTemplateType.DELETE_ALL} alignItems="start">
          <Box marginTop="-4px" display="flex" justifyContent="space-between">
            Delete all sections
            <Icon icon="trash-2" color="red.500" />
          </Box>
          <Text marginTop="1" color="gray.500">
            This template and all sections using this template in a workspace will be deleted
          </Text>
        </Radio>
      </RadioGroup>
    </FormModal>
  );
};
