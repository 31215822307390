import segmentPlugin from '@analytics/segment';
import snowplowPlugin from '@analytics/snowplow';
import type { AnalyticsClient } from 'additional';
import Analytics from 'analytics';
import type { ReactNode } from 'react';
import { useState, createContext, useCallback, useEffect, useMemo } from 'react';
import { EventTrackingEvents } from '@topo-io/constants';
import type { StringRecord } from '@topo-io/types';
import { isNil } from '@topo-io/utils';
import { all } from '@/config';
import { useOnRouteChanged } from '@/hooks/use-on-route-changed';
import { useUser } from '@/hooks/use-user';

const { APP_NAME, SEGMENT_FRONTEND_KEY, SNOWPLOW_COLLECTOR_URL } = all;

interface Plugins {
  segment: boolean;
  snowplow: boolean;
}

interface IdentifyArgs {
  userProfileId: string;
  plugins?: Plugins;
  shouldTrackAnonymousUser?: boolean;
}

interface TrackArgs {
  eventName: string;
  properties?: StringRecord<unknown>;
  plugins?: Plugins;
}

export interface AnalyticsContextReturnValue {
  identify: (args: IdentifyArgs) => void;
  track: (args: TrackArgs) => void;
  onUserChanged: (eventName: EventTrackingEvents) => void;
}

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsContext = createContext<AnalyticsContextReturnValue>({
  identify: () => {},
  track: () => {},
  onUserChanged: () => {},
});

/* TODO: Transform this file into a client component after Next13 and app directory is implemented
 to be loaded only in client side, and remove snowplow-tracker package */
export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const { user: userProfile, isAnonymousUser } = useUser();
  const [eventToTrigger, setEventToTrigger] = useState<EventTrackingEvents | null>(null);

  const analytics = useMemo((): AnalyticsClient | null => {
    if (!SEGMENT_FRONTEND_KEY) {
      return null;
    }
    return Analytics({
      app: APP_NAME,
      plugins: [
        segmentPlugin({
          writeKey: SEGMENT_FRONTEND_KEY,
          disableAnonymousTraffic: true,
        }),
        snowplowPlugin({
          name: 'snowplow',
          collectorUrl: SNOWPLOW_COLLECTOR_URL,
          trackerSettings: {
            appId: APP_NAME,
            discoverRootDomain: true,
            cookieSameSite: 'Lax',
          },
        }),
      ],
    }) as AnalyticsClient;
  }, []);

  const segmentPageCallback = useCallback(() => {
    if (!analytics || isAnonymousUser(userProfile)) {
      return;
    }
    void analytics.page(
      {},
      {
        plugins: {
          segment: true,
          snowplow: false,
        },
      }
    );
  }, [analytics, isAnonymousUser, userProfile]);

  // Track page views for all pages, only for segment plugin
  useOnRouteChanged({
    callback: () => segmentPageCallback(),
  });

  // Disable anonymous users by default for segment plugin
  const identify = useCallback(
    (args: IdentifyArgs) => {
      const { userProfileId, plugins, shouldTrackAnonymousUser = false } = args;
      if (!shouldTrackAnonymousUser && isAnonymousUser(userProfile)) {
        return;
      }
      if (!analytics || !userProfileId || isNil(userProfile)) {
        return;
      }
      void analytics.identify(
        userProfileId,
        {},
        {
          plugins,
          context: {
            groupId: userProfile.organizationId,
          },
        }
      );
    },
    [analytics, isAnonymousUser, userProfile]
  );

  const group = useCallback(
    (organizationId: string) => {
      if (!analytics || isAnonymousUser(userProfile)) {
        return;
      }
      analytics.plugins.segment.group(organizationId, {
        organization: {
          id: organizationId,
        },
      });
    },
    [analytics, isAnonymousUser, userProfile]
  );

  const track = useCallback(
    ({
      eventName,
      properties,
      plugins = {
        segment: true,
        snowplow: false,
      },
    }: TrackArgs) => {
      if (!analytics || !eventName) {
        return;
      }
      void analytics.track(eventName, properties, {
        plugins,
        context: {
          groupId: userProfile?.organizationId,
        },
      });
    },
    [analytics, userProfile]
  );

  const onUserChanged = useCallback(
    (eventName: EventTrackingEvents) => {
      setEventToTrigger(eventName);
    },
    [setEventToTrigger]
  );

  useEffect(() => {
    if (!eventToTrigger || !userProfile || !analytics) {
      return;
    }
    if (isAnonymousUser(userProfile)) {
      return;
    }
    const plugins = {
      snowplow: false,
      segment: true,
    };
    switch (eventToTrigger) {
      case EventTrackingEvents.SIGN_IN:
        identify({
          userProfileId: userProfile.id,
          plugins,
        });
        group(userProfile.organizationId);
        break;

      case EventTrackingEvents.SIGN_UP:
        identify({
          userProfileId: userProfile.id,
          plugins,
        });
        group(userProfile.organizationId);
        break;

      case EventTrackingEvents.SIGN_OUT:
        void analytics.reset();
        break;

      case EventTrackingEvents.PAGE_LOAD:
        identify({
          userProfileId: userProfile.id,
          plugins,
        });
        group(userProfile.organizationId);
    }
    setEventToTrigger(null);
  }, [eventToTrigger, userProfile, group, identify, analytics, isAnonymousUser]);

  useEffect(() => {
    if (isNil(userProfile)) {
      return;
    }
    onUserChanged(EventTrackingEvents.PAGE_LOAD);
  }, [userProfile, onUserChanged]);

  return (
    <AnalyticsContext.Provider value={{ identify, track, onUserChanged }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
