import { t } from '@lingui/macro';
import { isNil, formatAnalyticsDuration } from '@topo-io/utils';
import type { SectionsMapping, UserMetricsWithAverage } from '@/components/analytics/types';
import type { formatDiffBetweenPeriods } from './time-utils';

export const getGlobalAnalyticsTooltipLabel = ({
  metricKey,
  locale,
  periodDiff,
}: {
  metricKey: keyof UserMetricsWithAverage;
  locale: string;
  periodDiff: ReturnType<typeof formatDiffBetweenPeriods>;
}) => {
  const { countDiff, formattedCountDiff, formattedPercentageDiff, sign } = periodDiff;
  switch (metricKey) {
    case 'totalActiveWorkspaces':
      return t`${formattedCountDiff} workspace actives ${formattedPercentageDiff}`;
    case 'totalSharedWorkspaces':
      return t`${formattedCountDiff} workspace shared ${formattedPercentageDiff}`;
    case 'averageGuests':
      return t`${formattedCountDiff} members invited ${formattedPercentageDiff}`;
    case 'averageViews':
      return t`${formattedCountDiff} views ${formattedPercentageDiff}`;
    case 'averageTimeSpent':
      return t`${sign} ${formatAnalyticsDuration(countDiff, locale)} ${formattedPercentageDiff}`;
    default:
      return null;
  }
};

export const getSectionTitle = (mapping: SectionsMapping | null, sectionId: string) => {
  const section = mapping?.[sectionId];
  if (isNil(section) || !section.title) {
    return 'Untitled section';
  }
  return section.title;
};
