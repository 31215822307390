import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@topo-io/design-system';
import type { RoadmapReminderRule } from '@topo-io/graphql';
import { InlineField } from '@/components/workspace/common/share-workspace-modal/inline-field';
import { getFormattedRoadmapReminderRules, REMINDER_RULE_DEFAULT_VALUE } from './roadmap.constants';

export const RoadmapRuleEmailReminderSettings = ({
  roadmapReminderRule,
  updateRoadmapReminderRule,
}: {
  roadmapReminderRule?: RoadmapReminderRule;
  updateRoadmapReminderRule: (value: RoadmapReminderRule) => void;
}) => {
  const [ruleValue, setRuleValue] = useState<RoadmapReminderRule>(
    roadmapReminderRule ?? REMINDER_RULE_DEFAULT_VALUE
  );

  useEffect(() => {
    if (roadmapReminderRule) {
      setRuleValue(roadmapReminderRule);
    }
  }, [roadmapReminderRule]);

  const formattedRoadmapReminderRules = getFormattedRoadmapReminderRules();

  const roadmapReminderRuleLabel = ruleValue
    ? formattedRoadmapReminderRules[ruleValue].text
    : formattedRoadmapReminderRules[REMINDER_RULE_DEFAULT_VALUE].text;

  const getOnClickMenuItem = (rule: { text: string; value: RoadmapReminderRule }) => () => {
    setRuleValue(rule.value);
    updateRoadmapReminderRule(rule.value);
  };

  return (
    <InlineField label={t`Email reminder on Mutual Action Plan tasks`} my="2">
      <Menu>
        <MenuButton px="2" py="0" as={Button} rightIcon={<Icon icon="chevron-down" />}>
          {roadmapReminderRuleLabel}
        </MenuButton>
        <MenuList>
          {Object.values(formattedRoadmapReminderRules).map((rule) => (
            <MenuItem onClick={getOnClickMenuItem(rule)} key={rule.value}>
              {rule.text}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </InlineField>
  );
};
