import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import { useCallback, useMemo } from 'react';
import { useCookies } from '@topo-io/hooks';
import type { AccessTokenOrNull } from '@topo-io/types';
import { ACCESS_TOKEN_COOKIE_NAME, COOKIES_DEFAULT_OPTIONS } from '@/config';

const decodeToken = (token: string): AccessTokenOrNull => {
  try {
    const decodedToken = jwtDecode<AccessTokenOrNull>(token);
    if (
      decodedToken &&
      typeof decodedToken === 'object' &&
      'iat' in decodedToken &&
      'exp' in decodedToken
    ) {
      return decodedToken;
    }
  } catch (error) {
    if (!(error instanceof InvalidTokenError)) {
      throw error;
    }
  }
  return null;
};

interface UseAccessTokenReturn {
  accessToken: AccessTokenOrNull;
  setAccessTokenFromString: (payload: string) => void;
  clearAccessToken: () => void;
}

export const useAccessToken = (): UseAccessTokenReturn => {
  const [cookies, setCookie, removeCookie] = useCookies<
    string,
    Record<string, string | null | undefined>
  >([ACCESS_TOKEN_COOKIE_NAME]);

  const encodedAccessToken = cookies?.[ACCESS_TOKEN_COOKIE_NAME];

  const clearAccessToken = useCallback(
    () => removeCookie(ACCESS_TOKEN_COOKIE_NAME, { ...COOKIES_DEFAULT_OPTIONS }),
    [removeCookie]
  );

  const accessToken = useMemo(() => {
    if (!encodedAccessToken) {
      return null;
    }

    const decodedAccessToken = decodeToken(encodedAccessToken);
    if (decodedAccessToken && decodedAccessToken.exp * 1000 > Date.now()) {
      return decodedAccessToken;
    }

    clearAccessToken();
    return null;
  }, [encodedAccessToken, clearAccessToken]);

  const setAccessTokenFromString = useCallback(
    (payload: string) => {
      const decodedToken = decodeToken(payload);
      if (!decodedToken) {
        clearAccessToken();
        throw new Error('Invalid access token');
      }

      const expires = new Date(decodedToken.exp * 1000);
      setCookie(ACCESS_TOKEN_COOKIE_NAME, payload, {
        ...COOKIES_DEFAULT_OPTIONS,
        expires,
      });
    },
    [setCookie, clearAccessToken]
  );

  return {
    accessToken,
    setAccessTokenFromString,
    clearAccessToken,
  };
};
