import type { DragEndEvent } from '@dnd-kit/core';
import { useDroppable, useDraggable, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { isEqual, isNil, isNotNil } from '@topo-io/utils';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';
import type { SectionTemplateItem } from '@/components/workspace/overview/template-library-item';

export type DraggingData = { sectionTemplate: SectionTemplateItem } | { section: SectionJSON };

const BLANK_SECTION_ID = 'blank-section';

export const useDragSectionTemplate = ({ id, data }: { id: string; data: DraggingData }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
    data,
  });

  return {
    attributes,
    listeners,
    setNodeRef,
  };
};

export const useDropSectionTemplate = ({
  id,
  data,
}: {
  id: string | number;
  data?: Record<string, unknown>;
}) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data,
  });

  return {
    isOver,
    setNodeRef,
  };
};

export const useDragAndDropSectionTemplateContext = () => {
  const { tabId } = useWorkspaceTabs();
  const { addSection, addSectionTemplateAtIndex, moveSectionFromDnD } = useContent(tabId);

  const handleSectionTemplateDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (isNil(active?.data?.current) || isNil(over?.data?.current)) {
      return;
    }
    const sectionTemplate: SectionTemplateItem = active?.data?.current.sectionTemplate;
    const atIndex: number = over?.data?.current.atIndex;
    if (sectionTemplate && isNotNil(atIndex) && !isEqual(sectionTemplate.id, BLANK_SECTION_ID)) {
      addSectionTemplateAtIndex(sectionTemplate, atIndex);
    }
    if (sectionTemplate && isNotNil(atIndex) && isEqual(sectionTemplate.id, BLANK_SECTION_ID)) {
      addSection(atIndex);
    }
  };

  const handleSectionDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (isNil(active?.data?.current) || isNil(over?.data?.current)) {
      return;
    }
    const section: SectionJSON = active?.data?.current.section;
    const atIndex: number = over?.data?.current.atIndex;
    const currentIndex: number = active?.data?.current.index;

    if (currentIndex === atIndex) {
      return;
    }

    const direction: 'up' | 'down' = currentIndex > atIndex ? 'up' : 'down';
    if (section && isNotNil(atIndex)) {
      moveSectionFromDnD({
        sectionId: section.id,
        index: atIndex,
        direction: direction,
      });
    }
  };

  // Add sensor to be able to click on Draggable
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 20 },
    })
  );

  return {
    sensors,
    handleSectionTemplateDragEnd,
    handleSectionDragEnd,
  };
};
