import { t } from '@lingui/macro';
import { useCallback, useMemo } from 'react';
import type { ItemType } from '@topo-io/design-system';
import { Box, ComboboxItem } from '@topo-io/design-system';
import type { UserProfileObject } from '@topo-io/graphql';
import { useTemplatesQuery } from '@topo-io/graphql';
import { contains, getDisplayName, getFirstNthElements, partition } from '@topo-io/utils';
import { useUser } from '@/hooks';
import type { ComboboxFieldProps } from './combobox-field';
import { ComboboxField } from './combobox-field';

const SUGGESTED_ITEMS_COUNT = 5;

type TemplateFieldProps = Omit<ComboboxFieldProps, 'render' | 'options'>;
interface TemplateSuggestion extends ItemType {
  owner: string;
  ownerId: string;
}

const TemplateFieldSuggestionItem = ({ items }: { items: TemplateSuggestion[] }) =>
  items.map((item) => (
    <ComboboxItem key={item.label} item={item}>
      <Box fontWeight="400" flex="1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        {item.label} ({item.owner})
      </Box>
    </ComboboxItem>
  ));

const favorUserTemplate = (templates: TemplateSuggestion[], userId?: string) => {
  const [ownTemplates, teamTemplates] = partition(templates, (t) => t.ownerId === userId);
  return getFirstNthElements([...ownTemplates, ...teamTemplates], SUGGESTED_ITEMS_COUNT);
};

export const TemplateField = (props: TemplateFieldProps) => {
  const { user } = useUser();
  const { data } = useTemplatesQuery();

  const suggestedTemplates: TemplateSuggestion[] = useMemo(
    () =>
      data?.templates.map((template) => ({
        id: template.id,
        label: template.title,
        value: template.id,
        key: template.id,
        owner: getDisplayName(
          { ...template.owner, isFromTopo: template.isFromTopo },
          template.owner.user.email
        ),
        ownerId: template.owner.id,
      })) ?? [],
    [data?.templates]
  );

  const optionsFn = useCallback(
    async (value: string | undefined): Promise<ItemType[]> => {
      const userAsUserProfileObject = user as UserProfileObject;
      if (!value) {
        const options = favorUserTemplate(suggestedTemplates, userAsUserProfileObject?.id);
        return Promise.resolve(options);
      }

      const matchingTemplates = suggestedTemplates?.filter((template) =>
        contains(template.label, value, { mode: 'insensitive' })
      );

      const options = favorUserTemplate(matchingTemplates, userAsUserProfileObject?.id);
      return Promise.resolve(options);
    },
    [suggestedTemplates, user]
  );

  return (
    <ComboboxField
      placeholder={t`e.g Templates Sales`}
      showNewItem={false}
      options={optionsFn}
      render={TemplateFieldSuggestionItem}
      {...props}
    />
  );
};
