import { useCallback, useEffect, useState } from 'react';
import { useIsPageActive } from '@topo-io/hooks';
import { ANALYTICS_INACTIVITY_TIME, ANALYTICS_PING_INTERVAL } from '@/config';

interface Args {
  skip?: boolean;
}

export const useAnalyticsPingInterval = ({ skip = false }: Args) => {
  const [isActivityTrackingEnabled, setActivityTrackingEnabled] = useState(false);

  const isPageActive = useIsPageActive({
    maxInactiveTime: ANALYTICS_INACTIVITY_TIME,
    skip,
  });

  const handleAfterPing = useCallback(() => {
    setActivityTrackingEnabled(false);
  }, []);

  /*
  We use a state variable to track whether we should track activity
  Its better than pass a callback to call directly, because the callback reference
  can change and trigger a new useEffect call, that can break the interval logic
  */
  useEffect(() => {
    if (skip) {
      return;
    }
    const ping = () => {
      setActivityTrackingEnabled(isPageActive);
    };
    const intervalId = setInterval(ping, ANALYTICS_PING_INTERVAL);
    return () => {
      setActivityTrackingEnabled(false);
      clearInterval(intervalId);
    };
  }, [isPageActive, skip]);

  return { isActivityTrackingEnabled, handleAfterPing };
};
