import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { TemplatesDocument, useDuplicateTemplateMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import type { DuplicateTemplateFormValues } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { workspaceTemplateUrl } from '@/config';

export const useSubmitDuplicateWorkspaceTemplate = (props?: { shouldRefetchQueries?: boolean }) => {
  const router = useRouter();
  const [duplicateTemplate] = useDuplicateTemplateMutation();

  const shouldRefetchQueries = Boolean(props?.shouldRefetchQueries);

  const onSubmit = useCallback(
    async (values: DuplicateTemplateFormValues) => {
      const { data: template } = await duplicateTemplate({
        variables: { data: values },
        refetchQueries: shouldRefetchQueries ? [TemplatesDocument] : undefined,
      });
      const id = template?.duplicateTemplate?.id;
      if (isNil(id)) {
        return;
      }
      await router.push(workspaceTemplateUrl(id));
    },
    [duplicateTemplate, shouldRefetchQueries, router]
  );

  return onSubmit;
};
