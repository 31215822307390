import type { FC } from 'react';
import { useCallback } from 'react';
import { Box, Button, SimpleModal, Spacer, useToast } from '@topo-io/design-system';
import { useAssociateCRMToWorkspaceMutation } from '@topo-io/graphql';
import type { WorkspaceDetailsFormValues } from '@/components/workspace/details/form-values.types';

interface ConfirmUpdateWorkspaceOpportunityModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: WorkspaceDetailsFormValues) => Promise<void>;
  formValues: WorkspaceDetailsFormValues;
  workspaceId: string;
}

export const ConfirmUpdateWorkspaceOpportunityModal: FC<
  ConfirmUpdateWorkspaceOpportunityModalProps
> = ({ workspaceId, formValues, onSuccess, onClose, isOpen }) => {
  const { errorToast } = useToast();
  const [associateCRMToWorkspace] = useAssociateCRMToWorkspaceMutation();

  const onClickConfirm = useCallback(async () => {
    const CRMAccountId = formValues.CRMAccount?.id;
    const CRMOpportunityId = formValues.opportunity?.id;
    const { errors } = await associateCRMToWorkspace({
      variables: {
        input: { workspaceId, CRMAccountId, CRMOpportunityId },
      },
    });
    if (errors) {
      errorToast({ title: `Something went wrong: ${errors[0]?.message}` });
    } else {
      await onSuccess(formValues);
    }
    onClose();
  }, [associateCRMToWorkspace, errorToast, formValues, onClose, onSuccess, workspaceId]);

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title="Change CRM opportunity"
      footer={
        <Box display="flex" columnGap={'3'}>
          <Button colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onClickConfirm}>
            Save
          </Button>
        </Box>
      }
    >
      By selecting this opportunity, the amount, close date and stage will be synchronized with the
      new opportunity.
      <Spacer h="8" />
      Are you sure?
    </SimpleModal>
  );
};
