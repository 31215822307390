import type { BinaryOperator, Filter } from '@cubejs-client/core';
import { useMemo } from 'react';
import { CUBE_CONSTANTS } from '@topo-io/constants';
import type {
  EngagementScoreDateRange,
  WorkspaceScoreWithCompany,
} from '@/components/engagement-score/types';
import { mapDateRangeToDates } from '@/components/engagement-score/utils';
import { useAnalyticsQuery } from '@/hooks';

interface UseRankedScoreWorkspacesArgs {
  organizationId: string;
  userProfileId: string;
  isUserManager?: boolean;
  shouldSkip?: boolean;
  dateRange: EngagementScoreDateRange;
  order: 'asc' | 'desc';
}

export interface RankedByScoreRawData {
  [CUBE_CONSTANTS.WORKSPACE_TITLE]: string;
  [CUBE_CONSTANTS.COMPANY_NAME]: string;
  [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_WORKSPACE_ID]: string;
  [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_AT_DATE_ONE]: string;
  [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_ONE_FILTER]: string;
  [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_AT_DATE_TWO]: string;
  [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_TWO_FILTER]: string;
  [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_DIFF]: string;
}

interface UseRankedScoreWorkspacesReturnValue {
  data: WorkspaceScoreWithCompany[];
  isLoading: boolean;
}

const MAX_RESULTS = 10;

export const useRankedScoreWorkspaces = ({
  organizationId,
  userProfileId,
  shouldSkip,
  order,
  dateRange,
  isUserManager,
}: UseRankedScoreWorkspacesArgs): UseRankedScoreWorkspacesReturnValue => {
  const [startDate, endDate] = mapDateRangeToDates(dateRange).currentRange;
  const ORGANIZATION_FILTER: Filter = useMemo(
    () => ({
      member: 'workspace.organizationid',
      operator: 'equals' as BinaryOperator,
      values: [organizationId ?? ''],
    }),
    [organizationId]
  );

  const OWNER_FILTER: Filter = useMemo(
    () => ({
      member: 'workspace.ownerid',
      operator: 'equals' as BinaryOperator,
      values: [userProfileId ?? ''],
    }),
    [userProfileId]
  );

  const { tableData, isLoading } = useAnalyticsQuery<RankedByScoreRawData[]>({
    query: {
      measures: [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_DIFF],
      dimensions: [
        CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_ONE_FILTER,
        CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_TWO_FILTER,
        CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_WORKSPACE_ID,
        CUBE_CONSTANTS.WORKSPACE_TITLE,
        CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_AT_DATE_ONE,
        CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_AT_DATE_TWO,
        CUBE_CONSTANTS.COMPANY_NAME,
      ],
      order: {
        [CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_DIFF]: order,
      },
      filters: [
        ORGANIZATION_FILTER,
        {
          member: CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_ONE_FILTER,
          operator: 'equals',
          values: [startDate],
        },
        {
          member: CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_TWO_FILTER,
          operator: 'equals',
          values: [endDate],
        },
        ...(!isUserManager ? [OWNER_FILTER] : []),
      ],
      limit: MAX_RESULTS,
    },
    options: {
      skip: shouldSkip,
    },
  });

  const data = tableData.map((row) => ({
    workspaceId: row[CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_WORKSPACE_ID],
    workspaceTitle: row[CUBE_CONSTANTS.WORKSPACE_TITLE],
    score: Math.trunc(
      Number(row[CUBE_CONSTANTS.WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_AT_DATE_TWO])
    ),
    company: {
      name: row[CUBE_CONSTANTS.COMPANY_NAME],
    },
  }));

  return {
    data,
    isLoading,
  };
};
