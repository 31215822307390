import { Trans } from '@lingui/macro';
import type { FC, Ref } from 'react';
import { useState, createRef, useEffect, useRef } from 'react';
import { Box, H5, Text } from '@topo-io/design-system';
import type { TaskQuery } from '@topo-io/graphql';
import { getLastPosition, isEmptyArray, isNil, isNotNil } from '@topo-io/utils';
import { AddTask } from '@/components/roadmap/add-task';
import { useRoadmapContext } from '@/components/roadmap/hooks';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { TaskSubtask } from './task-subtask';

interface TaskSubtasksProps {
  task: TaskQuery['task'];
}

export const TaskSubtaskList: FC<TaskSubtasksProps> = ({ task }) => {
  const { isGuest } = useWorkspaceContext();
  const { canEditRoadmap } = useRoadmapContext();
  const subtaskInputRefs = useRef<Record<string, Ref<HTMLInputElement>>>();
  const { createSubtask } = useTaskContext();
  const [latestSubtaskId, setLatestSubtaskId] = useState<string | null>();
  const { id, subtasksCount } = task;

  const addSubtask = () =>
    createSubtask({ parentTaskId: id, position: getLastPosition(task.subtasks) });

  // Re-use this logic when updating the Roadmap steps / tasks
  useEffect(() => {
    let newSubtaskId: string | null = null;
    for (const subtask of task.subtasks) {
      if (isNil(subtaskInputRefs[subtask.id])) {
        subtaskInputRefs[subtask.id] = createRef();
        newSubtaskId = subtask.id;
      }
    }

    if (isNotNil(newSubtaskId)) {
      setLatestSubtaskId(newSubtaskId);
    }
  }, [task.subtasks]);

  useEffect(() => {
    if (isNotNil(latestSubtaskId) && isNotNil(subtaskInputRefs[latestSubtaskId]?.current)) {
      subtaskInputRefs[latestSubtaskId].current.focus();
      setLatestSubtaskId(null);
    }
  }, [latestSubtaskId]);

  return (
    <Box my={6}>
      <H5 mb={2}>
        <Trans>Subtasks ({subtasksCount})</Trans>
      </H5>
      {isGuest && isEmptyArray(task.subtasks) && (
        <Text color="gray.100">
          <Trans>No subtasks.</Trans>
        </Text>
      )}

      {task.subtasks.map((subtask) => (
        <TaskSubtask
          key={subtask.id}
          subtask={subtask}
          inputRef={subtaskInputRefs[subtask.id]}
          onCreate={addSubtask}
        />
      ))}

      {canEditRoadmap && (
        <AddTask addTask={addSubtask}>
          <Trans>Add subtask</Trans>
        </AddTask>
      )}
    </Box>
  );
};
