import { useFlags } from 'launchdarkly-react-client-sdk';
import type { ReactNode } from 'react';
import { FullPage } from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';
import { MaintenanceMode } from '@/components/common/maintenance-mode';
import { NeedSlug } from '@/components/common/need-slug';
import { useLoginRedirect, useSubscriptionRedirect } from '@/components/routing/hooks';
import { useIsMainSlug } from '@/hooks';

interface BaseLayoutProps {
  children: ReactNode;
  skipNeedSlug?: boolean;
}

export const BaseLayout = ({ children, skipNeedSlug = false }: BaseLayoutProps) => {
  useLoginRedirect();
  useSubscriptionRedirect();
  const { maintenanceMode, selfService } = useFlags();
  const { isMainSlug } = useIsMainSlug();

  if (maintenanceMode) {
    return (
      <FullPage>
        <MaintenanceMode />
      </FullPage>
    );
  }

  const isFlagLoading = isNil(selfService);
  if (isFlagLoading) {
    return null;
  }

  if (selfService) {
    return <>{children}</>;
  }

  if (isMainSlug && !skipNeedSlug) {
    return (
      <FullPage>
        <NeedSlug />
      </FullPage>
    );
  }

  return <>{children}</>;
};
