import { Trans, t } from '@lingui/macro';
import { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, Flex, Input, Text } from '@topo-io/design-system';
import { isNil, isNotNil } from '@topo-io/utils';
import { useWorkspaceFiltersContext } from '@/components/workspace/list/filters/workspace-filters-context';
import { useSearchFilters } from '@/hooks';

interface NumberRangeFilterProps {
  stateKey: 'value';
  title: string;
}

export const NumberRangeFilter: FC<NumberRangeFilterProps> = ({ stateKey, title }) => {
  const { updateFilter, updateOperator } = useSearchFilters();
  const { addFilter, filtersState, updateSelectedFilter } = useWorkspaceFiltersContext();
  const [minimum, setMinimum] = useState<number | null>(filtersState?.[stateKey]?.gte ?? null);
  const [maximum, setMaximum] = useState<number | null>(filtersState?.[stateKey]?.lte ?? null);

  const handleApplyFilter = () => {
    addFilter(stateKey, {
      ...(minimum && { gte: minimum }),
      ...(maximum && { lte: maximum }),
    });
    updateOperator({
      key: `${stateKey}Operator`,
      operator: 'AND',
      enabled: isNotNil(minimum) || isNotNil(maximum),
    });
    updateFilter({
      attribute: stateKey,
      value: minimum,
      operator: 'gte',
      parentNodeKey: `${stateKey}Operator`,
      enabled: isNotNil(minimum),
    });
    updateFilter({
      attribute: stateKey,
      value: maximum,
      operator: 'lte',
      parentNodeKey: `${stateKey}Operator`,
      enabled: isNotNil(maximum),
    });
    updateSelectedFilter(null);
  };

  return (
    <Box padding="4" w="350px">
      <Text>{title}</Text>
      <Flex mt="4" alignItems="center" gap="5px">
        <Input
          type="number"
          placeholder={t`Minimum`}
          defaultValue={minimum ?? ''}
          onChange={(event) => setMinimum(Number(event.target.value))}
        />
        <Input
          type="number"
          placeholder={t`Maximum`}
          defaultValue={maximum ?? ''}
          onChange={(event) => setMaximum(Number(event.target.value))}
        />
      </Flex>
      <Flex mt="4" alignItems="center" gap="10px" justifyContent="flex-end">
        <Button colorScheme="gray" onClick={() => updateSelectedFilter(null)}>
          <Trans>Cancel</Trans>
        </Button>
        <Button isDisabled={isNil(minimum) && isNil(maximum)} onClick={handleApplyFilter}>
          <Trans>Apply</Trans>
        </Button>
      </Flex>
    </Box>
  );
};
