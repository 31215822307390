import type { FC } from 'react';
import { Box, Container, Grid } from '@topo-io/design-system';
import type { SectionTemplate } from '@topo-io/graphql';
import { SECTION_TEMPLATE, useUpdateSectionTemplateMutation } from '@topo-io/graphql';
import type { ContentJSON } from '@topo-io/rich-text-editor';
import { SectionSimpleEditorMode } from '@/components/section/section-simple-editor-mode';
import { NAVBAR_HEIGHT } from '@/config';
import { EditSectionTemplateBanner } from './edit-section-template-banner';

export const EditSectionTemplate: FC<{
  sectionTemplate: Pick<SectionTemplate, 'id' | 'title' | 'content' | 'sectionContentId'>;
  isOwner: boolean;
}> = ({ sectionTemplate, isOwner }) => {
  const {
    id,
    title: defaultTitle,
    content: defaultContent,
    sectionContentId,
  } = sectionTemplate ?? {};

  const [updateSectionTemplate] = useUpdateSectionTemplateMutation({
    refetchQueries: [SECTION_TEMPLATE],
  });

  const updateSectionTitle = (input: { title: string }): void => {
    void updateSectionTemplate({ variables: { input: { id, title: input.title } } });
  };
  const updateSectionContent = (input: { sectionContent: ContentJSON }): void => {
    void updateSectionTemplate({ variables: { input: { id, content: [input.sectionContent] } } });
  };

  return (
    <Box overflowY="auto">
      <Container maxW="container.lg" h={`calc(100vh - ${NAVBAR_HEIGHT} - 16px)`}>
        <Grid templateRows="50px min-content" py="4">
          <EditSectionTemplateBanner isOwner={isOwner} />
          <SectionSimpleEditorMode
            key={sectionTemplate?.id}
            id={sectionTemplate?.id}
            sectionTitle={defaultTitle ?? ''}
            sectionContent={defaultContent?.[0]}
            sectionContentId={sectionContentId}
            updateSectionTitle={updateSectionTitle}
            updateSectionContent={updateSectionContent}
            isOwner={isOwner}
          />
        </Grid>
      </Container>
    </Box>
  );
};
