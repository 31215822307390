import type { FC } from 'react';
import type { TemplateQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { DetailsLogo } from './details-logo';

interface TemplateLogoProps {
  template?: TemplateQuery['template'];
}

export const TemplateLogo: FC<TemplateLogoProps> = ({ template }) => {
  if (isNil(template)) {
    return <></>;
  }
  const { company } = template.owner;

  if (isNil(company)) {
    return <></>;
  }

  return <DetailsLogo companyName={company.name} companyLogoUrl={company.logoUrl} />;
};
