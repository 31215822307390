import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import type { CreateWorkspaceTabInput, UpdateWorkspaceTabInput } from '@topo-io/graphql';
import {
  TabsDocument,
  WorkspaceTabFeatureName,
  useCreateWorkspaceTabMutation,
} from '@topo-io/graphql';
import { isEmptyArray, isNil } from '@topo-io/utils';
import { useUpdateWorkspaceTabWithCache } from '@/components/workspace/hooks/use-update-workspace-tab-with-cache';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useWorkspaceTabsContext } from '@/components/workspace/hooks/use-workspace-tabs-context';
import type {
  WorkspaceCurrentTab,
  WorkspaceTab,
  TabType,
} from '@/components/workspace/tabs/workspace-tab-type';
import {
  WORKSPACE_DEFAULT_TAB,
  WORKSPACE_RESERVED_SLUG_TO_TAB,
  WorkspaceTabNonEditableEnum,
  isReservedSlug,
  isTabFromDB,
} from '@/components/workspace/tabs/workspace-tab-type';
import { workspaceTabUrl } from '@/components/workspace/tabs/workspace-tab-url';

export const useWorkspaceTabs = () => {
  const { id, isWorkspace, isPreview, isGuest, readOnly } = useWorkspaceContext();
  const { workspaceTabs, queryTab, loading } = useWorkspaceTabsContext();
  const [createWorkspaceTabMutation] = useCreateWorkspaceTabMutation({
    refetchQueries: [TabsDocument],
  });
  const { updateWorkspaceTabWithCache } = useUpdateWorkspaceTabWithCache();
  const router = useRouter();

  const currentTab = useMemo<WorkspaceCurrentTab>(() => {
    if (loading || !queryTab) {
      return WORKSPACE_DEFAULT_TAB;
    }

    const workspaceTab = workspaceTabs.find((tab) => tab.slug === queryTab);
    if (workspaceTab) {
      return workspaceTab;
    }

    if (isReservedSlug(queryTab)) {
      return WORKSPACE_RESERVED_SLUG_TO_TAB[queryTab];
    }

    return WORKSPACE_DEFAULT_TAB;
  }, [workspaceTabs, queryTab, loading]);

  const tabId = isTabFromDB(currentTab) ? currentTab.id : undefined;

  const onChangeTab = useCallback(
    (slug: string): void => {
      if (isNil(id)) {
        return;
      }
      const pathname = workspaceTabUrl(id, {
        isWorkspace,
        isPreview,
        isGuest,
        tabSlug: slug,
      });
      void router.replace({ pathname });
    },
    [router, id, isGuest, isPreview, isWorkspace]
  );

  const isTabShown = useCallback(
    (tab: { type: TabType; isDisplayed?: boolean }) => {
      if (!tab.isDisplayed && readOnly) {
        return false;
      }

      switch (tab.type) {
        case WorkspaceTabFeatureName.CONTENT:
          return true;
        case WorkspaceTabFeatureName.MUTUAL_ACTION_PLAN:
          return true;
        case WorkspaceTabFeatureName.MEMBERS:
          return true;
        case WorkspaceTabNonEditableEnum.ANALYTICS:
          return !readOnly && isWorkspace;
        case WorkspaceTabNonEditableEnum.DETAILS:
          return !readOnly;
        default:
          return false;
      }
    },
    [isWorkspace, readOnly]
  );

  const createTab = useCallback(
    async (input: CreateWorkspaceTabInput) => {
      await createWorkspaceTabMutation({
        variables: {
          input,
        },
      });
    },
    [createWorkspaceTabMutation]
  );

  const updateTab = useCallback(
    ({ tabId, input }: { tabId: string; input: UpdateWorkspaceTabInput }) => {
      const workspaceTab = workspaceTabs.find((tab) => tab.id === tabId);
      if (isNil(workspaceTab)) {
        return;
      }
      void updateWorkspaceTabWithCache({
        input,
        workspaceTab,
        workspaceTabs,
      });
    },
    [updateWorkspaceTabWithCache, workspaceTabs]
  );

  const getActiveTab = useCallback(
    ({ queryTab }: { queryTab?: string }) => {
      return workspaceTabs.find((tab) => tab.slug === queryTab);
    },
    [workspaceTabs]
  );

  const getFirstVisibleWorkspaceTabByType = useCallback(
    ({
      type,
      displayedTabsOnly = false,
    }: {
      type: TabType;
      displayedTabsOnly?: boolean;
    }): WorkspaceTab | undefined => {
      let filteredArray: WorkspaceTab[];

      if (displayedTabsOnly) {
        filteredArray = workspaceTabs.filter(
          (workspaceTab) => workspaceTab.type === type && workspaceTab.isDisplayed === true
        );
      } else {
        filteredArray = workspaceTabs.filter((workspaceTab) => workspaceTab.type === type);
      }

      if (isEmptyArray(filteredArray)) {
        return undefined;
      }

      return [...filteredArray].sort((a, b) => a.position - b.position)[0];
    },
    [workspaceTabs]
  );

  return {
    tabId,
    queryTab,
    tab: currentTab,
    onChangeTab,
    getActiveTab,
    isTabShown,
    workspaceTabs,
    createTab,
    updateTab,
    getFirstVisibleWorkspaceTabByType,
  };
};
