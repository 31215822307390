import { useMemo } from 'react';
import { SIDEBAR_BREAKPOINT, SIDEBAR_WIDTH, SIDEBAR_WIDTH_EXPANDED } from '@/config';
import { useDevice } from '@/hooks';

export const useSidebarWidth = () => {
  const { isDesktop, windowWidth } = useDevice();

  const show = useMemo(() => isDesktop, [isDesktop]);
  const isExpanded = useMemo(
    () => (windowWidth ? windowWidth >= SIDEBAR_BREAKPOINT : true),
    [windowWidth]
  );

  const sidebarWidth = useMemo(() => {
    if (show && isExpanded) {
      return SIDEBAR_WIDTH_EXPANDED;
    }
    if (show && !isExpanded) {
      return SIDEBAR_WIDTH;
    }
    return '0';
  }, [show, isExpanded]);

  return {
    sidebarWidth,
    isExpanded,
    show,
  };
};
