import { Trans } from '@lingui/macro';
import { Box, Flex, Icon, Text } from '@topo-io/design-system';

export const SectionsSummaryEmptyState = () => {
  return (
    <Box w="full" h="full">
      <Flex w="full" justifyContent="flex-end" paddingRight="40px" mb="2">
        <Icon icon="CornerRightUp" w="24px" h="24px" color="primary.500" />
      </Flex>
      <Text color="gray.400" textAlign="center">
        <Trans>No sections were added yet. You can add some in the builder</Trans>
      </Text>
    </Box>
  );
};
