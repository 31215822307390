import type { FC } from 'react';
import { Box } from '@topo-io/design-system';
import { MostViewedSectionsGraph } from './graph';
import { MOST_VIEWED_SECTION_FAKE_DATA, MOST_VIEWED_SECTION_BASE_HEIGHT } from './utils';

const { data, keys, guestsMapping, sectionsMapping } = MOST_VIEWED_SECTION_FAKE_DATA;

export const MostViewedSectionsEmptyState: FC = () => {
  return (
    <Box
      opacity="0.4"
      pointerEvents="none"
      filter="grayscale(1)"
      h={`${MOST_VIEWED_SECTION_BASE_HEIGHT}px`}
    >
      <MostViewedSectionsGraph
        data={data}
        keys={keys}
        sectionsMapping={sectionsMapping}
        guestsMapping={guestsMapping}
      />
    </Box>
  );
};
