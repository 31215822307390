import React, { useState } from 'react';

export const enum Action {
  CREATE_SECTION_TEMPLATE = 'CREATE_SECTION_TEMPLATE',
  CREATE_WORKSPACE_TEMPLATE = 'CREATE_WORKSPACE_TEMPLATE',
}

interface ActionContextType {
  action: string;
  setAction: (action: string) => void;
}

export const ActionContext = React.createContext<ActionContextType>({
  action: '',
  setAction: () => {
    return '';
  },
});

export const ActionProvider = ({ children }: { children: JSX.Element }) => {
  const [action, setAction] = useState<string>('');

  return <ActionContext.Provider value={{ action, setAction }}>{children}</ActionContext.Provider>;
};

export const useActionContext = () => React.useContext(ActionContext);
