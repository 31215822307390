import { t } from '@lingui/macro';
import { useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Icon, Text } from '@topo-io/design-system';
import { UserRole, useUserProfilesForRoleQuery } from '@topo-io/graphql';
import { formatDate, getDisplayName, isNil, isNotNil } from '@topo-io/utils';
import { useSearchFilters, useUser } from '@/hooks';
import { useWorkspaceFiltersContext } from './workspace-filters-context';

const concatBothValues = (valueOne: string | undefined, valueTwo: string | undefined) => {
  if (valueOne && valueTwo) {
    return `${valueOne} - ${valueTwo}`;
  }
  return valueOne || valueTwo || '';
};

const formatDateString = (date: string | undefined) => {
  if (date) {
    return formatDate(date, 'dd/MM/yyyy');
  }
  return '';
};

interface AppliedFilterBoxProps {
  title: string;
  icon: string;
  value: string;
  handleRemove: () => void;
}

const AppliedFilterBox: FC<AppliedFilterBoxProps> = ({ title, icon, value, handleRemove }) => {
  return (
    <Flex
      bgColor="blue.50"
      padding="3"
      borderWidth="1px"
      borderColor="blue.100"
      borderRadius="8px"
      alignItems="center"
      gap="2"
    >
      <Icon icon={icon} w="16px" h="16px" color="blue.400" />
      <Text color="blue.500" fontWeight="500">
        {title}:
      </Text>
      <Text color="blue.400" fontWeight="500">
        {value}
      </Text>
      <Icon
        cursor="pointer"
        icon="XClose"
        w="16px"
        h="16px"
        color="blue.900"
        onClick={handleRemove}
      />
    </Flex>
  );
};

export const WorkspaceAppliedFilters = () => {
  const { filtersState, removeFilter } = useWorkspaceFiltersContext();
  const { updateFilter, updateOperator } = useSearchFilters();
  const { user } = useUser();

  const { data } = useUserProfilesForRoleQuery({
    variables: {
      role: UserRole.EDITOR,
      organizationId: user?.organizationId,
    },
    skip: isNil(user?.organizationId),
    fetchPolicy: 'cache-first',
  });

  const editorMap = useMemo(() => {
    return data?.userProfilesForRole.reduce((acc, editor) => {
      acc[editor.id] = editor;
      return acc;
    }, {});
  }, [data]);

  return (
    <Flex gap="2" alignItems="center" flexWrap="wrap" marginY="2">
      {isNotNil(filtersState?.closeDate) && (
        <AppliedFilterBox
          title={t`Close date`}
          icon="CalendarMinus"
          value={concatBothValues(
            formatDateString(filtersState.closeDate.gte),
            formatDateString(filtersState.closeDate.lte)
          )}
          handleRemove={() => {
            removeFilter('closeDate');
            updateOperator({
              key: 'closeDateOperator',
              operator: 'AND',
              enabled: false,
            });
            updateFilter({
              attribute: 'closeDate',
              value: undefined,
              operator: 'gte',
              parentNodeKey: 'closeDateOperator',
              enabled: false,
            });
            updateFilter({
              attribute: 'closeDate',
              value: undefined,
              operator: 'lte',
              parentNodeKey: 'closeDateOperator',
              enabled: false,
            });
          }}
        />
      )}
      {isNotNil(filtersState?.ownerId) && (
        <AppliedFilterBox
          title={t`Owner`}
          icon="User"
          value={filtersState.ownerId.in
            .map((id) =>
              editorMap?.[id] ? getDisplayName(editorMap[id], editorMap[id].user.email) : ''
            )
            .join(', ')}
          handleRemove={() => {
            removeFilter('ownerId');
            updateOperator({
              key: 'owner',
              operator: 'OR',
              enabled: false,
            });
            updateFilter({
              attribute: 'ownerId',
              value: [],
              operator: 'in',
              parentNodeKey: 'owner',
              enabled: false,
            });
          }}
        />
      )}
      {isNotNil(filtersState?.stage) && (
        <AppliedFilterBox
          title={t`Stage`}
          icon="Stage"
          value={filtersState.stage.in.join(', ')}
          handleRemove={() => {
            removeFilter('stage');
            updateOperator({
              key: 'stage',
              operator: 'OR',
              enabled: false,
            });
            updateFilter({
              attribute: 'stage',
              value: [],
              operator: 'in',
              parentNodeKey: 'stage',
              enabled: false,
            });
          }}
        />
      )}
      {isNotNil(filtersState?.value) && (
        <AppliedFilterBox
          title={t`Value`}
          icon="Dollar"
          value={concatBothValues(
            filtersState.value.gte?.toString(),
            filtersState.value.lte?.toString()
          )}
          handleRemove={() => {
            removeFilter('value');
            updateOperator({
              key: 'valueOperator',
              operator: 'AND',
              enabled: false,
            });
            updateFilter({
              attribute: 'value',
              value: undefined,
              operator: 'gte',
              parentNodeKey: 'valueOperator',
              enabled: false,
            });
            updateFilter({
              attribute: 'value',
              value: undefined,
              operator: 'lte',
              parentNodeKey: 'valueOperator',
              enabled: false,
            });
          }}
        />
      )}
    </Flex>
  );
};
