import { t } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useState } from 'react';
import type { ChakraComponent } from '@topo-io/design-system';
import { Box, Flex, Spacer } from '@topo-io/design-system';
import { useCountUserLegacyActivitiesQuery } from '@topo-io/graphql';
import { isNotNil } from '@topo-io/utils';
import { useSidebarWidth } from '@/components/layout/hooks';
import { signInUrl, all, profileSettingsUrl, tagsSettingsUrl } from '@/config';
import { useAuth } from '@/hooks';
import { useSubscription } from '@/hooks/use-subscription';
import { SidebarItem } from './sidebar-item';
import { SidebarLogo } from './sidebar-logo';
import { SidebarRow } from './sidebar-row';
import { SidebarUser } from './sidebar-user';

const { CUSTOMER_FEEDBACK_URL } = all;

const SidebarContainer: ChakraComponent<'div'> = ({ children, w, display }) => (
  <Box as="nav" w={w} overflowX="hidden" overflowY="auto" display={display} bg="sidebar.bg.color">
    {children}
  </Box>
);

const SidebarColumn: ChakraComponent<'div'> = ({ children, p = '4' }) => (
  <Flex
    direction="column"
    minH="100vh"
    as="nav"
    justifyContent="flex-start"
    alignItems="start"
    color="text.light.color"
    aria-label="Main Navigation"
    pt="32px"
    pb="4"
    p={p}
    overflowY="hidden"
  >
    {children}
  </Flex>
);

export const SidebarContent: ChakraComponent<'div', { referralOpen: () => void }> = ({
  display,
  referralOpen,
  ...props
}) => {
  const { signOut } = useAuth();
  const { engagementScore } = useFlags();
  const router = useRouter();
  const [isSettingsOpen, setIsSettingsOpen] = useState(router.pathname.startsWith('/settings'));
  const { remainingTrialDays } = useSubscription();

  const { data: dataCountUnseenActivities } = useCountUserLegacyActivitiesQuery({
    variables: { filters: { unseen: true } },
  });
  const unseenNotifications = dataCountUnseenActivities?.legacyActivities.count ?? 0;
  const { sidebarWidth, isExpanded } = useSidebarWidth();

  const routeIsActive = (path: string) => router.pathname.startsWith(path);

  return (
    <SidebarContainer w={sidebarWidth} display={display} {...props}>
      <SidebarColumn>
        <SidebarLogo expanded={isExpanded} />
        <SidebarRow h="48px" />
        {/* TODO */}
        {/* <SidebarItem icon="search" label="Search" url="#" /> */}
        {engagementScore ? (
          <>
            <SidebarItem
              icon="HomeLine"
              label={t`Home`}
              url="/home"
              active={routeIsActive('/home')}
              expanded={isExpanded}
            />
            <SidebarItem
              icon={'Tv01'}
              label={t`Workspaces`}
              url="/workspaces"
              active={routeIsActive('/workspaces')}
              expanded={isExpanded}
            />
          </>
        ) : (
          <SidebarItem
            icon="HomeLine"
            label={t`Workspaces`}
            url="/workspaces"
            active={routeIsActive('/workspaces')}
            expanded={isExpanded}
          />
        )}

        <SidebarItem
          expanded={isExpanded}
          icon="ThreeLayers"
          label={t`Workspace templates`}
          url="/templates"
          active={routeIsActive('/templates')}
        />
        <SidebarItem
          expanded={isExpanded}
          icon="OneLayer"
          label={t`Section templates`}
          url="/section-templates"
          active={routeIsActive('/section-templates')}
        />
        <SidebarItem
          expanded={isExpanded}
          icon="Activity"
          label={t`Analytics`}
          url="/analytics"
          active={routeIsActive('/analytics')}
        />

        <SidebarItem
          icon="bell"
          label={t`Notifications`}
          url="/notifications"
          active={routeIsActive('/notifications')}
          badgeNumber={unseenNotifications}
          expanded={isExpanded}
        />
        <Spacer />
        {/* <SidebarItem
          icon="gift"
          label={t`Earn $5000`}
          onClick={referralOpen}
          active={false}
          expanded={isExpanded}
        /> */}
        {isNotNil(remainingTrialDays) && (
          <SidebarItem
            icon="credit-card"
            label={t`${remainingTrialDays} remaining trial days`}
            active={false}
            expanded={isExpanded}
          />
        )}
        <SidebarItem
          icon="message-circle"
          label={t`Request a feature`}
          url={CUSTOMER_FEEDBACK_URL}
          active={false}
          expanded={isExpanded}
          isExternalLink
        />
        <SidebarUser isExpanded={isExpanded} onClick={() => setIsSettingsOpen(!isSettingsOpen)} />
        <AnimatePresence>
          {isSettingsOpen && (
            <Box
              as={motion.div}
              key={`settings-user`}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              w="full"
              pl={isExpanded ? '6' : '0'}
            >
              <SidebarItem
                icon="Profile"
                label={t`Profile`}
                url={profileSettingsUrl()}
                active={routeIsActive(profileSettingsUrl())}
                expanded={isExpanded}
              />

              <SidebarItem
                icon="Building"
                label={t`Organization`}
                url={tagsSettingsUrl()}
                active={routeIsActive(tagsSettingsUrl())}
                expanded={isExpanded}
              />

              <SidebarItem
                icon="LogOut"
                label={t`Logout`}
                onClick={() =>
                  void signOut(() => {
                    void router.push(signInUrl());
                  })
                }
                active={false}
                expanded={isExpanded}
              />
            </Box>
          )}
        </AnimatePresence>
      </SidebarColumn>
    </SidebarContainer>
  );
};
