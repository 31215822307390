import { useMemo } from 'react';
import type { FC } from 'react';
import { Flex, H6, Text } from '@topo-io/design-system';
import type { Workspace } from '@topo-io/graphql';
import { AccountLogo } from '@/components/common';

interface DetailsLogoProps {
  workspace?: Workspace;
  accountName?: string;
  accountLogoUrl?: string;
  companyName?: string;
  companyLogoUrl?: string;
  isWorkspace?: boolean;
  withBorder?: boolean;
  withPadding?: boolean;
  showTextBetweenCompanies?: boolean;
}

export const DetailsLogo: FC<DetailsLogoProps> = ({
  accountName = 'Customer',
  accountLogoUrl,
  companyName,
  companyLogoUrl,
  isWorkspace = true,
  withBorder = true,
  withPadding = true,
  showTextBetweenCompanies = true,
}) => {
  const account = {
    name: accountName,
    logoUrl: accountLogoUrl,
  };
  const company = {
    name: companyName,
    logoUrl: companyLogoUrl,
  };

  const shouldShowTextBetweenCompanies = useMemo(() => {
    if (!showTextBetweenCompanies) {
      return false;
    }
    if (isWorkspace) {
      return (companyLogoUrl ?? companyName) && (accountLogoUrl ?? accountName);
    }
    return companyLogoUrl ?? companyName;
  }, [
    accountLogoUrl,
    accountName,
    companyLogoUrl,
    companyName,
    isWorkspace,
    showTextBetweenCompanies,
  ]);

  return (
    <Flex
      as="header"
      alignItems="center"
      border={withBorder ? '1px' : undefined}
      borderRadius="8px"
      bg="white"
      borderColor="gray.30"
      p={withPadding ? '3' : undefined}
      width="fit-content"
    >
      {companyLogoUrl && <AccountLogo w="28px" mr="2" company={company} />}
      {companyName && <H6>{companyName}</H6>}
      {shouldShowTextBetweenCompanies && (
        <Text mx="2" color="gray.200">
          x
        </Text>
      )}
      {accountLogoUrl && (
        <AccountLogo w="28px" mr="2" company={account} shouldFallbackOnError={false} />
      )}
      {accountName && <H6>{accountName}</H6>}
    </Flex>
  );
};
