import { Trans } from '@lingui/macro';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';
import { TOOLBAR_SECTION_Z_INDEX } from '@topo-io/constants';
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@topo-io/design-system';
import { WorkspaceTabFeatureName } from '@topo-io/graphql';
import { isEmptyArray, isNil, isNotNil } from '@topo-io/utils';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';
import { sectionTemplateUrl } from '@/config';

interface SectionsActionsToolbarProps {
  index: number;
  sectionId: string;
  templateId?: string;
  show: boolean;
  onClickMenuItemDesynchronize: () => void;
  onClickMenuItemSaveSectionAsTemplate: () => void;
}

export const SectionsActionsToolbar = memo(
  ({
    sectionId,
    templateId,
    show,
    onClickMenuItemSaveSectionAsTemplate,
    onClickMenuItemDesynchronize,
  }: SectionsActionsToolbarProps) => {
    const { tabId, workspaceTabs } = useWorkspaceTabs();
    const workspaceTabId = tabId;
    const contentTabsFromWorkspace = workspaceTabs.filter(
      (tab) => tab.type === WorkspaceTabFeatureName.CONTENT && tab.id !== workspaceTabId
    );
    const { removeSection, toggleSectionVisibility, moveSectionToAnotherTab } =
      useContent(workspaceTabId);
    const router = useRouter();

    const onClickRemoveSection = useCallback(
      () => removeSection({ sectionId }),
      [removeSection, sectionId]
    );
    const onClickEditSectionTemplate = useCallback(() => {
      if (!templateId) return;
      void router.push(sectionTemplateUrl(templateId));
    }, [router, templateId]);

    const onClickToggleVisibility = useCallback(
      () => toggleSectionVisibility({ sectionId, show }),
      [show, sectionId, toggleSectionVisibility]
    );

    const onClickMoveToAnotherTab = useCallback(
      (tabId: string) => {
        if (workspaceTabId === tabId) {
          return;
        }
        if (isNil(workspaceTabId)) return;
        moveSectionToAnotherTab({
          id: sectionId,
          targetWorkspaceTabId: tabId,
          currentWorkspaceTabId: workspaceTabId,
        });
      },
      [moveSectionToAnotherTab, sectionId, workspaceTabId]
    );

    return (
      <Box display="flex" zIndex={TOOLBAR_SECTION_Z_INDEX}>
        <Menu variant="default" placement="left-start">
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                size="sm"
                variant="outline"
                colorScheme="gray"
                borderColor="gray.30"
                bgColor="white"
                icon={<Icon icon="more-horizontal" color="gray.100" />}
              >
                <Box position="relative" top="-3px">
                  ...
                </Box>
              </MenuButton>
              <Portal>
                <MenuList zIndex="2">
                  <Menu variant="default">
                    <Popover placement="right-start" offset={[-9, 0]}>
                      <PopoverTrigger>
                        <MenuItem
                          icon={
                            <Icon
                              icon="CornerUpRight"
                              position="relative"
                              w="16px"
                              h="16px"
                              top="-1px"
                            />
                          }
                        >
                          <Trans>Move to tab</Trans>
                        </MenuItem>
                      </PopoverTrigger>
                      <PopoverContent w="fit-content">
                        <PopoverBody>
                          {isEmptyArray(contentTabsFromWorkspace) ? (
                            <Trans>No tab available</Trans>
                          ) : (
                            contentTabsFromWorkspace.map((tab, index) => (
                              <MenuItem
                                key={`workspace-tab-${sectionId}-${index}`}
                                onClick={() => onClickMoveToAnotherTab(tab.id)}
                              >
                                {tab.name}
                              </MenuItem>
                            ))
                          )}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Menu>
                  <MenuItem
                    icon={
                      <Icon
                        icon={show ? 'eye' : 'eye-off'}
                        position="relative"
                        w="16px"
                        h="16px"
                        top="-1px"
                      />
                    }
                    onClick={onClickToggleVisibility}
                  >
                    {show ? <Trans>Hide section</Trans> : <Trans>Unhide section</Trans>}
                  </MenuItem>
                  {isNotNil(templateId) ? (
                    <>
                      <MenuItem
                        icon={<Icon icon="refresh-ccw" position="relative" top="-1px" />}
                        onClick={onClickMenuItemDesynchronize}
                      >
                        <Trans>Unsync template</Trans>
                      </MenuItem>
                      <MenuItem
                        icon={<Icon icon="edit-3" position="relative" top="-1px" />}
                        onClick={onClickEditSectionTemplate}
                      >
                        <Trans>Edit template</Trans>
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem
                      icon={
                        <Icon icon="Refresh" position="relative" w="16px" h="16px" top="-1px" />
                      }
                      onClick={onClickMenuItemSaveSectionAsTemplate}
                    >
                      <Trans>Save as a template</Trans>
                    </MenuItem>
                  )}
                  <MenuItem
                    icon={<Icon icon="Trash" position="relative" w="16px" h="16px" top="-1px" />}
                    color="red.500"
                    onClick={onClickRemoveSection}
                  >
                    <Trans>Delete</Trans>
                  </MenuItem>
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      </Box>
    );
  }
);

SectionsActionsToolbar.displayName = 'SectionsActionsToolbar';
