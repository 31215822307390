import { WORKSPACE_DEFAULT_TAB_SLUG } from '@/components/workspace/tabs/workspace-tab-type';

type routeList = string[];

export const authRoutes: routeList = ['/auth/*'];
export const previewRoutes: routeList = ['/workspaces/preview/*', '/templates/preview/*'];
export const sharedRoutes: routeList = ['/w/*'];
export const sectionRoutes: routeList = ['/(workspaces|w)/*/sections/*'];
export const sectionTemplateRoutes: routeList = ['/section-templates/*'];
export const baseRoutes: routeList = ['/workspaces', '/templates'];
export const guestSectionCommentsRoutes: routeList = ['/w/*/*/sections/*/comments'];

export const staticPublicRoutes: routeList = ['/404', '/settings/integrations/*/callback'];
export const publicRoutes: routeList = [...staticPublicRoutes, ...authRoutes, ...sharedRoutes];

export interface WorkspaceMode {
  isPreview: boolean;
  isGuest: boolean;
  isTemplate?: boolean;
}

export interface WorkspaceTemplateMode {
  isPreview: boolean;
}

const getWorkspaceBaseUrl = ({ isPreview, isGuest }: WorkspaceMode) => {
  if (isPreview) {
    return '/workspaces/preview';
  }
  if (isGuest) {
    return '/w';
  }
  return '/workspaces';
};

const getWorkspaceTemplateBaseUrl = ({ isPreview }: WorkspaceTemplateMode) => {
  if (isPreview) {
    return '/templates/preview';
  }
  return '/templates';
};

export const baseUrl = () => '/';
export const error404 = () => '/404';
export const homepageUrl = () => '/home';
export const workspacesUrl = () => '/workspaces';
export const workspaceUrl = (id: string, tabSlug?: string, mode?: WorkspaceMode) => {
  const { isPreview = false, isGuest = false } = mode ?? {};
  const slug = tabSlug ?? WORKSPACE_DEFAULT_TAB_SLUG;
  return getWorkspaceBaseUrl({ isPreview, isGuest }) + `/${id}/${slug}`;
};
export const workspacePreviewUrl = (id: string, tabSlug?: string) => {
  const slug = tabSlug ?? WORKSPACE_DEFAULT_TAB_SLUG;
  return `/workspaces/preview/${id}/${slug}`;
};

export const workspaceTemplatesUrl = () => `/templates`;
export const workspaceTemplateUrl = (
  id: string,
  tabSlug?: string,
  mode?: WorkspaceTemplateMode
) => {
  const { isPreview = false } = mode ?? {};
  const slug = tabSlug ?? WORKSPACE_DEFAULT_TAB_SLUG;
  return getWorkspaceTemplateBaseUrl({ isPreview }) + `/${id}/${slug}`;
};
export const workspaceTemplatePreviewUrl = (id: string, tabSlug?: string) => {
  const slug = tabSlug ?? WORKSPACE_DEFAULT_TAB_SLUG;
  return `/templates/preview/${id}/${slug}`;
};

export const sectionTemplateUrl = (id: string, tabSlug?: string) => {
  const slug = tabSlug ?? WORKSPACE_DEFAULT_TAB_SLUG;
  return `/section-templates/${id}/${slug}`;
};

export const sectionTemplatesUrl = () => `/section-templates`;
export const sectionCommentsUrl = ({
  workspaceId,
  sectionId,
  mode,
  tabSlug,
}: {
  workspaceId: string;
  sectionId: string;
  mode: WorkspaceMode;
  tabSlug?: string;
}) => {
  const { isPreview = false, isGuest = false } = mode;
  const tab = tabSlug ?? WORKSPACE_DEFAULT_TAB_SLUG;
  return (
    getWorkspaceBaseUrl({ isPreview, isGuest }) +
    `/${workspaceId}/${tab}/sections/${sectionId}/comments`
  );
};
export const roadmapTaskUrl = ({
  workspaceId,
  taskId,
  mode,
  tabSlug,
}: {
  workspaceId: string;
  taskId: string;
  mode: WorkspaceMode;
  tabSlug: string;
}) => {
  const { isPreview = false, isGuest = false, isTemplate = false } = mode;

  if (isTemplate) {
    return workspaceTemplateUrl(workspaceId, tabSlug, { isPreview }) + `/tasks/${taskId}`;
  }
  return getWorkspaceBaseUrl({ isPreview, isGuest }) + `/${workspaceId}/${tabSlug}/tasks/${taskId}`;
};

export const signInUrl = () => '/auth/signin';
export const signUpUrl = () => '/auth/signup';
export const pricingUrl = () => '/pricing';

export const profileSettingsUrl = () => '/settings/profile';
export const tagsSettingsUrl = () => '/settings/organization/branding';

const CDN_URL = 'https://assets.topo.io';
export const cdnUrl = (path: string) => `${CDN_URL}/${path.replace(/^\//g, '')}`;
