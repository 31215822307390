import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { MenuButton, IconButton, Icon, MenuList, MenuItem, Menu } from '@topo-io/design-system';
import { useRoadmapContext } from '@/components/roadmap/hooks/use-roadmap-context';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';

interface SubtaskMenuProps {
  subtaskId: string;
}

export const SubtaskMenu: FC<SubtaskMenuProps> = ({ subtaskId }) => {
  const { canEditRoadmap } = useRoadmapContext();
  const { deleteSubtask } = useTaskContext();

  const onClickRemoveTask = () => {
    deleteSubtask(subtaskId);
  };

  if (!canEditRoadmap) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="more-btn"
        icon={<Icon icon="more-horizontal" />}
        variant="ghost"
        colorScheme="gray"
        data-testid="subtask-menu"
      />
      <MenuList aria-label="task-menu-list">
        <MenuItem
          icon={<Icon icon="trash" />}
          aria-label="delete-task-menu-item"
          color="red.500"
          onClick={onClickRemoveTask}
        >
          <Trans>Delete</Trans>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
