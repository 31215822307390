import type { FC } from 'react';
import type { ButtonProps } from '@topo-io/design-system';
import { Button, HStack, Icon, Text } from '@topo-io/design-system';

export interface BackButtonProps extends ButtonProps {
  onClick?: () => void;
  text?: string;
}

export const BackButton: FC<BackButtonProps> = ({ onClick, text }) => {
  return (
    <HStack alignItems="center">
      <Button
        colorScheme="gray"
        p="0"
        h="25px"
        w="20px"
        onClick={onClick}
        data-testid="back-button"
        whiteSpace="nowrap"
      >
        <Icon icon="chevron-left" w="14px" h="14px" />
      </Button>
      {text && (
        <Text ml="2" minW="50px" width="100%">
          {text}
        </Text>
      )}
    </HStack>
  );
};
