import type { FC } from 'react';
import type { AvatarProps } from '@topo-io/design-system';
import { Avatar, AvatarGroup } from '@topo-io/design-system';
import { getDisplayName, isNil } from '@topo-io/utils';

interface MemberGroupItem {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  picture?: string;
}

interface MemberGroupProps {
  members: MemberGroupItem[];
  size?: AvatarProps['size'];
}

export const MemberGroup: FC<MemberGroupProps> = ({ members, size = 'sm' }) => {
  if (isNil(members)) {
    return <></>;
  }

  return (
    <AvatarGroup spacing="-5px" max={3} size={size ?? 'sm'}>
      {members.map((user) => {
        const name = getDisplayName(user, user.email ?? '');
        return <Avatar size={size} key={user.id ?? user.email} name={name} src={user?.picture} />;
      })}
    </AvatarGroup>
  );
};
