import { Tag, Box } from '@topo-io/design-system';
import { isZero } from '@topo-io/utils';
import type { RoadmapStep } from './types';

export const StepStatusIcon = ({ step }: { step: RoadmapStep }) => {
  const { tasks } = step;
  const visibleTasks = tasks.filter((t) => t.isVisible);
  const totalTasks = visibleTasks.length;
  const completedTasks = visibleTasks.filter((task) => task.done).length;
  let color = 'primary.100';
  let iconColor = 'primary.400';
  if (isZero(totalTasks)) {
    color = 'gray.40';
    iconColor = 'gray.100';
  } else if (isZero(completedTasks)) {
    color = 'red.100';
    iconColor = 'red.400';
  } else if (completedTasks === totalTasks) {
    color = 'green.100';
    iconColor = 'green.400';
  }

  return (
    <Tag bgColor={color}>
      <Box w="6px" h="6px" backgroundColor={iconColor} borderRadius="50%" />
    </Tag>
  );
};
