import { t } from '@lingui/macro';
import { Field } from '@saas-ui/forms';
import { useCallback } from 'react';
import { FormModal } from '@/components/common';
import { TagsField } from '@/components/common/fields/tags-field';

export interface NewTemplateFormValues {
  title: string;
  tagIds?: string[];
}

export type DuplicateTemplateFormValues = NewTemplateFormValues & {
  originalTemplateId: string;
};

type NewTemplateModalProps<T extends boolean> = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (props: NewTemplateFormValues | DuplicateTemplateFormValues) => void;
  title?: string;
  defaultTitle?: string;
  tagIds?: string[];
} & (T extends true
  ? { duplicate: T; originalTemplateId: string }
  : { duplicate?: never; originalTemplateId?: never });

export const NewTemplateModal = <T extends boolean>({
  title,
  defaultTitle,
  isOpen,
  onClose,
  onSubmit: handleSubmit,
  duplicate,
  originalTemplateId,
  tagIds = [],
}: NewTemplateModalProps<T>): JSX.Element => {
  const onSubmit = useCallback(
    (props: DuplicateTemplateFormValues) => {
      handleSubmit(props);
      onClose();
    },
    [onClose, handleSubmit]
  );

  return (
    <FormModal
      title={title ?? t`New workspace template`}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Field
        name="title"
        label={t`Template Title`}
        autoFocus
        type="text"
        defaultValue={defaultTitle}
        placeholder={t`e.g Template Enterprise`}
        isRequired
      />
      {duplicate && <Field name="originalTemplateId" value={originalTemplateId} display="none" />}
      <TagsField name="tagIds" initialValue={tagIds} label={t`Tags`} />
    </FormModal>
  );
};
