import { Button, Flex } from '@topo-io/design-system';
import { useBaseRoutesContext } from '@/components/routing';
import { useSectionTemplateTabs } from '@/components/section/hooks';

export const NavbarSectionTemplateTabs = () => {
  const { isSectionTemplatePage } = useBaseRoutesContext();
  const { tab, onChangeTab, getTabFormattedMessage, visibleTabs } = useSectionTemplateTabs();

  if (!isSectionTemplatePage) {
    return null;
  }

  return (
    <Flex gap="5px" ml="3">
      {visibleTabs.map((tabName, index) => {
        return (
          <Button
            key={tabName}
            variant="ghost"
            p="8px 16px"
            borderRadius="8px"
            color="gray.500"
            fontWeight="500"
            {...(tab === tabName && {
              color: 'primary.600',
              background: 'primary.50',
            })}
            _hover={{
              color: 'gray.300',
              background: 'gray.20',
            }}
            onClick={() => onChangeTab(index)}
          >
            {getTabFormattedMessage(tabName)}
          </Button>
        );
      })}
    </Flex>
  );
};
