import { Trans, t } from '@lingui/macro';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useMemo } from 'react';
import { Box, Button, Checkbox, Flex, H5, Icon, Text, HStack } from '@topo-io/design-system';
import type { CategoriesQuery } from '@topo-io/graphql';
import { useCategoriesQuery } from '@topo-io/graphql';
import { tagsSettingsUrl } from '@/config';
import { useSearchFilters } from '@/hooks';

const DEFAULT_COUNT = 0;

export const TagsFilter = () => {
  const { data } = useCategoriesQuery();
  const router = useRouter();

  const { updateFilter, updateOperator, resultsPerFilter } = useSearchFilters();

  const toggleUntagged = useCallback(
    (value: boolean) => {
      updateOperator({
        key: 'tags',
        operator: 'OR',
        enabled: true,
      });
      updateFilter({
        attribute: 'tags',
        operator: 'none',
        value: {},
        enabled: value,
        parentNodeKey: 'tags',
      });
    },
    [updateFilter, updateOperator]
  );

  const toggleFilter = useCallback(
    (tagId: string, value: boolean) => {
      updateOperator({
        key: 'tags',
        operator: 'OR',
        enabled: true,
      });
      updateFilter({
        attribute: 'tags.some.tagId',
        key: `tags.some.tagId.${tagId}`,
        operator: 'equals',
        value: tagId,
        enabled: value,
        parentNodeKey: 'tags',
      });
    },
    [updateFilter, updateOperator]
  );

  const categories = useMemo<CategoriesQuery['categories']>(() => {
    return data?.categories ?? [];
  }, [data]);

  const goToTagsSettings = useCallback(
    () => void router.push(tagsSettingsUrl() satisfies string),
    [router]
  );

  return (
    <Box p={4} mr={4} borderRadius="8px" bg="white" borderWidth="1px" height="fit-content">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <H5>
          <Icon icon="tag-01" mr="2" />
          <Trans>Tags</Trans>
        </H5>
        <Button
          size="sm"
          colorScheme="gray"
          leftIcon={<Icon icon="external-link" />}
          onClick={goToTagsSettings}
        >
          <Trans>Edit</Trans>
        </Button>
      </Box>
      <Flex justifyContent="space-between" pt={2}>
        <HStack>
          <Checkbox onChange={(e) => toggleUntagged(e.target.checked)} mr="1">
            {t`Untagged`}
          </Checkbox>
        </HStack>
        {/* FIXME: Algolia */}
        {/* <Text
          color="gray.100"
          bg="gray.20"
          px={2}
          py={0}
          height="24px"
          borderRadius="6px"
          fontSize="smaller"
        >
          {resultsPerFilter.false ?? DEFAULT_COUNT}
        </Text> */}
      </Flex>
      {categories.map((category) => (
        <Fragment key={category.id}>
          <Text fontSize="14px" fontWeight={600} lineHeight="24px" color="gray.500" pt={2}>
            {category.name}
          </Text>
          <Box w="full" py="2" key={category.id}>
            {category.tags.map((tag) => (
              <Flex key={tag.id} justifyContent="space-between" pt={2}>
                <HStack>
                  <Checkbox onChange={(e) => toggleFilter(tag.id, e.target.checked)} mr="1">
                    {tag.name}
                  </Checkbox>
                </HStack>
                {/* FIXME: Algolia */}
                {/* <Text
                  color="gray.100"
                  bg="gray.20"
                  px={2}
                  py={0}
                  height="24px"
                  borderRadius="6px"
                  fontSize="smaller"
                >
                  {resultsPerFilter.false ?? DEFAULT_COUNT}
                </Text> */}
              </Flex>
            ))}
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};
