import { Trans } from '@lingui/macro';
import { Flex, Icon, Text } from '@topo-io/design-system';

export const TableEmptyState = () => {
  return (
    <Flex flexDirection="column" alignItems="center" data-testid="table-empty-state">
      <Icon icon="LayerSingle" w="24px" h="24px" />
      <Text color="gray.100" fontWeight="600">
        <Trans>No results found</Trans>
      </Text>
    </Flex>
  );
};
