import { OVERLAY_SECTION_Z_INDEX } from '@topo-io/constants';
import { Box } from '@topo-io/design-system';

export const OverlayTemplateSection = (): JSX.Element => {
  return (
    <Box
      aria-label="Template section overlay"
      position="absolute"
      borderRadius="md"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="gray.50"
      opacity={0.5}
      zIndex={OVERLAY_SECTION_Z_INDEX}
    />
  );
};
