import type { PropsWithChildren, FC } from 'react';
import { createContext, useMemo } from 'react';
import type { TabsQuery } from '@topo-io/graphql';

type WorkspaceTab = TabsQuery['tabs'][number];

export interface WorkspaceTabsProviderProps {
  workspaceTabs: WorkspaceTab[];
  queryTab: string | undefined;
  loading: boolean;
}

export interface WorkspaceTabsContextProps {
  workspaceTabs: WorkspaceTab[];
  queryTab: string | undefined;
  loading: boolean;
}

export const WorkspaceTabsContext = createContext<WorkspaceTabsContextProps>({
  workspaceTabs: [],
  queryTab: undefined,
  loading: false,
});

export const WorkspaceTabsProvider: FC<PropsWithChildren<WorkspaceTabsProviderProps>> = ({
  workspaceTabs,
  queryTab,
  loading,
  children,
}) => {
  const value = useMemo(
    () => ({
      workspaceTabs,
      loading,
      queryTab,
    }),
    [workspaceTabs, queryTab, loading]
  );

  return <WorkspaceTabsContext.Provider value={value}>{children}</WorkspaceTabsContext.Provider>;
};
