import { Trans } from '@lingui/macro';
import dynamic from 'next/dynamic';
import type { RichTextEditorProps } from '@topo-io/rich-text-editor';

export const RichTextEditorSSR = dynamic<RichTextEditorProps>(() => import('./rich-text-editor'), {
  loading: () => (
    <p>
      <Trans>Loading...</Trans>
    </p>
  ),
  ssr: false,
});
