import type { FC } from 'react';
import { Box, Flex, useDisclosure } from '@topo-io/design-system';
import { ImageButton, MentionButton, useChainedCommands } from '@topo-io/rich-text-editor';

export const CommentToolbarEditor: FC = () => {
  const imageDisclosure = useDisclosure();
  const chainedCommand = useChainedCommands();

  const getFilesPreview = () => {
    imageDisclosure.onClose();
  };

  const insertMentionAtCursor = () => {
    chainedCommand.insertText('@').focus().run();
  };

  return (
    <Box>
      <Flex>
        <ImageButton {...imageDisclosure} onUploadCompleted={getFilesPreview} />
        <MentionButton onClick={insertMentionAtCursor} />
      </Flex>
    </Box>
  );
};
