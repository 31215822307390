import { isNil } from '@topo-io/utils';
import { TemplateLogo } from '@/components/workspace/common';
import { useTemplate, useWorkspaceContext } from '@/components/workspace/hooks';
import { DetailsLayout } from './details-layout';
import { TemplateEditForm } from './template-edit-form';
import { WorkspaceDetailsBanner } from './workspace-details-banner';

export const TemplateDetails = () => {
  const { id, readOnly } = useWorkspaceContext();
  const { template } = useTemplate(id!, { skip: isNil(id) });

  return (
    <DetailsLayout>
      {template && <TemplateLogo template={template} />}
      <WorkspaceDetailsBanner readOnly={readOnly} />
      <TemplateEditForm />
    </DetailsLayout>
  );
};
