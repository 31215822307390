import { Action } from '@topo-io/graphql';
import type { TimelineActivity } from '@/components/timeline/timeline.types';

type ActivityWorkspace = Extract<TimelineActivity, { __typename: 'ActivityWorkspace' }>;

export const isLegacyActivityWorkspace = (
  activity: TimelineActivity
): activity is ActivityWorkspace => {
  return [Action.ShareWorkspace, Action.OpenWorkspace].includes(activity.action);
};
