import { Box, Flex } from '@topo-io/design-system';
import { ActionIndirectObject } from './action-indirect-object';
import { ActionObject } from './action-object';
import { ActionSubject } from './action-subject';
import { ActionVerb } from './action-verb';
import { PreviewComment } from './preview-comment';
import { TimeIndicator } from './time-indicator';
import { TimelineItemIcon } from './timeline-item-icon';
import type { TimelineActivity } from './timeline.types';
import { isActivityCommentSection } from './utils';

interface TimelineRowProps {
  activity: TimelineActivity;
  isLast: boolean;
}

export const TimelineRow = ({ activity, isLast }: TimelineRowProps) => {
  const { action } = activity;

  return (
    <Flex alignItems="start" justifyContent="start" gap="4" mb="4">
      <Flex direction="column" h="auto" align="center">
        <TimelineItemIcon activity={activity} isLast={isLast} />
        <Box w="1px" bg="gray.40" h={isLast ? '0' : '100%'} mr="1.5" my="4px" />
      </Flex>
      <Flex direction="column" justifyContent="flex-start" h="100%">
        <Flex color="gray.900" flexWrap="wrap">
          <ActionSubject userProfile={activity.userProfile} />
          <ActionVerb action={action} />
          <ActionObject activity={activity} />
          <ActionIndirectObject activity={activity} />
        </Flex>
        <Box fontSize="sm" color="gray.400" fontWeight="normal">
          <TimeIndicator date={activity.createdAt} />
        </Box>
        {isActivityCommentSection(activity) && activity.comment && (
          <PreviewComment comment={activity.comment} />
        )}
      </Flex>
    </Flex>
  );
};
