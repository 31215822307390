import type { BaseFieldProps, FieldValues } from '@saas-ui/forms';
import { createField } from '@saas-ui/forms';
import type { MultiSelectProps } from '@topo-io/design-system';
import { MultiSelect } from '@topo-io/design-system';

export type MultiSelectFieldProps = MultiSelectProps &
  Omit<BaseFieldProps<FieldValues, string>, 'defaultValue'>;

export const MultiSelectField = createField<MultiSelectProps>(MultiSelect, {
  isControlled: true,
});
