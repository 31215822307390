import type { ReactNode } from 'react';
import { FullPage } from '@topo-io/design-system';
import { BaseLayout } from './base-layout';

interface NoLayoutProps {
  children: ReactNode;
}

export const NoLayout = ({ children }: NoLayoutProps) => {
  return (
    <BaseLayout>
      <FullPage>{children}</FullPage>;
    </BaseLayout>
  );
};
