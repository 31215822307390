import { ErrorBoundary } from '@sentry/nextjs';
import type { FC } from 'react';
import { Box, SortableItem, SortableList, VStack } from '@topo-io/design-system';
import { getLastPosition, isNotNil } from '@topo-io/utils';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { AddStep } from './add-step';
import { useUpdateStepPositionMutationWithCache, useRoadmapContext } from './hooks';
import { Step } from './step';
import type { RoadmapStep, RoadmapSteps } from './types';

export const Roadmap: FC<{ roadmapSteps: RoadmapSteps }> = ({ roadmapSteps }) => {
  const { id } = useWorkspaceContext();
  const { canEditRoadmap } = useRoadmapContext();

  const { updateStepPositionWithCache } = useUpdateStepPositionMutationWithCache();
  const handleChange = (steps: RoadmapStep[], updatedStep: RoadmapStep, newPosition: number) => {
    if (canEditRoadmap && isNotNil(id)) {
      updateStepPositionWithCache({
        steps,
        updatedStep,
        newPosition,
        workspaceId: id,
      });
    }
  };

  return (
    <Box mt="6">
      <VStack alignItems="stretch">
        <SortableList
          items={roadmapSteps}
          onChange={handleChange}
          renderItem={(step) => (
            <ErrorBoundary>
              <SortableItem id={step.id}>
                <Step step={step} />
              </SortableItem>
            </ErrorBoundary>
          )}
        />
      </VStack>
      {canEditRoadmap && id && <AddStep id={id} stepsLength={getLastPosition(roadmapSteps)} />}
    </Box>
  );
};
