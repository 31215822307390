import { RoadmapType, useCreateStepMutation } from '@topo-io/graphql';
import { getCreatedStepCache } from '@/components/roadmap/utils/cache-utils';
import { useWorkspaceContext } from '@/components/workspace/hooks';

export const useCreateStepMutationWithCache = () => {
  const { isWorkspace } = useWorkspaceContext();
  const [createStep] = useCreateStepMutation();
  const createStepWithCache = ({
    workspaceId,
    position,
  }: {
    workspaceId: string;
    position: number;
  }) => {
    void createStep({
      variables: {
        stepData: {
          workspaceId,
          position,
          roadmapType: isWorkspace ? RoadmapType.Workspace : RoadmapType.WorkspaceTemplate,
        },
      },
      update: (cache) => {
        cache.modify(getCreatedStepCache({ workspaceId }));
      },
    });
  };
  return { createStepWithCache };
};
