import { useClientMemo } from '@topo-io/hooks';
import { isNil } from '@topo-io/utils';
import { getOrganizationSlug } from '@/config';

export const useIsMainSlug = () => {
  const slug = useClientMemo(() => getOrganizationSlug(), []);
  return {
    isLoading: isNil(slug),
    isMainSlug: slug === 'rooms',
  };
};
