import { Trans, t } from '@lingui/macro';
import { Button, useDisclosure, Icon } from '@topo-io/design-system';
import { NewTemplateModal } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { useSubmitNewSectionTemplate } from '@/components/workspace/common/new-template-modal/use-submit-new-section-template';
import { useQueryParamId } from '@/hooks';

export const CTASectionTemplate = () => {
  const id = useQueryParamId();
  const {
    isOpen: isOpenDuplicate,
    onClose: onCloseDuplicate,
    onOpen: onOpenDuplicate,
  } = useDisclosure();
  const { getSubmitDuplicateSectionTemplate } = useSubmitNewSectionTemplate();

  // TODO: existing tags
  return (
    <>
      <Button mr="1" leftIcon={<Icon icon="copy" />} onClick={onOpenDuplicate}>
        <Trans>Duplicate section template</Trans>
      </Button>
      <NewTemplateModal
        title={t`Duplicate section template`}
        duplicate
        originalTemplateId={id}
        isOpen={isOpenDuplicate}
        onClose={onCloseDuplicate}
        onSubmit={getSubmitDuplicateSectionTemplate(id)}
      />
    </>
  );
};
