import { t } from '@lingui/macro';
import type { FieldValues } from '@saas-ui/forms';
import { Field } from '@saas-ui/forms';
import type { FC } from 'react';
import { useState } from 'react';
import { SimpleModal } from '@topo-io/design-system';
import { FormModal } from '@/components/common';
import { AccountAndTitleFields } from '@/components/workspace/common/new-workspace-modal/account-and-title-fields';
import { WorkspaceCRMFields } from '@/components/workspace/common/new-workspace-modal/workspace-crm-fields';
import { useSubscription } from '@/hooks/use-subscription';
import type {
  CreateWorkspaceFormValues,
  DuplicateWorkspaceFormValues,
  GenerateWorkspaceFormValues,
} from './types';
import { useNewWorkspaceModal } from './use-new-workspace-modal';

interface BaseWorkspaceProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
}

type NewWorkspaceModalProps = BaseWorkspaceProps & {
  duplicate?: never;
  originalWorkspaceId?: never;
  loading?: boolean;
  isFromAI?: boolean;
};

type DuplicateWorkspaceModalProps = BaseWorkspaceProps & {
  duplicate: true;
  originalWorkspaceId: string;
  isFromAI?: boolean;
};

export const NewWorkspaceModal: FC<NewWorkspaceModalProps | DuplicateWorkspaceModalProps> = ({
  title,
  isOpen,
  onClose,
  duplicate,
  originalWorkspaceId,
  isFromAI,
}) => {
  const {
    formRef,
    onSubmitCreateWorkspace,
    onSubmitDuplicateWorkspace,
    onSubmitGenerateWorkspace,
  } = useNewWorkspaceModal({
    closeModal: onClose,
  });

  const [isLoading, setIsLoading] = useState(false);
  const { hasCRMFeatures } = useSubscription();

  const handleSubmit = async (formValues: FieldValues) => {
    if (duplicate) {
      await onSubmitDuplicateWorkspace(formValues as DuplicateWorkspaceFormValues);
      return;
    }
    if (isFromAI) {
      setIsLoading(true);
      try {
        await onSubmitGenerateWorkspace(formValues as GenerateWorkspaceFormValues);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
      return;
    }

    await onSubmitCreateWorkspace(formValues as CreateWorkspaceFormValues);
  };

  if (isLoading && isFromAI) {
    return (
      <SimpleModal isOpen={isLoading} onClose={() => {}} skipCloseButton>
        <img src="/images/ai-loader.gif" alt="ai loader" />
      </SimpleModal>
    );
  }

  return (
    <FormModal
      title={title ?? 'New workspace'}
      isOpen={isOpen}
      formRef={formRef}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <AccountAndTitleFields />

      {duplicate && (
        <Field<DuplicateWorkspaceFormValues>
          name="originalWorkspaceId"
          value={originalWorkspaceId}
          display="none"
        />
      )}

      {isFromAI && (
        <Field
          name="userInput"
          label={t`User input`}
          type="textarea"
          placeholder={t`Text to describe your workspace`}
        />
      )}

      {hasCRMFeatures && <WorkspaceCRMFields formRef={formRef} />}
    </FormModal>
  );
};
