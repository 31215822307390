import type { FlexProps } from '@topo-io/design-system';
import { Flex } from '@topo-io/design-system';

interface SidebarRowProps extends FlexProps {
  children?: React.ReactNode;
}

export const SidebarRow = ({ children, ...rest }: SidebarRowProps) => (
  <Flex
    align="center"
    alignItems="center"
    alignContent="center"
    justify="center"
    rounded="md"
    cursor="pointer"
    role="group"
    fontWeight="normal"
    transition=".15s ease"
    _hover={{
      color: 'gray.10',
      bg: 'gray.800',
    }}
    {...rest}
  >
    {children}
  </Flex>
);
