import { Badge, Flex, Icon, Tooltip, Text } from '@topo-io/design-system';
import { isPositive } from '@topo-io/utils';

interface SidebarMenuIconProps {
  icon: string;
  label: string;
  active: boolean;
  expanded?: boolean;
  badgeNumber?: number;
}

const badgeStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bgColor: 'red.500',
  borderRadius: '32px',
  height: '16px',
  padding: '0 4px',
};

export const SidebarMenuLabel = ({
  icon,
  active,
  expanded,
  label,
  badgeNumber = 0,
}: SidebarMenuIconProps) => {
  const shouldShowBadge = isPositive(badgeNumber);
  const badgeLabel = badgeNumber > 99 ? '99+' : badgeNumber;
  return (
    <>
      <Tooltip label={label} placement="right" fontSize="md" isDisabled={expanded}>
        <Flex
          p="8px"
          borderRadius="8px"
          w={expanded ? '32px' : '40px'}
          color={active ? 'gray.10' : 'gray.50'}
          _groupHover={{
            color: 'gray.10',
          }}
        >
          {icon && (
            <>
              <Icon
                boxSize="4"
                height={expanded ? '16px' : '24px'}
                width={expanded ? '16px' : '24px'}
                icon={icon}
                strokeWidth="1.5px"
              />
              {!expanded && shouldShowBadge && (
                <Badge {...badgeStyle} position="relative" ml="-8px" mt="-4px">
                  {badgeLabel}
                </Badge>
              )}
            </>
          )}
        </Flex>
      </Tooltip>
      {expanded && (
        <Flex alignItems="center">
          <Text fontWeight="500">{label}</Text>
          {shouldShowBadge && (
            <Badge {...badgeStyle} height="20px" minWidth="28px" ml="3">
              {badgeLabel}
            </Badge>
          )}
        </Flex>
      )}
    </>
  );
};
