import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@topo-io/design-system';
import { InlineField } from '@/components/workspace/common/share-workspace-modal/inline-field';
import {
  getFormattedRoadmapReminderDelays,
  REMINDER_DELAY_DEFAULT_VALUE,
  RoadmapReminderDelay,
} from './roadmap.constants';

const getSecondsKey = (seconds: number) => {
  const index = Object.values(RoadmapReminderDelay).indexOf(seconds as RoadmapReminderDelay);
  const key = Object.keys(RoadmapReminderDelay)[index];
  return key;
};

export const RoadmapDelayEmailReminderSettings = ({
  roadmapReminderDelay,
  updateRoadmapReminderDelay,
}: {
  roadmapReminderDelay?: number;
  updateRoadmapReminderDelay: (value: number) => void;
}) => {
  const [delayValue, setDelayValue] = useState<RoadmapReminderDelay>(
    roadmapReminderDelay ?? RoadmapReminderDelay.ONE_DAY
  );

  useEffect(() => {
    if (roadmapReminderDelay) {
      setDelayValue(roadmapReminderDelay);
    }
  }, [roadmapReminderDelay]);

  const formattedRoadmapReminderDelays = getFormattedRoadmapReminderDelays();

  const roadmapReminderDelayLabel = delayValue
    ? formattedRoadmapReminderDelays[getSecondsKey(delayValue)].text
    : formattedRoadmapReminderDelays[REMINDER_DELAY_DEFAULT_VALUE].text;

  const getOnClickMenuItem = (delay: { text: string; value: RoadmapReminderDelay }) => () => {
    setDelayValue(delay.value);
    updateRoadmapReminderDelay(delay.value);
  };

  return (
    <InlineField label={t`How many days before the task`} my="2">
      <Menu>
        <MenuButton
          px="2"
          my="0"
          as={Button}
          bgColor="gray.40"
          color="gray.500"
          _hover={{
            bgColor: 'gray.20',
          }}
          _active={{
            bgColor: 'gray.20',
          }}
          leftIcon={<Icon icon="clock" />}
        >
          {roadmapReminderDelayLabel}
        </MenuButton>
        <MenuList>
          {Object.values(formattedRoadmapReminderDelays).map((delay) => (
            <MenuItem onClick={getOnClickMenuItem(delay)} key={delay.value}>
              {delay.text}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </InlineField>
  );
};
