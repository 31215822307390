import { Container } from '@topo-io/design-system';
import { RoadmapProvider } from '@/components/roadmap/contexts/roadmap-context';
import { RoadmapBlock } from '@/components/roadmap/roadmap-block';
import { RoadmapTaskDrawer } from '@/components/roadmap/roadmap-task-drawer';
import { TemplateLogo, TemplateEditionBanner } from '@/components/workspace/common';
import { useTemplate } from '@/components/workspace/hooks';
import { useQueryParamId, useUser } from '@/hooks';

export const TemplateRoadmap = () => {
  const id = useQueryParamId();
  const { user, isAnonymousUser } = useUser();
  const { template } = useTemplate(id);

  const isTemplateOwner = !isAnonymousUser(user) && template?.owner.id === user?.id;

  return (
    <Container maxW="container.lg">
      {template && <TemplateLogo template={template} />}
      {!isTemplateOwner && <TemplateEditionBanner />}
      <RoadmapProvider canEditRoadmap={isTemplateOwner}>
        <RoadmapBlock />
        <RoadmapTaskDrawer />
      </RoadmapProvider>
    </Container>
  );
};
