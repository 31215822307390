import { useContext } from 'react';
import { isBrowser } from '@topo-io/utils';
import { AnalyticsContext } from '@/contexts';
import { useDebug } from '@/hooks/use-debug';

export const useAnalytics = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const debug = useDebug();

  if (!analyticsContext) {
    throw new Error('Context used outside of its Provider!');
  }

  if (debug && isBrowser() && 'analytics' in window) {
    (window.analytics as { debug: (isDebug: boolean) => void })?.debug(true);
  }

  return analyticsContext;
};
