import { Box, Flex, Text } from '@topo-io/design-system';
import { formatLocalizedLongDate, isLastElement } from '@topo-io/utils';
import { TimelineRow } from './timeline-row';
import type { TimelineActivities } from './timeline.types';

interface DailyActivity {
  day: string;
  activities: TimelineActivities;
}

interface Props {
  dailyActivity: DailyActivity;
}

export const TimelineDay = ({ dailyActivity }: Props) => {
  const { day, activities } = dailyActivity;
  const formattedDate = formatLocalizedLongDate(day);

  return (
    <Box
      borderBottomWidth="1px"
      borderBottomColor="gray.30"
      mt={4}
      _last={{ borderBottom: 'none' }}
    >
      <Flex>
        <Box
          bg="white"
          px="3"
          py="1"
          borderWidth="1px"
          borderColor=" gray.30"
          borderRadius="md"
          mb={4}
        >
          <Text color="gray.100">{formattedDate}</Text>
        </Box>
      </Flex>
      <Box>
        {activities.map((activity, index) => (
          <TimelineRow
            activity={activity}
            isLast={isLastElement(activities, index)}
            key={activity.id}
          />
        ))}
      </Box>
    </Box>
  );
};
