import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { getOperationName, useQuery } from '@topo-io/graphql';
import type { DocumentNode, PaginationObject } from '@topo-io/graphql';
import { IndexSortDirection } from '@topo-io/types';
import { isNotNil } from '@topo-io/utils';
import type { SearchContextProps } from './search-context';
import { SearchContext } from './search-context';
import { transformFilters } from './search-utils';
import type { SearchGraph } from './types';

interface SearchContextProviderProps extends PropsWithChildren {
  graphQLDocument: DocumentNode;
  searchQuery?: string;
  defaultSortAttribute?: string;
  defaultSortDirection?: IndexSortDirection;
  defaultPerPage?: number;
}

const DEFAULT_DIRECTION = IndexSortDirection.DESC;
const DEFAULT_PER_PAGE = 20;
const DEFAULT_PAGE = 1;

export const SearchProvider = ({
  graphQLDocument,
  defaultSortAttribute = 'updatedAt',
  defaultSortDirection = DEFAULT_DIRECTION,
  defaultPerPage = DEFAULT_PER_PAGE,
  children,
  ...props
}: SearchContextProviderProps) => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(props.searchQuery);
  const [searchFilters, setSearchFilters] = useState<SearchGraph>([]);
  const [sortAttribute, setSortAttribute] = useState<string>(defaultSortAttribute);
  const [sortDirection, setSortDirection] = useState<IndexSortDirection>(defaultSortDirection);
  const [perPage, setPerPage] = useState<number>(defaultPerPage);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);

  const [pagination, setPagination] = useState<PaginationObject>({
    totalPages: 0,
    totalRecords: 0,
  });

  const filters = useMemo(() => transformFilters(searchFilters), [searchFilters]);
  const orderBy = useMemo(
    () => ({ [sortAttribute]: sortDirection }),
    [sortAttribute, sortDirection]
  );

  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery(graphQLDocument, {
    variables: {
      searchInput: {
        query: searchQuery,
        filters,
        orderBy,
        perPage,
        page,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const operationName = getOperationName(graphQLDocument);
  const results = data?.[operationName]?.results ?? [];

  useEffect(() => {
    const queryPagination: PaginationObject = data?.[operationName]?.pagination ?? null;
    if (isNotNil(queryPagination)) {
      setPagination(queryPagination);
    }
  }, [data, operationName]);

  const value: SearchContextProps = {
    results,
    pagination,
    refresh: refetch,
    isLoading,
    searchQuery,
    setSearchQuery,
    searchFilters,
    setSearchFilters,
    sortAttribute,
    setSortAttribute,
    sortDirection,
    setSortDirection,
    perPage,
    setPerPage,
    page,
    setPage,
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
