import { t } from '@lingui/macro';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { EventTrackingEvents } from '@topo-io/constants';
import type { UploadedFile } from '@topo-io/design-system';
import { useToast } from '@topo-io/design-system';
import type { FileUsage, Variable } from '@topo-io/graphql';
import {
  FileUsageUsage,
  FILES_USAGES,
  useCreateFileMutation,
  useCreateFileUsageMutation,
  useDeleteFileUsageMutation,
} from '@topo-io/graphql';
import { RichTextEditorProvider } from '@topo-io/rich-text-editor';
import { isNil } from '@topo-io/utils';
import { getEditorTranslations } from '@/components/rich-text-editor/utils/translations';
import type { VariableMapping } from '@/components/rich-text-editor/utils/variable-utils';
import {
  getVariableName,
  getVariableLabel,
} from '@/components/rich-text-editor/utils/variable-utils';
import { useBaseRoutesContext } from '@/components/routing';
import { useWorkspace } from '@/components/workspace/hooks/use-workspace';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useAnalytics, useUser } from '@/hooks';

export const SectionEditorProvider = ({
  sectionContentId,
  filesUsages,
  variables,
  children,
}: {
  sectionContentId?: string;
  filesUsages?: FileUsage[];
  variables?: Variable[];
  children: ReactNode;
}) => {
  const { user, isEditor, isIdentifiedUser } = useUser();
  const { track } = useAnalytics();
  const { id } = useWorkspaceContext();
  const { isSectionTemplatePage } = useBaseRoutesContext();
  const { workspace } = useWorkspace(id!, { skip: isNil(id) || isSectionTemplatePage });
  const { errorToast } = useToast();
  const [createFileMutation] = useCreateFileMutation();
  const [createFileUsageMutation] = useCreateFileUsageMutation({
    refetchQueries: [FILES_USAGES],
  });
  const [deleteFileUsageMutation] = useDeleteFileUsageMutation();

  const createFile = async ({
    ext,
    type,
    checksum,
    name,
    size,
  }: {
    ext: string;
    type: string;
    checksum: string;
    name: string;
    size: number;
  }): Promise<UploadedFile | undefined> => {
    const { data, errors } = await createFileMutation({
      variables: {
        input: {
          type,
          checksum,
          ext,
          name,
          size,
        },
      },
    });

    if (errors) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }

    return data?.createFile;
  };

  const createFileUsage = async (fileId: string): Promise<string | undefined> => {
    if (!sectionContentId) {
      console.warn('sectionContentId is provided for file usage creation');
      return;
    }

    if (!isIdentifiedUser(user) || !isEditor(user)) {
      return;
    }

    const { data, errors } = await createFileUsageMutation({
      variables: {
        input: {
          fileId,
          relatedId: sectionContentId,
          relatedType: 'sectionContent',
          usage: FileUsageUsage.SECTION,
          ownerId: user.id,
        },
      },
    });

    if (errors) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }

    return data?.createFileUsage.id;
  };

  const deleteFileUsage = async (id: string): Promise<void> => {
    try {
      await deleteFileUsageMutation({
        variables: {
          id,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const variablesMapping: VariableMapping = useMemo(() => {
    if (!variables) {
      return {};
    }
    return variables.reduce((mapping, variable) => {
      mapping[variable.type] = {
        type: variable.type,
        value: variable.value,
        name: getVariableName(variable.type),
        label: getVariableLabel(variable.type),
      };
      return mapping;
    }, {});
  }, [variables]);

  return (
    <RichTextEditorProvider
      recordId={sectionContentId}
      storedFiles={filesUsages}
      createFile={createFile}
      deleteFile={deleteFileUsage}
      linkFileToRecord={createFileUsage}
      translations={getEditorTranslations()}
      variablesContext={{
        mapping: variablesMapping,
        workspaceCompanyName: workspace?.company?.name,
        ownerCompanyName: workspace?.owner.company?.name,
        onVariableAdded: (type) => {
          track({
            eventName: EventTrackingEvents.VARIABLE_ADDED,
            properties: {
              type,
            },
          });
        },
      }}
    >
      {children}
    </RichTextEditorProvider>
  );
};
