import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { CustomSwitch } from '@topo-io/design-system';
import { InlineField } from '@/components/workspace/common/share-workspace-modal/inline-field';

export const NotificationWorkspaceSharedSettings = ({
  defaultSettingsNotifyWorkspaceShared,
  updateSettingsNotifyWorkspaceShared,
}: {
  defaultSettingsNotifyWorkspaceShared: boolean;
  updateSettingsNotifyWorkspaceShared: (value: boolean) => void;
}) => {
  const [notifyWorkspaceShared, setNotifyWorkspaceShared] = useState<boolean>(
    defaultSettingsNotifyWorkspaceShared
  );

  useEffect(() => {
    setNotifyWorkspaceShared(defaultSettingsNotifyWorkspaceShared);
  }, [defaultSettingsNotifyWorkspaceShared]);

  const onChangeSwitchSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.currentTarget.checked;
    setNotifyWorkspaceShared(checkedValue);
    updateSettingsNotifyWorkspaceShared(checkedValue);
  };

  return (
    <InlineField label={t`Notify me when workspace is shared`} my="2">
      <CustomSwitch
        name="notifyWorkspaceShared"
        aria-label="settings-notify-workspace-shared-checkbox"
        isChecked={notifyWorkspaceShared}
        onChange={onChangeSwitchSettings}
      />
    </InlineField>
  );
};
