import { TAGS_FILTER_WIDTH } from '@/config';
import { useSidebarWidth } from './use-sidebar-width';

export const useTableContainerWidth = () => {
  const { sidebarWidth } = useSidebarWidth();
  const layoutPaddingLeft = '32px';
  const layoutPaddingRight = '32px';

  return `calc(100vw - (${sidebarWidth} + ${TAGS_FILTER_WIDTH} + ${layoutPaddingLeft} + ${layoutPaddingRight}))`;
};
