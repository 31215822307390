import { ModalTabPanel } from '@topo-io/design-system';
import type { NotificationSettings, Workspace } from '@topo-io/graphql';
import {
  useNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from '@topo-io/graphql';
import { useDebouncedCallback } from '@topo-io/hooks';
import { NotificationSettingsActivities } from './notification-settings-activities';
import { NotificationSettingsRoadmap } from './notification-settings-roadmap';

interface NotificationSettingsTabProps {
  workspaceId: Workspace['id'];
}

export const NotificationSettingsTab = ({ workspaceId }: NotificationSettingsTabProps) => {
  const { data } = useNotificationSettingsQuery({ variables: { workspaceId } });

  const [updateNotificationSettings] = useUpdateNotificationSettingsMutation();

  const debouncedUpdateNotificationSettings = useDebouncedCallback(
    async (notificationSettings: Partial<NotificationSettings>) => {
      await updateNotificationSettings({
        variables: {
          ...notificationSettings,
          workspaceId,
        },
      });
    },
    300
  );

  const notifyWorkspaceOpened = data?.notificationSettings?.notifyWorkspaceOpened ?? false;
  const notifyWorkspaceShared = data?.notificationSettings?.notifyWorkspaceShared ?? false;

  const roadmapReminderRule = data?.notificationSettings?.roadmapReminderRule;
  const roadmapReminderDelay = data?.notificationSettings?.roadmapReminderDelay;

  return (
    <ModalTabPanel>
      <NotificationSettingsActivities
        defaultSettingsNotifyWorkspaceOpened={notifyWorkspaceOpened}
        defaultSettingsNotifyWorkspaceShared={notifyWorkspaceShared}
        updateNotificationSettings={debouncedUpdateNotificationSettings}
      />

      <NotificationSettingsRoadmap
        roadmapReminderRule={roadmapReminderRule}
        roadmapReminderDelay={roadmapReminderDelay}
        updateNotificationSettings={debouncedUpdateNotificationSettings}
      />
    </ModalTabPanel>
  );
};
