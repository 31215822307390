export interface SearchFilter {
  type: 'filter';
  key: string;
  attribute: string;
  operator: 'in' | 'equals' | 'none' | 'every' | 'some' | 'gte' | 'lte' | 'gt' | 'lt';
  value: unknown;
  enabled: boolean;
  parentNodeKey?: string;
}

export interface SearchOperator {
  type: 'operator';
  key: string;
  operator: 'OR' | 'AND' | 'NOT';
  enabled: boolean;
  parentNodeKey?: string;
}

export type SearchNode = SearchFilter | SearchOperator;

export type SearchGraph = SearchNode[];

export const isSearchFilter = (node: SearchNode): node is SearchFilter => {
  return node.type === 'filter';
};

export const isSearchOperator = (node: SearchNode): node is SearchOperator => {
  return node.type === 'operator';
};

export interface SearchResult {
  id: string;
}
