import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import { useState, useEffect, useMemo } from 'react';
import type { ToastFn } from '@topo-io/design-system';
import { useToast } from '@topo-io/design-system';
import { ApolloProvider } from '@topo-io/graphql';
import { usePrevious } from '@topo-io/hooks';
import { DEFAULT_GQL_URL, getGraphqlUrl, initApolloClient } from '@/config';
import { GraphqlContext } from '@/contexts/graphql-context/graphql-context';

export const GraphqlProvider: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { errorToast } = useToast();
  const [slug, setSlug] = useState<string | undefined>(undefined);
  const previous = usePrevious(slug);
  const [hasUrlChanged, setUrlChanged] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(() => DEFAULT_GQL_URL);
  const { query } = useRouter();
  const jwtToken = useMemo(() => {
    return query?.token instanceof Array ? query?.token[0] : query?.token;
  }, [query?.token]);
  const [apolloClient, setApolloClient] = useState(() =>
    initApolloClient(url, errorToast, jwtToken, slug)
  );

  useEffect(() => {
    const setUrlAsync = async () => {
      setUrlChanged(true);
      setUrl(await getGraphqlUrl());
    };
    void setUrlAsync();
  }, []);

  useEffect(() => {
    if (!hasUrlChanged) {
      return;
    }

    const setApolloClientAsync = (url: string, errorToast: ToastFn) => {
      const client = initApolloClient(url, errorToast, jwtToken, slug);
      setApolloClient(client);
      setUrlChanged(false);
    };
    setApolloClientAsync(url, errorToast);
  }, [url, hasUrlChanged, errorToast, jwtToken, slug]);

  useEffect(() => {
    if (previous === slug) {
      return;
    }

    const client = initApolloClient(url, errorToast, jwtToken, slug);
    setApolloClient(client);
  }, [slug, previous, url, errorToast, jwtToken]);

  if (!apolloClient) {
    return null;
  }

  return (
    <GraphqlContext.Provider value={{ slug, setSlug }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </GraphqlContext.Provider>
  );
};
