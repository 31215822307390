export const SAVING_STATUS_MUTATIONS_TO_LISTEN = [
  // sections
  'createSection',
  'createSectionFromTemplate',
  'updateSection',
  'deleteSection',
  'updateSectionTemplate',
  'updateDetailsWorkspace',
  'updateDetailsTemplate',

  // mutual action plan
  'createStep',
  'updateStep',
  'deleteStep',
  'createTask',
  'updateTask',
  'updateTaskAssignees',
  'deleteTask',

  // files
  'createFileUsage',
  'updateFileUsage',
  'deleteFileUsage',

  // tags
  'createCategory',
  'updateCategory',
  'deleteCategory',
  'createTag',
  'updateTag',
  'deleteTag',

  // Tabs
  'updateWorkspaceTab',
];
