import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@topo-io/design-system';
import type { Comment } from '@topo-io/graphql';
import { useDeleteCommentMutation, CommentsDocument } from '@topo-io/graphql';

interface CommentActionsProps {
  commentId: Comment['id'];
  onEdit: () => void;
}

export const CommentActions: FC<CommentActionsProps> = ({ commentId, onEdit }) => {
  const [deleteComment] = useDeleteCommentMutation({
    variables: { commentId },
    refetchQueries: [CommentsDocument],
    awaitRefetchQueries: true,
  });

  const onDelete = () => {
    void deleteComment();
  };

  return (
    <Menu variant="default">
      {({ isOpen }) => (
        <>
          <MenuButton
            size="xs"
            isActive={isOpen}
            as={IconButton}
            bgColor="white"
            variant="ghost"
            icon={<Icon icon="more-horizontal" />}
          />
          <MenuList>
            <MenuItem
              icon={<Icon icon="edit-2" position="relative" top="-1px" />}
              color="black.500"
              onClick={onEdit}
            >
              <Trans>Edit</Trans>
            </MenuItem>
            <MenuItem
              icon={<Icon icon="trash" position="relative" top="-1px" />}
              color="red.500"
              onClick={onDelete}
            >
              <Trans>Delete</Trans>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
