import { Trans } from '@lingui/macro';
import { Text } from '@topo-io/design-system';
import { Action } from '@topo-io/graphql';
import { Person } from './person';
import type { TimelineActivity } from './timeline.types';
import { isActivityCommentSection, isLegacyActivityWorkspace } from './utils';

interface ActionIndirectObjectProps {
  activity: TimelineActivity;
}

export const ActionIndirectObject = ({ activity }: ActionIndirectObjectProps) => {
  if (activity.action === Action.OpenWorkspace) {
    return <></>;
  }

  if (isLegacyActivityWorkspace(activity)) {
    return (
      <>
        <Text>
          &nbsp;
          <Trans>with</Trans>
          &nbsp;
        </Text>
        <Person
          firstName={activity.member?.firstName}
          lastName={activity.member?.lastName}
          email={activity.member?.email}
        />
      </>
    );
  }

  if (isActivityCommentSection(activity)) {
    return (
      <Text>
        &nbsp;
        <Trans>on</Trans>
        &nbsp;
        <Text as="span" fontWeight="bold">
          {activity.comment?.sectionTitle ?? <Trans>Untitled section</Trans>}
        </Text>
      </Text>
    );
  }

  return <></>;
};
