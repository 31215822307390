import { Container } from '@topo-io/design-system';
import { RoadmapProvider } from '@/components/roadmap/contexts/roadmap-context';
import { RoadmapBlock } from '@/components/roadmap/roadmap-block';
import { RoadmapTaskDrawer } from '@/components/roadmap/roadmap-task-drawer';
import { WorkspaceLogo } from '@/components/workspace';
import { useWorkspace, useWorkspaceContext } from '@/components/workspace/hooks';
import { useQueryParamId } from '@/hooks';

export const WorkspaceRoadmap = () => {
  const id = useQueryParamId();
  const { workspace } = useWorkspace(id);
  const { readOnly, isPreview } = useWorkspaceContext();

  return (
    <Container maxW="container.lg">
      {workspace && <WorkspaceLogo workspace={workspace} />}
      <RoadmapProvider canEditRoadmap={!readOnly || isPreview}>
        <RoadmapBlock />
        <RoadmapTaskDrawer />
      </RoadmapProvider>
    </Container>
  );
};
