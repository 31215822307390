import type { FC } from 'react';
import type { WorkspaceQuery } from '@topo-io/graphql';
import { DetailsLogo } from './details-logo';

interface WorkspaceLogoProps {
  workspace?: Pick<WorkspaceQuery['workspace'], 'company' | 'owner'>;
  withBorder?: boolean;
  withPadding?: boolean;
}

export const WorkspaceLogo: FC<WorkspaceLogoProps> = ({
  workspace,
  withBorder = true,
  withPadding = true,
}) => {
  const account = workspace?.company;
  const company = workspace?.owner?.company;

  if (!company && !account) {
    return <></>;
  }
  return (
    <DetailsLogo
      companyName={company?.name}
      companyLogoUrl={company?.logoUrl}
      accountName={account?.name}
      accountLogoUrl={account?.logoUrl}
      withBorder={withBorder}
      withPadding={withPadding}
    />
  );
};
