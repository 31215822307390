import { t } from '@lingui/macro';
import type { FC } from 'react';
import { useState } from 'react';
import type { UploadedFile } from '@topo-io/design-system';
import { Button, FileUploadModal, Icon, useToast } from '@topo-io/design-system';
import type { TemplateQuery, WorkspaceQuery } from '@topo-io/graphql';
import {
  FileUsageUsage,
  FILES_USAGES,
  useCreateFileMutation,
  useCreateFileUsageMutation,
} from '@topo-io/graphql';
import { isNil, isNotNil } from '@topo-io/utils';

interface AddCoverButtonProps {
  workspace?: WorkspaceQuery['workspace'];
  template?: TemplateQuery['template'];
}

export const AddCoverButton: FC<AddCoverButtonProps> = ({ workspace, template }) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

  const { errorToast } = useToast();
  const [createFileMutation] = useCreateFileMutation();
  const [createFileUsageMutation] = useCreateFileUsageMutation({
    refetchQueries: [
      {
        query: FILES_USAGES,
        variables: {
          input: {
            ...(workspace && { workspaceId: workspace.id }),
            ...(template && { workspaceTemplateId: template.id }),
          },
        },
      },
    ],
  });

  const createFile = async ({
    ext,
    type,
    checksum,
    name,
    size,
  }: {
    ext: string;
    type: string;
    checksum: string;
    name: string;
    size: number;
  }) => {
    const { data, errors } = await createFileMutation({
      variables: {
        input: {
          type,
          checksum,
          ext,
          name,
          size,
        },
      },
    });
    if (isNotNil(errors) || isNil(data)) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }
    return data.createFile;
  };

  const createFileUsage = async (fileId: string) => {
    if (isNil(workspace) && isNil(template)) {
      return;
    }
    const { data, errors } = await createFileUsageMutation({
      variables: {
        input: {
          fileId,
          usage: FileUsageUsage.COVER,
          ...(template
            ? { relatedType: 'workspaceTemplate', relatedId: template.id }
            : { relatedType: 'workspace', relatedId: workspace!.id }),
        },
      },
    });
    if (isNotNil(errors) || isNil(data)) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }
    return data.createFileUsage.id;
  };

  const handleUploadCompleted = (uploadedFile: UploadedFile) => {
    void createFileUsage(uploadedFile.fileId);
    setIsFileUploadModalOpen(false);
  };

  return (
    <>
      <Button
        leftIcon={<Icon icon="Image" w="16px" h="16px" />}
        color="gray.300"
        variant="ghost"
        size="lg"
        _hover={{ background: 'gray.30' }}
        borderRadius="8px"
        onClick={() => setIsFileUploadModalOpen(true)}
      >
        Add cover
      </Button>
      <FileUploadModal
        isOpen={isFileUploadModalOpen}
        onClose={() => setIsFileUploadModalOpen(false)}
        onUploadCompleted={handleUploadCompleted}
        accept="image"
        createFile={createFile}
      />
    </>
  );
};
