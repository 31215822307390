import type { WorkspaceQuery } from '@topo-io/graphql';
import { useWorkspaceQuery } from '@topo-io/graphql';

export const useWorkspace = (
  workspaceId: string,
  options?: {
    skip?: boolean;
  }
): {
  isLoading: boolean;
  workspace: WorkspaceQuery['workspace'] | undefined;
} => {
  const { data, loading: isLoading } = useWorkspaceQuery({
    variables: { id: workspaceId },
    skip: options?.skip,
  });
  const workspace = data?.workspace;

  return {
    workspace,
    isLoading,
  };
};
