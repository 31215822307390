import { Action } from '@topo-io/graphql';
import type { TimelineActivity } from '@/components/timeline/timeline.types';

type ActivityCommentSection = Extract<TimelineActivity, { __typename: 'ActivityCommentSection' }>;

export const isActivityCommentSection = (
  activity: TimelineActivity
): activity is ActivityCommentSection => {
  return [Action.CommentSection].includes(activity.action);
};
