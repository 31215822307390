import { t, Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useState } from 'react';
import {
  Button,
  Box,
  Text,
  Flex,
  Icon,
  InputGroup,
  Input,
  InputRightElement,
} from '@topo-io/design-system';
import { formatShortDate, isNil, isNotNil } from '@topo-io/utils';
import { EditableDate } from '@/components/common';
import { useWorkspaceFiltersContext } from '@/components/workspace/list/filters/workspace-filters-context';
import { useSearchFilters } from '@/hooks';

interface DateRangeFilterProps {
  stateKey: 'closeDate';
  title: string;
}

export const DateRangeFilter: FC<DateRangeFilterProps> = ({ stateKey, title }) => {
  const { updateFilter, updateOperator } = useSearchFilters();
  const { filtersState, addFilter, updateSelectedFilter } = useWorkspaceFiltersContext();
  const [startDate, setStartDate] = useState<string | null>(filtersState?.[stateKey]?.gte ?? null);
  const [endDate, setEndDate] = useState<string | null>(filtersState?.[stateKey]?.lte ?? null);

  const handleApplyFilter = () => {
    addFilter(stateKey, {
      ...(startDate && { gte: startDate }),
      ...(endDate && { lte: endDate }),
    });
    updateOperator({
      key: `${stateKey}Operator`,
      operator: 'AND',
      enabled: isNotNil(startDate) || isNotNil(endDate),
    });
    updateFilter({
      attribute: stateKey,
      value: startDate,
      operator: 'gte',
      parentNodeKey: `${stateKey}Operator`,
      enabled: isNotNil(startDate),
    });
    updateFilter({
      attribute: stateKey,
      value: endDate,
      operator: 'lte',
      parentNodeKey: `${stateKey}Operator`,
      enabled: isNotNil(endDate),
    });
    updateSelectedFilter(null);
  };

  return (
    <Box padding="4" w="350px">
      <Text>{title}</Text>
      <Flex mt="4" alignItems="center" gap="5px">
        <EditableDate
          setDate={(newDate: Date) => setStartDate(newDate.toISOString())}
          date={isNotNil(startDate) ? new Date(startDate) : undefined}
        >
          <InputGroup>
            <Input
              placeholder={t`Start date`}
              value={startDate ? formatShortDate(startDate) : undefined}
            />
            <InputRightElement cursor="pointer">
              <Icon icon="calendar" />
            </InputRightElement>
          </InputGroup>
        </EditableDate>
        <EditableDate
          setDate={(newDate: Date) => setEndDate(newDate.toISOString())}
          date={isNotNil(endDate) ? new Date(endDate) : undefined}
        >
          <InputGroup>
            <Input
              placeholder={t`End date`}
              value={endDate ? formatShortDate(endDate) : undefined}
            />
            <InputRightElement cursor="pointer">
              <Icon icon="calendar" />
            </InputRightElement>
          </InputGroup>
        </EditableDate>
      </Flex>
      <Flex mt="4" alignItems="center" gap="10px" justifyContent="flex-end">
        <Button colorScheme="gray" onClick={() => updateSelectedFilter(null)}>
          <Trans>Cancel</Trans>
        </Button>
        <Button isDisabled={isNil(startDate) && isNil(endDate)} onClick={handleApplyFilter}>
          <Trans>Apply</Trans>
        </Button>
      </Flex>
    </Box>
  );
};
