import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { VStack, Text } from '@topo-io/design-system';
import type { CommentsQuery } from '@topo-io/graphql';
import { isEmptyArray } from '@topo-io/utils';
import { Comment } from './comment';

interface CommentListProps {
  comments: CommentsQuery['comments'];
}

export const CommentList: FC<CommentListProps> = ({ comments }) => {
  if (isEmptyArray(comments)) {
    return (
      <Text color="gray.100">
        <Trans>No comments.</Trans>
      </Text>
    );
  }
  return (
    <VStack h="full" w="full">
      <>
        {comments.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </>
    </VStack>
  );
};
