import { useCallback } from 'react';
import { FILES_USAGES, useDesynchronizeSectionFromTemplateMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import type { DesynchronizeSectionFromTemplateInput } from '@/components/section/types';
import { getCacheSections } from '@/components/section/utils/cache-utils';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';

export const useDesynchronizeSection = ({
  workspaceTabId,
}: {
  workspaceTabId: string | undefined;
}) => {
  const { setSectionIdToRerender } = useWorkspaceContext();

  const [desynchronizeSectionMutation] = useDesynchronizeSectionFromTemplateMutation({
    update: (cache, { data }) => {
      const updatedSections = data?.desynchronizeSectionFromTemplate;
      if (isNil(updatedSections)) {
        return;
      }
      cache.modify(getCacheSections(updatedSections));
    },
    refetchQueries: [FILES_USAGES],
  });

  const desynchronizeSection = useCallback(
    ({ templateId, sectionId }: DesynchronizeSectionFromTemplateInput) => {
      if (isNil(workspaceTabId)) {
        return;
      }
      void desynchronizeSectionMutation({
        variables: {
          input: {
            sectionTemplateId: templateId,
            sectionId,
            workspaceTabIdToRefresh: workspaceTabId,
          },
        },
      });
      setSectionIdToRerender(sectionId);
    },
    [desynchronizeSectionMutation, workspaceTabId, setSectionIdToRerender]
  );

  return { desynchronizeSection };
};
