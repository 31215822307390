import { Block } from '@topo-io/design-system';
import { WorkspaceLogo } from '@/components/workspace';
import { useWorkspace } from '@/components/workspace/hooks';
import { useQueryParamId } from '@/hooks';
import { DetailsLayout } from './details-layout';
import { WorkspaceDetailsBanner } from './workspace-details-banner';
import { WorkspaceEditForm } from './workspace-edit-form';

export const WorkspaceDetails = () => {
  const id = useQueryParamId();
  const { workspace } = useWorkspace(id);

  return (
    <DetailsLayout>
      {workspace && <WorkspaceLogo workspace={workspace} />}
      <WorkspaceDetailsBanner />

      <Block mt="4">
        <WorkspaceEditForm />
      </Block>
    </DetailsLayout>
  );
};
