import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useIsSectionTemplate } from './use-is-section-template';

export const useSectionTemplateId = () => {
  const { query } = useRouter();
  const isSection = useIsSectionTemplate();
  const id = useMemo(() => {
    if (!isSection) {
      return null;
    }
    return query?.id instanceof Array ? query?.id[0] : query?.id;
  }, [query?.id, isSection]);
  return id;
};
