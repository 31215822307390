import { SubscriptionType, useSubscriptionQuery } from '@topo-io/graphql';
import { intervalToDuration, isNil, isNotNil } from '@topo-io/utils';
import { useUser } from './use-user';

export const useSubscription = () => {
  const { user } = useUser();
  const { data, loading: isLoading } = useSubscriptionQuery({
    skip: isNil(user),
    variables: {
      input: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        organizationId: user?.organizationId!,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const subscription = data?.subscription;
  const trialEndDate = isNotNil(subscription?.trialEndDate)
    ? new Date(subscription.trialEndDate)
    : undefined;
  const remainingTrialDays =
    isNotNil(trialEndDate) && trialEndDate > new Date()
      ? intervalToDuration({ start: new Date(), end: trialEndDate }).days
      : undefined;

  const hasCRMFeatures =
    isNotNil(subscription) &&
    [SubscriptionType.LEGACY, SubscriptionType.PROFESSIONAL].includes(
      subscription.subscriptionType
    );

  return {
    subscription,
    isLoading,
    remainingTrialDays,
    hasCRMFeatures,
  };
};
