import { MembersDocument, useUpdateMemberMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import type { UpdateMemberFormValues } from '@/components/workspace/common/share-workspace-modal/invite-members-tab/types';

export const useUpdateMember = ({
  editingMemberId,
  onSubmitSuccess,
  onSubmitFailure,
  onSubmitCompleted,
}: {
  workspaceId: string;
  editingMemberId: string | undefined;
  onSubmitSuccess: () => void;
  onSubmitFailure: () => void;
  onSubmitCompleted: () => void;
}): {
  updateMember: (input: UpdateMemberFormValues) => Promise<void>;
} => {
  const [updateMemberMutation] = useUpdateMemberMutation();

  const updateMember = async (formPayload: UpdateMemberFormValues) => {
    const {
      firstName,
      lastName,
      jobTitle,
      company,
      CRMAccount,
      CRMId,
      clearCRMData: shouldClearCRMData,
    } = formPayload;
    if (isNil(editingMemberId)) {
      return;
    }

    const { errors } = await updateMemberMutation({
      variables: {
        input: {
          id: editingMemberId,
          firstName,
          lastName,
          jobTitle,
          company: company?.label
            ? {
                id: company.id,
                name: company.label,
                domainUrl: company.domainUrl,
                logoUrl: company.logoUrl,
              }
            : undefined,
          CRMId,
          CRMAccount,
          clearCRMData: shouldClearCRMData,
        },
      },
      refetchQueries: [MembersDocument],
    });

    if (errors) {
      onSubmitFailure();
    } else {
      onSubmitSuccess();
    }
    onSubmitCompleted();
  };

  return {
    updateMember,
  };
};
