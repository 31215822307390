import { ErrorBoundary } from '@sentry/nextjs';
import { useEffect, useRef, useState } from 'react';
import { Box, SortableItem, SortableList, VStack } from '@topo-io/design-system';
import { useHoverDirty } from '@topo-io/hooks';
import { getFirstElement, getLastPosition, isNotNil } from '@topo-io/utils';
import { useWorkspaceContext } from '@/components/workspace';
import { AddTask } from './add-task';
import { useUpdateTaskPositionMutationWithCache, useRoadmapContext } from './hooks';
import { useCreateTaskMutationWithCache } from './hooks/use-create-task-mutation-with-cache';
import { StepHeader } from './step-header';
import { Task } from './task';
import type { RoadmapStep } from './types';

const MINIMUM_INDEX = 0;
const NEXT_INDEX = 1;

export const Step = ({ step }: { step: RoadmapStep }) => {
  const taskIds = useRef<string[]>(step.tasks.map((t) => t.id));
  const [newestTaskId, setNewestTaskId] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const { isPreview, isGuest } = useWorkspaceContext();
  const { createTaskWithCache } = useCreateTaskMutationWithCache();
  const stepRef = useRef(null);
  const isHovering = useHoverDirty(stepRef);
  const onCreate = () =>
    createTaskWithCache({ stepId: step.id, position: getLastPosition(step.tasks) });
  const { updateTaskPositionWithCache } = useUpdateTaskPositionMutationWithCache();
  const { canEditRoadmap } = useRoadmapContext();

  useEffect(() => {
    const stepTaskIds = step.tasks.map((t) => t.id);
    const newTaskId = getFirstElement(stepTaskIds.filter((id) => !taskIds.current.includes(id)));
    if (isNotNil(newTaskId)) {
      setNewestTaskId(newTaskId);
      taskIds.current = stepTaskIds;
    }
  }, [step.tasks, taskIds]);

  const handleChange = (
    tasks: RoadmapStep['tasks'],
    updatedTask: RoadmapStep['tasks'][number],
    newPosition: number
  ) => {
    if (canEditRoadmap) {
      updateTaskPositionWithCache({
        updatedTask,
        newPosition,
        stepId: step.id,
      });
    }
  };

  const addTask = () => {
    onCreate();
  };

  const getPreviousTaskId = (task: RoadmapStep['tasks'][number]): string | undefined => {
    const taskIndex = step.tasks.indexOf(task);
    if (!taskIndex) {
      return;
    }
    return step.tasks[Math.max(MINIMUM_INDEX, taskIndex - NEXT_INDEX)].id;
  };

  const isStepHidden = !step.isVisible;
  const showAllTasks = !isPreview && !isGuest;
  const filteredTasks = showAllTasks ? step.tasks : step.tasks.filter((t) => t.isVisible);

  return (
    <VStack alignItems="stretch" spacing="0" data-testid={`step-${step.id}`}>
      <ErrorBoundary>
        <Box ref={stepRef}>
          {step && (
            <StepHeader isHovering={isHovering} isOpen={isOpen} setIsOpen={setIsOpen} step={step} />
          )}
        </Box>
      </ErrorBoundary>
      {isOpen && (
        <Box
          px="2"
          pt="2"
          data-testid={`step-${step.id}-inner`}
          {...(isStepHidden && { opacity: '0.5' })}
        >
          <Box>
            {step.tasks && (
              <SortableList
                items={filteredTasks}
                onChange={handleChange}
                renderItem={(task) => (
                  <ErrorBoundary>
                    <SortableItem id={task.id}>
                      <Task
                        key={task.id}
                        task={task}
                        stepId={step.id}
                        previousTaskId={getPreviousTaskId(task)}
                        isFocused={task.id === newestTaskId}
                      />
                    </SortableItem>
                  </ErrorBoundary>
                )}
              />
            )}
          </Box>
          {canEditRoadmap && <AddTask addTask={addTask} px={4} />}
        </Box>
      )}
    </VStack>
  );
};
