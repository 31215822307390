import { Trans } from '@lingui/macro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MENU_BUTTON_WORKSPACE_Z_INDEX } from '@topo-io/constants';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@topo-io/design-system';
import { WorkspaceTabFeatureName } from '@topo-io/graphql';
import { useWorkspaceTabs } from '@/components/workspace';
import type { WorkspaceTab } from '@/components/workspace/tabs';

export const WorkspaceTabEditMenu = ({
  workspaceTab,
  isVisible,
  onRename,
  openDeleteTabModal,
  openManageAccessModal,
}: {
  workspaceTab: WorkspaceTab;
  isVisible: boolean;
  onRename: () => void;
  openManageAccessModal: (workspaceTab: WorkspaceTab) => void;
  openDeleteTabModal: (workspaceTab: WorkspaceTab) => void;
}) => {
  const { workspaceTabAccess } = useFlags();
  const { isDisplayed, type } = workspaceTab;
  const { updateTab } = useWorkspaceTabs();

  return (
    <Menu variant="default">
      <MenuButton
        pl={1}
        as={IconButton}
        aria-label="more-btn"
        icon={<Icon icon="DotsVertical" width="16px" height="16px" />}
        variant="secondary"
        minWidth="auto"
        {...(!isVisible && { visibility: 'hidden' })}
        onClick={(e) => e.stopPropagation()}
      />
      <Portal>
        <MenuList zIndex={MENU_BUTTON_WORKSPACE_Z_INDEX}>
          <MenuItem
            icon={<Icon icon="edit-2" width="16px" height="16px" />}
            aria-label="rename-tab"
            onClick={(e) => {
              e.stopPropagation();
              onRename();
            }}
          >
            <Trans>Rename tab</Trans>
          </MenuItem>
          {isDisplayed ? (
            <MenuItem
              icon={<Icon icon="eye-off" width="16px" height="16px" />}
              aria-label="hide-tab"
              onClick={(e) => {
                e.stopPropagation();
                updateTab({ input: { isDisplayed: false }, tabId: workspaceTab.id });
              }}
            >
              <Trans>Hide tab</Trans>
            </MenuItem>
          ) : (
            <MenuItem
              icon={<Icon icon="eye" width="16px" height="16px" />}
              aria-label="show-tab"
              onClick={(e) => {
                e.stopPropagation();
                updateTab({ input: { isDisplayed: true }, tabId: workspaceTab.id });
              }}
            >
              <Trans>Show tab</Trans>
            </MenuItem>
          )}
          {workspaceTabAccess && (
            <MenuItem
              icon={<Icon icon="Lock01" width="16px" height="16px" />}
              aria-label="manage-tab-access"
              onClick={(e) => {
                e.stopPropagation();
                openManageAccessModal(workspaceTab);
              }}
            >
              <Trans>Manage access</Trans>
            </MenuItem>
          )}
          {type === WorkspaceTabFeatureName.CONTENT && (
            <MenuItem
              icon={<Icon icon="trash" position="relative" top="-1px" width="16px" height="16px" />}
              color="red.500"
              aria-label="delete-tab"
              onClick={(e) => {
                e.stopPropagation();
                openDeleteTabModal(workspaceTab);
              }}
            >
              <Trans>Delete tab</Trans>
            </MenuItem>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};
