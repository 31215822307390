import { Trans, t } from '@lingui/macro';
import type { FC } from 'react';
import { useState } from 'react';
import { Box, H5 } from '@topo-io/design-system';
import type { TaskQuery } from '@topo-io/graphql';
import type { TaskDescriptionJSON } from '@topo-io/rich-text-editor';
import { SimpleEditor } from '@/components/rich-text-editor/simple-editor';
import { useRoadmapContext } from '@/components/roadmap/hooks';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';

interface TaskDescriptionProps {
  task: TaskQuery['task'];
}

export const TaskDescription: FC<TaskDescriptionProps> = ({ task }) => {
  const { updateTask } = useTaskContext();
  const { canEditRoadmap } = useRoadmapContext();
  const [description, setDescription] = useState<TaskDescriptionJSON>(
    task.content?.[0] ?? {
      type: 'doc',
      content: [{ type: 'paragraph' }],
    }
  );

  const updateDescription = (description: TaskDescriptionJSON) => {
    setDescription(description);
    updateTask({ ...task, content: [description] });
  };

  return (
    <Box my={6}>
      <H5>
        <Trans>Description</Trans>
      </H5>
      <Box my={2} p={3} borderWidth={1} borderRadius="md" borderColor="gray.30">
        <SimpleEditor
          key={`task-${task.id}-${canEditRoadmap ? 'edit' : 'readonly'}`}
          initialContent={description}
          onChange={updateDescription}
          readOnly={!canEditRoadmap}
          placeholder={t`No description.`}
          shouldFocusContent={true}
        ></SimpleEditor>
      </Box>
    </Box>
  );
};
