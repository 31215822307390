import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import type { ItemType } from '@topo-io/design-system/src/components';
import { useCRMAccountsLazyQuery } from '@topo-io/graphql';
import type { CRMAccount, CRMAccountsQuery } from '@topo-io/graphql';
import { isBlank, isNil, orderArrayAlphabetically } from '@topo-io/utils';
import { DefaultComboboxOptions } from '@/components/common/fields/default-combobox-options';
import { useIntegrations } from '@/components/integrations/hooks';
import { ComboboxField } from './combobox-field';

export type CRMAccountItemType = CRMAccountsQuery['CRMAccounts'][number];

interface CRMAccountsFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  defaultCRMAccount?: CRMAccountItemType;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  clearable?: boolean;
  onClear?: () => void;
}

const LIMIT_OPTIONS = 100;

const formatAccount = (account: CRMAccount): ItemType => ({
  id: account.id,
  label: account.name,
  value: account.id,
  key: account.id,
});

export const CRMAccountsField = ({
  name,
  label,
  placeholder,
  defaultCRMAccount,
  onChange,
  clearable = false,
  onClear,
}: CRMAccountsFieldProps) => {
  const { isConnectedToOneIntegration } = useIntegrations();
  const [retrieveCRMAccounts] = useCRMAccountsLazyQuery();

  const optionsFn = useCallback(
    async (value: string | undefined): Promise<ItemType[]> => {
      const { data } = await retrieveCRMAccounts({
        variables: {
          filters: {
            limit: LIMIT_OPTIONS,
            search: value,
          },
        },
      });

      const shouldOrderResults = isNil(value) || isBlank(value);
      const accounts = data?.CRMAccounts ?? [];
      const sortedAccounts = shouldOrderResults
        ? orderArrayAlphabetically(accounts, (account) => account.name)
        : accounts;

      return sortedAccounts.map(formatAccount);
    },
    [retrieveCRMAccounts]
  );

  return (
    <ComboboxField
      showNewItem={false}
      options={optionsFn}
      render={DefaultComboboxOptions}
      name={name}
      label={label}
      placeholder={placeholder}
      isDisabled={!isConnectedToOneIntegration}
      defaultValue={defaultCRMAccount?.name}
      onChange={onChange}
      clearable={clearable}
      onClear={onClear}
    />
  );
};
