import { Text } from '@topo-io/design-system';
import { formatTimeFromDate } from '@topo-io/utils';

export const TimeIndicator = ({ date }: { date: string }) => {
  const at = new Date(date);
  return (
    <Text fontSize="sm" color="gray.100">
      {formatTimeFromDate(at)}
    </Text>
  );
};
