import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { Avatar, Block, HStack, VStack } from '@topo-io/design-system';
import { getDisplayName } from '@topo-io/utils';
import { CommentEditorProvider } from '@/components/rich-text-editor/providers/comment-editor-provider';
import { useUser } from '@/hooks';
import { CommentEditor } from './comment-editor';
import { scrollToWriteComment } from './scroll-to-comment';

interface WriteCommentProps {
  onCreate: () => void;
  commentParentId: string;
  commentParentType: 'task' | 'section';
  shouldFocusContent?: boolean;
}

export const WriteComment: FC<WriteCommentProps> = ({ onCreate, ...parentProps }) => {
  const { user, isAnonymousUser } = useUser();
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToWriteComment(componentRef.current);
  }, []);

  if (isAnonymousUser(user)) {
    return null;
  }

  return (
    <HStack ref={componentRef} alignItems="start" w="full">
      <Avatar size="sm" name={getDisplayName(user, user?.user.email)} src={user?.picture} />
      <Block pl={3} pt={3} pb={1} pr={1} w="full">
        <VStack alignItems="start">
          <CommentEditorProvider>
            <CommentEditor state="create" onCreate={onCreate} {...parentProps} />
          </CommentEditorProvider>
        </VStack>
      </Block>
    </HStack>
  );
};
