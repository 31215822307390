import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import type { MultiSelectItem } from '@topo-io/design-system';
import { MultiSelect } from '@topo-io/design-system';
import type { Tag, UpdateDetailsTemplateInput } from '@topo-io/graphql';
import { useCategoriesQuery } from '@topo-io/graphql';

interface Taggable {
  tags: Pick<Tag, 'id' | 'name'>[];
}

interface TemplateTagsFormProps<T extends Taggable> {
  template: T;
  updateTemplateDetails: (data: UpdateDetailsTemplateInput) => Promise<void>;
  readOnly?: boolean;
}

export const TemplateTagsForm = <T extends Taggable>({
  template,
  updateTemplateDetails,
  readOnly = false,
}: TemplateTagsFormProps<T>) => {
  const initialValue = template.tags?.map((t) => t.id);
  const { data, loading: isLoading } = useCategoriesQuery();

  const items: MultiSelectItem[] = useMemo(() => {
    const categories = data?.categories ?? [];
    return categories.flatMap((category) => {
      const group = { label: category.name, value: category.id };
      return category.tags.map((tag) => ({
        label: tag.name,
        value: tag.id,
        category: group,
      }));
    });
  }, [data?.categories]);

  const handleOnItemsChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const newIds = evt.target.value as unknown as string[];
    void updateTemplateDetails({ tagIds: newIds });
  };

  const isDisabled = isLoading || readOnly;

  if (isLoading) {
    return null;
  }

  return (
    <MultiSelect
      label="Tags"
      name="tags"
      initialValue={initialValue}
      items={items}
      onChange={handleOnItemsChange}
      isDisabled={isDisabled}
    />
  );
};
