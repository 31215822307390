import { t } from '@lingui/macro';
import { memo, useEffect, useRef } from 'react';
import { Block, Box, HStack, Spacer } from '@topo-io/design-system';
import { isEmptyEditorContent } from '@topo-io/rich-text-editor';
import type { ContentJSON } from '@topo-io/rich-text-editor';
import { useSectionActivityTracking, useShouldTrackAnalytics } from '@/components/analytics/hooks';
import { SectionEditor } from '@/components/rich-text-editor/section-editor';
import { SectionCtaComment } from '@/components/section/section-cta-comment';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { useUser } from '@/hooks';
import { SectionTitle } from './section-title';

interface SectionReadonlyPreviewModeProps {
  id: string;
  templateId?: string;
  title: string;
  show: boolean;
  numberOfComments: number;
  sectionContent: ContentJSON;
  sectionContentId?: string;
}

const doNothing = (): undefined => undefined;

export const SectionReadonlyPreviewMode = memo(
  ({
    id,
    templateId,
    sectionContent,
    title,
    show,
    numberOfComments,
    sectionContentId,
  }: SectionReadonlyPreviewModeProps) => {
    const { user } = useUser();
    const { isWorkspace } = useWorkspaceContext();
    const isEmptyContent = isEmptyEditorContent(sectionContent);
    const shouldTrackAnalytics = useShouldTrackAnalytics();

    const sectionRef = useRef<HTMLDivElement>(null);
    const { addSectionRef } = useWorkspaceContext();

    useEffect(() => {
      if (sectionRef.current) {
        addSectionRef(id, sectionRef);
      }
    }, [sectionRef, id, addSectionRef]);

    useSectionActivityTracking({
      skip: !shouldTrackAnalytics,
      sectionId: id,
      sectionTemplateId: templateId,
      organizationId: user?.organizationId,
    });

    if (!show) {
      return <></>;
    }

    return (
      <Box position="relative" id={`section-${id}`} ref={sectionRef}>
        <Block my={2}>
          <HStack alignContent="center" mb="4">
            <SectionTitle
              id={id}
              readOnly={true}
              title={title || t`Untitled section`}
              updateSectionTitle={doNothing}
            />
            {isWorkspace && (
              <SectionCtaComment sectionId={id} numberOfComments={numberOfComments} />
            )}
            <Spacer />
          </HStack>
          {!isEmptyContent && (
            <SectionEditor
              sectionId={id}
              readOnly={true}
              initialContent={sectionContent}
              setContent={doNothing}
              sectionContentId={sectionContentId}
            />
          )}
        </Block>
      </Box>
    );
  }
);

SectionReadonlyPreviewMode.displayName = 'SectionReadonlyPreviewMode';
