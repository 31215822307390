import { t } from '@lingui/macro';
import { useMemo } from 'react';
import type { FC } from 'react';
import { CUBE_CONSTANTS } from '@topo-io/constants';
import { ZERO, isEmptyArray, isNil, isString, formatAnalyticsDuration } from '@topo-io/utils';
import { AnalyticsCard, GuestRaw, GuestRawLoadingState } from '@/components/analytics/common';
import type { AnalyticsGuest, GuestsMapping } from '@/components/analytics/types';
import { getGuestOrMergedAnonymousUser } from '@/components/analytics/utils';
import { useAnalyticsQuery, useLocale } from '@/hooks';

interface TotalTimeSpentProps {
  workspaceId: string | undefined;
  guestsMapping: GuestsMapping | null;
}

type TotalTimeSpentByUser = Record<
  string,
  {
    user: AnalyticsGuest;
    timeInSeconds: number;
  }
>;

export const TotalTimeSpent: FC<TotalTimeSpentProps> = ({ workspaceId, guestsMapping }) => {
  const locale = useLocale();
  const { tableData, isLoading } = useAnalyticsQuery<Record<string, string>>({
    query: {
      limit: 5000,
      order: {
        [CUBE_CONSTANTS.WORKSPACE_VIEWS_ENGAGED_TIME]: 'desc',
      },
      filters: [
        {
          member: CUBE_CONSTANTS.WORKSPACE_VIEWS_WORKSPACE_ID,
          operator: 'equals',
          values: [workspaceId!],
        },
        {
          member: CUBE_CONSTANTS.WORKSPACE_VIEWS_ENGAGED_TIME,
          operator: 'notEquals',
          values: ['0'],
        },
      ],
      measures: [CUBE_CONSTANTS.WORKSPACE_VIEWS_ENGAGED_TIME],
      timeDimensions: [
        {
          dimension: CUBE_CONSTANTS.WORKSPACE_VIEWS_START_TIMESTAMP,
        },
      ],
      dimensions: [CUBE_CONSTANTS.WORKSPACE_VIEWS_USER_ID],
    },
    options: {
      skip: isNil(workspaceId),
    },
  });

  const resultSetData = useMemo(() => {
    if (isEmptyArray(tableData)) {
      return null;
    }
    const totalTimeSpentByUser: TotalTimeSpentByUser = {};
    tableData.forEach((row) => {
      const userId = row[CUBE_CONSTANTS.WORKSPACE_VIEWS_USER_ID];
      const timeInSeconds = row[CUBE_CONSTANTS.WORKSPACE_VIEWS_ENGAGED_TIME];
      if (!isString(timeInSeconds)) {
        return;
      }
      const analyticsUser = getGuestOrMergedAnonymousUser({ guestsMapping, userId });
      const { id } = analyticsUser;
      if (!totalTimeSpentByUser[id]) {
        totalTimeSpentByUser[id] = {
          user: analyticsUser,
          timeInSeconds: 0,
        };
      }
      totalTimeSpentByUser[id].timeInSeconds += Number(timeInSeconds);
    });
    return Object.values(totalTimeSpentByUser);
  }, [tableData, guestsMapping]);

  const totalTimeSpent = useMemo(() => {
    if (!resultSetData) {
      return undefined;
    }
    const totalSeconds = resultSetData.reduce((acc, row) => {
      return acc + Number(row.timeInSeconds);
    }, 0);

    if (totalSeconds === ZERO) {
      return ZERO;
    }

    return formatAnalyticsDuration(totalSeconds, locale);
  }, [resultSetData, locale]);

  return (
    <AnalyticsCard
      title={t`Total time spent`}
      value={totalTimeSpent ?? null}
      iconName="Clock"
      isLoading={isLoading}
      loadingStateComponent={<GuestRawLoadingState />}
    >
      <>
        {resultSetData?.map(({ user, timeInSeconds }) => (
          <GuestRaw
            key={user.id}
            displayName={user.displayName}
            text={formatAnalyticsDuration(Number(timeInSeconds), locale)}
            picture={user.picture}
          />
        ))}
      </>
    </AnalyticsCard>
  );
};
