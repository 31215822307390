import type { FC, ReactNode } from 'react';
import { Avatar, Flex, Text } from '@topo-io/design-system';

interface GuestRawProps {
  displayName: string;
  picture?: string;
  text: ReactNode;
}

export const GuestRaw: FC<GuestRawProps> = ({ displayName, picture, text }) => {
  return (
    <Flex justifyContent="space-between" paddingBottom="2">
      <Flex gap="3" alignItems="center" flex={1}>
        <Avatar name={displayName} bg="primary.500" color="white" size="xs" src={picture} />
        <Text fontWeight="500">{displayName}</Text>
      </Flex>

      <Flex flexShrink={0}>
        <Text color="gray.400">{text}</Text>
      </Flex>
    </Flex>
  );
};
