import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import type { ItemType } from '@topo-io/design-system/src/components';
import type { OpportunityStagesQuery } from '@topo-io/graphql';
import { contains } from '@topo-io/utils';
import { DefaultComboboxOptions } from '@/components/common/fields/default-combobox-options';
import { useOpportunityStages } from '@/components/workspace/hooks/use-opportunity-stages';
import { ComboboxField } from './combobox-field';

export type StageItemType = OpportunityStagesQuery['opportunityStages'][number];

interface StagesFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  defaultStage?: StageItemType;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const StagesField = ({
  name,
  label,
  placeholder,
  defaultStage,
  onChange,
}: StagesFieldProps) => {
  const { stages, isFieldDisabled } = useOpportunityStages();

  const suggestedStages = useMemo(
    () =>
      stages.map((stage) => ({
        id: stage.id,
        label: stage.name,
        value: stage.name,
        key: stage.id,
      })) ?? [],
    [stages]
  );

  const optionsFn = useCallback(
    async (value: string | undefined): Promise<ItemType[]> => {
      if (!value || value === defaultStage?.name) {
        return Promise.resolve(suggestedStages);
      }

      const matchingStages = suggestedStages?.filter((stage) =>
        contains(stage.label, value, { mode: 'insensitive' })
      );

      return Promise.resolve(matchingStages);
    },
    [defaultStage?.name, suggestedStages]
  );

  return (
    <ComboboxField
      showNewItem={false}
      options={optionsFn}
      render={DefaultComboboxOptions}
      name={name}
      label={label}
      placeholder={placeholder}
      isDisabled={isFieldDisabled}
      defaultValue={defaultStage?.name}
      onChange={onChange}
    />
  );
};
