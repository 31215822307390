/* eslint-disable @typescript-eslint/no-empty-function */
import type { PropsWithChildren, FC, RefObject } from 'react';
import { useState, useRef, createContext, useMemo, useCallback } from 'react';
import { useAnalyticsPingInterval } from '@/components/analytics/hooks/common/use-analytics-ping-interval';
import { useVisibleSections } from '@/components/section/hooks/use-visible-sections';
import { useQueryParamId } from '@/hooks';

enum WorkspaceType {
  Workspace = 'Workspace',
  WorkspaceTemplate = 'WorkspaceTemplate',
}

type SectionsRefs = Record<string, RefObject<HTMLDivElement>>;

export interface WorkspaceContextProps {
  id?: string;
  readOnly: boolean;
  isGuest: boolean;
  isTemplate: boolean;
  isWorkspace: boolean;
  isPreview: boolean;
  workspaceType?: WorkspaceType;
  overviewRef: RefObject<HTMLDivElement>;
  workspaceTabRef: RefObject<HTMLDivElement>;
  sectionIdToRerender: string | null;
  setSectionIdToRerender: (id: string) => void;
  sectionsRefs: SectionsRefs;
  addSectionRef: (sectionId: string, sectionRef: RefObject<HTMLDivElement>) => void;
  visibleSections: string[];
  isActivityTrackingEnabled: boolean;
  handleAfterPing: () => void;
  workspaceViewId: string | undefined;
  setWorkspaceViewId: (id: string) => void;
  workspaceTabViewId: string | undefined;
  setWorkspaceTabViewId: (id: string) => void;
}

export const WorkspaceContext = createContext<WorkspaceContextProps>({
  id: undefined,
  readOnly: false,
  isGuest: false,
  isTemplate: false,
  isWorkspace: false,
  isPreview: false,
  workspaceType: undefined,
  overviewRef: { current: null },
  workspaceTabRef: { current: null },
  sectionIdToRerender: null,
  setSectionIdToRerender: () => {},
  sectionsRefs: {},
  addSectionRef: () => {},
  visibleSections: [],
  isActivityTrackingEnabled: false,
  handleAfterPing: () => {},
  workspaceViewId: undefined,
  setWorkspaceViewId: () => {},
  workspaceTabViewId: undefined,
  setWorkspaceTabViewId: () => {},
});

export interface WorkspaceProviderProps {
  readOnly?: boolean;
  isGuest?: boolean;
  isTemplate?: boolean;
  isPreview?: boolean;
}

export const WorkspaceProvider: FC<PropsWithChildren<WorkspaceProviderProps>> = ({
  readOnly = false,
  isGuest = false,
  isTemplate = false,
  isPreview = false,
  children,
}) => {
  const id = useQueryParamId();
  const overviewRef = useRef<HTMLDivElement>(null);
  const workspaceTabRef = useRef<HTMLDivElement>(null);

  const [sectionsRefs, setSectionsRefs] = useState<SectionsRefs>({});
  const addSectionRef = useCallback((sectionId: string, sectionRef: RefObject<HTMLDivElement>) => {
    setSectionsRefs((prevSectionsRefs) => ({ ...prevSectionsRefs, [sectionId]: sectionRef }));
  }, []);

  const visibleSections = useVisibleSections({ overviewRef, sectionsRefs });

  const { isActivityTrackingEnabled, handleAfterPing } = useAnalyticsPingInterval({
    skip: !isGuest,
  });
  const [workspaceViewId, setWorkspaceViewId] = useState<string | undefined>(undefined);
  const [workspaceTabViewId, setWorkspaceTabViewId] = useState<string | undefined>(undefined);

  // Hack to refresh the editor content when the content prop changes (when section is unsynced)
  const [sectionIdToRerender, setSectionIdToRerender] = useState<string | null>(null);
  if (sectionIdToRerender) {
    setTimeout(() => {
      setSectionIdToRerender(null);
    }, 1000);
  }

  const value = useMemo(
    () => ({
      id,
      readOnly,
      isGuest,
      isWorkspace: !isTemplate,
      isTemplate,
      isPreview,
      workspaceType: isTemplate ? WorkspaceType.WorkspaceTemplate : WorkspaceType.Workspace,
      overviewRef,
      workspaceTabRef,
      sectionIdToRerender,
      setSectionIdToRerender,
      sectionsRefs,
      addSectionRef,
      visibleSections,
      isActivityTrackingEnabled,
      handleAfterPing,
      workspaceViewId,
      setWorkspaceViewId,
      workspaceTabViewId,
      setWorkspaceTabViewId,
    }),
    [
      id,
      readOnly,
      isGuest,
      isTemplate,
      isPreview,
      overviewRef,
      workspaceTabRef,
      sectionIdToRerender,
      setSectionIdToRerender,
      sectionsRefs,
      addSectionRef,
      visibleSections,
      isActivityTrackingEnabled,
      handleAfterPing,
      workspaceViewId,
      setWorkspaceViewId,
      workspaceTabViewId,
      setWorkspaceTabViewId,
    ]
  );

  if (!id) {
    return null;
  }
  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>;
};
