import Image from 'next/image';
import Link from 'next/link';
import { Flex } from '@topo-io/design-system';
import logo from 'public/logo.png';
import { SidebarLogoName } from './sidebar-logo-name';

interface SidebarLogoProps {
  expanded?: boolean;
}

export const SidebarLogo = ({ expanded }: SidebarLogoProps) => {
  if (expanded) {
    return (
      <Link href={{ pathname: '/' }} passHref>
        <Flex align="center">
          <SidebarLogoName />
        </Flex>
      </Link>
    );
  }

  return (
    <Link href={{ pathname: '/' }} passHref style={{ width: '100%' }}>
      <Flex align="center" justifyContent="center">
        <Image src={logo} alt="logo" height={32} width={32} />
      </Flex>
    </Link>
  );
};
