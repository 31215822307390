import { Trans, t } from '@lingui/macro';
import { useRouter } from 'next/router';
import { Button, Icon, useDisclosure, useToast } from '@topo-io/design-system';
import { useGenerateSectionTemplateMutation } from '@topo-io/graphql';
import { isNotNil } from '@topo-io/utils';
import { sectionTemplateUrl } from '@/config';
import { useUser } from '@/hooks';
import { AISectionModal } from './ai-section-modal';
import type { NewSectionFormValues } from './ai-section-modal';

export const AISectionTemplateButton = () => {
  const { errorToast } = useToast();

  const router = useRouter();
  const modalAISectionDisclosure = useDisclosure();
  const { user, isIdentifiedUser } = useUser();
  const [generateSectionTemplateMutation, { loading }] = useGenerateSectionTemplateMutation();

  const onSubmit = async (props: NewSectionFormValues) => {
    try {
      if (!isIdentifiedUser(user)) {
        throw new Error('User is not identified');
      }
      const { data } = await generateSectionTemplateMutation({
        variables: {
          data: {
            ownerId: user.id,
            sectionType: props.type,
            userInput: props.input ?? '',
          },
        },
      });
      modalAISectionDisclosure.onClose();
      if (isNotNil(data?.generateSectionTemplate.id)) {
        void router.push(sectionTemplateUrl(data.generateSectionTemplate.id));
      }
    } catch (error) {
      errorToast({
        title: t`An error occurred during generation of your section`,
      });
    }
  };

  return (
    <>
      <Button
        colorScheme="gray"
        onClick={modalAISectionDisclosure.onOpen}
        leftIcon={<Icon icon="Sparkle" w="12px" h="12px" />}
      >
        <Trans>Create from AI</Trans>
      </Button>
      <>
        <AISectionModal
          onSubmit={onSubmit}
          loading={loading}
          isOpen={modalAISectionDisclosure.isOpen}
          onClose={modalAISectionDisclosure.onClose}
        />
      </>
    </>
  );
};
