import { isSSR } from '@topo-io/utils';
import { all } from './secrets';

const { ENV, FORCE_ORGANIZATION } = all;

export const getOrganizationSlug = (): string | undefined => {
  if (isSSR()) {
    return;
  }

  const url = new URL(window.location.href);
  const [slug] = url.hostname.split('.');

  if (ENV !== 'production' && FORCE_ORGANIZATION) {
    return FORCE_ORGANIZATION;
  }

  if (slug === 'rooms') {
    const org =
      url.searchParams.get('organization') ??
      window.localStorage.getItem('organization') ??
      undefined;
    return org === '' ? undefined : org;
  }

  if (slug) {
    return slug;
  }
};

export const getUrlForOrganization = (slug?: string): URL | undefined => {
  if (isSSR()) {
    return;
  }

  // This URL generation logic is the same as the one used in the backend when replacing the
  // legacy base url by one using the organization slug.
  // In the future, we will extract this logic package.
  // See <https://linear.app/topo-io/issue/topo-992/>.
  const originalUrl = window.location;
  const url = new URL(originalUrl.href);
  const [_slug, ...domain] = url.hostname.split('.');
  url.hostname = [slug, ...domain].join('.');
  url.pathname = '';

  return new URL(originalUrl.pathname, url);
};
