import { Plural, t } from '@lingui/macro';
import type { FC } from 'react';
import { useMemo } from 'react';
import { CUBE_CONSTANTS } from '@topo-io/constants';
import { isEmptyArray, isNil, isString } from '@topo-io/utils';
import { AnalyticsCard, GuestRaw, GuestRawLoadingState } from '@/components/analytics/common';
import type { AnalyticsGuest, GuestsMapping } from '@/components/analytics/types';
import { getGuestOrMergedAnonymousUser } from '@/components/analytics/utils';
import { useAnalyticsQuery } from '@/hooks';

interface TotalViewsProps {
  workspaceId: string | undefined;
  guestsMapping: GuestsMapping | null;
}

type TotalViewsByUser = Record<
  string,
  {
    user: AnalyticsGuest;
    count: number;
  }
>;

export const TotalViews: FC<TotalViewsProps> = ({ workspaceId, guestsMapping }) => {
  const { tableData, isLoading } = useAnalyticsQuery<Record<string, string>>({
    query: {
      measures: [CUBE_CONSTANTS.WORKSPACE_VIEWS_COUNT],
      timeDimensions: [
        {
          dimension: CUBE_CONSTANTS.WORKSPACE_VIEWS_START_TIMESTAMP,
        },
      ],
      order: {
        [CUBE_CONSTANTS.WORKSPACE_VIEWS_COUNT]: 'desc',
      },
      dimensions: [CUBE_CONSTANTS.WORKSPACE_VIEWS_USER_ID],
      filters: [
        {
          member: CUBE_CONSTANTS.WORKSPACE_VIEWS_WORKSPACE_ID,
          operator: 'equals',
          values: [workspaceId!],
        },
      ],
    },
    options: {
      skip: isNil(workspaceId),
    },
  });

  const resultSetData = useMemo(() => {
    if (isEmptyArray(tableData) || !guestsMapping) {
      return null;
    }
    const viewsByUser: TotalViewsByUser = {};
    tableData.forEach((row) => {
      const userId = row[CUBE_CONSTANTS.WORKSPACE_VIEWS_USER_ID];
      const count = row[CUBE_CONSTANTS.WORKSPACE_VIEWS_COUNT];
      if (!isString(userId)) {
        return;
      }
      const analyticsUser = getGuestOrMergedAnonymousUser({ guestsMapping, userId });
      const { id } = analyticsUser;
      if (!viewsByUser[id]) {
        viewsByUser[id] = {
          user: analyticsUser,
          count: 0,
        };
      }
      viewsByUser[id] = {
        user: analyticsUser,
        count: Number(viewsByUser[id].count) + Number(count),
      };
    });
    return Object.values(viewsByUser);
  }, [tableData, guestsMapping]);

  const totalViews = useMemo(() => {
    if (!resultSetData) {
      return null;
    }
    return resultSetData.reduce((acc, row) => {
      return acc + Number(row.count);
    }, 0);
  }, [resultSetData]);

  return (
    <AnalyticsCard
      title={t`Total views`}
      value={totalViews}
      iconName="eye"
      isLoading={isLoading}
      loadingStateComponent={<GuestRawLoadingState />}
    >
      <>
        {resultSetData?.map(({ user, count }) => (
          <GuestRaw
            key={user.id}
            displayName={user.displayName}
            text={<Plural value={count} one="1 time" other="{count} times" />}
            picture={user.picture}
          />
        ))}
      </>
    </AnalyticsCard>
  );
};
