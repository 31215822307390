import { isNil } from '@topo-io/utils';
import { useWorkspace } from '@/components/workspace/hooks';
import { useQueryParamId } from '@/hooks';
import { WorkspaceDetailsForm } from './workspace-details-form';

export const WorkspaceEditForm = () => {
  const id = useQueryParamId();
  const { workspace } = useWorkspace(id);

  if (isNil(workspace)) {
    return <>No workspace found</>;
  }
  return <WorkspaceDetailsForm workspace={workspace} />;
};
