import { Trans, t } from '@lingui/macro';
import type { FC } from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Skeleton, Spacer, Text } from '@topo-io/design-system';
import { useCommentsQuery, useSectionQuery } from '@topo-io/graphql';
import { isNil, isNotEmptyArray } from '@topo-io/utils';
import { CommentList, WriteComment } from '@/components/comments';
import { PageSection } from '@/components/common';
import { useSectionId } from '@/components/section/hooks';
import { useUser } from '@/hooks';

export const SectionComments: FC = () => {
  const sectionId = useSectionId();
  const { isConnected } = useUser();
  const [key, setKey] = useState(uuid());
  const { data: section, loading } = useSectionQuery({
    variables: {
      sectionId: sectionId!,
    },
    skip: isNil(sectionId),
  });

  const onCreateComment = () => {
    setKey(uuid());
  };

  const pageTitle = t`Comments`;

  const { data } = useCommentsQuery({
    variables: { input: { sectionId: sectionId! } },
    skip: isNil(sectionId),
  });
  const sectionsComments = data?.comments ?? [];

  return (
    <Skeleton isLoaded={!loading} fitContent>
      <PageSection title={`${pageTitle} - ${section?.section.title}`}>
        <Text color="gray.400" pb="8">
          <Trans>
            Post a comment and the workspace owner will be notified. Want to notify someone else?
          </Trans>
          <Spacer />
          <Trans>Just “@” mention them in your comment.</Trans>
        </Text>
        {isNotEmptyArray(sectionsComments) && <CommentList comments={sectionsComments} />}
        {isConnected && (
          <Box mt="4">
            <WriteComment
              key={key}
              onCreate={onCreateComment}
              commentParentId={sectionId!}
              commentParentType="section"
            />
          </Box>
        )}
      </PageSection>
    </Skeleton>
  );
};
