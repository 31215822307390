import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import {
  Banner,
  BannerContent,
  BannerDescription,
  BannerIcon,
  BannerTitle,
} from '@topo-io/design-system';

export const EditSectionTemplateBanner: FC<{ isOwner: boolean }> = ({ isOwner }) => {
  return (
    <Banner status="info" bg="white">
      <BannerIcon />
      <BannerContent>
        <BannerTitle>Information</BannerTitle>
        <BannerDescription>
          {isOwner ? (
            <Trans>You’re editing a section template.</Trans>
          ) : (
            <Trans>
              This template was created by someone else. To edit it, you need to duplicate it first.
            </Trans>
          )}
        </BannerDescription>
      </BannerContent>
    </Banner>
  );
};
