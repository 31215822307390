import type { FC, PropsWithChildren } from 'react';
import { useMemo, createContext } from 'react';

export interface BaseRoutesContextProps {
  isWorkspacesPage: boolean;
  isSectionTemplatesPage: boolean;
  isSectionTemplatePage: boolean;
  isWorkspaceTemplatesPage: boolean;
  isNotificationsPage: boolean;
  isAnalyticsPage: boolean;
  isIntegrationsPage: boolean;
  isTagsSettingsPage: boolean;
  isProfilePage: boolean;
  isOrganizationSettingsPage: boolean;
  isHomePage: boolean;
}

export const BaseRoutesContext = createContext<BaseRoutesContextProps>({
  isWorkspacesPage: false,
  isSectionTemplatesPage: false,
  isSectionTemplatePage: false,
  isWorkspaceTemplatesPage: false,
  isNotificationsPage: false,
  isAnalyticsPage: false,
  isIntegrationsPage: false,
  isTagsSettingsPage: false,
  isProfilePage: false,
  isOrganizationSettingsPage: false,
  isHomePage: false,
});

export interface BaseRoutesContextProviderProps {
  isWorkspacesPage?: boolean;
  isSectionTemplatesPage?: boolean;
  isSectionTemplatePage?: boolean;
  isWorkspaceTemplatesPage?: boolean;
  isNotificationsPage?: boolean;
  isAnalyticsPage?: boolean;
  isIntegrationsPage?: boolean;
  isTagsSettingsPage?: boolean;
  isProfilePage?: boolean;
  isOrganizationSettingsPage?: boolean;
  isHomePage?: boolean;
}

export const BaseRoutesContextProvider: FC<PropsWithChildren<BaseRoutesContextProviderProps>> = ({
  isWorkspacesPage = false,
  isSectionTemplatesPage = false,
  isSectionTemplatePage = false,
  isWorkspaceTemplatesPage = false,
  isNotificationsPage = false,
  isAnalyticsPage = false,
  isIntegrationsPage = false,
  isTagsSettingsPage = false,
  isProfilePage = false,
  isOrganizationSettingsPage = false,
  isHomePage = false,
  children,
}) => {
  const value = useMemo(
    () => ({
      isWorkspacesPage,
      isSectionTemplatesPage,
      isTagsSettingsPage,
      isSectionTemplatePage,
      isWorkspaceTemplatesPage,
      isNotificationsPage,
      isAnalyticsPage,
      isIntegrationsPage,
      isProfilePage,
      isOrganizationSettingsPage,
      isHomePage,
    }),
    [
      isNotificationsPage,
      isAnalyticsPage,
      isIntegrationsPage,
      isSectionTemplatesPage,
      isTagsSettingsPage,
      isSectionTemplatePage,
      isWorkspaceTemplatesPage,
      isWorkspacesPage,
      isProfilePage,
      isOrganizationSettingsPage,
      isHomePage,
    ]
  );

  return <BaseRoutesContext.Provider value={value}>{children}</BaseRoutesContext.Provider>;
};
