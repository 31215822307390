import type { FC, PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';

export interface RoadmapContextProps {
  canEditRoadmap: boolean;
}

export const RoadmapContext = createContext<RoadmapContextProps>({
  canEditRoadmap: false,
});

export interface RoadmapProviderProps {
  canEditRoadmap: boolean;
}

export const RoadmapProvider: FC<PropsWithChildren<RoadmapProviderProps>> = ({
  canEditRoadmap,
  children,
}) => {
  const value = useMemo(() => ({ canEditRoadmap }), [canEditRoadmap]);

  return <RoadmapContext.Provider value={value}>{children}</RoadmapContext.Provider>;
};
