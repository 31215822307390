import type { Cell, Row, SortingState } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { Box, DataTable } from '@topo-io/design-system';
import { IndexSortDirection } from '@topo-io/types';
import type { SectionTemplateSearchRecord } from '@topo-io/types';
import { isEmptyArray } from '@topo-io/utils';
import { SearchableDataTablePagination, TableEmptyState } from '@/components/common';
import { useTableContainerWidth } from '@/components/layout/hooks';
import { sectionTemplateUrl } from '@/config';
import { useSearchSort } from '@/hooks';
import { useSearchResults } from '@/hooks/use-search-results';
import { buildSectionTemplateListColumns } from './section-template-list-columns';

const loadingRowExample = {
  template: 'Section title fake example',
  owner: 'John Doe',
  lastUpdated: '2021-01-01',
  tags: 'no tags',
};

export const SectionTemplateList: FC = () => {
  const router = useRouter();
  const containerWitdh = useTableContainerWidth();
  const goToTemplate = useCallback(
    (row: Row<SectionTemplateSearchRecord>) =>
      void router.push(sectionTemplateUrl(row.original.id)),
    [router]
  );

  const { results, isLoading, refresh } = useSearchResults<SectionTemplateSearchRecord>();
  const { sortBy, reset } = useSearchSort({
    attribute: 'updatedAt',
    sortDirection: IndexSortDirection.DESC,
  });

  const columns = useMemo(
    () => buildSectionTemplateListColumns({ onTemplateDeletionSuccess: refresh }),
    [refresh]
  );

  const handleOnSortingchange = (sorting: SortingState) => {
    if (isEmptyArray(sorting)) {
      reset();
      return;
    }

    const attribute = sorting[0].id;
    const direction = sorting[0].desc ? IndexSortDirection.DESC : IndexSortDirection.ASC;
    sortBy({ attribute, direction });
  };

  const getTooltipLabel = (cell: Cell<SectionTemplateSearchRecord, unknown>) => {
    const record = cell.row.original;
    const { tags } = record ?? {};
    const [_, ...otherTags] = tags ?? [];

    if (cell.column.id !== 'tags' || isEmptyArray(otherTags)) {
      return null;
    }

    return otherTags.map((t) => t.name).join(', ');
  };

  return (
    <Box width={containerWitdh}>
      <DataTable
        data={results}
        columns={columns}
        onClick={goToTemplate}
        loadingState={{
          isLoading,
          loadingRowExample,
        }}
        emptyState={<TableEmptyState />}
        manualSorting={true}
        onSortingChange={handleOnSortingchange}
        getTooltipLabel={getTooltipLabel}
      />
      <SearchableDataTablePagination />
    </Box>
  );
};
