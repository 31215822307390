import type { UseFormReturn } from '@saas-ui/forms';
import { useRouter } from 'next/router';
import { useCallback, useRef } from 'react';
import { useToast } from '@topo-io/design-system';
import type {
  CreateWorkspaceMutationVariables,
  DuplicateWorkspaceMutationVariables,
  GenerateWorkspaceMutationVariables,
} from '@topo-io/graphql';
import {
  useGenerateWorkspaceMutation,
  useAssociateCRMToWorkspaceMutation,
  useCreateWorkspaceMutation,
  useDuplicateWorkspaceMutation,
  WorkspacesDocument,
} from '@topo-io/graphql';
import { formatDate, isNil } from '@topo-io/utils';
import type { CRMAccountItemType, OpportunityItemType } from '@/components/common';
import { workspaceUrl } from '@/config';
import type {
  BaseWorkspaceFormValues,
  CreateWorkspaceFormValues,
  CreateWorkspaceFromTemplateValues,
  DuplicateWorkspaceFormValues,
  GenerateWorkspaceFormValues,
} from './types';

export const useNewWorkspaceModal = ({
  templateId,
  closeModal,
}: {
  templateId?: string;
  closeModal: () => void;
}) => {
  const formRef = useRef<UseFormReturn>(null!);
  const { errorToast } = useToast();
  const router = useRouter();
  const [createWorkspace] = useCreateWorkspaceMutation({
    refetchQueries: [WorkspacesDocument],
  });
  const [duplicateWorkspace] = useDuplicateWorkspaceMutation();
  const [associateCRMToWorkspace] = useAssociateCRMToWorkspaceMutation();

  const [generateWorkspace] = useGenerateWorkspaceMutation();

  const getBaseMutationVariables = (
    formValues: BaseWorkspaceFormValues
  ): CreateWorkspaceMutationVariables => {
    const { account, title, opportunity } = formValues;

    return {
      name: account.label,
      logoUrl: account.logoUrl,
      domainUrl: account.domainUrl,
      companyId: account?.id,
      title,
      closeDate: opportunity?.closeDate ? formatDate(opportunity?.closeDate) : undefined,
      value: opportunity?.amount,
      stage: opportunity?.stage,
    };
  };

  const handleMutationErrors = useCallback(
    (errors: readonly { message: string }[]) => {
      errorToast({
        title: `Something went wrong: ${errors[0]?.message}`,
      });
    },
    [errorToast]
  );

  const onMutationSuccess = useCallback(
    async (
      createdWorkspaceId: string,
      CRMAccount?: CRMAccountItemType,
      CRMOpportunity?: OpportunityItemType
    ) => {
      const CRMAccountId = CRMAccount?.id;
      const CRMOpportunityId = CRMOpportunity?.id;
      if (CRMAccountId) {
        const { errors } = await associateCRMToWorkspace({
          variables: {
            input: {
              workspaceId: createdWorkspaceId,
              CRMAccountId,
              CRMOpportunityId,
            },
          },
        });
        if (errors) {
          handleMutationErrors(errors);
        }
      }
      closeModal();
      const url = workspaceUrl(createdWorkspaceId);
      await router.push(url);
    },
    [associateCRMToWorkspace, closeModal, handleMutationErrors, router]
  );

  const onSubmitCreateWorkspaceFromTemplate = useCallback(
    async (formValues: CreateWorkspaceFromTemplateValues) => {
      const { template, CRMAccount, opportunity } = formValues;
      const variables: CreateWorkspaceMutationVariables = {
        workspaceTemplateId: templateId ?? template.id,
        ...getBaseMutationVariables(formValues),
      };
      const { data, errors } = await createWorkspace({ variables });
      if (errors) {
        return handleMutationErrors(errors);
      }
      if (isNil(data)) {
        return;
      }
      const workspaceId = data.createWorkspace.id;

      await onMutationSuccess(workspaceId, CRMAccount, opportunity);
    },
    [templateId, createWorkspace, onMutationSuccess, handleMutationErrors]
  );

  const onSubmitCreateWorkspace = useCallback(
    async (formValues: CreateWorkspaceFormValues) => {
      const { CRMAccount, opportunity } = formValues;
      const variables: CreateWorkspaceMutationVariables = getBaseMutationVariables(formValues);
      const { data, errors } = await createWorkspace({ variables });
      if (errors) {
        return handleMutationErrors(errors);
      }
      if (isNil(data)) {
        return;
      }
      const workspaceId = data.createWorkspace.id;

      await onMutationSuccess(workspaceId, CRMAccount, opportunity);
    },
    [createWorkspace, onMutationSuccess, handleMutationErrors]
  );

  const onSubmitDuplicateWorkspace = useCallback(
    async (formValues: DuplicateWorkspaceFormValues) => {
      const { originalWorkspaceId, CRMAccount, opportunity } = formValues;
      const variables: DuplicateWorkspaceMutationVariables = {
        originalWorkspaceId,
        ...getBaseMutationVariables(formValues),
      };
      const { data, errors } = await duplicateWorkspace({ variables });
      if (errors) {
        return handleMutationErrors(errors);
      }
      if (isNil(data)) {
        return;
      }
      const workspaceId = data.duplicateWorkspace.id;

      await onMutationSuccess(workspaceId, CRMAccount, opportunity);
    },
    [duplicateWorkspace, onMutationSuccess, handleMutationErrors]
  );

  const onSubmitGenerateWorkspace = useCallback(
    async (formValues: GenerateWorkspaceFormValues) => {
      const { CRMAccount, opportunity, userInput } = formValues;
      const variables: GenerateWorkspaceMutationVariables = {
        userInput,
        ...getBaseMutationVariables(formValues),
      };
      const { data, errors } = await generateWorkspace({ variables });
      if (errors) {
        return handleMutationErrors(errors);
      }
      if (isNil(data)) {
        return;
      }
      const workspaceId = data.generateWorkspace.id;

      await onMutationSuccess(workspaceId, CRMAccount, opportunity);
    },
    [generateWorkspace, onMutationSuccess, handleMutationErrors]
  );

  return {
    formRef,
    onSubmitCreateWorkspace,
    onSubmitDuplicateWorkspace,
    onSubmitCreateWorkspaceFromTemplate,
    onSubmitGenerateWorkspace,
  };
};
