import { Trans } from '@lingui/macro';
import { Field, Form } from '@saas-ui/forms';
import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { InputGroupWithIcon, Text } from '@topo-io/design-system';
import { useUpdateWorkspacePasswordMutation } from '@topo-io/graphql';
import { useCopyToClipboard, useDebouncedCallback } from '@topo-io/hooks';
import { isNil, isNotNil } from '@topo-io/utils';
import { useWorkspace } from '@/components/workspace/hooks';

const SUCCESS_MESSAGE_SHOW_DURATION = 2000;
const THROTTLE_TIME = 300;

const WorkspacePasswordInput = ({ id }: { id: string }) => {
  const { workspace } = useWorkspace(id);
  const [password, setPassword] = useState<string | undefined>();
  const [showCopiedPassword, setShowCopiedPassword] = useState(false);
  const [showSavedPassword, setShowSavedPassword] = useState(false);
  const [updatePasswordMutation] = useUpdateWorkspacePasswordMutation();
  const [_, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const password = workspace?.password;
    if (isNotNil(password)) {
      setPassword(password);
    }
  }, [workspace?.password]);

  const showSuccessMessage = (action: 'copyPassword' | 'savePassword') => {
    const showFn = action === 'savePassword' ? setShowSavedPassword : setShowCopiedPassword;
    showFn(true);
    setTimeout(() => {
      showFn(false);
    }, SUCCESS_MESSAGE_SHOW_DURATION);
  };
  const debouncedUpdatePassword = useDebouncedCallback(async (password: string) => {
    if (password !== workspace?.password) {
      await updatePasswordMutation({ variables: { id, password } });
    }
    showSuccessMessage('savePassword');
  }, THROTTLE_TIME);

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.currentTarget.value;
    setPassword(newPassword);
    void debouncedUpdatePassword(newPassword);
  };

  const copyPassword = () => {
    if (isNil(password)) {
      return;
    }
    copyToClipboard(password);
    showSuccessMessage('copyPassword');
  };

  return (
    <Form onSubmit={() => true}>
      <InputGroupWithIcon iconName="copy" aria-label="Copy" onIconClick={copyPassword}>
        <Field
          aria-label="workspace-password-input"
          data-testid="workspace-password-input"
          name="password"
          defaultValue={workspace?.password}
          placeholder="your-access-code"
          onChange={handlePasswordChange}
        />
      </InputGroupWithIcon>
      {showSavedPassword && (
        <Text mt="1" color="green.500">
          <Trans>Password saved!</Trans>
        </Text>
      )}
      {showCopiedPassword && (
        <Text mt="1" color="green.500">
          <Trans>Copied to clipboard!</Trans>
        </Text>
      )}
    </Form>
  );
};

export { WorkspacePasswordInput };
