import { Trans, t } from '@lingui/macro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Flex, Icon, useDisclosure } from '@topo-io/design-system';
import { AISectionTemplateButton } from '@/components/ai';
import { useBaseRoutesContext } from '@/components/routing/hooks';
import { NewTemplateModal } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { useSubmitNewSectionTemplate } from '@/components/workspace/common/new-template-modal/use-submit-new-section-template';
import { useSubmitNewWorkspaceTemplate } from '@/components/workspace/common/new-template-modal/use-submit-new-workspace-template';
import { NewWorkspaceFromTemplateModal } from '@/components/workspace/common/new-workspace-modal/new-workspace-from-template-modal';
import { CTASectionTemplate } from './cta-section-template';

export const CTAMenu = () => {
  const { topoGpt } = useFlags();
  const {
    isSectionTemplatePage,
    isSectionTemplatesPage,
    isWorkspaceTemplatesPage,
    isTagsSettingsPage,
    isAnalyticsPage,
    isOrganizationSettingsPage,
  } = useBaseRoutesContext();
  const onSubmit = useSubmitNewWorkspaceTemplate();
  const { onSubmit: onSubmitNewSection } = useSubmitNewSectionTemplate();
  const newWorkspaceDisclosure = useDisclosure();
  const newWorkspaceTemplateDisclosure = useDisclosure();
  const newSectionTemplateDisclosure = useDisclosure();

  const renderButton = () => {
    if (isTagsSettingsPage || isAnalyticsPage || isOrganizationSettingsPage) {
      return;
    }
    if (isWorkspaceTemplatesPage) {
      return (
        <Button
          mr="1"
          leftIcon={<Icon icon="plus-circle" />}
          onClick={newWorkspaceTemplateDisclosure.onOpen}
        >
          <Trans>Create a template</Trans>
        </Button>
      );
    } else if (isSectionTemplatePage) {
      return <CTASectionTemplate />;
    } else if (isSectionTemplatesPage) {
      return (
        <Flex gap="2">
          {topoGpt && <AISectionTemplateButton />}
          <Button
            mr="1"
            leftIcon={<Icon icon="plus-circle" />}
            onClick={newSectionTemplateDisclosure.onOpen}
          >
            <Trans>Create a template</Trans>
          </Button>
        </Flex>
      );
    } else {
      return (
        <Button
          mr="1"
          leftIcon={<Icon icon="plus-circle" />}
          onClick={newWorkspaceDisclosure.onOpen}
        >
          <Trans>Create a workspace</Trans>
        </Button>
      );
    }
  };

  return (
    <>
      {renderButton()}
      <NewTemplateModal
        isOpen={newWorkspaceTemplateDisclosure.isOpen}
        onClose={newWorkspaceTemplateDisclosure.onClose}
        onSubmit={onSubmit}
      />
      <NewTemplateModal
        title={t`New section template`}
        isOpen={newSectionTemplateDisclosure.isOpen}
        onClose={newSectionTemplateDisclosure.onClose}
        onSubmit={onSubmitNewSection}
      />
      <NewWorkspaceFromTemplateModal
        isOpen={newWorkspaceDisclosure.isOpen}
        onClose={newWorkspaceDisclosure.onClose}
      />
    </>
  );
};
