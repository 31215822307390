import { t } from '@lingui/macro';
import { useMemo } from 'react';
import { Tag } from '@topo-io/design-system';
import { isZero } from '@topo-io/utils';
import type { RoadmapStep } from './types';

type StepStatusType = 'ongoing' | 'empty' | 'notStarted' | 'completed';

export const StepStatus = ({ step }: { step: RoadmapStep }) => {
  const { tasks } = step;
  const visibleTasks = tasks.filter((t) => t.isVisible);
  const totalTasks = visibleTasks.length;
  const completedTasks = visibleTasks.filter((task) => task.done).length;
  let color = 'primary.100';
  let status: StepStatusType = 'ongoing';
  if (isZero(totalTasks)) {
    color = 'gray.40';
    status = 'empty';
  } else if (isZero(completedTasks)) {
    color = 'red.100';
    status = 'notStarted';
  } else if (completedTasks === totalTasks) {
    color = 'green.100';
    status = 'completed';
  }

  const labelByStatus = useMemo(() => {
    return {
      ongoing: t`Ongoing`,
      empty: t`Empty`,
      notStarted: t`Not started`,
      completed: t`Completed`,
    };
  }, []);

  return (
    <Tag bgColor={color} data-testid={`step-status-${status}`}>
      {labelByStatus[status]}
    </Tag>
  );
};
