import { Trans, t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { EventTrackingEvents } from '@topo-io/constants';
import { H6, Radio, RadioGroup, SimpleModal, Stack, Text, useToast } from '@topo-io/design-system';
import {
  TabAccessDocument,
  useCreateTabAccessMutation,
  useDeleteTabAccessMutation,
  useTabAccessQuery,
} from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { useWorkspaceTabs, useWorkspace, useWorkspaceMembers } from '@/components/workspace/hooks';
import type { WorkspaceTab } from '@/components/workspace/tabs';
import { useAnalytics, useQueryParamId } from '@/hooks';
import { MemberTabAccess } from './member-tab-access';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workspaceTab: WorkspaceTab | null;
}

type AccessMode = 'all' | 'limited';

export const ManageTabAccessModal: React.FC<Props> = ({ isOpen, onClose, workspaceTab }) => {
  const workspaceId = useQueryParamId();
  const { successToast, errorToast } = useToast();
  const analytics = useAnalytics();

  const { members } = useWorkspaceMembers(workspaceId);
  const { workspace } = useWorkspace(workspaceId);
  const { updateTab } = useWorkspaceTabs();
  const { data, loading } = useTabAccessQuery({
    variables: {
      workspaceTabId: workspaceTab?.id ?? '',
    },
    skip: isNil(workspaceTab),
  });

  const [createTabAccessMutation] = useCreateTabAccessMutation({
    refetchQueries: [TabAccessDocument],
  });
  const [deleteTabAccessMutation] = useDeleteTabAccessMutation({
    refetchQueries: [TabAccessDocument],
  });

  const [accessMode, setAccessMode] = useState<AccessMode>('all');

  const tabAccessSet = new Set(data?.tabAccess?.map((access) => access.userProfileId));
  const ownerId = workspace?.owner.id;
  const isInitialLoading = loading && isNil(data);

  useEffect(() => {
    setAccessMode(workspaceTab?.hasRestrictedAccess ? 'limited' : 'all');
  }, [workspaceTab]);

  if (!workspaceTab) {
    return null;
  }

  const handleRadioChange = (nextValue: string) => {
    try {
      setAccessMode(nextValue as AccessMode);
      updateTab({
        input: {
          hasRestrictedAccess: nextValue === 'limited',
        },
        tabId: workspaceTab.id,
      });
      if (nextValue === 'limited') {
        analytics.track({
          eventName: EventTrackingEvents.TAB_LIMIT_ACCESS_CREATED,
          properties: {
            workspaceId,
            workspaceTabId: workspaceTab.id,
            workspaceTabName: workspaceTab.name,
          },
        });
      }
      successToast({
        title: t`Your tab access has been updated successfully!`,
      });
    } catch (err) {
      errorToast({
        title: t`Error updating tab access`,
      });
    }
  };

  const handleMemberAccessChange = async (userProfileId: string, checked: boolean) => {
    try {
      if (checked) {
        await createTabAccessMutation({
          variables: {
            input: {
              userProfileId,
              workspaceTabId: workspaceTab.id,
            },
          },
        });
      } else {
        await deleteTabAccessMutation({
          variables: {
            input: {
              userProfileId,
              workspaceTabId: workspaceTab.id,
            },
          },
        });
      }
      successToast({
        title: t`Your tab access has been updated successfully!`,
      });
    } catch (err) {
      errorToast({
        title: t`Error updating tab access`,
      });
    }
  };

  return (
    <SimpleModal isOpen={isOpen} onClose={onClose} size="2xl">
      <H6>Manage access to &quot;{workspaceTab.name}&quot; tab</H6>
      <Text color="gray.500">
        <Trans>Hide or show tab to specific members</Trans>
      </Text>

      {!isInitialLoading && (
        <>
          <RadioGroup onChange={handleRadioChange} value={accessMode} mt="6">
            <Stack>
              <Radio value="all">
                <Trans>All members can access the tab</Trans>
              </Radio>
              <Radio value="limited">
                <Trans>Limit tab access to specific members</Trans>
              </Radio>
            </Stack>
          </RadioGroup>

          {accessMode === 'limited' && (
            <Stack mt="6" spacing="2">
              {members.map((member) => (
                <MemberTabAccess
                  key={member.id}
                  member={member}
                  isWorkspaceOwner={member.userProfileId === ownerId}
                  tabAccess={tabAccessSet}
                  onMemberAccessChange={handleMemberAccessChange}
                />
              ))}
            </Stack>
          )}
        </>
      )}
    </SimpleModal>
  );
};
