import { t } from '@lingui/macro';
import { ModalSection } from '@topo-io/design-system';
import type { NotificationSettings } from '@topo-io/graphql';
import type { DebouncedState } from '@topo-io/hooks';
import { NotificationWorkspaceOpenedSettings } from './notification-workspace-opened-settings';
import { NotificationWorkspaceSharedSettings } from './notification-workspace-shared-settings';

export const NotificationSettingsActivities = ({
  defaultSettingsNotifyWorkspaceOpened,
  defaultSettingsNotifyWorkspaceShared,
  updateNotificationSettings,
}: {
  defaultSettingsNotifyWorkspaceOpened: boolean;
  defaultSettingsNotifyWorkspaceShared: boolean;
  updateNotificationSettings: DebouncedState<
    (data: Partial<NotificationSettings>) => Promise<void>
  >;
}) => {
  const updateSettinsgNotifyWorkspaceOpened = (value: boolean) => {
    void updateNotificationSettings({
      notifyWorkspaceOpened: value,
    });
  };
  const updateSettingsNotifyWorkspaceShared = (value: boolean) => {
    void updateNotificationSettings({
      notifyWorkspaceShared: value,
    });
  };

  return (
    <ModalSection indent title={t`Activities`}>
      <NotificationWorkspaceOpenedSettings
        defaultSettingsNotifyWorkspaceOpened={defaultSettingsNotifyWorkspaceOpened}
        updateSettingsNotifyWorkspaceOpened={updateSettinsgNotifyWorkspaceOpened}
      />
      <NotificationWorkspaceSharedSettings
        defaultSettingsNotifyWorkspaceShared={defaultSettingsNotifyWorkspaceShared}
        updateSettingsNotifyWorkspaceShared={updateSettingsNotifyWorkspaceShared}
      />
    </ModalSection>
  );
};
