import { t } from '@lingui/macro';

export enum RoadmapReminderRule {
  NOTIFY_ME = 'NOTIFY_ME',
  NOTIFY_ASSIGNED_MEMBER = 'NOTIFY_ASSIGNED_MEMBER',
  NOTIFY_ALL_MEMBERS = 'NOTIFY_ALL_MEMBERS',
  NO_NOTIFICATION = 'NO_NOTIFICATION',
}

export enum RoadmapReminderDelay {
  ONE_DAY = 86400,
  TWO_DAYS = 172800,
  THREE_DAYS = 259200,
  FIVE_DAYS = 432000,
  ONE_WEEK = 604800,
}

export const getFormattedRoadmapReminderRules = () => ({
  NOTIFY_ALL_MEMBERS: {
    text: t`Notify all members`,
    value: RoadmapReminderRule.NOTIFY_ALL_MEMBERS,
  },
  NOTIFY_ASSIGNED_MEMBER: {
    text: t`Notify assigned members`,
    value: RoadmapReminderRule.NOTIFY_ASSIGNED_MEMBER,
  },
  NOTIFY_ME: {
    text: t`Notify me`,
    value: RoadmapReminderRule.NOTIFY_ME,
  },
  NO_NOTIFICATION: {
    text: t`No notification`,
    value: RoadmapReminderRule.NO_NOTIFICATION,
  },
});

export const getFormattedRoadmapReminderDelays = () => ({
  ONE_DAY: {
    text: t`1d`,
    value: RoadmapReminderDelay.ONE_DAY,
  },
  TWO_DAYS: {
    text: t`2d`,
    value: RoadmapReminderDelay.TWO_DAYS,
  },
  THREE_DAYS: {
    text: t`3d`,
    value: RoadmapReminderDelay.THREE_DAYS,
  },
  FIVE_DAYS: {
    text: t`5d`,
    value: RoadmapReminderDelay.FIVE_DAYS,
  },
  ONE_WEEK: {
    text: t`1w`,
    value: RoadmapReminderDelay.ONE_WEEK,
  },
});

export const REMINDER_RULE_DEFAULT_VALUE = RoadmapReminderRule.NOTIFY_ALL_MEMBERS;
export const REMINDER_DELAY_DEFAULT_VALUE = RoadmapReminderDelay[RoadmapReminderDelay.ONE_DAY];

// Had to duplicate because type resolution is broken with enums
