import { Trans } from '@lingui/macro';
import { Form, FormLayout, SubmitButton } from '@saas-ui/forms';
import type { FormProps, UseFormReturn } from '@saas-ui/forms';
import type { MutableRefObject } from 'react';
import type { ReactNode } from 'react';
import { Button, ModalFooter, SimpleModal } from '@topo-io/design-system';

interface FormModalProps {
  children: ReactNode;
  title: ReactNode;
  onSubmit: FormProps['onSubmit'];
  defaultValues?: FormProps['defaultValues'];
  isOpen: boolean;
  onClose: () => void;
  formRef?: MutableRefObject<UseFormReturn>;
  submitButtonText?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const FormModal = ({
  children,
  title,
  onSubmit,
  isOpen,
  onClose,
  formRef,
  size,
  submitButtonText,
  ...rest
}: FormModalProps): JSX.Element => {
  return (
    <SimpleModal isOpen={isOpen} onClose={onClose} title={title} size={size}>
      <Form onSubmit={onSubmit} {...rest} formRef={formRef}>
        <FormLayout>{children}</FormLayout>
        <ModalFooter px="0">
          <Button colorScheme="gray" onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <SubmitButton ml={3} variant="solid" colorScheme="blue" disableIfInvalid>
            {submitButtonText ?? <Trans>Save</Trans>}
          </SubmitButton>
        </ModalFooter>
      </Form>
    </SimpleModal>
  );
};

export { FormModal };

FormModal.displayName = 'FormModal';
