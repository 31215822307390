import type { FC } from 'react';
import { Box, Tooltip } from '@topo-io/design-system';
import { ZERO } from '@topo-io/utils';
import { EngagementScoreCircle } from '@/components/engagement-score/components/circle/engagement-score-circle';
import { TooltipContent } from '@/components/engagement-score/components/tooltip/tooltip-content';
import type { EngagementScoreDateRange } from '@/components/engagement-score/types';

interface EngagementScoreTooltipProps {
  workspaceId: string;
  score?: number;
  isScoreLoading: boolean;
  dateRange: EngagementScoreDateRange;
  withGraph?: boolean;
}

export const EngagementScoreTooltip: FC<EngagementScoreTooltipProps> = ({
  workspaceId,
  score,
  isScoreLoading,
  dateRange,
  withGraph,
}) => {
  return (
    <Tooltip
      padding="4"
      placement="right"
      label={
        <TooltipContent
          score={score ?? ZERO}
          workspaceId={workspaceId}
          dateRange={dateRange}
          withGraph={withGraph}
        />
      }
    >
      <Box>
        <EngagementScoreCircle score={score ?? ZERO} isScoreLoading={isScoreLoading} />
      </Box>
    </Tooltip>
  );
};
