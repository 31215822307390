import { useCallback, useContext, useEffect } from 'react';
import { isNil } from '@topo-io/utils';
import { GraphqlContext } from '@/contexts';

export const useSlug = () => {
  const context = useContext(GraphqlContext);

  if (isNil(context)) {
    throw new Error('Context used outside of its Provider!');
  }

  const { setSlug } = context;

  const onStorageChange = useCallback(
    (e: StorageEvent) => {
      const { key, newValue } = e;
      if (key === 'organization') {
        setSlug(newValue ?? undefined);
      }
    },
    [setSlug]
  );

  useEffect(() => {
    window.addEventListener('storage', onStorageChange);
    return () => {
      return window.removeEventListener('storage', onStorageChange);
    };
  }, [onStorageChange]);

  return context;
};
