import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { Box, Flex, H6, Text } from '@topo-io/design-system';
import { ZERO, isNil, formatAnalyticsDuration } from '@topo-io/utils';
import { EngagementScoreCircle } from '@/components/engagement-score/components/circle';
import { TooltipBox } from '@/components/engagement-score/components/tooltip/tooltip-box';
import {
  TrendLineGraph,
  TrendLineGraphLoadingState,
} from '@/components/engagement-score/components/trend';
import {
  useWorkspaceScoreInteractions,
  useWorkspaceScoresForPeriod,
} from '@/components/engagement-score/hooks';
import type { EngagementScoreDateRange } from '@/components/engagement-score/types';
import {
  getEngagementScoreText,
  getIconForScoreDiff,
  getScoreDiffForPeriod,
  mapDateRangeToLabel,
} from '@/components/engagement-score/utils';
import { useLocale } from '@/hooks';

interface TooltipContentProps {
  workspaceId: string;
  score: number;
  dateRange: EngagementScoreDateRange;
  withGraph?: boolean;
}
export const TooltipContent: FC<TooltipContentProps> = ({
  workspaceId,
  score,
  dateRange,
  withGraph,
}) => {
  const locale = useLocale();
  const text = getEngagementScoreText(score ?? ZERO);

  const { data: workspaceInteractions, isLoading: isInteractionsLoading } =
    useWorkspaceScoreInteractions({ workspaceId, dateRange });

  const { data: scoresForPeriod, isLoading: isScoresForPeriodLoading } =
    useWorkspaceScoresForPeriod({
      workspaceId,
      dateRange,
    });

  const isLoading = isInteractionsLoading || isScoresForPeriodLoading;
  const { diff, formattedDiff } = getScoreDiffForPeriod(scoresForPeriod ?? [], locale) ?? {};
  const dateRangeLabel = mapDateRangeToLabel(dateRange);
  const icon = getIconForScoreDiff(diff ?? ZERO, 'small');

  return (
    <Box minW="200px">
      <Flex alignItems="center" gap="3">
        <EngagementScoreCircle score={score} isScoreLoading={false} />
        <H6>{text}</H6>
      </Flex>
      {withGraph && (
        <Box marginTop="4">
          <Text color="gray.90">
            <Trans>Trend</Trans>
          </Text>
          {isLoading ? (
            <TrendLineGraphLoadingState />
          ) : (
            <TrendLineGraph scoresForPeriod={scoresForPeriod ?? []} />
          )}
        </Box>
      )}

      <Box marginTop="4">
        <Text color="gray.90">{dateRangeLabel}</Text>
        <TooltipBox isLoading={isLoading || isNil(diff) || isNil(formattedDiff)}>
          <Flex alignItems="center" gap="2">
            {icon}
            <Text>
              <Trans>{formattedDiff} points scoring</Trans>
            </Text>
          </Flex>
        </TooltipBox>
      </Box>
      <Flex
        flexDirection="column"
        gap="2"
        marginTop="4"
        paddingTop="4"
        borderTop="1px solid"
        borderTopColor="gray.800"
      >
        <TooltipBox isLoading={isLoading}>
          <Trans>{workspaceInteractions?.views} views</Trans>
        </TooltipBox>
        <TooltipBox isLoading={isLoading}>
          <Trans>{workspaceInteractions?.shares} shares</Trans>
        </TooltipBox>
        <TooltipBox isLoading={isLoading}>
          <Trans>{workspaceInteractions?.comments} comments</Trans>
        </TooltipBox>
        <TooltipBox isLoading={isLoading}>
          <Trans>
            {formatAnalyticsDuration(workspaceInteractions?.engagedTime ?? ZERO, locale)} on
            workspace
          </Trans>
        </TooltipBox>
      </Flex>
    </Box>
  );
};
