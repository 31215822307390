import { useContext } from 'react';
import { WorkspaceTabsContext } from '@/components/workspace/contexts/workspace-tabs-context';

export const useWorkspaceTabsContext = () => {
  const workspaceTabsContext = useContext(WorkspaceTabsContext);

  if (!workspaceTabsContext) {
    throw new Error('Context used outside of its Provider!');
  }

  return workspaceTabsContext;
};
