import { useDeleteTaskMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { getDeletedTaskCache } from '@/components/roadmap/utils/cache-utils';

export const useDeleteTaskMutationWithCache = (onCompleted: () => void) => {
  const [deleteTask] = useDeleteTaskMutation();

  const deleteTaskWithCache = ({ stepId, taskId }: { stepId: string; taskId: string }) => {
    void deleteTask({
      variables: {
        taskId: taskId,
      },
      update: (cache, { data }) => {
        const deleteTask = data?.deleteTask;
        if (isNil(deleteTask)) {
          return;
        }
        cache.modify(getDeletedTaskCache({ deleteTask, stepId }));
        onCompleted();
      },
    });
  };
  return { deleteTaskWithCache };
};
