import Image from 'next/image';
import { Box } from '@topo-io/design-system';

export const ComingSoon = () => {
  return (
    <Box>
      <Image alt="Coming soon" src="/images/coming-soon.png" height={225} width={300} />
    </Box>
  );
};
