import { withLDProvider } from 'launchdarkly-react-client-sdk';
import type { NextComponentType, NextPageContext } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { Layout } from '@/components/layout';
import { DEFAULT_PAGE_TITLE, all, getLogger } from '@/config';
import './styles.css';
import Setup from './_setup.page';

const { LAUNCH_DARKLY_CLIENT_SIDE_ID } = all;

const ldLogger = getLogger({
  name: 'ld-logger',
  level: 'warn',
});

interface TopoAppProps {
  cookies: string;
  Component?: NextComponentType<NextPageContext> & {
    layout?: FunctionComponent<PropsWithChildren>;
  };
}

const TopoApp = ({ Component, pageProps, cookies }: AppProps & TopoAppProps) => {
  const AppLayout = Component.layout ?? Layout;

  return (
    <Setup cookies={cookies}>
      <Head>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Head>
      <AppLayout>
        <Component {...pageProps} />
      </AppLayout>
    </Setup>
  );
};

// Send cookies to the cookie provider
// https://github.com/reactivestack/cookies/issues/299#issuecomment-1010429229
// Will activate server side rendering on all pages
TopoApp.getInitialProps = async (context: AppContext): Promise<TopoAppProps> => {
  const appProps = await App.getInitialProps(context);
  const cookies = context.ctx.req?.headers?.cookie ?? '';
  return { ...appProps, cookies };
};

export default withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
  options: {
    logger: ldLogger,
  },
})(TopoApp);
