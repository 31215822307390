import { Trans } from '@lingui/macro';
import { AddButton } from '@/components/common';
import { useCreateStepMutationWithCache } from './hooks';

export const AddStep = ({ id, stepsLength }: { id: string; stepsLength: number }) => {
  const { createStepWithCache } = useCreateStepMutationWithCache();
  const addStep = () => {
    createStepWithCache({
      workspaceId: id,
      position: stepsLength,
    });
  };
  return (
    // Using onMouseDown instead of onClick here to prevent interacting with keyboard shotcuts
    <AddButton mt="2" px="2" onMouseDown={addStep} type="button">
      <Trans>Add step</Trans>
    </AddButton>
  );
};
