import { Trans } from '@lingui/macro';
import { Field, FormStep } from '@saas-ui/forms';
import { useFormContext } from '@saas-ui/forms';
import { useRef, useState } from 'react';
import { Card, Grid, H5, Text } from '@topo-io/design-system';
import type { SectionType } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { AISectionModalStep, AI_SECTION_MODAL_SECTIONS } from '@/components/ai/section/utils';

export const SectionTypeStep = () => {
  const { setValue } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedSectionType, setSelectedSectionType] = useState<SectionType | null>(null);

  const handleCardClick = (sectionType: SectionType) => {
    if (isNil(inputRef.current)) {
      return;
    }
    inputRef.current.value = sectionType;
    setValue(AISectionModalStep.SECTION_TYPE, sectionType);
    setSelectedSectionType(sectionType);
  };

  return (
    <FormStep name={AISectionModalStep.SECTION_TYPE}>
      <H5>
        <Trans>What type of section do you want to create?</Trans>
      </H5>
      <Grid templateColumns="1fr 1fr" mt="4" gap="4">
        {AI_SECTION_MODAL_SECTIONS.map((section, index) => (
          <Card
            key={index}
            padding="3"
            flexDirection="column"
            alignItems="center"
            minW="200px"
            cursor="pointer"
            bg={selectedSectionType === section.type ? 'gray.20' : 'white'}
            _hover={{ bg: 'gray.20' }}
            onClick={() => handleCardClick(section.type)}
          >
            <Text>{section.smiley} </Text>
            <Text>{section.title}</Text>
          </Card>
        ))}
      </Grid>
      <Field
        type="text"
        name={AISectionModalStep.SECTION_TYPE}
        ref={inputRef}
        isRequired
        visibility="hidden"
        opacity="0"
      />
    </FormStep>
  );
};
