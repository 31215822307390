import type { FC } from 'react';
import type { HTMLChakraProps } from '@topo-io/design-system';
import { Button, Icon } from '@topo-io/design-system';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';

interface NewSectionPlaceholderProps extends HTMLChakraProps<'button'> {
  readOnly?: boolean;
}

export const NewSectionPlaceholder: FC<NewSectionPlaceholderProps> = (props) => {
  const { tabId } = useWorkspaceTabs();
  const { onClickAddSection } = useContent(tabId);

  return (
    <Button
      aria-label="new-section-placeholder-btn"
      borderWidth="1px"
      borderRadius="md"
      borderColor="gray.40"
      bg="white"
      onClick={onClickAddSection}
      variant="outline"
      leftIcon={<Icon icon="plus-circle" />}
      colorScheme="gray"
      color="gray.200"
      fontSize="h4"
      fontWeight="500"
      w="full"
      h="64px"
      px="6"
      justifyContent="start"
      {...props}
    >
      Add section
    </Button>
  );
};
