import { Trans } from '@lingui/macro';
import type { FC, PropsWithChildren } from 'react';
import type { HTMLChakraProps } from '@topo-io/design-system';
import { AddButton } from '@/components/common';

interface AddTaskProps extends HTMLChakraProps<'div'> {
  addTask: () => void;
}
export const AddTask: FC<PropsWithChildren<AddTaskProps>> = ({
  addTask,
  children,
  ...containerProps
}) => {
  return (
    <AddButton onClick={addTask} containerProps={containerProps}>
      {children ? children : <Trans>Add task</Trans>}
    </AddButton>
  );
};
