import type { BinaryOperator, Filter } from '@cubejs-client/core';
import { useMemo } from 'react';
import { CUBE_CONSTANTS } from '@topo-io/constants';
import type { EngagementScore } from '@topo-io/types';
import { formatDate, isNil } from '@topo-io/utils';
import { useAnalyticsQuery } from '@/hooks';

interface UseWorkspacesDailyScoreArgs {
  organizationId?: string;
  shouldSkip?: boolean;
}

interface UseWorkspacesDailyScoreReturnValue {
  data: EngagementScore[];
  isLoading: boolean;
}

interface RawData {
  [CUBE_CONSTANTS.DAILY_SCORE_SCORE]: string;
  [CUBE_CONSTANTS.DAILY_SCORE_WORKSPACE_ID]: string;
}

export const useWorkspacesDailyScore = ({
  organizationId,
  shouldSkip,
}: UseWorkspacesDailyScoreArgs): UseWorkspacesDailyScoreReturnValue => {
  const today = formatDate(new Date(), 'yyyy-MM-dd');
  const ORGANIZATION_FILTER: Filter = useMemo(
    () => ({
      member: 'workspace.organizationid',
      operator: 'equals' as BinaryOperator,
      values: [organizationId ?? ''],
    }),
    [organizationId]
  );
  const { tableData: engagementScoreData, isLoading } = useAnalyticsQuery<RawData>({
    query: {
      dimensions: [CUBE_CONSTANTS.DAILY_SCORE_SCORE, CUBE_CONSTANTS.DAILY_SCORE_WORKSPACE_ID],
      order: {
        [CUBE_CONSTANTS.DAILY_SCORE_SCORE]: 'asc',
      },
      timeDimensions: [
        {
          dimension: CUBE_CONSTANTS.DAILY_SCORE_SCORE_DATE,
          dateRange: [today, today],
        },
      ],
      filters: [ORGANIZATION_FILTER],
    },
    options: {
      skip: isNil(organizationId) || shouldSkip,
    },
  });

  return {
    data: engagementScoreData.map((data) => ({
      score: Math.trunc(Number(data[CUBE_CONSTANTS.DAILY_SCORE_SCORE])),
      workspaceId: data[CUBE_CONSTANTS.DAILY_SCORE_WORKSPACE_ID],
    })),
    isLoading,
  };
};
