import { HStack, Box } from '@topo-io/design-system';

export const InlineField = ({ children, label, ...rest }) => {
  return (
    <HStack w="full" justifyContent="space-between" alignItems="stretch" {...rest}>
      <Box my="auto">{label}</Box>
      <Box>{children}</Box>
    </HStack>
  );
};
