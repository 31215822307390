import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import { Button, DragHandle, Flex, Icon, Input, Text } from '@topo-io/design-system';
import { WorkspaceTabFeatureName, useMembersQuery } from '@topo-io/graphql';
import { isNil, isNotEmptyArray } from '@topo-io/utils';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace';
import type { WorkspaceTab } from '@/components/workspace/tabs';
import { getTabButtonStyle } from './utils';
import { WorkspaceTabEditMenu } from './workspace-tab-edit-menu';

interface NavbarWorkspaceTabProps {
  workspaceTab: WorkspaceTab;
  openDeleteTabModal: (workspaceTab: WorkspaceTab) => void;
  openManageAccessModal: (workspaceTab: WorkspaceTab) => void;
}

export const NavbarWorkspaceTab: FC<NavbarWorkspaceTabProps> = ({
  workspaceTab,
  openDeleteTabModal,
  openManageAccessModal,
}) => {
  const { id, name, type, slug, isDisplayed, hasRestrictedAccess } = workspaceTab;

  const { id: workspaceId, isWorkspace, readOnly, isPreview, isGuest } = useWorkspaceContext();
  const { updateTab, onChangeTab, queryTab, getActiveTab, isTabShown } = useWorkspaceTabs();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const { data } = useMembersQuery({
    variables: { workspaceId: workspaceId! },
    skip: isNil(id) || !isWorkspace,
  });
  const members = data?.members ?? [];

  const shouldDisplayMembersLength =
    type === WorkspaceTabFeatureName.MEMBERS && !isEditing && isNotEmptyArray(members);
  const shouldRenderDragHandle = !isPreview && !isGuest;

  const shouldDisplayTabRestrictedAccessIcon = hasRestrictedAccess && !isPreview && !isGuest;

  const activeTab = getActiveTab({ queryTab });
  const tabStyle = getTabButtonStyle({
    isActive: activeTab?.id === id,
    isDisplayed,
  });

  if (!isTabShown({ type, isDisplayed })) {
    return null;
  }

  const changeTabName = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEditing(false);
    updateTab({ input: { name: e.target.value }, tabId: id });
  };

  const handleTabClick = () => {
    if (isEditing) {
      return;
    }
    onChangeTab(slug);
  };

  return (
    <Button
      role="link"
      as="a"
      minW="unset"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => handleTabClick()}
      cursor="pointer"
      {...(!readOnly && { pr: 1 })}
      {...tabStyle}
    >
      {isEditing ? (
        <Input
          minW={`${name.length * 8}px`}
          onBlur={changeTabName}
          defaultValue={name}
          variant="unstyled"
          size="md"
          autoFocus
        />
      ) : (
        <Flex gap="5px" alignItems="center">
          {shouldRenderDragHandle && <DragHandle isHovering={isHovering} />}
          <Text pl="5px">{name}</Text>
        </Flex>
      )}
      {shouldDisplayMembersLength && (
        <Text
          as="span"
          borderRadius="4px"
          paddingY="0.25rem"
          paddingX="0.5rem"
          backgroundColor="primary.100"
          ml="2"
          color="primary.500"
        >
          {members.length}
        </Text>
      )}
      {shouldDisplayTabRestrictedAccessIcon && (
        <Icon icon="Lock01" width="16px" height="16px" ml="2" />
      )}
      {!readOnly && !isEditing && (
        <WorkspaceTabEditMenu
          workspaceTab={workspaceTab}
          isVisible={isHovering}
          openDeleteTabModal={openDeleteTabModal}
          openManageAccessModal={openManageAccessModal}
          onRename={() => setIsEditing(true)}
        />
      )}
    </Button>
  );
};
