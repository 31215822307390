export interface AnalyticsGuest {
  id: string;
  displayName: string;
  picture: string | undefined;
}
export interface AnalyticsSection {
  id: string;
  title: string;
}

export type GuestsMapping = Record<string, AnalyticsGuest>;
export type EditorsMapping = Record<string, AnalyticsGuest>;
export type SectionsMapping = Record<string, AnalyticsSection>;

export interface PeriodMetrics {
  currentPeriod: number;
  previousPeriod: number;
}

export interface UserMetricsWithAverage {
  userDisplayName: string;
  totalActiveWorkspaces: PeriodMetrics;
  totalSharedWorkspaces: PeriodMetrics;
  averageGuests: PeriodMetrics;
  averageViews: PeriodMetrics;
  averageTimeSpent: PeriodMetrics;
  picture?: string;
}

export interface UserMetricsWithoutAverage {
  totalActiveWorkspaces: PeriodMetrics;
  totalSharedWorkspaces: PeriodMetrics;
  totalGuests: PeriodMetrics;
  totalViews: PeriodMetrics;
  totalTimeSpent: PeriodMetrics;
}

export interface MetricsTotalAverages {
  averageActiveWorkspaces: PeriodMetrics;
  averageSharedWorkspaces: PeriodMetrics;
  averageGuests: PeriodMetrics;
  averageViews: PeriodMetrics;
  averageTimeSpent: PeriodMetrics;
}

export const enum TimePeriod {
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  Last90Days = 'last90Days',
  AllTime = 'allTime',
}

export interface WorkspaceMetricData {
  'workspace.ownerid': string;
  'workspace.total_active_count'?: string;
  'workspace.total_shared_count'?: string;
  'workspace.total_guests_sum'?: string;
  'workspace.total_views_sum'?: string;
  'workspace.total_time_spent_sum'?: string;
}

export interface TotalActiveWorkspacesByOwner {
  'workspace.ownerid': string;
  'workspace.total_active_count': string;
}

export interface TotalSharedWorkspacesByOwner {
  'workspace.ownerid': string;
  'workspace.total_shared_count': string;
}

export interface TotalGuestsByOwner {
  'workspace.ownerid': string;
  'workspace.total_guests_sum': string;
}

export interface TotalViewsByOwner {
  'workspace.ownerid': string;
  'workspace.total_views_sum': string;
}

export interface TotalTimeSpentByOwner {
  'workspace.ownerid': string;
  'workspace.total_time_spent_sum': string;
}
