import type { MutableRefObject } from 'react';

const TIMEOUT_DURATION_IN_MS = 100;

export const scrollToWriteComment = (
  element: MutableRefObject<HTMLDivElement | null>['current']
) => {
  setTimeout(() => {
    if (!element) {
      return;
    }

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, TIMEOUT_DURATION_IN_MS);
};
