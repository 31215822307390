import { useEffect, useMemo, useState } from 'react';
import {
  FileUsageUsage,
  VariableParentType,
  WorkspaceTabFeatureName,
  useFilesUsagesQuery,
  useVariablesQuery,
} from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { useWorkspaceTabSections } from '@/components/section/hooks/use-workspace-tab-sections';
import { useTemplate, useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';
import type { Cover } from '@/components/workspace/overview/cover/cover-types';
import { OverviewProvider } from '@/contexts';
import { useQueryParamId } from '@/hooks';
import { BaseOverview } from './base-overview';
import { WorkspaceContent } from './workspace-content';

export const TemplateOverview = () => {
  const id = useQueryParamId();
  const { readOnly } = useWorkspaceContext();

  const { tab, tabId } = useWorkspaceTabs();
  const { template } = useTemplate(id, { skip: isNil(id) });
  const [hasCoverError, setHasCoverError] = useState(false);

  const { sections: content } = useWorkspaceTabSections({ workspaceTabId: tabId });

  const { data: filesUsagesData, loading } = useFilesUsagesQuery({
    variables: {
      input: {
        workspaceTemplateId: template?.id,
      },
    },
    skip: !template?.id || tab.type !== WorkspaceTabFeatureName.CONTENT,
  });
  const filesUsages = filesUsagesData?.filesUsages;

  const { data: variablesData } = useVariablesQuery({
    variables: {
      parentId: id,
      parentType: VariableParentType.WorkspaceTemplate,
    },
  });
  const variables = variablesData?.variables;

  const cover = useMemo<Cover>(() => {
    if (loading) {
      return {
        url: '',
        metadata: {},
        id: null,
      };
    }
    const fileUsageCover = [...(filesUsages ?? [])]
      .reverse()
      .find((fileUsage) => fileUsage?.usage === FileUsageUsage.COVER);
    return {
      url: fileUsageCover?.url ?? '',
      metadata: fileUsageCover?.metadata ?? {},
      id: fileUsageCover?.id ?? null,
    };
  }, [loading, filesUsages]);

  // Reset state when cover changes
  useEffect(() => {
    setHasCoverError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cover]);

  return (
    <BaseOverview
      cover={cover}
      template={template}
      hasCoverError={hasCoverError}
      onCoverError={() => setHasCoverError(true)}
    >
      {content && (
        <OverviewProvider filesUsages={filesUsages ?? []} variables={variables ?? []}>
          <WorkspaceContent content={content} readOnly={readOnly} />
        </OverviewProvider>
      )}
    </BaseOverview>
  );
};
