import { Trans } from '@lingui/macro';
import { TabList, TabPanel, TabPanels, Tabs } from '@topo-io/design-system';
import type { ChakraComponent } from '@topo-io/design-system';
import { SearchSectionTemplatesDocument } from '@topo-io/graphql';
import { SectionsSummary } from '@/components/workspace/overview/sections-summary';
import { TemplateLibrary } from '@/components/workspace/overview/template-library';
import { LibTab } from '@/components/workspace/tabs';
import { SearchProvider } from '@/contexts';

export const SidebarLib: ChakraComponent<'div'> = () => {
  return (
    <Tabs w="full" h="95%" isLazy>
      <TabList
        justifyContent="center"
        bg="gray.30"
        borderColor="gray.40"
        p="4px"
        gap="4px"
        borderRadius="12px"
        borderWidth="1px"
      >
        <LibTab>
          <Trans>Summary</Trans>
        </LibTab>
        <LibTab>
          <Trans>Builder</Trans>
        </LibTab>
      </TabList>
      <TabPanels h="full" w="full">
        <TabPanel h="full" w="full" px={0} position="relative">
          <SectionsSummary />
        </TabPanel>
        <TabPanel h="full" w="full" px={0}>
          <SearchProvider graphQLDocument={SearchSectionTemplatesDocument} defaultPerPage={1000}>
            <TemplateLibrary />
          </SearchProvider>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
