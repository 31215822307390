import { useCallback } from 'react';
import { useSearchContext } from './use-search-context';

interface UseSearchQuery {
  search: (term: string) => void;
  clear: () => void;
}

export const useSearchQuery = (): UseSearchQuery => {
  const { setSearchQuery } = useSearchContext();

  const search = useCallback(
    (term: string) => {
      setSearchQuery(term);
    },
    [setSearchQuery]
  );

  const clear = useCallback(() => {
    setSearchQuery(undefined);
  }, [setSearchQuery]);

  return {
    search,
    clear,
  };
};
