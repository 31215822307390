import { sift, first, draw, group, unique as unique$1 } from 'radash';
export { debounce, diff, throttle } from 'radash';
import CryptoJS from 'crypto-js';
import { formatDuration as formatDuration$1, intervalToDuration, format, parseISO, intlFormat, parse, formatDistance, startOfDay, subDays } from 'date-fns';
export { add, intervalToDuration, isToday, sub, subDays } from 'date-fns';
export { default as enUS } from 'date-fns/locale/en-US/index.js';
import enGB from 'date-fns/locale/en-GB/index.js';
export { default as enGB } from 'date-fns/locale/en-GB/index.js';
import fr from 'date-fns/locale/fr/index.js';
export { default as fr } from 'date-fns/locale/fr/index.js';
import es from 'date-fns/locale/es/index.js';
export { default as es } from 'date-fns/locale/es/index.js';
export { default as de } from 'date-fns/locale/de/index.js';
export { default as ms } from 'ms';
export { deepEqual } from 'fast-equals';
import objectHash from 'object-hash';
import { paramCase } from 'change-case';
export { camelCase, capitalCase, constantCase, dotCase, headerCase, noCase, paramCase, pascalCase, pathCase, sentenceCase, snakeCase } from 'change-case';
export { default as pluralize } from 'pluralize';
import { Buffer } from 'buffer';

const chunkArray = (array, chunkSize)=>{
    const chunks = [];
    for(let i = 0; i < array.length; i += chunkSize){
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};

const compact = sift;

const getFirstElement = first;

const getFirstNthElements = (array, count)=>array.slice(0, count);

const EMPTY_ARRAY_LENGTH$1 = 0;
const isEmptyArray = (array)=>array.length === EMPTY_ARRAY_LENGTH$1;

const LAST_ELEMENT_OFFSET$1 = 1;
const getLastElement = (array)=>{
    if (isEmptyArray(array)) {
        return undefined;
    }
    return array[array.length - LAST_ELEMENT_OFFSET$1];
};

const ZERO$3 = 0;
const isZero = (value)=>value === ZERO$3;

const calculatePercentageDiff = (value, valueToCompare)=>{
    if (value === ZERO$3) {
        return ZERO$3;
    }
    const absoluteDiff = valueToCompare - value;
    const percentageDiff = absoluteDiff / Math.abs(value) * 100;
    return parseFloat(percentageDiff.toFixed(1));
};

const clamp = (value, min, max)=>{
    if (value > max) {
        return max;
    }
    if (value < min) {
        return min;
    }
    return value;
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const decrement = (value)=>value - 1;

const divide = (dividend, divisor)=>{
    const result = dividend / divisor;
    if (!isFinite(result)) {
        return ZERO$3;
    }
    return result;
};

const formatNumber = ({ locale, value, options })=>{
    return new Intl.NumberFormat(locale, options).format(value);
};

const formatDiff = ({ diff, isPercentage = false, locale = 'en' })=>{
    const sign = diff >= ZERO$3 ? '+' : '-';
    const formattedNumber = formatNumber({
        locale,
        value: Math.abs(diff),
        options: {
            maximumFractionDigits: 1
        }
    });
    const formattedDiff = isPercentage ? `${sign}${formattedNumber}%` : `${sign}${formattedNumber}`;
    return formattedDiff;
};

var FileSizeUnit;
(function(FileSizeUnit) {
    FileSizeUnit[FileSizeUnit["SI"] = 0] = "SI";
    FileSizeUnit[FileSizeUnit["IEC"] = 1] = "IEC";
})(FileSizeUnit || (FileSizeUnit = {}));
const UNITS = {
    [0]: [
        'kB',
        'MB',
        'GB',
        'TB',
        'PB',
        'EB',
        'ZB',
        'YB'
    ],
    [1]: [
        'KiB',
        'MiB',
        'GiB',
        'TiB',
        'PiB',
        'EiB',
        'ZiB',
        'YiB'
    ]
};
const DEFAULT_OPTIONS = {
    unit: 0,
    displayedDecimalCount: 1
};
const formatFileSize = (bytes, options)=>{
    const { unit, displayedDecimalCount } = {
        ...DEFAULT_OPTIONS,
        ...options ?? {}
    };
    const threshold = unit === 0 ? 1000 : 1024;
    if (Math.abs(bytes) < threshold) {
        return bytes + ' B';
    }
    const units = UNITS[unit];
    let power = -1;
    const r = 10 ** displayedDecimalCount;
    do {
        bytes /= threshold;
        ++power;
    }while (Math.round(Math.abs(bytes) * r) / r >= threshold && power < units.length - 1)
    return bytes.toFixed(displayedDecimalCount) + ' ' + units[power];
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const increment = (value)=>value + 1;

const ZERO$2 = 0;
const isNegative = (value)=>value < ZERO$2;

const ZERO$1 = 0;
const isNonNegative = (value)=>value >= ZERO$1;

const ZERO = 0;
const isPositive = (value)=>value > ZERO;

const modulo = (n, m)=>{
    return (n % m + m) % m;
};

const FIRST_POSITION = 0;
const NEXT_POSITION = 1;
const getLastPosition = (array)=>{
    if (!array) {
        throw new TypeError('Array is required');
    }
    const lastElement = getLastElement(array);
    if (!lastElement) {
        return FIRST_POSITION;
    }
    const { position } = lastElement;
    return isNonNegative(position) ? position + NEXT_POSITION : FIRST_POSITION;
};

const getRandomElement = draw;

const FIRST_ELEMENT_INDEX = 0;
const isFirstElement = (index)=>index === FIRST_ELEMENT_INDEX;

const NOT_FOUND_VALUE$1 = -1;
const isIndexFound = (index)=>index !== NOT_FOUND_VALUE$1;

const NOT_FOUND_VALUE = -1;
const isIndexNotFound = (index)=>index === NOT_FOUND_VALUE;

const LAST_ELEMENT_OFFSET = -1;
const isLastElement = (array, index)=>index === array.length + LAST_ELEMENT_OFFSET;

const EMPTY_ARRAY_LENGTH = 0;
const isNotEmptyArray = (array)=>array.length !== EMPTY_ARRAY_LENGTH;

const orderArrayAlphabetically = (array, transform, locales, options)=>{
    return [
        ...array
    ].sort((a, b)=>transform(a).localeCompare(transform(b), locales, options));
};

const partition = (array, fn)=>{
    const grouper = (item)=>fn(item) ? 'truthy' : 'falsy';
    const { truthy, falsy } = group(array, grouper);
    return [
        truthy ?? [],
        falsy ?? []
    ];
};

const unique = unique$1;

const fileChecksum = async (file)=>{
    // Convert the file to a binary string
    const binaryString = await new Promise((resolve, reject)=>{
        const reader = new FileReader();
        reader.onload = (event)=>{
            if (!event.target || typeof event.target.result !== 'string') {
                reject('Oops, something went wrong with the file reader.');
                return;
            }
            resolve(event.target.result);
        };
        reader.onerror = ()=>{
            reject('Oops, something went wrong with the file reader.');
        };
        reader.readAsBinaryString(file);
    });
    // Convert binary string to a WordArray
    const wordArray = CryptoJS.enc.Latin1.parse(binaryString);
    // Compute the SHA-256 hash and convert to Base64
    const hash = CryptoJS.SHA256(wordArray);
    const base64Hash = CryptoJS.enc.Base64.stringify(hash);
    return base64Hash;
};

const isArrayBuffer = (value)=>{
    return value instanceof ArrayBuffer;
};

const getLocale = (localeString)=>{
    const localeStringToLocale = {
        fr: fr,
        en: enGB,
        es: es
    };
    return localeStringToLocale[localeString] || enGB;
};

const DEFAULT_LOCALE = enGB;
const FORMAT_DATE_STRING = 'yyyy-MM-dd';

const formatDuration = (duration, options)=>{
    const locale = options?.locale ? getLocale(options.locale) : DEFAULT_LOCALE;
    return formatDuration$1(duration, {
        format: options?.format,
        zero: options?.zero,
        delimiter: options?.delimiter,
        locale: locale
    });
};

const formatAnalyticsDuration = (seconds, locale)=>{
    if (isZero(seconds)) {
        return '0 s';
    }
    const duration = intervalToDuration({
        start: 0,
        end: seconds * 1000
    });
    //see https://github.com/date-fns/date-fns/issues/2134 for custom format to support locales
    const formattedDuration = formatDuration(duration, {
        zero: false,
        format: [
            'days',
            'hours',
            'minutes',
            'seconds'
        ],
        delimiter: ' ',
        locale
    });
    return formattedDuration.replace(/heures?/, 'h').replace(/hours?/, 'h').replace(/minutes?/, 'min').replace(/days?/, 'd').replace(/jours?/, 'j').replace(/secondes?/, 's').replace(/seconds?/, 's');
};

// eslint-disable-next-line func-style
function formatDate(dateValue, dateFormat) {
    try {
        if (dateValue instanceof Date) {
            return format(dateValue, dateFormat ?? FORMAT_DATE_STRING);
        }
        return format(parseISO(dateValue), dateFormat ?? FORMAT_DATE_STRING);
    } catch (error) {
        console.error({
            function: 'formatToDateWithoutTime',
            error
        });
        return '-';
    }
}

const FORMAT_LOCALIZED_DATE$1 = 'PPp';
// eslint-disable-next-line func-style
function formatLocalizedDateTime(dateString, locale = DEFAULT_LOCALE) {
    try {
        if (dateString instanceof Date) {
            return format(dateString, FORMAT_LOCALIZED_DATE$1, {
                locale
            });
        }
        return format(parseISO(dateString), FORMAT_LOCALIZED_DATE$1, {
            locale
        });
    } catch (error) {
        console.error({
            function: 'formatLocalizedDateTime',
            error
        });
        return '-';
    }
}

const FORMAT_LOCALIZED_DATE = 'P';
// eslint-disable-next-line func-style
function formatLocalizedDate(dateString, locale = DEFAULT_LOCALE) {
    try {
        if (dateString instanceof Date) {
            return format(dateString, FORMAT_LOCALIZED_DATE, {
                locale
            });
        }
        return format(parseISO(dateString), FORMAT_LOCALIZED_DATE, {
            locale
        });
    } catch (error) {
        console.error({
            function: 'formatLocalizedDate',
            error
        });
        return '-';
    }
}

// eslint-disable-next-line func-style
function formatLocalizedLongDate(dateValue, locale = DEFAULT_LOCALE) {
    const localOptions = locale?.code ? {
        locale: locale.code
    } : undefined;
    const date = dateValue instanceof Date ? dateValue : parseISO(dateValue);
    try {
        return intlFormat(date, // date-fns doesn't expose a type for the format options argument and the
        // `Intl.DateTimeFormatOptions` doesn't match the argument type either.
        // So we need to pass the argument like this.
        {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
        }, localOptions);
    } catch (error) {
        console.error({
            function: 'formatLocalizedLongDate',
            error
        });
        return '-';
    }
}

const ERROR_VALUE = 'Invalid Date';
const isDateString = (value)=>{
    const parsedDate = parse(value, FORMAT_DATE_STRING, new Date());
    if (parsedDate.toString() === ERROR_VALUE) {
        return false;
    }
    return true;
};

const parseDate = (dateString)=>{
    if (isDateString(dateString)) {
        return new Date(dateString);
    }
    return parseISO(dateString);
};

// eslint-disable-next-line func-style
function formatReadableDateDistance(date, baseDate, locale = DEFAULT_LOCALE) {
    try {
        // TS is not automatically inferring `baseDate` based on `date` type
        if (date instanceof Date && baseDate instanceof Date) {
            return formatDistance(date, baseDate, {
                addSuffix: true,
                locale
            });
        }
        // TS is not guessing that `date` and `baseDate` are strings
        if (typeof date === 'string' && typeof baseDate === 'string') {
            const formattedDate = parseDate(date);
            const formattedBaseDate = parseDate(baseDate);
            return formatDistance(formattedDate, formattedBaseDate, {
                addSuffix: true,
                locale
            });
        }
    } catch (error) {
        console.error({
            function: 'formatLocalizedDate',
            error
        });
    }
    return '-';
}

const FORMAT_SHORT_DATE = 'dd MMM';
// eslint-disable-next-line func-style
function formatShortDate(dateValue, locale = DEFAULT_LOCALE) {
    try {
        if (dateValue instanceof Date) {
            return format(dateValue, FORMAT_SHORT_DATE, {
                locale
            });
        }
        return format(parseISO(dateValue), FORMAT_SHORT_DATE, {
            locale
        });
    } catch (error) {
        console.error({
            function: 'formatShortDate',
            error
        });
        return '-';
    }
}

const formatTimeFromDate = (date, locale = DEFAULT_LOCALE)=>{
    try {
        return format(date, 'p', {
            locale
        });
    } catch (error) {
        console.error({
            function: 'formatTimeFromDate',
            error
        });
        return '-';
    }
};

const getDaysToSubtract = (days)=>{
    if (days === 0) return 0;
    if (days === 1) return 1;
    return days - 1;
};
const calculateDateRanges = (days, currentDate = new Date())=>{
    const currentEndDate = startOfDay(currentDate);
    const currentStartDate = startOfDay(subDays(currentEndDate, days));
    const previousEndDate = startOfDay(subDays(currentStartDate, 1));
    const daysToSubtractToPreviousStartDate = getDaysToSubtract(days);
    const previousStartDate = startOfDay(subDays(previousEndDate, daysToSubtractToPreviousStartDate));
    const currentRange = [
        formatDate(currentStartDate, 'yyyy-MM-dd'),
        formatDate(currentEndDate, 'yyyy-MM-dd')
    ];
    const previousRange = [
        formatDate(previousStartDate, 'yyyy-MM-dd'),
        formatDate(previousEndDate, 'yyyy-MM-dd')
    ];
    return {
        currentRange,
        previousRange
    };
};

const isSSR = ()=>typeof window === 'undefined';
const isBrowser = ()=>typeof window !== 'undefined';

// From : https://www.localeplanet.com/api/codelist.json
const LANGUAGES = [
    'af',
    'af-NA',
    'af-ZA',
    'agq',
    'agq-CM',
    'ak',
    'ak-GH',
    'am',
    'am-ET',
    'ar',
    'ar-001',
    'ar-AE',
    'ar-BH',
    'ar-DJ',
    'ar-DZ',
    'ar-EG',
    'ar-EH',
    'ar-ER',
    'ar-IL',
    'ar-IQ',
    'ar-JO',
    'ar-KM',
    'ar-KW',
    'ar-LB',
    'ar-LY',
    'ar-MA',
    'ar-MR',
    'ar-OM',
    'ar-PS',
    'ar-QA',
    'ar-SA',
    'ar-SD',
    'ar-SO',
    'ar-SS',
    'ar-SY',
    'ar-TD',
    'ar-TN',
    'ar-YE',
    'as',
    'as-IN',
    'asa',
    'asa-TZ',
    'ast',
    'ast-ES',
    'az',
    'az-Cyrl',
    'az-Cyrl-AZ',
    'az-Latn',
    'az-Latn-AZ',
    'bas',
    'bas-CM',
    'be',
    'be-BY',
    'bem',
    'bem-ZM',
    'bez',
    'bez-TZ',
    'bg',
    'bg-BG',
    'bm',
    'bm-ML',
    'bn',
    'bn-BD',
    'bn-IN',
    'bo',
    'bo-CN',
    'bo-IN',
    'br',
    'br-FR',
    'brx',
    'brx-IN',
    'bs',
    'bs-Cyrl',
    'bs-Cyrl-BA',
    'bs-Latn',
    'bs-Latn-BA',
    'ca',
    'ca-AD',
    'ca-ES',
    'ca-FR',
    'ca-IT',
    'ccp',
    'ccp-BD',
    'ccp-IN',
    'ce',
    'ce-RU',
    'cgg',
    'cgg-UG',
    'chr',
    'chr-US',
    'ckb',
    'ckb-IQ',
    'ckb-IR',
    'cs',
    'cs-CZ',
    'cy',
    'cy-GB',
    'da',
    'da-DK',
    'da-GL',
    'dav',
    'dav-KE',
    'de',
    'de-AT',
    'de-BE',
    'de-CH',
    'de-DE',
    'de-IT',
    'de-LI',
    'de-LU',
    'dje',
    'dje-NE',
    'dsb',
    'dsb-DE',
    'dua',
    'dua-CM',
    'dyo',
    'dyo-SN',
    'dz',
    'dz-BT',
    'ebu',
    'ebu-KE',
    'ee',
    'ee-GH',
    'ee-TG',
    'el',
    'el-CY',
    'el-GR',
    'en',
    'en-001',
    'en-150',
    'en-AG',
    'en-AI',
    'en-AS',
    'en-AT',
    'en-AU',
    'en-BB',
    'en-BE',
    'en-BI',
    'en-BM',
    'en-BS',
    'en-BW',
    'en-BZ',
    'en-CA',
    'en-CC',
    'en-CH',
    'en-CK',
    'en-CM',
    'en-CX',
    'en-CY',
    'en-DE',
    'en-DG',
    'en-DK',
    'en-DM',
    'en-ER',
    'en-FI',
    'en-FJ',
    'en-FK',
    'en-FM',
    'en-GB',
    'en-GD',
    'en-GG',
    'en-GH',
    'en-GI',
    'en-GM',
    'en-GU',
    'en-GY',
    'en-HK',
    'en-IE',
    'en-IL',
    'en-IM',
    'en-IN',
    'en-IO',
    'en-JE',
    'en-JM',
    'en-KE',
    'en-KI',
    'en-KN',
    'en-KY',
    'en-LC',
    'en-LR',
    'en-LS',
    'en-MG',
    'en-MH',
    'en-MO',
    'en-MP',
    'en-MS',
    'en-MT',
    'en-MU',
    'en-MW',
    'en-MY',
    'en-NA',
    'en-NF',
    'en-NG',
    'en-NL',
    'en-NR',
    'en-NU',
    'en-NZ',
    'en-PG',
    'en-PH',
    'en-PK',
    'en-PN',
    'en-PR',
    'en-PW',
    'en-RW',
    'en-SB',
    'en-SC',
    'en-SD',
    'en-SE',
    'en-SG',
    'en-SH',
    'en-SI',
    'en-SL',
    'en-SS',
    'en-SX',
    'en-SZ',
    'en-TC',
    'en-TK',
    'en-TO',
    'en-TT',
    'en-TV',
    'en-TZ',
    'en-UG',
    'en-UM',
    'en-US',
    'en-US-POSIX',
    'en-VC',
    'en-VG',
    'en-VI',
    'en-VU',
    'en-WS',
    'en-ZA',
    'en-ZM',
    'en-ZW',
    'eo',
    'es',
    'es-419',
    'es-AR',
    'es-BO',
    'es-BR',
    'es-BZ',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-CU',
    'es-DO',
    'es-EA',
    'es-EC',
    'es-ES',
    'es-GQ',
    'es-GT',
    'es-HN',
    'es-IC',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PH',
    'es-PR',
    'es-PY',
    'es-SV',
    'es-US',
    'es-UY',
    'es-VE',
    'et',
    'et-EE',
    'eu',
    'eu-ES',
    'ewo',
    'ewo-CM',
    'fa',
    'fa-AF',
    'fa-IR',
    'ff',
    'ff-CM',
    'ff-GN',
    'ff-MR',
    'ff-SN',
    'fi',
    'fi-FI',
    'fil',
    'fil-PH',
    'fo',
    'fo-DK',
    'fo-FO',
    'fr',
    'fr-BE',
    'fr-BF',
    'fr-BI',
    'fr-BJ',
    'fr-BL',
    'fr-CA',
    'fr-CD',
    'fr-CF',
    'fr-CG',
    'fr-CH',
    'fr-CI',
    'fr-CM',
    'fr-DJ',
    'fr-DZ',
    'fr-FR',
    'fr-GA',
    'fr-GF',
    'fr-GN',
    'fr-GP',
    'fr-GQ',
    'fr-HT',
    'fr-KM',
    'fr-LU',
    'fr-MA',
    'fr-MC',
    'fr-MF',
    'fr-MG',
    'fr-ML',
    'fr-MQ',
    'fr-MR',
    'fr-MU',
    'fr-NC',
    'fr-NE',
    'fr-PF',
    'fr-PM',
    'fr-RE',
    'fr-RW',
    'fr-SC',
    'fr-SN',
    'fr-SY',
    'fr-TD',
    'fr-TG',
    'fr-TN',
    'fr-VU',
    'fr-WF',
    'fr-YT',
    'fur',
    'fur-IT',
    'fy',
    'fy-NL',
    'ga',
    'ga-IE',
    'gd',
    'gd-GB',
    'gl',
    'gl-ES',
    'gsw',
    'gsw-CH',
    'gsw-FR',
    'gsw-LI',
    'gu',
    'gu-IN',
    'guz',
    'guz-KE',
    'gv',
    'gv-IM',
    'ha',
    'ha-GH',
    'ha-NE',
    'ha-NG',
    'haw',
    'haw-US',
    'he',
    'he-IL',
    'hi',
    'hi-IN',
    'hr',
    'hr-BA',
    'hr-HR',
    'hsb',
    'hsb-DE',
    'hu',
    'hu-HU',
    'hy',
    'hy-AM',
    'id',
    'id-ID',
    'ig',
    'ig-NG',
    'ii',
    'ii-CN',
    'is',
    'is-IS',
    'it',
    'it-CH',
    'it-IT',
    'it-SM',
    'it-VA',
    'ja',
    'ja-JP',
    'jgo',
    'jgo-CM',
    'jmc',
    'jmc-TZ',
    'ka',
    'ka-GE',
    'kab',
    'kab-DZ',
    'kam',
    'kam-KE',
    'kde',
    'kde-TZ',
    'kea',
    'kea-CV',
    'khq',
    'khq-ML',
    'ki',
    'ki-KE',
    'kk',
    'kk-KZ',
    'kkj',
    'kkj-CM',
    'kl',
    'kl-GL',
    'kln',
    'kln-KE',
    'km',
    'km-KH',
    'kn',
    'kn-IN',
    'ko',
    'ko-KP',
    'ko-KR',
    'kok',
    'kok-IN',
    'ks',
    'ks-IN',
    'ksb',
    'ksb-TZ',
    'ksf',
    'ksf-CM',
    'ksh',
    'ksh-DE',
    'kw',
    'kw-GB',
    'ky',
    'ky-KG',
    'lag',
    'lag-TZ',
    'lb',
    'lb-LU',
    'lg',
    'lg-UG',
    'lkt',
    'lkt-US',
    'ln',
    'ln-AO',
    'ln-CD',
    'ln-CF',
    'ln-CG',
    'lo',
    'lo-LA',
    'lrc',
    'lrc-IQ',
    'lrc-IR',
    'lt',
    'lt-LT',
    'lu',
    'lu-CD',
    'luo',
    'luo-KE',
    'luy',
    'luy-KE',
    'lv',
    'lv-LV',
    'mas',
    'mas-KE',
    'mas-TZ',
    'mer',
    'mer-KE',
    'mfe',
    'mfe-MU',
    'mg',
    'mg-MG',
    'mgh',
    'mgh-MZ',
    'mgo',
    'mgo-CM',
    'mk',
    'mk-MK',
    'ml',
    'ml-IN',
    'mn',
    'mn-MN',
    'mr',
    'mr-IN',
    'ms',
    'ms-BN',
    'ms-MY',
    'ms-SG',
    'mt',
    'mt-MT',
    'mua',
    'mua-CM',
    'my',
    'my-MM',
    'mzn',
    'mzn-IR',
    'naq',
    'naq-NA',
    'nb',
    'nb-NO',
    'nb-SJ',
    'nd',
    'nd-ZW',
    'nds',
    'nds-DE',
    'nds-NL',
    'ne',
    'ne-IN',
    'ne-NP',
    'nl',
    'nl-AW',
    'nl-BE',
    'nl-BQ',
    'nl-CW',
    'nl-NL',
    'nl-SR',
    'nl-SX',
    'nmg',
    'nmg-CM',
    'nn',
    'nn-NO',
    'nnh',
    'nnh-CM',
    'nus',
    'nus-SS',
    'nyn',
    'nyn-UG',
    'om',
    'om-ET',
    'om-KE',
    'or',
    'or-IN',
    'os',
    'os-GE',
    'os-RU',
    'pa',
    'pa-Arab',
    'pa-Arab-PK',
    'pa-Guru',
    'pa-Guru-IN',
    'pl',
    'pl-PL',
    'ps',
    'ps-AF',
    'pt',
    'pt-AO',
    'pt-BR',
    'pt-CH',
    'pt-CV',
    'pt-GQ',
    'pt-GW',
    'pt-LU',
    'pt-MO',
    'pt-MZ',
    'pt-PT',
    'pt-ST',
    'pt-TL',
    'qu',
    'qu-BO',
    'qu-EC',
    'qu-PE',
    'rm',
    'rm-CH',
    'rn',
    'rn-BI',
    'ro',
    'ro-MD',
    'ro-RO',
    'rof',
    'rof-TZ',
    'ru',
    'ru-BY',
    'ru-KG',
    'ru-KZ',
    'ru-MD',
    'ru-RU',
    'ru-UA',
    'rw',
    'rw-RW',
    'rwk',
    'rwk-TZ',
    'sah',
    'sah-RU',
    'saq',
    'saq-KE',
    'sbp',
    'sbp-TZ',
    'se',
    'se-FI',
    'se-NO',
    'se-SE',
    'seh',
    'seh-MZ',
    'ses',
    'ses-ML',
    'sg',
    'sg-CF',
    'shi',
    'shi-Latn',
    'shi-Latn-MA',
    'shi-Tfng',
    'shi-Tfng-MA',
    'si',
    'si-LK',
    'sk',
    'sk-SK',
    'sl',
    'sl-SI',
    'smn',
    'smn-FI',
    'sn',
    'sn-ZW',
    'so',
    'so-DJ',
    'so-ET',
    'so-KE',
    'so-SO',
    'sq',
    'sq-AL',
    'sq-MK',
    'sq-XK',
    'sr',
    'sr-Cyrl',
    'sr-Cyrl-BA',
    'sr-Cyrl-ME',
    'sr-Cyrl-RS',
    'sr-Cyrl-XK',
    'sr-Latn',
    'sr-Latn-BA',
    'sr-Latn-ME',
    'sr-Latn-RS',
    'sr-Latn-XK',
    'sv',
    'sv-AX',
    'sv-FI',
    'sv-SE',
    'sw',
    'sw-CD',
    'sw-KE',
    'sw-TZ',
    'sw-UG',
    'ta',
    'ta-IN',
    'ta-LK',
    'ta-MY',
    'ta-SG',
    'te',
    'te-IN',
    'teo',
    'teo-KE',
    'teo-UG',
    'tg',
    'tg-TJ',
    'th',
    'th-TH',
    'ti',
    'ti-ER',
    'ti-ET',
    'to',
    'to-TO',
    'tr',
    'tr-CY',
    'tr-TR',
    'tt',
    'tt-RU',
    'twq',
    'twq-NE',
    'tzm',
    'tzm-MA',
    'ug',
    'ug-CN',
    'uk',
    'uk-UA',
    'ur',
    'ur-IN',
    'ur-PK',
    'uz',
    'uz-Arab',
    'uz-Arab-AF',
    'uz-Cyrl',
    'uz-Cyrl-UZ',
    'uz-Latn',
    'uz-Latn-UZ',
    'vai',
    'vai-Latn',
    'vai-Latn-LR',
    'vai-Vaii',
    'vai-Vaii-LR',
    'vi',
    'vi-VN',
    'vun',
    'vun-TZ',
    'wae',
    'wae-CH',
    'wo',
    'wo-SN',
    'xog',
    'xog-UG',
    'yav',
    'yav-CM',
    'yi',
    'yi-001',
    'yo',
    'yo-BJ',
    'yo-NG',
    'yue',
    'yue-Hans',
    'yue-Hans-CN',
    'yue-Hant',
    'yue-Hant-HK',
    'zgh',
    'zgh-MA',
    'zh',
    'zh-Hans',
    'zh-Hans-CN',
    'zh-Hans-HK',
    'zh-Hans-MO',
    'zh-Hans-SG',
    'zh-Hant',
    'zh-Hant-HK',
    'zh-Hant-MO',
    'zh-Hant-TW',
    'zu',
    'zu-ZA'
];

const getDisplayName = (person, fallback)=>{
    if (person?.isFromTopo) {
        return 'Topo';
    }
    if (person?.firstName && person.lastName) {
        return `${person.firstName} ${person.lastName}`;
    }
    if (person?.firstName) {
        return person.firstName;
    }
    if (person?.lastName) {
        return person.lastName;
    }
    if (fallback) {
        return fallback;
    }
    return 'someone';
};

const exhaustiveGuard = (_value)=>{
    throw new Error(`Reached exhaustive guard function with unexpected value: ${JSON.stringify(_value)}`);
};

const isNil = (arg)=>{
    return arg === null || arg === undefined;
};

const isNotNil = (arg)=>{
    return arg !== null && arg !== undefined;
};

const deepClone = (object)=>{
    return JSON.parse(JSON.stringify(object));
};

/* eslint-disable @typescript-eslint/ban-ts-comment */ const dig = (object, path)=>{
    const paths = path.split('.');
    const value = paths.reduce((acc, key)=>{
        // @ts-ignore
        if (acc && key in acc && acc[key]) {
            // @ts-ignore
            return acc[key];
        }
    }, object);
    // type-coverage:ignore-next-line
    return value;
};

const filterWithoutUndefined = (data)=>{
    const filtered = Object.keys(data).reduce((acc, key)=>data[key] === undefined ? acc : {
            ...acc,
            [key]: data[key]
        }, {});
    return filtered;
};

const hash = (value)=>{
    if (value === undefined) return undefined;
    if (value === null) return null;
    return objectHash(value);
};

const isEmptyObject = (o)=>{
    return Object.keys(o).length === 0;
};

const deleteTypename = (key, value)=>key === '__typename' ? undefined : value;
const omitTypename = (payload)=>JSON.parse(JSON.stringify(payload), deleteTypename);

const omit = function(obj, keys) {
    if (!obj) {
        // type-coverage:ignore-next-line
        return {};
    }
    const omited = {};
    for (const key of Object.keys(obj)){
        // type-coverage:ignore-next-line
        const typedKey = key;
        if (!keys.includes(typedKey)) {
            omited[typedKey] = obj[typedKey];
        }
    }
    // type-coverage:ignore-next-line
    return omited;
};

const DELTA = 0.5;
const getNextPositionForCache = ({ previousPosition, newPosition })=>{
    if (previousPosition === newPosition) {
        return previousPosition;
    }
    return previousPosition > newPosition ? newPosition - DELTA : newPosition + DELTA;
};

const capitalizeFirstLetter = (str)=>{
    return str[0].toUpperCase() + str.slice(1);
};

const capitalize = (str)=>{
    return str.toLocaleLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match)=>match.toLocaleUpperCase());
};

const contains = (str1, str2, opts)=>{
    const { mode = 'sensitive' } = opts ?? {};
    if (mode === 'insensitive') {
        return str1.toLocaleLowerCase().includes(str2.toLocaleLowerCase());
    }
    return str1.includes(str2);
};

const createHash = (stringToHash)=>{
    let hash = 0;
    if (!stringToHash) {
        return hash;
    }
    for(let i = 0; i < stringToHash.length; i++){
        hash = Math.imul(31, hash) + stringToHash.charCodeAt(i) | 0;
    }
    return hash;
};

const escapeJSON = (inputString)=>{
    const modifiedString = inputString.replace(/({|})/g, '$1$1');
    return modifiedString;
};

const removeAccents = (str)=>{
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const generateSlugFromString = (str)=>{
    const withoutAccents = removeAccents(str);
    const slug = withoutAccents.toLowerCase()// replace special chars
    .replace(/[^\w\s-]/g, '');
    const withoutSpacesOrUnderscore = paramCase(slug);
    return withoutSpacesOrUnderscore;
};

const isBlank = (str)=>str.trim() === '';

const isEqual = (str1, str2)=>str1 === str2;

const isLink = (text)=>{
    const regex = /^((https?:)?\/\/)?[\w.-]+\.[a-z]{2,}([/?].*)?$/;
    return regex.test(text);
};
const isMailtoLink = (text)=>{
    const regex = /^mailto:[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(text);
};

const isLower = (str)=>{
    return str.toLowerCase() === str;
};

const isString = (value)=>{
    return typeof value === 'string';
};

const isUpper = (str)=>{
    return str.toUpperCase() === str;
};

const isomorphicAtob = (str)=>{
    if (window?.atob) {
        return window.atob(str);
    } else if (Buffer?.from) {
        return Buffer.from(str, 'base64').toString();
    }
    throw new Error('No atob implementation found');
};

const isomorphicBtoa = (str)=>{
    if (window?.btoa) {
        return window.btoa(str);
    } else if (Buffer?.from) {
        return Buffer.from(str).toString('base64');
    }
    throw new Error('No btoa implementation found');
};

const matchRule = (str, rule)=>{
    const pattern = RegExp('/([.*+?^=!:${}()|[]/\\])/g');
    const escapeRegex = (str)=>str.replace(pattern, '\\$1');
    return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
};
const matchRules = (str, rules)=>{
    return rules.some((r)=>matchRule(str, r));
};

const parseBoolean = (value)=>{
    switch(value){
        case true:
        case 'true':
        case 1:
        case '1':
            return true;
        default:
            return false;
    }
};

const START_INDEX = 0;
const removeSuffix = (str, suffix)=>{
    if (str.endsWith(suffix)) {
        return str.slice(START_INDEX, -suffix.length);
    }
    return str;
};

const replaceLineBreaksWithBr = (text)=>{
    return text.replace(/\n/g, '<br>');
};

const truncate = (input, maxLength)=>{
    if (input.length <= maxLength) {
        return input;
    } else {
        return `${input.slice(0, maxLength)}...`;
    }
};

const sanitizeUrl = (value, options)=>{
    const defaultUrl = options.defaultUrl ?? 'http://localhost';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const ssl = options.ssl ?? false;
    let url;
    try {
        url = new URL(value);
    } catch (error) {
        url = new URL(defaultUrl);
    }
    const scheme = ssl ? 'https' : 'http';
    const host = url.port ? `${url.hostname}:${url.port}` : url.hostname;
    return `${scheme}://${host}`;
};

const REPLACEMENTS = [
    {
        escapedForm: '&',
        replaceBy: '&amp;'
    },
    {
        escapedForm: '<',
        replaceBy: '&gt;'
    },
    {
        escapedForm: '>',
        replaceBy: '&lt;'
    },
    {
        escapedForm: '"',
        replaceBy: '&quot;'
    },
    {
        escapedForm: "'",
        replaceBy: '&apos;'
    }
];
const escapeSpecialCharactersForXML = (str)=>{
    let finalString = str;
    for (const replacement of REPLACEMENTS){
        if (contains(str, replacement.escapedForm)) {
            const re = new RegExp(replacement.escapedForm, 'g');
            finalString = finalString.replace(re, replacement.replaceBy);
        }
    }
    return finalString;
};

const toXMLString = (data, options)=>{
    let result = '';
    for (const key of Object.keys(data)){
        const formattedKey = options?.capitalize ? capitalizeFirstLetter(key) : key;
        const value = data[key];
        result += `<${formattedKey}>${isString(value) ? escapeSpecialCharactersForXML(value) : value}</${formattedKey}>`;
    }
    return result;
};

export { DEFAULT_LOCALE, FORMAT_DATE_STRING, FileSizeUnit, LANGUAGES, ZERO$3 as ZERO, calculateDateRanges, calculatePercentageDiff, capitalize, capitalizeFirstLetter, chunkArray, clamp, compact, contains, createHash, decrement, deepClone, deleteTypename, dig, divide, escapeJSON, escapeSpecialCharactersForXML, exhaustiveGuard, fileChecksum, filterWithoutUndefined, formatAnalyticsDuration, formatDate, formatDiff, formatDuration, formatFileSize, formatLocalizedDate, formatLocalizedDateTime, formatLocalizedLongDate, formatNumber, formatReadableDateDistance, formatShortDate, formatTimeFromDate, generateSlugFromString, getDisplayName, getFirstElement, getFirstNthElements, getLastElement, getLastPosition, getLocale, getNextPositionForCache, getRandomElement, hash, increment, isArrayBuffer, isBlank, isBrowser, isDateString, isEmptyArray, isEmptyObject, isEqual, isFirstElement, isIndexFound, isIndexNotFound, isLastElement, isLink, isLower, isMailtoLink, isNegative, isNil, isNonNegative, isNotEmptyArray, isNotNil, isPositive, isSSR, isString, isUpper, isZero, isomorphicAtob, isomorphicBtoa, matchRule, matchRules, modulo, omit, omitTypename, orderArrayAlphabetically, parseBoolean, parseDate, partition, removeAccents, removeSuffix, replaceLineBreaksWithBr, sanitizeUrl, toXMLString, truncate, unique };
