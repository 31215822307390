import type { ItemType } from '@topo-io/design-system';
import { Box, ComboboxItem } from '@topo-io/design-system';

export const DefaultComboboxOptions = <T extends ItemType>({ items }: { items: T[] }) =>
  items.map((item) => (
    <ComboboxItem key={item.key} item={item}>
      <Box fontWeight="400" flex="1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        {item.label}
      </Box>
    </ComboboxItem>
  ));
