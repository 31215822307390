import { Text, Tooltip } from '@topo-io/design-system';
import type { TextProps } from '@topo-io/design-system';

interface TooltipParagraphProps extends TextProps {
  text: string;
  maxTextLength?: number;
}

export const TooltipParagraph = ({
  text,
  maxTextLength = 20,
  ...otherProps
}: TooltipParagraphProps) => {
  if (text.length <= maxTextLength) {
    return (
      <Text width="100%" textAlign="center" {...otherProps}>
        {text ?? ''}
      </Text>
    );
  }

  return (
    <Tooltip label={text} placement="top">
      <Text
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
        width="100%"
        textAlign="center"
        {...otherProps}
      >
        {text}
      </Text>
    </Tooltip>
  );
};
