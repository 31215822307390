import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { Box, Button, ColorPickerInput, Flex, Text, Icon } from '@topo-io/design-system';
import { BrandingPreview } from './branding-preview';

interface BrandingFormProps {
  primaryColor: string;
  contrastTextColor: string;
  onPrimaryColorChange: (color: string) => void;
  onContrastTextColorChange: (color: string) => void;
  onReset: () => void;
}

export const BrandingForm: FC<BrandingFormProps> = ({
  primaryColor,
  contrastTextColor,
  onPrimaryColorChange,
  onContrastTextColorChange,
  onReset,
}) => {
  return (
    <Flex gap="6">
      <Box flex="1 1 0px">
        <Text mb="6" color="gray.500">
          <Trans>Brand elements will be used in your workspaces, and in the guests views.</Trans>
        </Text>

        <Flex gap="4">
          <Box flex="1 1 0px">
            <Text mb="2" fontWeight="500">
              <Trans>Primary color</Trans>
            </Text>
            <ColorPickerInput color={primaryColor} onChange={onPrimaryColorChange} />
          </Box>
          <Box flex="1 1 0px">
            <Text mb="2" fontWeight="500">
              <Trans>Contrast text color</Trans>
            </Text>
            <ColorPickerInput color={contrastTextColor} onChange={onContrastTextColorChange} />
          </Box>
        </Flex>

        <Button
          color="gray.500"
          bgColor="gray.20"
          mt="6"
          leftIcon={<Icon icon="Refresh" w="16px" h="16px" />}
          variant="ghost"
          onClick={onReset}
        >
          <Trans>Reset branding</Trans>
        </Button>
      </Box>
      <Box flex="1 1 0px" h="auto">
        <BrandingPreview />
      </Box>
    </Flex>
  );
};
