import { t } from '@lingui/macro';
import type { UseFormReturn } from '@saas-ui/forms';
import type { ChangeEvent, FC, MutableRefObject } from 'react';
import { useState } from 'react';
import { Box } from '@topo-io/design-system';
import { isNotNil } from '@topo-io/utils';
import type { CRMAccountItemType, OpportunityItemType } from '@/components/common/fields';
import { CRMAccountsField, OpportunitiesField } from '@/components/common/fields';

interface WorkspaceCRMFieldsProps {
  formRef: MutableRefObject<UseFormReturn>;
}

export const WorkspaceCRMFields: FC<WorkspaceCRMFieldsProps> = ({ formRef }) => {
  const [selectedCRMAccountId, setSelectedCRMAccountId] = useState<string | undefined>();

  const onChangeOpportunity = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value as unknown as OpportunityItemType | undefined;

    formRef.current.setValue('opportunity', inputValue);
  };

  const onChangeCRMAccount = (e: ChangeEvent<HTMLInputElement>) => {
    const item = e.target.value as unknown as CRMAccountItemType | undefined;
    setSelectedCRMAccountId(item?.id);
  };

  return (
    <>
      <CRMAccountsField
        name="CRMAccount"
        defaultCRMAccount={undefined}
        onChange={onChangeCRMAccount}
        label={t`CRM Account Sync`}
        placeholder={t`eg. Acme`}
      />
      <Box h={4} />
      {isNotNil(selectedCRMAccountId) && (
        <OpportunitiesField
          name="opportunity"
          defaultOpportunity={undefined}
          CRMAccountId={selectedCRMAccountId}
          onChange={onChangeOpportunity}
          label={t`CRM Opportunity Sync`}
          placeholder={t`eg. Acme Opportunity`}
        />
      )}
    </>
  );
};
