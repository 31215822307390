import { Trans, t } from '@lingui/macro';
import { Button, H6, SimpleModal, Text, useToast } from '@topo-io/design-system';
import { isNotNil } from '@topo-io/utils';
import { useDeleteWorkspace, useDeleteWorkspaceTemplate } from '@/components/workspace/hooks';

interface DeleteWorkspaceOrTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteSuccess?: () => void;
  isWorkspace?: boolean;
  id: string;
}

export const DeleteWorkspaceOrTemplateModal: React.FC<DeleteWorkspaceOrTemplateModalProps> = ({
  isOpen,
  onClose,
  isWorkspace,
  id,
  onDeleteSuccess,
}) => {
  const { successToast, errorToast } = useToast();
  const { deleteWorkspace, loading: isDeleteWorkspaceLoading } = useDeleteWorkspace(id);
  const { deleteWorkspaceTemplate, loading: isDeleteWorkspaceTemplateLoading } =
    useDeleteWorkspaceTemplate(id);

  const handleSubmit = async () => {
    try {
      if (isWorkspace) {
        await deleteWorkspace();
      } else {
        await deleteWorkspaceTemplate();
      }
      successToast({
        title: t`Deleted successfully`,
      });
      if (isNotNil(onDeleteSuccess)) {
        onDeleteSuccess();
      }
    } catch (error) {
      errorToast({
        title: t`An error occurred during deletion`,
      });
    }

    onClose();
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      footer={
        <Button
          colorScheme="red"
          onClick={handleSubmit}
          isLoading={isWorkspace ? isDeleteWorkspaceLoading : isDeleteWorkspaceTemplateLoading}
        >
          <Trans>Delete</Trans>
        </Button>
      }
    >
      <H6 mt="1">
        {isWorkspace ? <Trans>Delete workspace</Trans> : <Trans>Delete template</Trans>}
      </H6>
      <Text mt="6">
        <Trans>
          Are you sure you want to delete this {isWorkspace ? 'workspace' : 'template'}? This action
          cannot be undone.
        </Trans>
      </Text>
    </SimpleModal>
  );
};
