import { t } from '@lingui/macro';
import { useCallback } from 'react';
import { useSectionTemplateQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { useBaseRoutesContext } from '@/components/routing/hooks';
import { useSectionTemplateContext } from '@/components/section/hooks';

export const usePageTitle = () => {
  const {
    isWorkspacesPage,
    isNotificationsPage,
    isAnalyticsPage,
    isIntegrationsPage,
    isTagsSettingsPage,
    isSectionTemplatesPage,
    isWorkspaceTemplatesPage,
    isProfilePage,
    isOrganizationSettingsPage,
    isHomePage,
  } = useBaseRoutesContext();

  const { id: sectionTemplateId, isSectionTemplate } = useSectionTemplateContext();

  const { data: sectionTemplateData } = useSectionTemplateQuery({
    variables: { id: sectionTemplateId! },
    skip: isNil(sectionTemplateId),
  });

  const sectionTemplateTitle = sectionTemplateData?.sectionTemplate?.title;

  const getTitle = useCallback(() => {
    if (isSectionTemplate) {
      return sectionTemplateTitle ?? t`Section templates`;
    } else if (isSectionTemplatesPage) {
      return t`Section templates`;
    } else if (isWorkspaceTemplatesPage) {
      return t`Workspace templates`;
    } else if (isWorkspacesPage) {
      return t`Workspaces`;
    } else if (isNotificationsPage) {
      return t`Notifications`;
    } else if (isAnalyticsPage) {
      return t`Analytics`;
    } else if (isIntegrationsPage) {
      return t`Integrations`;
    } else if (isProfilePage) {
      return t`Profile`;
    } else if (isTagsSettingsPage) {
      return t`Manage tags and categories`;
    } else if (isHomePage) {
      return t`Home`;
    } else if (isOrganizationSettingsPage) {
      return t`Organization`;
    }

    return '';
  }, [
    isSectionTemplate,
    isSectionTemplatesPage,
    isWorkspaceTemplatesPage,
    isWorkspacesPage,
    isNotificationsPage,
    isAnalyticsPage,
    isIntegrationsPage,
    isTagsSettingsPage,
    sectionTemplateTitle,
    isProfilePage,
    isHomePage,
    isOrganizationSettingsPage,
  ]);

  return getTitle();
};
