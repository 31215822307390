import Link from 'next/link';
import { Box, H3, Flex, Text } from '@topo-io/design-system';

export const NeedSlug = () => {
  return (
    <Flex w="full" h="full" alignItems="center" justifyContent="center">
      <Box>
        <H3>The URL of your dashboard has changed!</H3>
        <Text>
          The new url should start like this <strong>your-company</strong>.topo.io.
        </Text>
        <Text>
          If you have any question, please reach out to{' '}
          <Text as="span" color="primary.500">
            <Link href="mailto:tech@topo.io">this email</Link>
          </Text>
          .
        </Text>
      </Box>
    </Flex>
  );
};
