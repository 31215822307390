import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { isNil } from '@topo-io/utils';
import type { FilterKey, WorkspaceFiltersItem, WorkspaceFiltersState, FilterValue } from './types';

interface WorkspaceFiltersContextValue {
  selectedFilter: WorkspaceFiltersItem | null;
  filtersState: WorkspaceFiltersState | null;
  updateSelectedFilter: React.Dispatch<React.SetStateAction<WorkspaceFiltersItem | null>>;
  hasAppliedFilters: boolean;
  addFilter: (key: FilterKey, value: FilterValue) => void;
  removeFilter: (key: FilterKey) => void;
}

const WorkspaceFiltersContext = createContext<WorkspaceFiltersContextValue>({
  selectedFilter: null,
  filtersState: null,
  updateSelectedFilter: () => {},
  hasAppliedFilters: false,
  addFilter: () => {},
  removeFilter: () => {},
});

export const WorkspaceFiltersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState<WorkspaceFiltersItem | null>(null);
  const [filtersState, setFiltersState] = useState<WorkspaceFiltersState | null>(null);

  const hasAppliedFilters = useMemo(() => filtersState !== null, [filtersState]);

  const addFilter = useCallback((key: FilterKey, value: FilterValue) => {
    setFiltersState((prevState) => {
      if (prevState === null) {
        return { [key]: value };
      }
      return { ...prevState, [key]: value };
    });
  }, []);

  const removeFilter = useCallback((key: FilterKey) => {
    setFiltersState((prevState) => {
      if (prevState === null) {
        return null;
      }
      const newState = { ...prevState };
      delete newState[key];
      return newState;
    });
  }, []);

  const value = useMemo(
    () => ({
      selectedFilter,
      filtersState,
      updateSelectedFilter: setSelectedFilter,
      hasAppliedFilters,
      addFilter,
      removeFilter,
    }),
    [selectedFilter, filtersState, hasAppliedFilters, addFilter, removeFilter]
  );

  return (
    <WorkspaceFiltersContext.Provider value={value}>{children}</WorkspaceFiltersContext.Provider>
  );
};

export const useWorkspaceFiltersContext = () => {
  const context = useContext(WorkspaceFiltersContext);
  if (isNil(context)) {
    throw new Error('useWorkspaceFiltersContext must be used within a WorkspaceFiltersProvider');
  }

  return context;
};
