// import { useRouter } from 'next/router';
import type { Row, SortingState } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { DataTable } from '@topo-io/design-system';
import { CompanyRole } from '@topo-io/graphql';
import type { EngagementScore, WorkspaceSearchRecord } from '@topo-io/types';
import { IndexSortDirection } from '@topo-io/types';
import { isEmptyArray } from '@topo-io/utils';
import { SearchableDataTablePagination } from '@/components/common';
import { TableEmptyState } from '@/components/common/table-empty-state';
import { useWorkspacesDailyScore } from '@/components/engagement-score';
import { workspaceUrl } from '@/config';
import { useSearchFilters, useSearchResults, useUser, useSearchSort } from '@/hooks';
import { buildWorkspaceListColumns } from './workspace-list-columns';

const loadingRowExample: Record<string, string> = {
  workspace: 'Fake workspace title',
  value: '1000',
  stage: 'Pending qualification',
  closeDate: '2021-01-01',
  viewsCount: '100',
  lastViewDate: '2021-01-01',
  owner: 'John Doe',
  members: 'John Doe, Jane Doe',
};

export const WorkspaceList = () => {
  const { user } = useUser();
  const router = useRouter();

  const { results, isLoading, refresh } = useSearchResults<WorkspaceSearchRecord>();
  const { updateFilter } = useSearchFilters();
  const { sortBy, reset } = useSearchSort({
    attribute: 'updatedAt',
    sortDirection: IndexSortDirection.DESC,
  });

  const { data: engagementScoreData, isLoading: isEngagementScoreLoading } =
    useWorkspacesDailyScore({
      organizationId: user?.organizationId,
    });

  const shouldFilterOwnerWorkspaces = useMemo(() => {
    if (!user || !('id' in user)) {
      return true;
    }

    if (user?.companyRole === CompanyRole.MANAGER) {
      return false;
    }

    return true;
  }, [user]);

  useEffect(() => {
    if (!user || !('id' in user)) {
      return;
    }

    updateFilter({
      attribute: 'ownerId',
      operator: 'equals',
      value: user?.id,
      enabled: shouldFilterOwnerWorkspaces,
    });
  }, [updateFilter, shouldFilterOwnerWorkspaces, user]);

  const goToWorkspace = useCallback(
    (e: Row<WorkspaceSearchRecord>) => {
      const url = workspaceUrl(e.original.id);
      void router.push(url);
    },
    [router]
  );

  const columns = useMemo(
    () =>
      buildWorkspaceListColumns({
        isEngagementScoreLoading,
        onWorkspaceDeletionSuccess: refresh,
      }),
    [refresh, isEngagementScoreLoading]
  );

  const handleOnSortingchange = (sorting: SortingState) => {
    if (isEmptyArray(sorting)) {
      reset();
      return;
    }

    const attribute = sorting[0].id;
    const direction = sorting[0].desc ? IndexSortDirection.DESC : IndexSortDirection.ASC;
    sortBy({ attribute, direction });
  };

  const resultsWithEngagementScore = useMemo(() => {
    const engagementScoreByWorkspaceId = engagementScoreData.reduce<
      Record<string, EngagementScore | undefined>
    >((acc, curr) => {
      acc[curr.workspaceId] = curr;
      return acc;
    }, {});

    return results.map((workspaceSearchRecord) => {
      const engagementScore = engagementScoreByWorkspaceId[workspaceSearchRecord.id];
      return {
        ...workspaceSearchRecord,
        engagementScore,
      };
    });
  }, [results, engagementScoreData]);

  return (
    <>
      <DataTable
        data={resultsWithEngagementScore}
        loadingState={{
          isLoading: isLoading,
          loadingRowExample: { ...loadingRowExample, score: '100' },
        }}
        columns={columns}
        onClick={goToWorkspace}
        onSortingChange={handleOnSortingchange}
        emptyState={<TableEmptyState />}
      />
      <SearchableDataTablePagination />
    </>
  );
};
