import axios from 'axios';
import { all } from './secrets';

const { GRAPHQL_URL, BACKEND_URL, FRONTEND_URL } = all;

const TIMEOUT_MS = 5000;

export const HEALTH_URL = new URL('/api/health/status', BACKEND_URL).toString();
export const DEFAULT_GQL_URL = new URL(GRAPHQL_URL, BACKEND_URL).toString();
export const FALLBACK_GQL_URL = new URL(GRAPHQL_URL, FRONTEND_URL).toString();

export const getGraphqlUrl = async () => {
  try {
    await axios.get(HEALTH_URL, { timeout: TIMEOUT_MS });
    return DEFAULT_GQL_URL;
  } catch (error) {
    console.error(`Failed to connect to ${HEALTH_URL}`);
  }

  console.warn(`Using ${FALLBACK_GQL_URL} endpoint`);
  return FALLBACK_GQL_URL;
};
