import { useState, createContext, useMemo, useCallback } from 'react';
import type { ReactNode } from 'react';
import { TimePeriod } from '@/components/analytics/types';

export interface GlobalAnalyticsContextReturnValue {
  timePeriod: TimePeriod;
  selectedUsers: string[];
  changeTimePeriod: (timePeriod: TimePeriod) => void;
  changeSelectedUsers: (users: string[]) => void;
}

interface GlobalAnalyticsProviderProps {
  children: ReactNode;
}

export const GlobalAnalyticsContext = createContext<GlobalAnalyticsContextReturnValue>({
  timePeriod: TimePeriod.AllTime,
  selectedUsers: [],
  changeTimePeriod: () => {},
  changeSelectedUsers: () => {},
});

export const GlobalAnalyticsProvider = ({ children }: GlobalAnalyticsProviderProps) => {
  const [timePeriod, setTimePeriod] = useState(TimePeriod.AllTime);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const changeTimePeriod = useCallback((timePeriod: TimePeriod) => {
    setTimePeriod(timePeriod);
  }, []);

  const changeSelectedUsers = useCallback((users: string[]) => {
    setSelectedUsers(users);
  }, []);

  const value = useMemo(() => {
    return {
      timePeriod,
      selectedUsers,
      changeTimePeriod,
      changeSelectedUsers,
    };
  }, [timePeriod, selectedUsers, changeTimePeriod, changeSelectedUsers]);

  return (
    <GlobalAnalyticsContext.Provider value={value}>{children}</GlobalAnalyticsContext.Provider>
  );
};
