import { Provider } from 'jotai';
import { DevTools } from 'jotai-devtools';
import type { FC, PropsWithChildren } from 'react';

// Because we need to handle SSR scenarios, we explicitly wrap our app inside a Provider.
// This way, our state will be held in the subtree instead of in Jotai's global store.
export const StateProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider>
      {children}
      <DevTools />
    </Provider>
  );
};
