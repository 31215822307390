import { isNil } from '@topo-io/utils';

export const DEFAULT_PAGE_TITLE = 'Topo';

export const formatPageTitle = (mainTitle: string | undefined) => {
  if (isNil(mainTitle) || mainTitle === '') {
    return DEFAULT_PAGE_TITLE;
  }
  return `${mainTitle} | ${DEFAULT_PAGE_TITLE}`;
};
