import { CUBE_CONSTANTS } from '@topo-io/constants';
import { getFirstElement, isNil } from '@topo-io/utils';
import type {
  EngagementScoreDateRange,
  EngagementScoreInteractions,
} from '@/components/engagement-score/types';
import { mapDateRangeToDates } from '@/components/engagement-score/utils';
import { useAnalyticsQuery } from '@/hooks';

interface UseWorkspaceScoreInteractionsArgs {
  workspaceId: string;
  dateRange: EngagementScoreDateRange;
  shouldSkip?: boolean;
}

interface UseWorkspaceScoreInteractionsReturnValue {
  data: EngagementScoreInteractions;
  isLoading: boolean;
}

interface RawData {
  [CUBE_CONSTANTS.WEIGHTED_VALUES_WORKSPACE_ID]: string;
  [CUBE_CONSTANTS.WEIGHTED_VALUES_COMMENT_SUM]: string;
  [CUBE_CONSTANTS.WEIGHTED_VALUES_ENGAGED_TIME_SUM]: string;
  [CUBE_CONSTANTS.WEIGHTED_VALUES_SHARES_SUM]: string;
  [CUBE_CONSTANTS.WEIGHTED_VALUES_VIEWS_SUM]: string;
}

export const useWorkspaceScoreInteractions = ({
  workspaceId,
  shouldSkip,
  dateRange,
}: UseWorkspaceScoreInteractionsArgs): UseWorkspaceScoreInteractionsReturnValue => {
  const [startDate, endDate] = mapDateRangeToDates(dateRange).currentRange;

  const { tableData, isLoading } = useAnalyticsQuery<RawData>({
    query: {
      measures: [
        CUBE_CONSTANTS.WEIGHTED_VALUES_VIEWS_SUM,
        CUBE_CONSTANTS.WEIGHTED_VALUES_SHARES_SUM,
        CUBE_CONSTANTS.WEIGHTED_VALUES_ENGAGED_TIME_SUM,
        CUBE_CONSTANTS.WEIGHTED_VALUES_COMMENT_SUM,
      ],
      dimensions: [CUBE_CONSTANTS.WEIGHTED_VALUES_WORKSPACE_ID],
      order: {
        [CUBE_CONSTANTS.WEIGHTED_VALUES_VIEWS_SUM]: 'desc',
      },
      filters: [
        {
          member: CUBE_CONSTANTS.WEIGHTED_VALUES_WORKSPACE_ID,
          operator: 'equals',
          values: [workspaceId],
        },
      ],
      timeDimensions: [
        {
          dimension: CUBE_CONSTANTS.WEIGHTED_VALUES_INTERACTION_DATE,
          dateRange: [startDate, endDate],
        },
      ],
    },
    options: {
      skip: shouldSkip,
    },
  });

  const workspaceInteractions = getFirstElement(tableData);

  if (isNil(workspaceInteractions)) {
    return {
      data: {
        comments: 0,
        engagedTime: 0,
        shares: 0,
        views: 0,
      },
      isLoading,
    };
  }

  return {
    data: {
      comments: Math.trunc(
        Number(workspaceInteractions[CUBE_CONSTANTS.WEIGHTED_VALUES_COMMENT_SUM])
      ),
      engagedTime: Number(workspaceInteractions[CUBE_CONSTANTS.WEIGHTED_VALUES_ENGAGED_TIME_SUM]),
      shares: Math.trunc(Number(workspaceInteractions[CUBE_CONSTANTS.WEIGHTED_VALUES_SHARES_SUM])),
      views: Math.trunc(Number(workspaceInteractions[CUBE_CONSTANTS.WEIGHTED_VALUES_VIEWS_SUM])),
    },
    isLoading,
  };
};
