import { setUser as setSentryUser } from '@sentry/nextjs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo } from 'react';
import type { UserProfileObject, AnonymousUserObject, ApolloError } from '@topo-io/graphql';
import { UserRole, useMeQuery } from '@topo-io/graphql';
import { getDisplayName, isNil, isNotNil } from '@topo-io/utils';
import { getOrganizationSlug } from '@/config';

export type CurrentUser = UserProfileObject | AnonymousUserObject | null;

interface UseUserReturnType {
  user: CurrentUser;
  loading: boolean;
  error?: ApolloError;
  isConnected: boolean;
  isEditor: (user: CurrentUser) => boolean;
  isAnonymousUser: (user: CurrentUser) => user is AnonymousUserObject;
  isIdentifiedUser: (user: CurrentUser) => user is UserProfileObject;
  refetch: () => void;
}

export const useUser = (): UseUserReturnType => {
  const { data, loading, error, refetch } = useMeQuery();
  const featureFlagClient = useLDClient();

  const userProfile: CurrentUser = useMemo(() => {
    if (!data?.me) {
      return null;
    }
    const { me } = data;
    const { __typename } = me;
    if (__typename === 'AnonymousUserObject') {
      const anonymousUser: AnonymousUserObject = {
        anonymousId: me.anonymousId,
        organizationId: me.organizationId,
        __typename,
      };
      return anonymousUser;
    }
    return data.me as UserProfileObject;
  }, [data]);

  const isConnected = useMemo(() => Boolean(userProfile), [userProfile]);

  const isAnonymousUser = useCallback((user: CurrentUser): user is AnonymousUserObject => {
    return isNotNil(user) && user.__typename === 'AnonymousUserObject';
  }, []);

  const isIdentifiedUser = useCallback((user: CurrentUser): user is UserProfileObject => {
    return isNotNil(user) && user.__typename === 'UserProfileObject';
  }, []);

  const isEditor = useCallback(
    (user: CurrentUser) => {
      return isNotNil(user) && !isAnonymousUser(user) && user.role === UserRole.EDITOR;
    },
    [isAnonymousUser]
  );

  useEffect(() => {
    if (isNil(userProfile) || isAnonymousUser(userProfile)) {
      setSentryUser(null);
      return;
    }
    const {
      id,
      user: { email },
      organizationId,
    } = userProfile;
    const name = getDisplayName(userProfile, undefined);
    const organizationSlug = getOrganizationSlug();
    setSentryUser({ id, email, username: name, organizationId, organizationSlug });

    // We identify the user here for LaunchDarkly and not directly in Segment
    // Because this is a paid feature requiring you to upgrade
    if (isEditor(userProfile)) {
      void featureFlagClient?.identify({
        kind: 'user',
        anonymous: false,
        key: id,
        name,
        email,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        custom: {
          organizationId,
          organizationSlug,
        },
      });
    }
  }, [userProfile, featureFlagClient, isEditor, isAnonymousUser]);

  return {
    user: userProfile,
    error,
    loading,
    isConnected,
    isEditor,
    isAnonymousUser,
    isIdentifiedUser,
    refetch,
  };
};
