import { useMemo } from 'react';
import { useOrganizationBySlugQuery } from '@topo-io/graphql';
import { getOrganizationSlug } from '@/config';

export const useOrganizationBySlug = () => {
  const slug = useMemo(() => getOrganizationSlug(), []);
  const { data, loading } = useOrganizationBySlugQuery({
    variables: {
      slug: slug!,
    },
    skip: !slug,
  });

  const organization = data?.organizationBySlug;

  return {
    organization,
    isLoading: loading,
  };
};
