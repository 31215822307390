import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { PaginationObject } from '@topo-io/graphql';
import type { IndexSortDirection } from '@topo-io/types';
import type { SearchGraph, SearchResult } from './types';

export interface SearchContextProps {
  results: SearchResult[];
  pagination: PaginationObject;
  refresh: () => void;
  isLoading: boolean;
  searchQuery: string | undefined;
  setSearchQuery: Dispatch<SetStateAction<string | undefined>>;
  searchFilters: SearchGraph;
  setSearchFilters: Dispatch<SetStateAction<SearchGraph>>;
  sortAttribute: string;
  setSortAttribute: Dispatch<SetStateAction<string>>;
  sortDirection: IndexSortDirection;
  setSortDirection: Dispatch<SetStateAction<IndexSortDirection>>;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

export const SearchContext = createContext<SearchContextProps | undefined>(undefined);
