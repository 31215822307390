import { Form } from '@saas-ui/forms';
import { useRef, useState } from 'react';
import { Box, ModalTabPanel, SlideFade } from '@topo-io/design-system';
import type { Member, Workspace } from '@topo-io/graphql';
import { decrement, increment } from '@topo-io/utils';
import { AddDetailsStep } from './steps/add-details-step';
import { AddEmailStep } from './steps/add-email-step';
import { CustomizeEmailStep } from './steps/customize-email-step';
import type { InviteMemberFormValues, EditingMember, UpdateMemberFormValues } from './types';

enum InvitationStep {
  ADD_EMAIL = 1,
  ADD_DETAILS = 2,
  CUSTOMIZE_EMAIL = 3,
}

export const InviteMembersTab = ({
  workspaceId,
  workspaceMembers,
  editingMember,
}: {
  workspaceId: Workspace['id'];
  workspaceMembers: Pick<Member, 'email'>[];
  editingMember: EditingMember | undefined;
}) => {
  const initialStep = editingMember ? InvitationStep.ADD_DETAILS : InvitationStep.ADD_EMAIL;
  const [step, setStep] = useState(initialStep);
  const ref = useRef(null);

  const membersEmails = workspaceMembers.map((member) => member.email);

  const goBack = () => {
    setStep(decrement);
  };

  const goForward = (email?: string): void => {
    if (step === InvitationStep.ADD_EMAIL && !email) {
      return;
    }
    setStep(increment);
  };

  const goToStart = () => {
    setStep(InvitationStep.ADD_EMAIL);
  };

  return (
    <ModalTabPanel>
      <Box position="relative">
        <Form<InviteMemberFormValues | UpdateMemberFormValues> onSubmit={() => true}>
          {(form) => (
            <>
              {step === InvitationStep.ADD_EMAIL && (
                <>
                  <AddEmailStep membersEmails={membersEmails} goToNextStep={goForward} />
                </>
              )}

              {step === InvitationStep.ADD_DETAILS && (
                <SlideFade
                  in={step === InvitationStep.ADD_DETAILS}
                  offsetX={10}
                  offsetY={0}
                  ref={ref}
                >
                  <AddDetailsStep
                    form={form}
                    workspaceId={workspaceId}
                    editingMember={editingMember}
                    goToStart={goToStart}
                    goBack={goBack}
                    goToNextStep={goForward}
                  />
                </SlideFade>
              )}

              {step === InvitationStep.CUSTOMIZE_EMAIL && (
                <SlideFade
                  in={step === InvitationStep.CUSTOMIZE_EMAIL}
                  offsetX={10}
                  offsetY={0}
                  ref={ref}
                >
                  <CustomizeEmailStep
                    form={form}
                    workspaceId={workspaceId}
                    goToStart={goToStart}
                    goBack={goBack}
                  />
                </SlideFade>
              )}
            </>
          )}
        </Form>
      </Box>
    </ModalTabPanel>
  );
};
