import { useRouter } from 'next/router';
import { useToast } from '@topo-io/design-system';
import {
  SectionTemplatesDocument,
  useCreateSectionTemplateMutation,
  useDuplicateSectionTemplateMutation,
  useSaveSectionAsTemplateMutation,
} from '@topo-io/graphql';
import { isNil, isNotNil } from '@topo-io/utils';
import { createInitialSectionContent } from '@/components/section/utils/adapters';
import { getCacheSections, getCacheSectionTemplate } from '@/components/section/utils/cache-utils';
import { useWorkspaceTabs } from '@/components/workspace/hooks';
import { sectionTemplateUrl } from '@/config';
import type { NewTemplateFormValues } from './new-template-modal';

export const useSubmitNewSectionTemplate = () => {
  const { tabId } = useWorkspaceTabs();
  const router = useRouter();
  const { errorToast } = useToast();

  const displayToastError = (errorMessage: string) => {
    errorToast({
      title: `Something went wrong: ${errorMessage}`,
    });
  };

  const [createSectionTemplateMutation] = useCreateSectionTemplateMutation({
    update: (cache, { data }) => {
      const createSectionTemplate = data?.createSectionTemplate;
      if (isNil(createSectionTemplate)) {
        return;
      }
      cache.modify(getCacheSectionTemplate(cache, createSectionTemplate));
    },
  });
  const [duplicateSectionTemplateMutation] = useDuplicateSectionTemplateMutation({
    update: (cache, { data }) => {
      const duplicateSectionTemplate = data?.duplicateSectionTemplate;
      if (isNil(duplicateSectionTemplate)) {
        return;
      }
      cache.modify(getCacheSectionTemplate(cache, duplicateSectionTemplate));
    },
    onError: (error) => displayToastError(error.message),
  });
  const [saveSectionAsTemplateMutation] = useSaveSectionAsTemplateMutation();

  const onSubmit = async (formData: NewTemplateFormValues) => {
    const { title, tagIds } = formData;
    const initialDefaultContent = createInitialSectionContent();
    const { data } = await createSectionTemplateMutation({
      variables: { input: { title, content: [initialDefaultContent], tagIds } },
    });

    const templateId = data?.createSectionTemplate?.id;
    if (isNotNil(templateId)) {
      void router.push(sectionTemplateUrl(templateId));
    }
  };

  const getSubmitDuplicateSectionTemplate =
    (sectionTemplateId: string) => async (formData: NewTemplateFormValues) => {
      const { title, tagIds = [] } = formData;
      const { data } = await duplicateSectionTemplateMutation({
        variables: { input: { id: sectionTemplateId, title, tagIds } },
      });
      const templateId = data?.duplicateSectionTemplate?.id;
      if (isNotNil(templateId)) {
        void router.push(sectionTemplateUrl(templateId));
      }
    };

  const getSubmitSaveSectionAsTemplate =
    (sectionId: string) => async (formData: NewTemplateFormValues) => {
      const { title, tagIds } = formData;
      if (isNil(tabId)) {
        return;
      }
      await saveSectionAsTemplateMutation({
        variables: {
          input: {
            sectionId,
            title,
            workspaceTabId: tabId,
            tagIds,
          },
        },
        update: (cache, { data }) => {
          const updatedSections = data?.saveSectionAsTemplate;
          if (isNil(updatedSections)) {
            return;
          }
          cache.modify(getCacheSections(updatedSections));
        },
        refetchQueries: [{ query: SectionTemplatesDocument }],
      });
    };

  return { onSubmit, getSubmitSaveSectionAsTemplate, getSubmitDuplicateSectionTemplate };
};
