import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  Text,
} from '@topo-io/design-system';
import { TaskAction } from '@topo-io/graphql';
import type { TaskQuery } from '@topo-io/graphql';
import { isEmptyArray } from '@topo-io/utils';
import { useRoadmapContext } from '@/components/roadmap/hooks';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import type { TaskActionButtonProps } from './task-action-button';
import { UploadFileActionItem, DownloadFilesActionItem } from './task-action-button';
import { TaskActionMenuEmptyState } from './task-action-menu-empty-state';
import { TaskActionMenuGuestEmptyState } from './task-action-menu-guest-empty-state';

interface TaskActionMenuProps {
  task: TaskQuery['task'];
}

const ActionToButtonMap: Record<TaskAction, FC<TaskActionButtonProps>> = {
  [TaskAction.UPLOAD_FILE]: UploadFileActionItem,
  [TaskAction.DOWNLOAD_FILES]: DownloadFilesActionItem,
};

export const TaskActionMenu: FC<TaskActionMenuProps> = ({ task }) => {
  const { isGuest } = useWorkspaceContext();
  const { updateTask } = useTaskContext();
  const { canEditRoadmap } = useRoadmapContext();
  const { actions } = task;

  const toggleAction = (action: TaskAction) => {
    const newActions = actionIsEnabled(action)
      ? actions.filter((a) => a !== action)
      : [...actions, action];

    updateTask({ ...task, actions: newActions });
  };

  const actionIsEnabled = (action: TaskAction) => actions.includes(action);

  if ((!canEditRoadmap || isGuest) && isEmptyArray(actions)) {
    return <TaskActionMenuGuestEmptyState />;
  }

  return (
    <>
      {actions.map((action) => {
        const ActionComponent = ActionToButtonMap[action];
        return <ActionComponent key={action} taskId={task.id} />;
      })}
      {canEditRoadmap && (
        <Menu>
          <MenuButton as="div">
            <HStack>
              {isEmptyArray(actions) ? (
                <TaskActionMenuEmptyState />
              ) : (
                <Button variant="link" data-testid="action-edit">
                  <Trans>Edit</Trans>
                </Button>
              )}
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => toggleAction(TaskAction.UPLOAD_FILE)} gap={2}>
              <Icon icon="upload" color="gray.900" h={4} w={4} alignSelf="flex-start" />
              <VStack alignItems="flex-start">
                <Text fontSize="md" color="gray.900">
                  <Trans>Request files</Trans>
                </Text>
                <Text fontSize="sm" color="gray.400" mt={0}>
                  <Trans>This will allow guests to upload a file</Trans>
                </Text>
              </VStack>
            </MenuItem>
            <MenuItem onClick={() => toggleAction(TaskAction.DOWNLOAD_FILES)} gap={2}>
              <Icon icon="upload" color="gray.900" h={4} w={4} alignSelf="flex-start" />
              <VStack alignItems="flex-start">
                <Text fontSize="md" color="gray.900">
                  <Trans>Files download</Trans>
                </Text>
                <Text fontSize="sm" color="gray.400" mt={0}>
                  <Trans>This will allow guests to download your files</Trans>
                </Text>
              </VStack>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
};
