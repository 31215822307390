import { Trans, t } from '@lingui/macro';
import { Field, FormStep, useFormContext } from '@saas-ui/forms';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { Box, Card, H5, Icon, Text } from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';
import type { AISectionModalValues } from '@/components/ai/section/utils';
import { AISectionModalStep, AI_SECTION_MODAL_SECTIONS } from '@/components/ai/section/utils';

export const CustomInstructionsStep = () => {
  const { getValues } = useFormContext<AISectionModalValues>();
  const values = getValues();

  const sectionTitle = useMemo(() => {
    const selectedSection = AI_SECTION_MODAL_SECTIONS.find(
      (section) => section.type === values[AISectionModalStep.SECTION_TYPE]
    );
    if (isNil(selectedSection)) {
      return t`dynamic sections`;
    }
    return `${selectedSection.smiley} ${selectedSection.title} ${t`section`}`;
  }, [values]);

  return (
    <FormStep name="customInstructions">
      <H5>
        <Trans>
          Start creating {sectionTitle} with AI. <br /> Just provide some details and let the AI do
          the rest.
        </Trans>
      </H5>
      <Box mt="4">
        <Field
          type="textarea"
          name={AISectionModalStep.CUSTOM_INSTRUCTIONS}
          placeholder={t`Enter details like section size, tone of content, number of columns, or specific content to include.`}
        />
      </Box>

      <Box mt="6">
        <Text fontWeight="500">
          <Trans>Ideas for you</Trans>
        </Text>
        <CustomInstructionsIdea>
          <Trans>
            Create a 3-column pricing section for Standard at $29, Team at $49, Enterprise at $99.
          </Trans>
        </CustomInstructionsIdea>
        <CustomInstructionsIdea>
          <Trans>
            Design a 2-column customer story section featuring Google. Place the narrative text on
            the right, detailing how Google has reduced sales cycle duration using Topo. Ensure the
            tone is engaging and narrative-driven.
          </Trans>
        </CustomInstructionsIdea>
      </Box>
    </FormStep>
  );
};

export const CustomInstructionsIdea: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Card
      mt="2"
      bgColor="gray.10"
      color="gray.300"
      paddingY="2"
      paddingX="4"
      flexDirection="row"
      alignItems="center"
      gap="4"
    >
      <Icon icon="Sparkle" w="20px" h="20px" color="gray.200" mr="2" />
      <Text>{children}</Text>
    </Card>
  );
};
