import { useCallback } from 'react';
import type { MoveSectionInput as MoveSectionInputGraphQL } from '@topo-io/graphql';
import { useMoveSectionMutation, useUpdateSectionMutation } from '@topo-io/graphql';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { clamp, isNil } from '@topo-io/utils';
import { useWorkspaceSections } from '@/components/section/hooks/use-workspace-sections';
import type {
  MoveSectionInput,
  ToggleSectionVisibilityInput,
  UpdateSectionContentInput,
  UpdateSectionTitleInput,
} from '@/components/section/types';
import {
  moveSectionPositionOfSections,
  moveSectionPositionOfSectionsFromDnD,
  updateSectionContentOfSections,
  updateSectionTitleOfSections,
} from '@/components/section/utils/adapters';
import { getCacheSections } from '@/components/section/utils/cache-utils';
import { useWorkspace } from '@/components/workspace/hooks/use-workspace';
import { useQueryParamId } from '@/hooks';

export const useUpdateSection = ({
  workspaceTabId,
  sections,
}: {
  workspaceTabId: string | undefined;
  sections: SectionJSON[];
}) => {
  const id = useQueryParamId();
  const { workspace } = useWorkspace(id);
  const { sections: sectionsFromWorkspace } = useWorkspaceSections({ workspaceId: workspace?.id });
  const [updateSectionMutation] = useUpdateSectionMutation({
    update: (cache, { data }) => {
      const updatedSections = data?.updateSection;
      if (isNil(updatedSections)) {
        return;
      }
      cache.modify(getCacheSections(updatedSections));
    },
  });

  const [moveSectionMutation] = useMoveSectionMutation({
    update: (cache, { data }) => {
      const updatedSections = data?.moveSection;
      if (isNil(updatedSections)) {
        return;
      }
      cache.modify(getCacheSections(updatedSections));
    },
  });

  const updateSectionContent = useCallback(
    (input: UpdateSectionContentInput) => {
      const { sectionId, sectionContent } = input;
      const { nextSectionsState } = updateSectionContentOfSections({
        sectionId,
        sectionContent,
        sections,
      });
      if (isNil(workspaceTabId)) {
        return;
      }
      void updateSectionMutation({
        variables: {
          input: {
            id: sectionId,
            workspaceTabId,
            content: [sectionContent],
          },
        },
        optimisticResponse: {
          updateSection: { content: nextSectionsState },
        },
      });
    },
    [sections, updateSectionMutation, workspaceTabId]
  );

  const updateSectionTitle = useCallback(
    (input: UpdateSectionTitleInput) => {
      const { sectionId, title } = input;
      const { nextSectionsState } = updateSectionTitleOfSections({
        sectionId,
        title,
        sections,
      });
      if (isNil(workspaceTabId)) {
        return;
      }
      void updateSectionMutation({
        variables: {
          input: {
            id: sectionId,
            workspaceTabId,
            title,
          },
        },
        optimisticResponse: {
          updateSection: { content: nextSectionsState },
        },
      });
    },
    [sections, updateSectionMutation, workspaceTabId]
  );

  const updateSectionVisibility = useCallback(
    ({ show, sectionId }: ToggleSectionVisibilityInput) => {
      if (isNil(workspaceTabId)) {
        return;
      }
      void updateSectionMutation({
        variables: {
          input: {
            id: sectionId,
            workspaceTabId,
            show,
          },
        },
      });
    },
    [updateSectionMutation, workspaceTabId]
  );

  const moveSection = useCallback(
    (input: MoveSectionInput) => {
      const { sectionId, index, direction } = input;
      const { nextSectionsState } = moveSectionPositionOfSections({
        index,
        direction,
        sections,
      });
      const shiftingPosition = direction === 'up' ? index - 1 : index + 1;
      const borderedPosition = clamp(shiftingPosition, 0, sections.length - 1);

      if (isNil(workspaceTabId)) {
        return;
      }

      void updateSectionMutation({
        variables: {
          input: {
            id: sectionId,
            workspaceTabId,
            position: borderedPosition,
          },
        },
        optimisticResponse: {
          updateSection: { content: nextSectionsState },
        },
      });
    },
    [sections, updateSectionMutation, workspaceTabId]
  );

  const moveSectionFromDnD = useCallback(
    (input: MoveSectionInput) => {
      const { sectionId, index, direction } = input;
      const shiftingPosition = direction === 'down' ? index - 1 : index;
      const { nextSectionsState } = moveSectionPositionOfSectionsFromDnD({
        index: shiftingPosition,
        direction,
        sections,
      });
      const borderedPosition = clamp(shiftingPosition, 0, sections.length - 1);
      if (isNil(workspaceTabId)) {
        return;
      }
      void updateSectionMutation({
        variables: {
          input: {
            id: sectionId,
            workspaceTabId,
            position: borderedPosition,
          },
        },
        optimisticResponse: {
          updateSection: { content: nextSectionsState },
        },
      });
    },
    [sections, updateSectionMutation, workspaceTabId]
  );

  const moveSectionToAnotherTab = useCallback(
    (input: MoveSectionInputGraphQL) => {
      const { id, targetWorkspaceTabId, currentWorkspaceTabId } = input;

      if (isNil(targetWorkspaceTabId) || isNil(sectionsFromWorkspace)) {
        return;
      }

      void moveSectionMutation({
        variables: {
          input: {
            id,
            currentWorkspaceTabId,
            targetWorkspaceTabId,
          },
        },
      });
    },
    [sectionsFromWorkspace, moveSectionMutation]
  );

  return {
    updateSectionContent,
    updateSectionTitle,
    moveSection,
    moveSectionToAnotherTab,
    moveSectionFromDnD,
    updateSectionVisibility,
  };
};
