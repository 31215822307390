import { useContext } from 'react';
import { OverviewContext } from '@/contexts/overview-context';

export const useOverviewContext = () => {
  const overviewContext = useContext(OverviewContext);

  if (!overviewContext) {
    throw new Error('Context used outside of its Provider!');
  }

  return overviewContext;
};
