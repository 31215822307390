import type { Cell, Row, SortingState } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { Box, DataTable } from '@topo-io/design-system';
import { IndexSortDirection } from '@topo-io/types';
import type { WorkspaceTemplateSearchRecord } from '@topo-io/types';
import { isEmptyArray } from '@topo-io/utils';
import { SearchableDataTablePagination, TableEmptyState } from '@/components/common';
import { useTableContainerWidth } from '@/components/layout/hooks';
import { workspaceTemplatePreviewUrl, workspaceTemplateUrl } from '@/config';
import { useSearchResults, useSearchSort, useUser } from '@/hooks';
import { buildTemplateListColumns } from './template-list-columns';

const loadingRowExample = {
  template: 'Fake example template',
  owner: 'John Doe',
  lastUpdated: '2021-01-01',
  tags: 'no tags',
};

export const TemplateList = () => {
  const router = useRouter();
  const { user, isAnonymousUser } = useUser();
  const containerWitdh = useTableContainerWidth();

  const { results, isLoading, refresh } = useSearchResults<WorkspaceTemplateSearchRecord>();
  const { sortBy, reset } = useSearchSort({
    attribute: 'updatedAt',
    sortDirection: IndexSortDirection.DESC,
  });

  const columns = useMemo(
    () => buildTemplateListColumns({ onTemplateDeletionSuccess: refresh }),
    [refresh]
  );

  const goToTemplate = useCallback(
    (e: Row<WorkspaceTemplateSearchRecord>) =>
      !isAnonymousUser(user) && user?.id === e.original.owner.id
        ? void router.push(workspaceTemplateUrl(e.original.id))
        : void router.push(workspaceTemplatePreviewUrl(e.original.id)),
    [router, user, isAnonymousUser]
  );

  const handleOnSortingchange = (sorting: SortingState) => {
    if (isEmptyArray(sorting)) {
      reset();
      return;
    }

    const attribute = sorting[0].id;
    const direction = sorting[0].desc ? IndexSortDirection.DESC : IndexSortDirection.ASC;
    sortBy({ attribute, direction });
  };

  const getTooltipLabel = (cell: Cell<WorkspaceTemplateSearchRecord, unknown>) => {
    const record = cell.row.original;
    const { tags } = record ?? {};
    const [_, ...otherTags] = tags ?? [];

    if (cell.column.id !== 'tags' || isEmptyArray(otherTags)) {
      return null;
    }

    return otherTags.map((t) => t.name).join(', ');
  };

  return (
    <Box width={containerWitdh}>
      <DataTable
        data={results}
        columns={columns}
        onClick={goToTemplate}
        loadingState={{
          isLoading,
          loadingRowExample,
        }}
        emptyState={<TableEmptyState />}
        manualSorting={true}
        onSortingChange={handleOnSortingchange}
        getTooltipLabel={getTooltipLabel}
      />
      <SearchableDataTablePagination />
    </Box>
  );
};
