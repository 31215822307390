import { Trans } from '@lingui/macro';
import type { Serie } from '@nivo/line';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { CUBE_CONSTANTS } from '@topo-io/constants';
import { Box, H5, useToken, ButtonGroup, Button, HStack, Spacer } from '@topo-io/design-system';
import { useTruthyOnce } from '@topo-io/hooks';
import { isEmptyArray, isNil, isNotNil, isString, parseDate } from '@topo-io/utils';
import { useAnalyticsQuery } from '@/hooks';
import { EngagementOverTimeEmptyState } from './empty-state';
import { EngagementOverTimeGraph } from './graph';
import { ENGAGEMENT_OVER_TIME_HEIGHT } from './utils';

const DATA_POINTS = 24;

interface EngagementOverTimeProps {
  workspaceId: string | undefined;
}

interface EngageTimeData {
  x: Date;
  y: number | null;
}

type TimeGranularity = 'hour' | 'day' | 'week';

interface TimeGranularityProps {
  precision: 'hour' | 'day';
  format: string;
  xFormat: string;
  tickValues: string;
}

export const EngagementOverTime: FC<EngagementOverTimeProps> = ({ workspaceId }) => {
  const [primary500] = useToken('colors', ['primary.500']);
  const [timeGranularity, setTimeGranularity] = useState<TimeGranularity>('day');

  const timeGranularityProps: TimeGranularityProps = useMemo(() => {
    if (timeGranularity === 'hour') {
      return {
        precision: 'hour',
        format: '%H:%M',
        xFormat: 'time:%b %d %H:%M',
        tickValues: 'every 2 hours',
      };
    }
    if (timeGranularity === 'day') {
      return {
        precision: 'day',
        format: '%d/%m',
        xFormat: 'time:%b %d',
        tickValues: 'every 2 days',
      };
    }
    return {
      precision: 'day',
      format: '%d/%m',
      xFormat: 'time:%b %d',
      tickValues: 'every 2 weeks',
    };
  }, [timeGranularity]);

  const { precision, format, xFormat, tickValues } = timeGranularityProps;

  const { tableData } = useAnalyticsQuery<Record<string, string>>({
    query: {
      measures: [CUBE_CONSTANTS.WORKSPACE_VIEWS_COUNT],
      timeDimensions: [
        {
          dimension: CUBE_CONSTANTS.WORKSPACE_VIEWS_START_TIMESTAMP,
          granularity: timeGranularity,
          dateRange: `from ${DATA_POINTS} ${timeGranularity} ago to now`,
        },
      ],
      filters: [
        {
          member: CUBE_CONSTANTS.WORKSPACE_VIEWS_WORKSPACE_ID,
          operator: 'equals',
          values: [workspaceId!],
        },
      ],
      order: {
        [CUBE_CONSTANTS.WORKSPACE_VIEWS_START_TIMESTAMP]: 'asc',
      },
    },
    options: {
      skip: isNil(workspaceId),
      shouldFillMissingDates: true,
    },
  });

  const chartData = useMemo(() => {
    if (isEmptyArray(tableData)) {
      return null;
    }

    const data: EngageTimeData[] = tableData
      .map((row) => {
        const count = row[CUBE_CONSTANTS.WORKSPACE_VIEWS_COUNT];
        const createdAt =
          row[`${CUBE_CONSTANTS.WORKSPACE_VIEWS_START_TIMESTAMP}.${timeGranularity}`];
        if (!isString(createdAt)) {
          return;
        }

        const formatedDate = parseDate(createdAt);
        return {
          x: formatedDate,
          y: count ? parseInt(count) : 0,
        };
      })
      .filter(isNotNil);
    return [
      {
        id: 'engagement',
        color: primary500,
        data,
      },
    ];
  }, [tableData, timeGranularity, primary500]);

  const shouldDisplayData = useTruthyOnce(isNotNil(chartData));

  const getBtnGroupProps = useCallback(
    (time: TimeGranularity) => {
      return {
        onClick: () => setTimeGranularity(time),
        disabled: !shouldDisplayData,
        bg: timeGranularity === time ? 'gray.20' : 'white',
      };
    },
    [timeGranularity, shouldDisplayData]
  );

  return (
    <Box mt="16px" borderWidth="1px" borderRadius="md" bg="cards.bg.color" padding="6" width="full">
      <HStack w="full">
        <H5>
          <Trans>Engagement over time</Trans>
        </H5>
        <Spacer />
        <ButtonGroup isAttached size="sm" variant="outline" colorScheme="gray">
          <Button {...getBtnGroupProps('hour')}>
            <Trans>Hour</Trans>
          </Button>
          <Button {...getBtnGroupProps('day')}>
            <Trans>Day</Trans>
          </Button>
          <Button {...getBtnGroupProps('week')}>
            <Trans>Week</Trans>
          </Button>
        </ButtonGroup>
      </HStack>
      {!shouldDisplayData ? (
        <EngagementOverTimeEmptyState />
      ) : (
        <Box h={String(ENGAGEMENT_OVER_TIME_HEIGHT)}>
          <EngagementOverTimeGraph
            chartData={chartData as Serie[]}
            format={format}
            xFormat={xFormat}
            tickValues={tickValues}
            precision={precision}
          />
        </Box>
      )}
    </Box>
  );
};
