import { useDeleteStepMutation } from '@topo-io/graphql';
import { getDeletedStepCache } from '@/components/roadmap/utils/cache-utils';

export const useDeleteStepMutationWithCache = () => {
  const [deleteStep] = useDeleteStepMutation();

  const deleteStepWithCache = ({ stepId }: { stepId: string }) => {
    void deleteStep({
      variables: {
        stepId,
      },
      update: (cache) => {
        cache.modify(getDeletedStepCache({ stepId }));
      },
    });
  };
  return { deleteStepWithCache };
};
