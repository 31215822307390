import { Trans } from '@lingui/macro';
import { ErrorBoundary } from '@sentry/nextjs';
import type { FC } from 'react';
import { Block, Box, H4 } from '@topo-io/design-system';
import { RoadmapType, useRoadmapContentQuery } from '@topo-io/graphql';
import { isEmptyArray, isNil } from '@topo-io/utils';
import { useRoadmapContext } from '@/components/roadmap/hooks';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { Roadmap } from './roadmap';

export const RoadmapBlock: FC = () => {
  const { id, isWorkspace, isPreview, isGuest } = useWorkspaceContext();
  const { canEditRoadmap } = useRoadmapContext();
  const { data } = useRoadmapContentQuery({
    variables: {
      input: {
        workspaceId: id!,
        type: isWorkspace ? RoadmapType.Workspace : RoadmapType.WorkspaceTemplate,
      },
    },
    skip: isNil(id),
  });

  const steps = data?.roadmap.steps ?? [];
  const showAllSteps = !isPreview && !isGuest;
  const filteredSteps = showAllSteps ? steps : steps.filter((s) => s.isVisible);

  const emptyState = !canEditRoadmap && data && (!data.roadmap || isEmptyArray(filteredSteps));

  return (
    <Block mt="4">
      <Box>
        <H4 whiteSpace="nowrap">
          <Trans>Mutual Action Plan</Trans>
        </H4>
      </Box>
      <ErrorBoundary>
        {emptyState ? (
          <>
            <Trans>No mutual action plan yet</Trans>
          </>
        ) : (
          <Roadmap roadmapSteps={filteredSteps} />
        )}
      </ErrorBoundary>
    </Block>
  );
};
