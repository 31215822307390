import type { FC, ReactNode } from 'react';
import { Box } from '@topo-io/design-system';
import { useDragSectionTemplate } from '@/components/section/hooks';
import type { SectionTemplateItem } from '@/components/workspace/overview';

export const DraggableItem: FC<{
  id: string;
  data: { sectionTemplate: SectionTemplateItem };
  children: ReactNode;
}> = ({ id, data, children }) => {
  const { attributes, listeners, setNodeRef } = useDragSectionTemplate({ id, data });
  return (
    <Box ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </Box>
  );
};
