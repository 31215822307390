import type { FC } from 'react';
import { useState } from 'react';
import { Box, Container, Fade, Flex, Text } from '@topo-io/design-system';
import type { TemplateQuery, WorkspaceQuery } from '@topo-io/graphql';
import { TemplateLogo, WorkspaceLogo, TemplateEditionBanner } from '@/components/workspace/common';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { AddCoverButton } from '@/components/workspace/overview/cover/add-cover-button';
import type { Cover } from '@/components/workspace/overview/cover/cover-types';
import { useUser } from '@/hooks';
import { CoverContainer } from './cover/cover-container';

interface BaseOverviewProps {
  cover?: Cover;
  children?: React.ReactNode;
  workspace?: WorkspaceQuery['workspace'];
  template?: TemplateQuery['template'];
  hasCoverError?: boolean;
  onCoverError?: () => void;
}

export const BaseOverview: FC<BaseOverviewProps> = ({
  children,
  cover,
  workspace,
  template,
  hasCoverError,
  onCoverError,
}) => {
  const [shouldDisplayAddCover, setShouldDisplayAddCover] = useState(false);
  const { user, isIdentifiedUser } = useUser();
  const { readOnly, isTemplate } = useWorkspaceContext();

  const workspaceTitle = workspace?.title ?? template?.title;

  const hasValidCover = cover?.url && !hasCoverError;

  const shouldDisplayTemplateOwnershipWarning =
    isTemplate && isIdentifiedUser(user) && user.id !== template?.owner.id;

  const handleMouseEnter = () => {
    if (hasValidCover) {
      return;
    }
    setShouldDisplayAddCover(true);
  };

  const handleMouseLeave = () => {
    if (hasValidCover) {
      return;
    }
    setShouldDisplayAddCover(false);
  };

  return (
    <Container maxW="container.lg">
      <Box position="relative" mb="4">
        <CoverContainer cover={cover} hasCoverError={hasCoverError} onCoverError={onCoverError} />
        <Flex
          alignItems="center"
          w="full"
          gap={4}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            {...(hasValidCover && {
              position: 'absolute',
              bottom: '8px',
              left: '16px',
            })}
          >
            {workspace && <WorkspaceLogo workspace={workspace} />}
            {template && <TemplateLogo template={template} />}
          </Box>

          {(!cover?.url || hasCoverError) && !readOnly && (
            <Fade in={shouldDisplayAddCover}>
              <AddCoverButton workspace={workspace} template={template} />
            </Fade>
          )}
        </Flex>
      </Box>

      {workspaceTitle && (
        <Text
          as="h4"
          color="gray.800"
          fontSize="xl"
          mb="4"
          fontWeight="500"
          data-testid="workspace-title"
        >
          {workspaceTitle}
        </Text>
      )}

      {shouldDisplayTemplateOwnershipWarning && <TemplateEditionBanner />}

      {children}
    </Container>
  );
};
