import { Trans } from '@lingui/macro';
import { useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { H5, Box, ButtonGroup, Button, Flex, Text, Skeleton, Icon } from '@topo-io/design-system';
import { ZERO, getLastElement, isNotNil, formatAnalyticsDuration } from '@topo-io/utils';
import { EngagementScoreCircle } from '@/components/engagement-score/components/circle';
import {
  useWorkspaceScoreInteractions,
  useWorkspaceScoresForPeriod,
} from '@/components/engagement-score/hooks';
import { EngagementScoreDateRange } from '@/components/engagement-score/types';
import { getIconForScoreDiff, getScoreDiffForPeriod } from '@/components/engagement-score/utils';
import { useLocale } from '@/hooks';

interface DateRangeButtonProps extends PropsWithChildren {
  onClick: () => void;
  isActive: boolean;
}

const DateRangeButton: FC<DateRangeButtonProps> = ({ children, isActive, onClick }) => {
  return (
    <Button backgroundColor={isActive ? 'gray.20' : 'white'} onClick={onClick}>
      {children}
    </Button>
  );
};

interface InteractionContainerProps extends PropsWithChildren {
  isLoading: boolean;
  iconName: string;
}

const InteractionContainer: FC<InteractionContainerProps> = ({ isLoading, iconName, children }) => {
  return (
    <Skeleton isLoaded={!isLoading}>
      <Box gap="3" alignItems="center">
        <Icon icon={iconName} w="20px" h="20px" color="gray.100" />
        <Text fontWeight="500" color="gray.400" as="span" pl="3">
          {children}
        </Text>
      </Box>
    </Skeleton>
  );
};

interface WorkspaceEngagementScoreCardProps {
  workspaceId: string;
}

export const WorkspaceEngagementScoreCard: FC<WorkspaceEngagementScoreCardProps> = ({
  workspaceId,
}) => {
  const locale = useLocale();
  const [dateRange, setDateRange] = useState<EngagementScoreDateRange>(
    EngagementScoreDateRange.Last7Days
  );

  const { data: workspaceInteractions, isLoading: isInteractionsLoading } =
    useWorkspaceScoreInteractions({ workspaceId, dateRange });

  const { data: scoresForPeriod, isLoading: isScoresForPeriodLoading } =
    useWorkspaceScoresForPeriod({
      workspaceId,
      dateRange,
    });

  const workspaceScore = getLastElement(scoresForPeriod)?.score ?? ZERO;

  const isLoading = isInteractionsLoading || isScoresForPeriodLoading;
  const { diff, formattedDiff } = getScoreDiffForPeriod(scoresForPeriod ?? [], locale) ?? {};

  const icon = getIconForScoreDiff(diff ?? ZERO, 'big');

  return (
    <Box bg="cards.bg.color" p="6" w="full" borderRadius="md">
      <Flex alignItems="center" justifyContent="space-between" mb="6">
        <H5>
          <Trans>Scoring</Trans>
        </H5>
        <ButtonGroup isAttached size="sm" variant="outline" colorScheme="gray">
          <DateRangeButton
            isActive={dateRange === EngagementScoreDateRange.Last7Days}
            onClick={() => setDateRange(EngagementScoreDateRange.Last7Days)}
          >
            <Trans>7 d</Trans>
          </DateRangeButton>
          <DateRangeButton
            isActive={dateRange === EngagementScoreDateRange.Last30Days}
            onClick={() => setDateRange(EngagementScoreDateRange.Last30Days)}
          >
            <Trans>30 d</Trans>
          </DateRangeButton>
          <DateRangeButton
            isActive={dateRange === EngagementScoreDateRange.Last90Days}
            onClick={() => setDateRange(EngagementScoreDateRange.Last90Days)}
          >
            <Trans>90 d</Trans>
          </DateRangeButton>
        </ButtonGroup>
      </Flex>

      <Flex gap="2" alignItems="center" justifyContent="center">
        <EngagementScoreCircle
          score={workspaceScore}
          isScoreLoading={isLoading}
          size="big"
          withHoverStyleByDefault
        />
        {isNotNil(formattedDiff) && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            gap="1"
          >
            <Skeleton isLoaded={!isLoading}>
              <Flex gap="2">
                {icon}
                <Text fontSize="lg" color="gray.900" fontWeight="500">
                  <Trans>{formattedDiff} points</Trans>
                </Text>
              </Flex>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text color="gray.100" ml="2">
                <Trans>Deal scoring evolution</Trans>
              </Text>
            </Skeleton>
          </Flex>
        )}
      </Flex>

      <Flex
        flexDirection="column"
        fontSize="lg"
        mt="6"
        gap="2"
        textAlign="center"
        bgColor="gray.10"
        py="2"
        borderRadius="8px"
      >
        <InteractionContainer isLoading={isLoading} iconName="eye">
          <Trans>{workspaceInteractions.views} views</Trans>
        </InteractionContainer>
        <InteractionContainer isLoading={isLoading} iconName="send">
          <Trans>{workspaceInteractions.shares} shares</Trans>
        </InteractionContainer>
        <InteractionContainer isLoading={isLoading} iconName="Comment">
          <Trans>{workspaceInteractions.comments} comments</Trans>
        </InteractionContainer>
        <InteractionContainer isLoading={isLoading} iconName="Clock">
          <Trans>
            {formatAnalyticsDuration(workspaceInteractions?.engagedTime ?? ZERO, locale)} on
            workspace
          </Trans>
        </InteractionContainer>
      </Flex>
    </Box>
  );
};
