import { Trans } from '@lingui/macro';
import { TOOLBAR_TEMPLATE_SECTION_Z_INDEX } from '@topo-io/constants';
import { Box, Button, Icon } from '@topo-io/design-system';
import { Link } from '@/components/common';
import { sectionTemplateUrl } from '@/config';

interface TemplateSectionActionsProps {
  sectionTemplateId: string;
  onClickMenuItemDesynchronize: () => void;
}

export const TemplateSectionActions: React.FC<TemplateSectionActionsProps> = ({
  sectionTemplateId,
  onClickMenuItemDesynchronize,
}) => {
  return (
    <Box
      aria-label="Template section toolbar"
      textAlign="right"
      bgColor="white"
      fontSize="sm"
      borderRadius="md"
      display="flex"
      zIndex={TOOLBAR_TEMPLATE_SECTION_Z_INDEX}
    >
      <Link href={sectionTemplateUrl(sectionTemplateId)}>
        <Button
          variant="ghost"
          color="gray.500"
          gap={2}
          leftIcon={<Icon icon="Edit01" w="16px" h="16px" />}
          iconSpacing="0"
          borderWidth="1px"
          borderColor="gray.20"
          borderRightRadius="0"
          size="sm"
          fontSize="md"
        >
          <Trans>Edit template</Trans>
        </Button>
      </Link>

      <Button
        variant="ghost"
        color="gray.500"
        gap={2}
        leftIcon={<Icon icon="Unlink" w="16px" h="16px" />}
        iconSpacing="0"
        size="sm"
        fontSize="md"
        borderWidth="1px"
        borderColor="gray.20"
        borderLeftRadius="0"
        onClick={onClickMenuItemDesynchronize}
      >
        <Trans>Unsync from template</Trans>
      </Button>
    </Box>
  );
};
