import { Trans, t } from '@lingui/macro';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import { OpenGraphParams, OPEN_GRAPH_CDN_URL } from '@topo-io/constants';
import {
  ModalTabPanel,
  ModalSection,
  Flex,
  Card,
  Icon,
  Button,
  Text,
  Input,
} from '@topo-io/design-system';
import { useCopyThumbnailToClipboard, useCopyToClipboard } from '@topo-io/hooks';
import { useBrandingColors } from '@/components/branding';
import { useOrganizationBySlug } from '@/components/organization';
import { useWorkspace } from '@/components/workspace/hooks';
import { useUser, useQueryParamId } from '@/hooks';
import { ShareSettingsInput } from './share-settings-input';

export const ShareSettingsTab = () => {
  const id = useQueryParamId();
  const { isConnected, user, isEditor } = useUser();
  const previewUrl = new URL(`/w/${id}`, window.location.href).href;
  const [shouldShowCopiedUrl, setShouldShowCopiedUrl] = useState(false);
  const [_, copyToClipboard] = useCopyToClipboard();
  const { copyThumbnailToClipboard } = useCopyThumbnailToClipboard();

  const { workspace } = useWorkspace(id);
  const { organization } = useOrganizationBySlug();
  const { theme } = useBrandingColors(id);

  const thumbnailUrl = useMemo(() => {
    const url = new URL(OPEN_GRAPH_CDN_URL);
    const searchParams = new URLSearchParams({
      [OpenGraphParams.BUYER_DOMAIN]: workspace?.company?.domainUrl ?? '',
      [OpenGraphParams.SELLER_DOMAIN]: organization?.company?.domainUrl ?? '',
      [OpenGraphParams.ACCENT_COLOR]: theme.primaryColor,
    });
    url.search = searchParams.toString();
    return url.toString();
  }, [workspace, organization, theme.primaryColor]);

  const copyUrl = () => {
    void copyToClipboard(previewUrl);
    setShouldShowCopiedUrl(true);
    setTimeout(() => {
      setShouldShowCopiedUrl(false);
    }, 2000);
  };

  const copyThumbnail = async () => {
    await copyThumbnailToClipboard({
      imageUrl: thumbnailUrl,
      previewUrl,
      workspaceTitle: workspace?.title ?? '',
    });
    setShouldShowCopiedUrl(true);
    setTimeout(() => {
      setShouldShowCopiedUrl(false);
    }, 2000);
  };

  return (
    <ModalTabPanel>
      <ModalSection title={t`Share link`}>
        <Flex gap="4" mt="2">
          <Card
            color="gray.500"
            w="full"
            p="2"
            display="flex"
            flexDirection="column"
            gap="1"
            justifyContent="space-between"
          >
            <Flex bgColor="gray.10" p="4" justifyContent="center" alignItems="center" flex="1">
              <Icon icon="Link" w="24px" h="24px" />
            </Flex>
            <Button colorScheme="gray" onClick={copyUrl}>
              <Trans>Copy link</Trans>
            </Button>
          </Card>
          <Card color="gray.500" w="full" p="2" display="flex" flexDirection="column" gap="1">
            <Flex bgColor="gray.10" p="4" justifyContent="center" alignItems="center">
              <Image src={thumbnailUrl} alt="Thumbnail" width={300} height={100} />
            </Flex>
            <Button colorScheme="gray" onClick={copyThumbnail}>
              <Trans>Copy image link</Trans>
            </Button>
          </Card>
        </Flex>

        {/*  Hack for e2e test to allow to get the guest url and open it */}
        <Input
          data-testid="share-link-input"
          pointerEvents="none"
          value={previewUrl}
          readOnly
          position="absolute"
          opacity="0"
          visibility="hidden"
        />

        {shouldShowCopiedUrl && (
          <Text mt="1" color="green.500">
            <Trans>Copied to clipboard!</Trans>
          </Text>
        )}
      </ModalSection>
      {isConnected && isEditor(user) && <ShareSettingsInput id={id} />}
    </ModalTabPanel>
  );
};
