import { t } from '@lingui/macro';

export const getEditorTranslations = () => ({
  'align.title': t`Align`,
  'align.long-title': t`Text alignment`,
  'align.left': t`left`,
  'align.right': t`right`,
  'align.center': t`center`,
  'align.justify': t`justify`,
  'block-types.transform': t`Transform into`,
  'block-types.heading-1': t`Heading 1`,
  'block-types.heading-2': t`Heading 2`,
  'block-types.paragraph': t`Paragraph`,
  'block-types.bullet-list': t`Bullet list`,
  'block-types.ordered-list': t`Ordered list`,
  'block-types.divider': t`Divider`,
  'block-types.code-block': t`Code block`,
  'block-types.embed': t`Embed`,
  'common.delete': t`Delete`,
  'common.more': t`More`,
  'common.replace': t`Replace`,
  'common.link': t`Link`,
  'columns.label': t`Columns`,
  'columns-button.two.label': t`Two columns`,
  'columns-button.three.label': t`Three columns`,
  'columns-button.remove': t`Remove columns`,
  'embed.label': t`Embed`,
  'embed.failed-to-fetch': t`Could not load`,
  'embed.google-drive': t`Google Drive`,
  'embed.google-docs': t`Google Docs`,
  'embed.typeform': t`Typeform`,
  'embed.google-sheet': t`Google Sheet`,
  'embed.google-slide': t`Google Slide`,
  'embed.calendly': t`Calendly`,
  'embed.pitch': t`Pitch`,
  'embed.hubspot-calendar': t`Hubspot Calendar`,
  'embed.loom': t`Loom`,
  'embed.youtube': t`YouTube`,
  'embed.vimeo': t`Vimeo`,
  'embed.modjo': t`Modjo`,
  'embed.vidyard': t`Vidyard`,
  'embed-modal.hint': t`Make sure your file is viewable to anybody with the link`,
  'embed-modal.link.hint': t`Works with links such as`,
  'embed-modal.paste-link': t`Paste the link`,
  'embed-modal.embed-anything': t`Embed anything`,
  'file.label': t`File`,
  'highlight.label': t`Highlight color`,
  'insert-link.add': t`Add link`,
  'insert-link.remove': t`Remove link`,
  'link-toolbar.placeholder': t`Enter link...`,
  'link-toolbar.set-link': t`Set link`,
  'mention.label': t`Mention`,
  'menu.resize': t`Resize`,
  'menu.align-left': t`Align left`,
  'menu.align-right': t`Align right`,
  'menu.align-center': t`Align center`,
  'quick-start-menu.label': t`Type text or add :`,
  'text-toolbar-buttons.bold': t`Bold`,
  'text-toolbar-buttons.italic': t`Italic`,
  'text-toolbar-buttons.underline': t`Underline`,
  'variable.label': t`Variable`,
  'variable.add': t`Add dynamic variable`,
  'variable.insert-modal.insert': t`will be inserted here`,
  'variable.fallback.title': t`Fallback`,
  'variable.fallback.placeholder': t`If we can't find the`,
});
