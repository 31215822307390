import type { ButtonProps } from '@topo-io/design-system';

export const getTabButtonStyle = ({
  isActive,
  isDisplayed,
}: {
  isActive: boolean;
  isDisplayed: boolean;
}): ButtonProps => {
  const baseStyle = {
    variant: 'ghost',
    py: '8px',
    pl: '16px',
    pr: '16px',
    borderRadius: '8px',
    fontWeight: '500',
  };

  if (!isDisplayed) {
    return {
      ...baseStyle,
      color: 'gray.50',
      _hover: {
        background: 'gray.20',
      },
    };
  }

  if (isActive) {
    return {
      ...baseStyle,
      color: 'primary.600',
      background: 'primary.50',
      _hover: {
        background: 'primary.100',
      },
    };
  }

  return {
    ...baseStyle,
    color: 'gray.500',
    _hover: {
      color: 'gray.300',
      background: 'gray.20',
    },
  };
};
