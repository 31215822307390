import { Trans } from '@lingui/macro';
import {
  Button,
  Container,
  Icon,
  useDisclosure,
  Box,
  Banner,
  BannerIcon,
  BannerContent,
  BannerTitle,
  BannerDescription,
} from '@topo-io/design-system';
import { MemberList, ShareWorkspaceModal } from '@/components/workspace';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { useUser } from '@/hooks';

export const WorkspaceMembers = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isTemplate } = useWorkspaceContext();
  const { isAnonymousUser, user } = useUser();

  if (isTemplate) {
    return (
      <Container maxW="container.lg" mt="5">
        <Banner status="info" bg="white">
          <BannerIcon />
          <BannerContent>
            <BannerTitle>Information</BannerTitle>
            <BannerDescription>
              <Trans>
                You will be able to add members after creating a workspace from this template
              </Trans>
            </BannerDescription>
          </BannerContent>
        </Banner>
      </Container>
    );
  }

  return (
    <Container maxW="container.lg">
      {!isAnonymousUser(user) && (
        <Button
          leftIcon={<Icon icon="plus-circle" />}
          colorScheme="light-gray"
          color="primary.500"
          variant="outline"
          onClick={onOpen}
        >
          <Trans>Add member</Trans>
        </Button>
      )}

      <Box mt="4">
        <MemberList />
      </Box>

      <ShareWorkspaceModal isOpen={isOpen} onClose={onClose} initialTab={1} />
    </Container>
  );
};
