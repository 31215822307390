import { TaskStatus, useUpdateTaskMutation } from '@topo-io/graphql';
import { omit } from '@topo-io/utils';
import type { RoadmapTask } from '@/components/roadmap/types';
import { getModifiedTaskCache } from '@/components/roadmap/utils/cache-utils';

export const useUpdateTaskMutationWithCache = () => {
  const [updateTask] = useUpdateTaskMutation();

  const updateTaskWithCache = ({
    stepId,
    taskId,
    task,
  }: {
    stepId: string;
    taskId: string;
    task: RoadmapTask;
  }) => {
    const payload = omit(task, [
      'id',
      'assignees',
      'subtasksCount',
      'completedSubtasksCount',
      'commentCount',
    ]);
    void updateTask({
      variables: {
        taskData: payload,
        taskId,
      },
      update: (cache) => {
        task.status = payload.done ? TaskStatus.COMPLETED : TaskStatus.NOT_STARTED;
        cache.modify(getModifiedTaskCache({ updateTask: task, stepId }));
      },
    });
  };
  return { updateTaskWithCache };
};
