export interface EngagementScoreInteractions {
  comments: number;
  engagedTime: number;
  shares: number;
  views: number;
}

export interface EngagementScoreForPeriod {
  date: string;
  score: number;
}

export interface WorkspaceScoreWithCompany {
  workspaceId: string;
  workspaceTitle: string;
  score: number;
  company: {
    name: string;
    logoUrl?: string;
  };
}

export enum EngagementScoreDateRange {
  Last7Days = 'Last 7 days',
  Last30Days = 'Last 30 days',
  Last90Days = 'Last 90 days',
}
