import { useMemo } from 'react';
import { Box, Ellipsis, Flex, appTheme } from '@topo-io/design-system';
import type { WorkspacesQuery } from '@topo-io/graphql';
import { AccountLogo } from '@/components/common';

interface WorkspaceTitlePropsWithoutCompany {
  isTemplate: true;
  company?: never;
  withLogo?: boolean;
}
interface WorkspaceTitlePropsWithCompany {
  isTemplate?: boolean;
  company?: WorkspacesQuery['workspaces'][number]['company'];
  withLogo?: boolean;
}

type WorkspaceTitleProps<IsTemplate> = {
  title: string;
} & (IsTemplate extends true ? WorkspaceTitlePropsWithoutCompany : WorkspaceTitlePropsWithCompany);

export const WorkspaceTitle = <IsTemplate extends boolean = false>({
  title,
  company,
  isTemplate = false,
  withLogo = true,
}: WorkspaceTitleProps<IsTemplate>) => {
  const matchMedia = window.matchMedia(`(min-width: ${appTheme.breakpoints?.lg ?? '62em'})`);
  const maxTitleLength = useMemo(() => (matchMedia.matches ? 40 : 20), [matchMedia.matches]);

  const shouldDisplayLogo = !isTemplate && withLogo;

  return (
    <Flex alignItems="center" h="24px">
      {shouldDisplayLogo && (
        <Box my="2" mr="2" w="24px" h="24px">
          <AccountLogo company={company} />
        </Box>
      )}
      <Ellipsis text={title} maxTextLength={maxTitleLength} />
    </Flex>
  );
};
