import { t } from '@lingui/macro';
import type { FC } from 'react';
import { Tag } from '@topo-io/design-system';
import { TaskStatus } from '@topo-io/graphql';

interface StatusDescription {
  color: string; // TODO: type of color token?
  message: string;
}

interface TaskStatusTagProps {
  status: TaskStatus;
  onClick?: () => void;
  opacity?: string;
}

export const TaskStatusTag: FC<TaskStatusTagProps> = ({ status, onClick, opacity }) => {
  const TaskStatusDescriptionMap: Record<TaskStatus, StatusDescription> = {
    [TaskStatus.NOT_STARTED]: {
      color: 'gray.40',
      message: t`Not started`,
    },
    [TaskStatus.ONGOING]: {
      color: 'primary.50',
      message: t`Ongoing`,
    },
    [TaskStatus.COMPLETED]: {
      color: 'green.100',
      message: t`Completed`,
    },
  };

  const { color, message } = TaskStatusDescriptionMap[status];

  return (
    <Tag bgColor={color} data-testid="task-status" onClick={onClick} opacity={opacity}>
      {message}
    </Tag>
  );
};
