import { useCallback } from 'react';
import type { MakeOptional } from '@topo-io/types';
import { isSearchFilter, isSearchOperator } from '@/contexts/search-context/types';
import type { SearchFilter, SearchOperator } from '@/contexts/search-context/types';
import { useSearchContext } from './use-search-context';

type ResultPerFilter = Record<string, number>;

export const useSearchFilters = () => {
  const { searchFilters, setSearchFilters } = useSearchContext();

  const resultsPerFilter = {}; // FIXME: Algolia

  // const resultsPerFilter = useMemo(
  //   () =>
  //     results.reduce<ResultPerFilter>((acc, facet) => ({ ...acc, [facet.value]: facet.count }), {}),
  //   [results]
  // );

  const updateFilter = useCallback(
    (filter: MakeOptional<Omit<SearchFilter, 'type'>, 'key' | 'enabled'>) => {
      const enabled = filter.enabled ?? true;
      const key = filter.key ?? `${filter.attribute}.${filter.operator}`;

      setSearchFilters((prevFilters) => {
        const filters = [...prevFilters];
        const existingFilter = filters.find((f) => f.key === key && isSearchFilter(f));
        if (existingFilter && isSearchFilter(existingFilter)) {
          existingFilter.enabled = enabled;
          existingFilter.value = filter.value;
          existingFilter.operator = filter.operator;
        } else {
          filters.push({ ...filter, enabled, key, type: 'filter' });
        }
        return filters;
      });
    },
    [setSearchFilters]
  );

  const updateOperator = useCallback(
    (filter: MakeOptional<Omit<SearchOperator, 'type'>, 'enabled'>) => {
      const enabled = filter.enabled ?? true;
      const { key } = filter;
      setSearchFilters((prevFilters) => {
        const filters = [...prevFilters];
        const existingFilter = filters.find((f) => f.key === key && isSearchOperator(f));
        if (existingFilter && isSearchOperator(existingFilter)) {
          existingFilter.enabled = enabled;
        } else {
          filters.push({ ...filter, enabled, key, type: 'operator' });
        }
        return filters;
      });
    },
    [setSearchFilters]
  );

  return {
    updateFilter,
    updateOperator,
    resultsPerFilter,
    searchFilters,
  };
};
