import type { ReactNode } from 'react';
import { Tab } from '@topo-io/design-system';

interface LibTabProps {
  children: ReactNode;
}

export const LibTab = ({ children }: LibTabProps) => {
  return (
    <Tab
      width="100%"
      color="gray.300"
      fontWeight="500"
      lineHeight="base"
      marginBottom="0"
      borderBottom="0"
      _active={{ backgroundColor: 'gray.40' }}
      _selected={{
        color: 'white',
        backgroundColor: 'primary.500',
      }}
      borderRadius="md"
    >
      {children}
    </Tab>
  );
};
