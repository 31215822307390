import type { MutableRefObject } from 'react';
import { useKey } from '@topo-io/hooks';
import type { KeyFilter } from '@topo-io/hooks';

export const useDeleteOnKeypress = ({
  onKeypress,
  target,
  ref,
  key,
}: {
  onKeypress: () => void;
  target: string;
  ref: MutableRefObject<unknown>;
  key: KeyFilter;
}) => {
  useKey(key, (event) => handleKeypress(event), {}, [target, ref?.current]);

  const handleKeypress = (event: KeyboardEvent) => {
    const isSameElementAndIsTargetEmpty = event.target === ref?.current && !target;
    if (isSameElementAndIsTargetEmpty) {
      onKeypress();
    }
  };
};
