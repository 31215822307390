import { useContext } from 'react';
import { GlobalAnalyticsContext } from '@/components/analytics/contexts';

export const useGlobalAnalyticsContext = () => {
  const globalAnalyticsContext = useContext(GlobalAnalyticsContext);

  if (!globalAnalyticsContext) {
    throw new Error('Context used outside of its Provider!');
  }

  return globalAnalyticsContext;
};
