import { Flex, Pagination } from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';
import { useSearchContext } from '@/hooks';

export const SearchableDataTablePagination = () => {
  const { page, pagination, setPage } = useSearchContext();

  return (
    <Flex w="full" justifyContent="flex-end" mt="4">
      <Pagination
        currentPage={page}
        totalPages={pagination.totalPages}
        onPageChange={setPage}
        isFirstPage={isNil(pagination.previousPage)}
        isLastPage={isNil(pagination.nextPage)}
      />
    </Flex>
  );
};
