import type { FC } from 'react';
import { VariableParentType, useFilesUsagesQuery, useVariablesQuery } from '@topo-io/graphql';
import type { SectionTemplateQuery } from '@topo-io/graphql';
import { OverviewProvider } from '@/contexts';
import { useUser } from '@/hooks';
import { EditSectionTemplate } from './edit-section-template';

interface SectionTemplateOverviewTabProps {
  sectionTemplate: SectionTemplateQuery['sectionTemplate'];
}
export const SectionTemplateOverviewTab: FC<SectionTemplateOverviewTabProps> = ({
  sectionTemplate,
}) => {
  const { user, isAnonymousUser } = useUser();
  const skip = !sectionTemplate?.sectionContentId;
  const { data: filesUsagesData } = useFilesUsagesQuery({
    variables: {
      input: {
        sectionContentId: sectionTemplate?.sectionContentId,
      },
    },
    skip,
  });
  const filesUsages = filesUsagesData?.filesUsages;

  const { data: variablesData } = useVariablesQuery({
    variables: {
      parentId: sectionTemplate.id,
      parentType: VariableParentType.SectionTemplate,
    },
  });
  const variables = variablesData?.variables;

  const isOwner = !isAnonymousUser(user) && sectionTemplate?.owner.id === user?.id;

  return (
    <OverviewProvider filesUsages={filesUsages ?? []} variables={variables ?? []}>
      <EditSectionTemplate sectionTemplate={sectionTemplate} isOwner={isOwner} />
    </OverviewProvider>
  );
};
