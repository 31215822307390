import type { FC, PropsWithChildren } from 'react';
import { Flex, Icon } from '@topo-io/design-system';

export const BrandingSettingsInfoBox: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      gap="4"
      alignItems="center"
      borderWidth="1px"
      borderColor="gray.40"
      padding="4"
      borderRadius="md"
      color="blue.500"
    >
      <Icon color="blue.500" icon="InfoCircleFilled" w="24px" h="24px" />
      {children}
    </Flex>
  );
};
