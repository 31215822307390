import type { TimelineActivity } from '@/components/timeline';
import { Person } from './person';

interface ActionSubjectProps {
  userProfile?: TimelineActivity['userProfile'];
}

export const ActionSubject = ({ userProfile }: ActionSubjectProps) => {
  return (
    <Person
      firstName={userProfile?.firstName}
      lastName={userProfile?.lastName}
      email={userProfile?.user.email}
      capitalized
    />
  );
};
