import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import {
  Banner,
  BannerCloseButton,
  BannerContent,
  BannerDescription,
  BannerIcon,
  BannerTitle,
  useDisclosure,
} from '@topo-io/design-system';

export const TemplateEditionBanner = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Banner status="info" bg="white">
      <BannerIcon />
      <BannerContent>
        <BannerTitle>Information</BannerTitle>
        <BannerDescription>
          <Trans>
            This template was created by someone else. To edit it, you need to duplicate it first.
          </Trans>
        </BannerDescription>
      </BannerContent>
      <BannerCloseButton onClick={onClose} />
    </Banner>
  );
};
