import { t } from '@lingui/macro';
import { useMemo } from 'react';
import { Box, Button, Icon, Menu, MenuButton, MenuList } from '@topo-io/design-system';
import { OwnersFilter } from '@/components/common';
import { getWorkspaceFilterItems } from './constants';
import { DateRangeFilter, NumberRangeFilter, StageFilter } from './filters-items';
import { useWorkspaceFiltersContext } from './workspace-filters-context';
import { WorkspaceFilterMenuItem } from './workspace-filters-menu-item';

export const WorkspaceFilters = () => {
  const { filtersState, selectedFilter, updateSelectedFilter, addFilter } =
    useWorkspaceFiltersContext();

  const menuListItems = useMemo(() => {
    const items = getWorkspaceFilterItems();
    return items.map((item) => (
      <WorkspaceFilterMenuItem
        key={item.key}
        icon={item.icon}
        text={item.text}
        onClick={() => updateSelectedFilter(item)}
      />
    ));
  }, [updateSelectedFilter]);

  const menuListContent = useMemo(() => {
    if (!selectedFilter) {
      return menuListItems;
    }
    switch (selectedFilter?.key) {
      case 'value':
        return <NumberRangeFilter stateKey="value" title={t`Select a value range`} />;
      case 'closeDate':
        return <DateRangeFilter stateKey="closeDate" title={t`Select a close date range`} />;
      case 'ownerId':
        return (
          <Box padding="2" h="300px" w="300px">
            <OwnersFilter
              width="100%"
              withoutTopoTemplate
              isInitialOpen
              withBorder={false}
              withControl={false}
              initialValue={filtersState?.ownerId?.in}
              onChange={(event) => {
                const { value } = event.target;
                if (Array.isArray(value)) {
                  addFilter('ownerId', {
                    in: value as string[],
                  });
                }
              }}
            />
          </Box>
        );
      case 'stage':
        return <StageFilter />;
      default:
        return <></>;
    }
  }, [selectedFilter, menuListItems, filtersState, addFilter]);

  return (
    <Menu variant="default" onClose={() => updateSelectedFilter(null)}>
      <MenuButton
        as={Button}
        rightIcon={<Icon icon="chevron-down" />}
        leftIcon={<Icon icon="FilterFunnel" />}
        bgColor="white"
        colorScheme="gray"
        variant="ghost"
        color="black.500"
        width="10%"
      >
        {t`Filters`}
      </MenuButton>
      <MenuList>{menuListContent}</MenuList>
    </Menu>
  );
};
