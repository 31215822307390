import { Fragment } from 'react';
import type { FC, ReactNode } from 'react';
import { Box, Circle, Flex, H5, Icon, Skeleton, Text, Tooltip } from '@topo-io/design-system';
import { ZERO } from '@topo-io/utils';

interface AnalyticsCardProps {
  title: string;
  tooltipLabel?: ReactNode;
  value: string | number | null;
  children?: React.ReactNode;
  iconName: string;
  isLoading?: boolean;
  loadingStateComponent?: React.ReactNode;
  loadingStateCount?: number;
}

export const AnalyticsCard: FC<AnalyticsCardProps> = ({
  title,
  tooltipLabel,
  value,
  iconName,
  isLoading,
  loadingStateComponent,
  loadingStateCount = 3,
  children,
}) => {
  return (
    <Flex
      borderWidth="1px"
      borderRadius="md"
      bg="cards.bg.color"
      padding="6"
      width="full"
      color="gray.700"
      flexDirection="column"
      justifyContent="space-between"
      gap="4"
    >
      <Flex justifyContent="space-between" alignItems="center" position="relative">
        {tooltipLabel ? (
          <Tooltip label={tooltipLabel} placement="top">
            <Box cursor="pointer">
              <H5>{title}</H5>
            </Box>
          </Tooltip>
        ) : (
          <H5>{title}</H5>
        )}
        <Circle bg="primary.50" p="2.5">
          <Icon
            boxSize="4"
            height="20px"
            width="20px"
            icon={iconName}
            color="primary.500"
            aria-label={`${iconName} icon`}
          />
        </Circle>
      </Flex>
      <Skeleton isLoaded={!isLoading} fitContent>
        <Text fontSize="3xl" lineHeight="48px" fontWeight="500">
          {value ?? ZERO}
        </Text>
      </Skeleton>
      <Box
        maxH="90px"
        overflowY="auto"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {isLoading ? (
          <>
            {Array.from({ length: loadingStateCount }).map((_, index) => (
              <Fragment key={index}>{loadingStateComponent}</Fragment>
            ))}
          </>
        ) : (
          <>{children}</>
        )}
      </Box>
    </Flex>
  );
};
