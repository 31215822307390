import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import type { FileLike } from '@topo-io/design-system';
import {
  H6,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FilePreview,
  Text,
} from '@topo-io/design-system';
import { isEmptyArray } from '@topo-io/utils';

interface TaskDownloadFilesModalProps {
  files: FileLike[];
  isOpen: boolean;
  onClose: () => void;
}

export const TaskDownloadFilesModal: FC<TaskDownloadFilesModalProps> = ({
  files,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <H6>
            <Trans>Download files</Trans>
          </H6>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isEmptyArray(files) ? (
            <Text color="gray.100" data-testid="files-preview-empty-state">
              No files.
            </Text>
          ) : (
            <>
              {files.map((file) => (
                <FilePreview key={file.fileId} file={file} mb={2} />
              ))}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
