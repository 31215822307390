import { useMemo } from 'react';
import { useWorkspaceTabSectionsQuery } from '@topo-io/graphql';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { isNil } from '@topo-io/utils';
import { useBaseRoutesContext } from '@/components/routing';

const REFRESH_INTERVAL_IN_MS = 10000;

export const useWorkspaceTabSections = ({
  workspaceTabId,
}: {
  workspaceTabId: string | undefined;
}) => {
  const { isSectionTemplatePage } = useBaseRoutesContext();
  const { data, loading } = useWorkspaceTabSectionsQuery({
    variables: {
      workspaceTabId: workspaceTabId!,
    },
    fetchPolicy: 'cache-and-network',
    skip: isSectionTemplatePage || isNil(workspaceTabId),
    pollInterval: REFRESH_INTERVAL_IN_MS,
  });
  const sections: SectionJSON[] = useMemo(
    () => data?.workspaceTabSections?.content ?? [],
    [data?.workspaceTabSections?.content]
  );

  return {
    sections,
    loading,
  };
};
