import Link from 'next/link';
import { useMemo } from 'react';
import type { FC } from 'react';
import { Box, Text } from '@topo-io/design-system';
import { Action } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { sectionCommentsUrl, workspaceUrl } from '@/config';
import type { TimelineActivity } from './timeline.types';
import { isActivityCommentSection } from './utils';

export const ActionObject: FC<{
  activity: TimelineActivity;
}> = ({ activity }) => {
  const { workspace } = activity;

  const redirectUrl = useMemo((): string | undefined => {
    const { workspace, action } = activity;
    if (isNil(workspace)) {
      return;
    }

    if (action === Action.OpenWorkspace) {
      return workspaceUrl(workspace.id);
    }

    if (action === Action.ShareWorkspace) {
      return workspaceUrl(workspace.id);
    }

    if (action === Action.CommentSection && isActivityCommentSection(activity)) {
      const sectionId = activity?.comment?.sectionId;
      const workspaceTabSlug = activity?.comment?.workspaceTabSlug;
      if (isNil(sectionId)) {
        return;
      }
      return sectionCommentsUrl({
        workspaceId: workspace.id,
        sectionId,
        mode: {
          isPreview: false,
          isGuest: false,
        },
        tabSlug: workspaceTabSlug,
      });
    }
  }, [activity]);

  return (
    <Box>
      <Link href={redirectUrl ?? '#'}>
        <Text color="primary.500" fontWeight="600">
          &nbsp;{workspace?.title}
        </Text>
      </Link>
    </Box>
  );
};
