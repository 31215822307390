import type { Logger, LoggerOptions } from 'pino';
import pino from 'pino';

export const getLogger = (options: LoggerOptions): Logger => {
  return pino(options);
};

export const logger = getLogger({
  name: 'app',
});
