import { useCallback } from 'react';
import { SNOWPLOW_WORKSPACE_SCHEMA } from '@/config';
import { useAnalytics } from '@/hooks';

type WorkspaceTrackingAction = 'view';

// Persistant args
interface WorkspaceEventTrackingArgs {
  workspaceId: string | undefined;
  workspaceTemplateId: string | undefined;
  skip?: boolean;
  organizationId: string | undefined;
}

// Args that change on each call
interface TrackWorkspaceArgs {
  action: WorkspaceTrackingAction;
  workspaceViewId: string | undefined;
}

// Wrapper around analytics.track to track workspace events
export const useTrackWorkspace = ({
  workspaceId,
  workspaceTemplateId,
  skip = false,
  organizationId,
}: WorkspaceEventTrackingArgs) => {
  const analytics = useAnalytics();

  const trackWorkspace = useCallback(
    ({ action, workspaceViewId }: TrackWorkspaceArgs) => {
      if (!workspaceId || !organizationId || !analytics || skip) {
        return;
      }
      void analytics.track({
        eventName: 'selfDescribingEvent',
        properties: {
          schema: SNOWPLOW_WORKSPACE_SCHEMA,
          data: {
            workspaceId,
            workspaceTemplateId: workspaceTemplateId ?? null,
            organizationId,
            action,
            workspaceViewId,
          },
        },
        plugins: {
          snowplow: true,
          segment: false,
        },
      });
    },
    [workspaceId, workspaceTemplateId, analytics, skip, organizationId]
  );

  return { trackWorkspace };
};
