import { useMemo } from 'react';
import { useWindowSize } from '@topo-io/hooks';
import { MOBILE_BREAKPOINT } from '@/config';

export const useDevice = () => {
  const { width } = useWindowSize();

  const isDesktop = useMemo(() => (width ? width >= MOBILE_BREAKPOINT : true), [width]);
  const isMobile = !isDesktop;

  return { isDesktop, isMobile, windowWidth: width };
};
