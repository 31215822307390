import { Trans, t } from '@lingui/macro';
import { NextButton, PrevButton } from '@saas-ui/forms';
import { StepForm } from '@saas-ui/forms/zod';
import * as zod from 'zod';
import type { StepFormStep } from '@topo-io/design-system';
import { Box, H5, SimpleModal, Icon, Text, Flex, StepFormStepper } from '@topo-io/design-system';
import { SectionType } from '@topo-io/graphql';
import { AISectionLoading } from './ai-section-loading';
import { CustomInstructionsStep, SectionTypeStep } from './steps';
import type { AISectionModalValues } from './utils';
import { AISectionModalStep } from './utils';

export interface NewSectionFormValues {
  type: SectionType;
  input?: string;
}

interface NewTemplateModalProps {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (props: NewSectionFormValues) => void;
}

const steps: StepFormStep[] = [
  {
    name: AISectionModalStep.SECTION_TYPE,
    title: t`Choose a section type`,
    description: t`Tell us more about the section to create`,
    schema: zod.object({
      sectionType: zod.string(),
    }),
  },
  {
    name: AISectionModalStep.CUSTOM_INSTRUCTIONS,
    title: t`Custom instructions`,
    description: t`Tell us more about the section to create`,
    schema: zod.object({
      customInstructions: zod.string(),
    }),
  },
];

const FORM_MIN_HEIGHT = '35rem';
const STEP_WRAPPER_MAX_WIDTH = '30rem';

export const AISectionModal = ({
  loading,
  isOpen,
  onClose,
  onSubmit: handleSubmit,
}: NewTemplateModalProps): JSX.Element => {
  const onSubmit = (values: AISectionModalValues) => {
    handleSubmit({
      type: values.sectionType,
      input: values.customInstructions,
    });
  };

  if (loading) {
    return <AISectionLoading loading={loading} />;
  }

  return (
    <SimpleModal isOpen={isOpen} onClose={onClose} size="5xl" padding="4">
      <StepForm
        steps={steps}
        defaultValues={{
          sectionType: SectionType.INTRODUCTION,
          customInstructions: '',
        }}
        onSubmit={onSubmit}
      >
        <Flex minH={FORM_MIN_HEIGHT}>
          <Flex flex="1 0 36%" bgColor="primary.50" borderRadius="lg" paddingY="4" paddingX="6">
            <Flex flexDirection="column">
              <Flex alignItems="center">
                <Icon icon="Sparkle" w="20px" h="20px" color="primary.600" mr="2" />
                <H5 fontWeight="600">
                  <Trans>Create new section with AI</Trans>
                </H5>
              </Flex>
              <Text color="gray.400">
                <Trans>Save time and create section with AI.</Trans>
              </Text>

              <Flex mt="4" h="100%" w="100%" flexDirection="column" justifyContent="center">
                <Text color="gray.100" fontSize="sm" mb="4">
                  <Trans>How it works</Trans>
                </Text>
                <StepFormStepper orientation="vertical" steps={steps} height="120px" gap="0" />
              </Flex>
            </Flex>
          </Flex>
          <Flex flex="2 0 64%" flexDirection="column">
            <Flex alignItems="center" justifyContent="center" h="100%">
              <Box mt="4" maxW={STEP_WRAPPER_MAX_WIDTH}>
                <SectionTypeStep />
                <CustomInstructionsStep />
              </Box>
            </Flex>
            <Flex alignItems="center" justifyContent="flex-end" gap="10px" mt="8">
              <PrevButton colorScheme="gray">
                <Trans>Back</Trans>
              </PrevButton>
              <NextButton label={t`Continue`} submitLabel={t`Generate my section`} />
            </Flex>
          </Flex>
        </Flex>
      </StepForm>
    </SimpleModal>
  );
};
