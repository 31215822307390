import { Trans } from '@lingui/macro';
import { HStack, Text, Spinner } from '@topo-io/design-system';

export const Loader = () => {
  return (
    <HStack data-testid="loader">
      <Text>
        <Trans>Loading</Trans>
        {'...'}
      </Text>
      <Spinner size="sm" ml="2" color="gray.500" />
    </HStack>
  );
};
