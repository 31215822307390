import { RoadmapType, useUpdateStepMutation } from '@topo-io/graphql';
import type { MakeOptional } from '@topo-io/types';
import { deepClone } from '@topo-io/utils';
import type { RoadmapStep, RoadmapSteps } from '@/components/roadmap/types';
import { useWorkspaceContext } from '@/components/workspace/hooks';

export const useUpdateStepPositionMutationWithCache = () => {
  const { isWorkspace } = useWorkspaceContext();
  const [updateStep] = useUpdateStepMutation();

  const updateStepPositionWithCache = ({
    steps,
    updatedStep,
    newPosition,
    workspaceId,
  }: {
    steps: RoadmapSteps;
    updatedStep: RoadmapStep;
    newPosition: number;
    workspaceId: string;
  }) => {
    const clonedUpdateStep: MakeOptional<RoadmapStep, 'tasks'> = deepClone(updatedStep);
    delete clonedUpdateStep.tasks;
    void updateStep({
      variables: {
        stepData: {
          ...clonedUpdateStep,
          position: newPosition,
          workspaceId,
          roadmapType: isWorkspace ? RoadmapType.Workspace : RoadmapType.WorkspaceTemplate,
        },
        stepId: updatedStep.id,
      },
      update(cache) {
        cache.modify({
          fields: {
            roadmap() {
              return steps;
            },
          },
        });
      },
    });
  };
  return { updateStepPositionWithCache };
};
