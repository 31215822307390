import type {
  DuplicateSectionTemplateMutation,
  CreateSectionTemplateMutation,
  Sections,
} from '@topo-io/graphql';
import { SECTIONS_TEMPLATES_FRAGMENT } from '@topo-io/graphql';

export const getCacheSections = (updatedSections: Sections) => ({
  fields: {
    workspaceTabSections() {
      return updatedSections;
    },
  },
});

export const getCacheSectionTemplate = (
  cache,
  newSectionTemplate:
    | CreateSectionTemplateMutation['createSectionTemplate']
    | DuplicateSectionTemplateMutation['duplicateSectionTemplate']
) => ({
  fields: {
    sectionTemplates(existingSectionTemplates = []) {
      const newTemplateRef = cache.writeFragment({
        data: newSectionTemplate,
        fragment: SECTIONS_TEMPLATES_FRAGMENT,
      });
      return [...existingSectionTemplates, newTemplateRef];
    },
  },
});
