import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import type { FC } from 'react';
import { EventTrackingEvents } from '@topo-io/constants';
import { SimpleModal, H4, Box, Button, Text, Image } from '@topo-io/design-system';
import { all } from '@/config';
import { cdnUrl } from '@/config/routes';
import { useAnalytics } from '@/hooks';

const { CUSTOMER_REFERRAL_URL } = all;

interface ReferralModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ReferralModal: FC<ReferralModalProps> = ({ isOpen, onClose }) => {
  const analytics = useAnalytics();
  useEffect(() => {
    if (isOpen) {
      analytics.track({
        eventName: EventTrackingEvents.REFERALL_PROGRAM_CLICKED,
        properties: {},
      });
    }
  }, [analytics, isOpen]);

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      closeButtonColor="white"
      isCentered
      px="0"
      py="0"
      size="lg"
    >
      <Box textAlign="center" minH="418px">
        <Box
          backgroundImage={cdnUrl('images/banner-referral.png')}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          borderTopRadius="md"
          h="202px"
        >
          <H4 verticalAlign="center" pt="10" color="white">
            <Trans>Refer Topo. </Trans>
          </H4>
          <H4 verticalAlign="center" color="white">
            <Trans>Earn an Amazon gift card up to $5,000.</Trans>
          </H4>
        </Box>
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Image src={cdnUrl('/images/amazon-card.png')} alt="Amazon gift card" />
        </Box>
        <Box px="2">
          <Text mt="20" mb="6">
            <Trans>
              Refer new customers to Topo and earn 10% from each deal. Click the button below to
              submit a referral.
            </Trans>
          </Text>
          <Button mb="6" px="4" py="2" onClick={() => window.open(CUSTOMER_REFERRAL_URL)}>
            <Trans>Refer a new customer</Trans>
          </Button>
        </Box>
      </Box>
    </SimpleModal>
  );
};
