import { useDebouncedCallback } from '@topo-io/hooks';
import type { ContentJSON } from '@topo-io/rich-text-editor';
import type { BasicSectionProps } from '@/components/section/types';

const THROTTLE_TIME = 300;

export const useDebouncedUpdateSectionContent = (
  props: Pick<BasicSectionProps, 'id' | 'updateSectionContent'>
) => {
  const { id: sectionId, updateSectionContent } = props;

  const debounced = useDebouncedCallback((sectionContent: ContentJSON) => {
    updateSectionContent({ sectionId, sectionContent });
  }, THROTTLE_TIME);

  return debounced;
};
