import type { BinaryOperator, DateRange, Filter } from '@cubejs-client/core';
import { useMemo } from 'react';
import { CUBE_CONSTANTS } from '@topo-io/constants';
import { formatDate, isNil, subDays } from '@topo-io/utils';
import type {
  TotalActiveWorkspacesByOwner,
  TotalSharedWorkspacesByOwner,
  TotalGuestsByOwner,
  TotalViewsByOwner,
  TotalTimeSpentByOwner,
  WorkspaceMetricData,
} from '@/components/analytics/types';
import { useAnalyticsQuery } from '@/hooks';

export interface UseGlobalAnalyticsValue {
  totalActiveWorkspaces: Record<string, string>;
  totalSharedWorkspaces: Record<string, string>;
  totalGuests: Record<string, string>;
  totalViews: Record<string, string>;
  totalTimeSpent: Record<string, string>;
  isLoading: boolean;
}

interface UseGlobalAnalyticsArgs {
  organizationId?: string;
  shouldSkip?: boolean;
  dateRange?: string[];
}

const mapWorkspaceMetricsToObject = (
  metricData: WorkspaceMetricData[],
  metricKey: keyof WorkspaceMetricData
): Record<string, string> => {
  return metricData.reduce((acc, data) => {
    acc[data[CUBE_CONSTANTS.WORKSPACE_OWNER_ID]] = data[metricKey];
    return acc;
  }, {});
};

// eslint-disable-next-line complexity
export const useGlobalAnalyticsMetrics = ({
  organizationId,
  shouldSkip,
  dateRange,
}: UseGlobalAnalyticsArgs): UseGlobalAnalyticsValue => {
  const ORGANIZATION_FILTER: Filter = useMemo(
    () => ({
      member: CUBE_CONSTANTS.WORKSPACE_ORGANIZATION_ID,
      operator: 'equals' as BinaryOperator,
      values: [organizationId ?? ''],
    }),
    [organizationId]
  );

  const activeWorkspaceFilters = useMemo<Filter[]>(() => {
    const FILTERS = [ORGANIZATION_FILTER];
    if (dateRange) {
      // If there is a date range, we filter the workspaces with activity before the end of the date range
      FILTERS.push({
        member: CUBE_CONSTANTS.ACTIVITY_CREATED_AT,
        operator: 'beforeDate' as BinaryOperator,
        values: [dateRange[1]],
      });
      // If there is a date range, we filter the workspaces that have been opened 30 days before the start of the date range
      FILTERS.push({
        member: CUBE_CONSTANTS.ACTIVITY_CREATED_AT,
        operator: 'afterDate' as BinaryOperator,
        values: [formatDate(subDays(new Date(dateRange[0]), 30), 'yyyy-MM-dd')],
      });
    }
    return FILTERS;
  }, [dateRange, ORGANIZATION_FILTER]);

  const {
    tableData: totalActiveWorkspacesByOwner,
    isLoading: isTotalActiveWorkspacesByOwnerLoading,
  } = useAnalyticsQuery<TotalActiveWorkspacesByOwner>({
    query: {
      measures: [CUBE_CONSTANTS.WORKSPACE_TOTAL_ACTIVE_COUNT],
      dimensions: [CUBE_CONSTANTS.WORKSPACE_OWNER_ID],
      filters: activeWorkspaceFilters,
    },
    options: {
      skip: isNil(organizationId) || shouldSkip,
    },
  });

  const {
    tableData: totalSharedWorkspacesByOwner,
    isLoading: isTotalSharedWorkspacesByOwnerLoading,
  } = useAnalyticsQuery<TotalSharedWorkspacesByOwner>({
    query: {
      measures: [CUBE_CONSTANTS.WORKSPACE_TOTAL_SHARED_COUNT],
      dimensions: [CUBE_CONSTANTS.WORKSPACE_OWNER_ID],
      filters: [ORGANIZATION_FILTER],
      timeDimensions: [
        {
          dimension: CUBE_CONSTANTS.INVITATION_CREATED_AT,
          ...(dateRange && { dateRange: dateRange as DateRange }),
        },
      ],
    },
    options: {
      skip: isNil(organizationId) || shouldSkip,
    },
  });

  const { tableData: totalGuestsByOwner, isLoading: isTotalGuestsByOwnerLoading } =
    useAnalyticsQuery<TotalGuestsByOwner>({
      query: {
        measures: [CUBE_CONSTANTS.WORKSPACE_TOTAL_GUESTS_SUM],
        dimensions: [CUBE_CONSTANTS.WORKSPACE_OWNER_ID],
        filters: activeWorkspaceFilters,
      },
      options: {
        skip: isNil(organizationId) || shouldSkip,
      },
    });

  const { tableData: totalViewsByOwner, isLoading: isTotalViewsByOwnerLoading } =
    useAnalyticsQuery<TotalViewsByOwner>({
      query: {
        measures: [CUBE_CONSTANTS.WORKSPACE_TOTAL_VIEWS_SUM],
        dimensions: [CUBE_CONSTANTS.WORKSPACE_OWNER_ID],
        filters: activeWorkspaceFilters,
      },
      options: {
        skip: isNil(organizationId) || shouldSkip,
      },
    });

  const { tableData: totalTimeSpentByOwner, isLoading: isTotalTimeSpentByOwnerLoading } =
    useAnalyticsQuery<TotalTimeSpentByOwner>({
      query: {
        measures: [CUBE_CONSTANTS.WORKSPACE_TOTAL_TIME_SPENT_SUM],
        filters: activeWorkspaceFilters,
        dimensions: [CUBE_CONSTANTS.WORKSPACE_OWNER_ID],
      },
      options: {
        skip: isNil(organizationId) || shouldSkip,
      },
    });

  const isLoading =
    isTotalActiveWorkspacesByOwnerLoading ||
    isTotalSharedWorkspacesByOwnerLoading ||
    isTotalGuestsByOwnerLoading ||
    isTotalViewsByOwnerLoading ||
    isTotalTimeSpentByOwnerLoading;

  return {
    totalActiveWorkspaces: mapWorkspaceMetricsToObject(
      totalActiveWorkspacesByOwner,
      CUBE_CONSTANTS.WORKSPACE_TOTAL_ACTIVE_COUNT
    ),
    totalSharedWorkspaces: mapWorkspaceMetricsToObject(
      totalSharedWorkspacesByOwner,
      CUBE_CONSTANTS.WORKSPACE_TOTAL_SHARED_COUNT
    ),
    totalGuests: mapWorkspaceMetricsToObject(
      totalGuestsByOwner,
      CUBE_CONSTANTS.WORKSPACE_TOTAL_GUESTS_SUM
    ),
    totalViews: mapWorkspaceMetricsToObject(
      totalViewsByOwner,
      CUBE_CONSTANTS.WORKSPACE_TOTAL_VIEWS_SUM
    ),
    totalTimeSpent: mapWorkspaceMetricsToObject(
      totalTimeSpentByOwner,
      CUBE_CONSTANTS.WORKSPACE_TOTAL_TIME_SPENT_SUM
    ),
    isLoading,
  };
};
