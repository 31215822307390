import { Box } from '@topo-io/design-system';
import { EngagementOverTimeGraph } from './graph';
import { ENGAGEMENT_OVER_TIME_FAKE_DATA, ENGAGEMENT_OVER_TIME_HEIGHT } from './utils';

export const EngagementOverTimeEmptyState = () => {
  return (
    <Box
      opacity="0.4"
      pointerEvents="none"
      height={`${ENGAGEMENT_OVER_TIME_HEIGHT}px`}
      filter="grayscale(1)"
    >
      <EngagementOverTimeGraph
        chartData={ENGAGEMENT_OVER_TIME_FAKE_DATA}
        format="%d/%m"
        xFormat="time:%b %d"
        tickValues="every 2 days"
        precision="day"
      />
    </Box>
  );
};
