import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react'; // eslint-disable-line
import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { messages as en } from '@/i18n/en';
import { messages as es } from '@/i18n/es';
import { messages as fr } from '@/i18n/fr';

interface LocaleProviderProps {
  children: ReactNode;
  locale?: string;
}

i18n.load({
  en,
  es,
  fr,
});
i18n.activate('en');

export const LocaleProvider = ({ children, ...props }: LocaleProviderProps) => {
  const locale = props.locale ?? 'en';

  useEffect(() => {
    i18n.activate(locale);
  }, [locale]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};
