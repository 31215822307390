import { t } from '@lingui/macro';
import type { FC } from 'react';
import { HStack, Icon, Text } from '@topo-io/design-system';
import { formatShortDate } from '@topo-io/utils';

interface TaskDateProps {
  date?: Date;
  type: 'start' | 'due';
}

export const TaskDate: FC<TaskDateProps> = ({ date, type }) => {
  const icon = type === 'start' ? 'calendar' : 'CalendarPlus';

  const message = type === 'start' ? t`add start date` : t`add due date`;

  const color = date ? 'gray.700' : 'gray.100';

  return (
    <HStack cursor="pointer">
      <Icon icon={icon} color={color} h={6} w={6} />
      <Text color="gray.500">{date ? formatShortDate(date) : message}</Text>
    </HStack>
  );
};
