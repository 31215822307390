import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import type { TagProps } from '@topo-io/design-system';
import { Text, Tag as DesignSystemTag } from '@topo-io/design-system';
import type { Tag } from '@topo-io/graphql';
import { isEmptyArray, isPositive } from '@topo-io/utils';

const SimpleTag: FC<TagProps> = ({ children, ...props }) => (
  <DesignSystemTag borderRadius="md" borderWidth="1px" py={1} px={2} {...props}>
    {children}
  </DesignSystemTag>
);

interface TemplateListTagCellProps {
  tags: Pick<Tag, 'id' | 'name'>[];
}

export const TemplateListTagCell: FC<TemplateListTagCellProps> = ({ tags }) => {
  if (isEmptyArray(tags)) {
    return (
      <Text color="gray.300">
        <Trans>No tag</Trans>
      </Text>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const otherTagsCount = tags.length - 1;

  return (
    <>
      <SimpleTag bgColor="primary.50" borderColor="primary.200" color="primary.700" data-testid="tag">
        {tags[0].name}
      </SimpleTag>
      {isPositive(otherTagsCount) && (
        <SimpleTag bgColor="white" borderColor="gray.40" color="gray.100" ml={1}>
          + {otherTagsCount}
        </SimpleTag>
      )}
    </>
  );
};
