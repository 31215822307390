import type { FC } from 'react';
import { useState } from 'react';
import type { HTMLChakraProps } from '@topo-io/design-system';
import { Box, Center } from '@topo-io/design-system';

interface AccountLogoProps extends HTMLChakraProps<'div'> {
  company?: {
    name?: string;
    logoUrl?: string;
  };
  w?: string | number;
  h?: string | number;
  shouldFallbackOnError?: boolean;
}

export const AccountLogo: FC<AccountLogoProps> = ({
  company,
  w,
  h,
  shouldFallbackOnError: shouldFallbackOnError = true,
  ...props
}) => {
  const [isLogoValid, setIsLogoValid] = useState(true);

  w = w ?? '24px';
  h = h ?? w;

  if (!company?.logoUrl || !isLogoValid) {
    if (shouldFallbackOnError) {
      return <Box width={w} height={h} />;
    }

    return null;
  }

  return (
    <Center width={w} height={h} {...props}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt={company?.name}
        src={company?.logoUrl}
        style={{ borderRadius: '4px', objectFit: 'fill', width: w, maxHeight: h }}
        onError={() => {
          setIsLogoValid(false);
        }}
      />
    </Center>
  );
};
