import type { FC } from 'react';
import { Menu, MenuButton, MenuItem, MenuList } from '@topo-io/design-system';
import type { TaskQuery } from '@topo-io/graphql';
import { TaskStatus } from '@topo-io/graphql';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';
import { TaskStatusTag } from './task-status-tag';

interface TaskStatusMenuProps {
  task: TaskQuery['task'];
}

export const TaskStatusMenu: FC<TaskStatusMenuProps> = ({ task }) => {
  const { status } = task;
  const { updateTask } = useTaskContext();

  const updateStatus = (newStatus: TaskStatus) => {
    void updateTask({ ...task, status: newStatus });
  };

  return (
    <Menu>
      <MenuButton>
        <TaskStatusTag status={status} />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => updateStatus(TaskStatus.NOT_STARTED)}
          data-testid={TaskStatus.NOT_STARTED}
        >
          <TaskStatusTag status={TaskStatus.NOT_STARTED} />
        </MenuItem>
        <MenuItem onClick={() => updateStatus(TaskStatus.ONGOING)} data-testid={TaskStatus.ONGOING}>
          <TaskStatusTag status={TaskStatus.ONGOING} />
        </MenuItem>
        <MenuItem
          onClick={() => updateStatus(TaskStatus.COMPLETED)}
          data-testid={TaskStatus.COMPLETED}
        >
          <TaskStatusTag status={TaskStatus.COMPLETED} />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
