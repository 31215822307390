import { Trans, t } from '@lingui/macro';
import type { FieldValues, UseFormReturn } from '@saas-ui/forms';
import { Field, Form, FormLayout, SubmitButton } from '@saas-ui/forms';
import { useState, useMemo, useRef } from 'react';
import type { ChangeEvent, FC } from 'react';
import { useDisclosure, useToast } from '@topo-io/design-system';
import {
  useAssociateCRMToWorkspaceMutation,
  useUpdateDetailsWorkspaceMutation,
  WorkspaceDocument,
} from '@topo-io/graphql';
import type { WorkspaceQuery } from '@topo-io/graphql';
import { isNotNil, formatDate, isNil } from '@topo-io/utils';
import type { OpportunityItemType, CRMAccountItemType } from '@/components/common/fields';
import { CRMAccountsField, OpportunitiesField } from '@/components/common/fields';
import { StagesField } from '@/components/common/fields/stages-field';
import { useIntegrations } from '@/components/integrations/hooks';
import { ConfirmUpdateWorkspaceOpportunityModal } from './confirm-update-workspace-opportunity-modal/confirm-update-workspace-opportunity-modal';
import type { WorkspaceDetailsFormValues } from './form-values.types';

const formatCloseDate = (closeDate: string | undefined) =>
  closeDate ? formatDate(closeDate) : '-';

export const WorkspaceDetailsForm: FC<{
  workspace: WorkspaceQuery['workspace'];
}> = ({ workspace }) => {
  const [updateWorkspaceDetailsMutation] = useUpdateDetailsWorkspaceMutation();
  const { successToast, errorToast } = useToast();
  const { isOpen, onOpen: openConfirmUpdateWorkspaceOpportunityModal, onClose } = useDisclosure();
  const [associateCRMToWorkspace] = useAssociateCRMToWorkspaceMutation();

  const amountValue = workspace.value ?? undefined;
  const closeDate = useMemo(() => formatCloseDate(workspace.closeDate), [workspace.closeDate]);
  const stage = workspace.stage ?? undefined;
  const defaultValues: WorkspaceDetailsFormValues = {
    accountName: workspace.company?.name,
    title: workspace.title,
    logoUrl: workspace.company?.logoUrl,
    notes: workspace.notes,
    CRMAccount: workspace.CRMAssociation?.CRMAccount,
    opportunity: workspace.CRMAssociation?.CRMOpportunity,
    value: amountValue,
    closeDate,
    stage: isNotNil(stage) ? { id: stage, label: stage } : undefined,
  };

  const [selectedCRMAccountId, setSelectedCRMAccountId] = useState<string | undefined>(
    workspace.CRMAssociation?.CRMAccount?.id
  );
  const { isCloseDateUpdateAvailable } = useIntegrations();
  const formRef = useRef<UseFormReturn<FieldValues, object>>(null);

  const formValues: WorkspaceDetailsFormValues = (formRef.current?.getValues?.() ??
    defaultValues) as WorkspaceDetailsFormValues;

  const updateWorkspace = async (data: WorkspaceDetailsFormValues) => {
    delete data.opportunity;
    delete data.CRMAccount;
    const amountValue = isNotNil(data.value) ? parseInt(String(data.value)) : undefined;
    data.closeDate =
      isNotNil(data.closeDate) && data.closeDate !== '-' ? formatDate(data.closeDate) : undefined;

    await updateWorkspaceDetailsMutation({
      variables: {
        ...data,
        id: workspace.id,
        value: amountValue,
        stage: data.stage?.label ?? undefined,
      },
      refetchQueries: [
        {
          fetchPolicy: 'network-only',
          query: WorkspaceDocument,
          variables: { id: workspace.id },
        },
      ],
    });
    successToast({ title: `Workspace details updated` });
  };

  const onSubmit = async (data: WorkspaceDetailsFormValues) => {
    const hasOpportunityBeenUpdated =
      data.opportunity?.id !== workspace.CRMAssociation?.CRMOpportunity?.id;
    const hasAccountBeenUpdated = data.CRMAccount?.id !== workspace.CRMAssociation?.CRMAccount?.id;

    if (hasOpportunityBeenUpdated) {
      openConfirmUpdateWorkspaceOpportunityModal();
      return;
    } else if (hasAccountBeenUpdated) {
      const CRMAccountId = formValues.CRMAccount?.id;
      const { errors } = await associateCRMToWorkspace({
        variables: {
          input: {
            workspaceId: workspace.id,
            CRMAccountId,
          },
        },
      });
      if (errors) {
        errorToast({ title: `Something went wrong: ${errors[0]?.message}` });
        return;
      }
    }
    await updateWorkspace(data);
  };

  const onChangeOpportunity = (e: ChangeEvent<HTMLInputElement>) => {
    const opportunityItemType = e.target.value as unknown as OpportunityItemType;
    if (isNil(formRef.current)) {
      return;
    }

    if (!opportunityItemType?.id) {
      formRef.current.setValue('opportunity', undefined);
      formRef.current.setValue('stage', undefined);
      formRef.current.setValue('closeDate', undefined);
      formRef.current.setValue('value', undefined);
    } else {
      formRef.current.setValue('opportunity', opportunityItemType);
      formRef.current.setValue('value', opportunityItemType?.amount ?? '');
      formRef.current.setValue(
        'closeDate',
        opportunityItemType.closeDate ? formatCloseDate(opportunityItemType.closeDate) : undefined
      );
      formRef.current.setValue(
        'stage',
        opportunityItemType.stage
          ? {
              id: opportunityItemType.stage,
              label: opportunityItemType.stage ?? '',
            }
          : undefined
      );
    }
  };

  const onChangeCRMAccount = (e: ChangeEvent<HTMLInputElement>) => {
    const item = e.target.value as unknown as CRMAccountItemType | undefined;
    setSelectedCRMAccountId(item?.id);
  };

  return (
    <>
      <ConfirmUpdateWorkspaceOpportunityModal
        isOpen={isOpen}
        onClose={onClose}
        workspaceId={workspace.id}
        formValues={formValues}
        onSuccess={updateWorkspace}
      />
      <Form<WorkspaceDetailsFormValues>
        defaultValues={defaultValues}
        formRef={formRef}
        mode="all"
        criteriaMode="all"
        onSubmit={onSubmit}
      >
        <FormLayout columns={2}>
          <Field<WorkspaceDetailsFormValues> name="accountName" label={t`Account`} isDisabled />
          <Field<WorkspaceDetailsFormValues>
            name="title"
            label={t`Workspace Name`}
            rules={{ required: true }}
            isRequired
          />
          <CRMAccountsField
            name="CRMAccount"
            defaultCRMAccount={workspace.CRMAssociation?.CRMAccount}
            onChange={onChangeCRMAccount}
            label={t`CRM Account Sync`}
            placeholder={t`eg. Acme`}
          />
          <OpportunitiesField
            name="opportunity"
            label={t`CRM Opportunity Sync`}
            CRMAccountId={selectedCRMAccountId}
            defaultOpportunity={workspace.CRMAssociation?.CRMOpportunity}
            onChange={onChangeOpportunity}
          />
          <Field<WorkspaceDetailsFormValues>
            type="number"
            name="value"
            label={t`Amount`}
            defaultValue={amountValue}
          />
          <Field<WorkspaceDetailsFormValues>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type="date"
            name="closeDate"
            label={t`Close date`}
            defaultValue={closeDate}
            isDisabled={isCloseDateUpdateAvailable}
          />

          <StagesField
            name="stage"
            label={t`Stage`}
            defaultStage={isNotNil(stage) ? { id: stage, name: stage } : undefined}
          />
          <Field<WorkspaceDetailsFormValues> type="url" name="logoUrl" label={t`Company Logo`} />
        </FormLayout>
        <FormLayout mt="4">
          <Field<WorkspaceDetailsFormValues>
            type="textarea"
            name="notes"
            label={t`Internal notes`}
          />
        </FormLayout>
        <FormLayout mt="4">
          <SubmitButton>
            <Trans>Save details</Trans>
          </SubmitButton>
        </FormLayout>
      </Form>
    </>
  );
};
