import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { Box, H5 } from '@topo-io/design-system';
import type { CommentsQuery } from '@topo-io/graphql';
import { CommentList } from '@/components/comments';

interface TaskCommentListProps {
  comments: CommentsQuery['comments'];
}

export const TaskCommentList: FC<TaskCommentListProps> = ({ comments }) => {
  return (
    <Box my={6}>
      <H5 mb={2}>
        <Trans>Comments ({comments.length})</Trans>
      </H5>

      <CommentList comments={comments} />
    </Box>
  );
};
