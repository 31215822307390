import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useIsSection } from './use-is-section';

export const useSectionId = () => {
  const { query } = useRouter();
  const isSection = useIsSection();
  const id = useMemo(() => {
    if (!isSection) {
      return;
    }
    let sectionId: string | undefined = undefined;
    if (query?.tab instanceof Array) {
      sectionId = query?.tab[2];
    }

    return sectionId;
  }, [isSection, query?.tab]);
  return id;
};
