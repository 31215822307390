import { useDndContext } from '@dnd-kit/core';
import { Trans } from '@lingui/macro';
import { motion, AnimatePresence } from 'framer-motion';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Box, DropzoneArea } from '@topo-io/design-system';
import { DividerWithPlusCircleButton } from '@/components/section/components/section-divider/divider-with-plus-circle-button';
import { useDropSectionTemplate } from '@/components/section/hooks/use-drag-and-drop-section-template';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';

interface AddSectionProps {
  atIndex: number;
  onDragStart?: () => void;
  onDragEnd?: () => void;
}
export const Droppable: FC<{
  id: string | number;
  data: Record<string, unknown>;
  children: ReactNode;
  onOverEnter: () => void;
  onOverLeave: () => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
}> = ({ id, children, data, onOverEnter, onOverLeave, onDragStart, onDragEnd }) => {
  const { isOver, setNodeRef } = useDropSectionTemplate({
    id,
    data,
  });
  const { active } = useDndContext();

  useEffect(() => {
    if (isOver) {
      onOverEnter();
    } else {
      onOverLeave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOver]);

  useEffect(() => {
    if (!onDragStart || !onDragEnd) {
      return;
    }
    if (active) {
      onDragStart();
    } else {
      onDragEnd();
    }
  }, [active, onDragStart, onDragEnd]);

  return (
    <Box ref={setNodeRef}>
      <AnimatePresence>
        {active && isOver ? (
          <DropzoneArea
            as={motion.div}
            key={`droppable-${id}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            p={10}
            my={4}
          >
            <Trans>Drag & drop here</Trans>
          </DropzoneArea>
        ) : (
          children
        )}
      </AnimatePresence>
    </Box>
  );
};

export const SectionDividerWithAddButton: FC<AddSectionProps> = ({
  atIndex,
  onDragStart,
  onDragEnd,
}) => {
  const { tabId } = useWorkspaceTabs();
  const { addSection } = useContent(tabId);
  const [isButtonDisplayed, setIsButtonDisplayed] = useState(false);

  const onClickAddSectionAtIndex = () => {
    addSection(atIndex);
  };

  const showButton = () => {
    setIsButtonDisplayed(true);
  };
  const hideButton = () => {
    setIsButtonDisplayed(false);
  };

  return (
    <Droppable
      id={atIndex}
      data={{ atIndex }}
      onOverEnter={showButton}
      onOverLeave={hideButton}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Box
        data-testid={`section-divider-with-add-button-${atIndex}`}
        h="8"
        w="full"
        onMouseEnter={showButton}
        onMouseLeave={hideButton}
      >
        {isButtonDisplayed && <DividerWithPlusCircleButton onClick={onClickAddSectionAtIndex} />}
      </Box>
    </Droppable>
  );
};
