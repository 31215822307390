import { Plural } from '@lingui/macro';
import type { Serie } from '@nivo/line';
import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { Flex, Text } from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';
import { AnalyticsTooltip } from '@/components/analytics/common';
import { useBrandingColors } from '@/components/branding/hooks';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { useQueryParamId } from '@/hooks';

const ResponsiveLine = dynamic(() => import('@nivo/line').then((m) => m.ResponsiveLine), {
  ssr: false,
});

interface Props {
  chartData: Serie[];
  precision: 'hour' | 'day';
  xFormat: string;
  format: string;
  tickValues: string;
}

export const EngagementOverTimeGraph: FC<Props> = ({
  chartData,
  precision,
  xFormat,
  format,
  tickValues,
}) => {
  const id = useQueryParamId();
  const { isWorkspace } = useWorkspaceContext();

  const {
    theme: { primaryColor },
  } = useBrandingColors(id, !isWorkspace);

  if (isNil(chartData)) {
    return null;
  }

  return (
    <ResponsiveLine
      data={chartData}
      margin={{ top: 32, right: 16, bottom: 56, left: 32 }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        precision,
        nice: true,
        useUTC: false,
      }}
      xFormat={xFormat}
      axisBottom={{
        tickSize: 0,
        format,
        tickValues,
      }}
      enableGridX={false}
      axisLeft={{
        tickSize: 0,
        tickPadding: 16,
        tickRotation: 0,
        tickValues: 0,
        legend: 'Hotness',
        legendPosition: 'end',
        legendOffset: -8,
      }}
      yFormat=" >-.0d"
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      enableGridY={false}
      pointLabelYOffset={-12}
      colors={primaryColor}
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      lineWidth={1}
      enableCrosshair={false}
      pointSize={4}
      pointColor={{ from: 'color', modifiers: [] }}
      pointBorderWidth={1}
      pointBorderColor={{ from: 'serieColor' }}
      enableArea={true}
      areaOpacity={0.3}
      areaBaselineValue={0}
      useMesh={true}
      legends={[]}
      tooltip={({ point }) => (
        <AnalyticsTooltip
          label={
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Text>{point.data.xFormatted}</Text>
              <Text>
                <Plural
                  value={Number(point.data.yFormatted)}
                  one="# interaction"
                  other="# interactions"
                />
              </Text>
            </Flex>
          }
        />
      )}
    />
  );
};
