import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Box, HStack, Text } from '@topo-io/design-system';
import type { TaskQuery } from '@topo-io/graphql';
import { formatDate, parseDate } from '@topo-io/utils';
import { EditableDate } from '@/components/common/editable-date';
import type { MemberMenuItem } from '@/components/common/member-menu';
import { MemberMenu } from '@/components/common/member-menu';
import { useRoadmapContext } from '@/components/roadmap/hooks';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useWorkspaceMembers } from '@/components/workspace/hooks/use-workspace-members';
import { TaskActionMenu } from './task-action-menu';
import { TaskDate } from './task-date';
import { TaskStatusMenu } from './task-status-menu';
import { TaskVisibility } from './task-visibility';

interface TaskInformationProps {
  task: TaskQuery['task'];
}

export const TaskInformation: FC<TaskInformationProps> = ({ task }) => {
  const { id: workspaceId, isGuest } = useWorkspaceContext();
  const { canEditRoadmap } = useRoadmapContext();
  const { updateTask, updateTaskAssignee } = useTaskContext();
  const { members } = useWorkspaceMembers(workspaceId);

  const workspaceMembers = useMemo(
    () =>
      members.map(({ userProfileId, firstName, lastName, email, picture }) => ({
        id: userProfileId,
        firstName,
        lastName,
        email,
        picture,
      })) ?? [],
    [members]
  );

  const dueDate = useMemo(
    () => (task.dueDate ? parseDate(task.dueDate) : undefined),
    [task.dueDate]
  );

  const startDate = useMemo(
    () => (task.startDate ? parseDate(task.startDate) : undefined),
    [task.startDate]
  );

  const setTaskDate = (newDate: Date, key: 'startDate' | 'dueDate') => {
    updateTask({ ...task, [key]: newDate ? formatDate(newDate) : null! });
  };

  const onMemberSelect = (selectedMember: MemberMenuItem) => updateTaskAssignee(selectedMember.id);

  return (
    <Box>
      <HStack h={10}>
        <Text w={24}>
          <Trans>Status</Trans>
        </Text>
        <TaskStatusMenu task={task} />
      </HStack>

      <HStack h={10}>
        <Text w={24}>
          <Trans>Start date</Trans>
        </Text>
        <EditableDate
          setDate={(newDate: Date) => setTaskDate(newDate, 'startDate')}
          date={startDate}
          readOnly={!canEditRoadmap}
        >
          <TaskDate date={startDate} type="start" />
        </EditableDate>
      </HStack>

      <HStack h={10}>
        <Text w={24}>
          <Trans>Due date</Trans>
        </Text>
        <EditableDate
          setDate={(newDate: Date) => setTaskDate(newDate, 'dueDate')}
          date={dueDate}
          readOnly={!canEditRoadmap}
        >
          <TaskDate date={dueDate} type="due" />
        </EditableDate>
      </HStack>

      {!isGuest && canEditRoadmap && (
        <HStack h={10}>
          <Text w={24}>
            <Trans>Visibility</Trans>
          </Text>
          <TaskVisibility task={task} />
        </HStack>
      )}

      <HStack h={10}>
        <Text w={24}>
          <Trans>Assignees</Trans>
        </Text>
        <MemberMenu
          selectedMembers={task.assignees}
          allMembers={workspaceMembers}
          onMemberSelect={onMemberSelect}
          readOnly={!canEditRoadmap}
          menuCTA={<Text color="gray.500">add assignees</Text>}
        />
      </HStack>

      <HStack h={10}>
        <Text w={24}>
          <Trans>Actions</Trans>
        </Text>
        <TaskActionMenu task={task} />
      </HStack>
    </Box>
  );
};
