import { useRouter } from 'next/router';
import type { FunctionComponent, ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { CustomThemeOverride, Grid, GridItem } from '@topo-io/design-system';
import type { GridItemProps } from '@topo-io/design-system';
import { useIntercom } from '@topo-io/hooks';
import { isNil } from '@topo-io/utils';
import {
  useShouldTrackAnalytics,
  useWorkspaceActivityTracking,
} from '@/components/analytics/hooks';
import { useWorkspaceTabActivityTracking } from '@/components/analytics/hooks/workspace-tab';
import { useBrandingColors } from '@/components/branding';
import { scrollToSection, useIsGuestSectionComments } from '@/components/section';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { TableOfContent } from '@/components/workspace/overview';
import { NAVBAR_HEIGHT } from '@/config';
import { useAnalytics, useQueryParamId, useUser } from '@/hooks';
import { SavingStatusProvider } from '@/state/providers';
import { BaseLayout } from './base-layout';
import { useTableOfContent } from './hooks/use-table-of-content';
import { Navbar } from './navbar';

interface LayoutProps {
  children: ReactNode;
}

export const ReadOnlyLayout: FunctionComponent<LayoutProps> = ({ children }) => {
  const analytics = useAnalytics();
  const { disable } = useIntercom();
  const { query } = useRouter();

  const workspaceId = useQueryParamId();
  const shouldTrackAnalytics = useShouldTrackAnalytics();
  const isGuestSectionComments = useIsGuestSectionComments();

  const { shouldShowTableOfContent, templateLibraryWidth } = useTableOfContent();
  const { overviewRef, isWorkspace } = useWorkspaceContext();
  const { user: userProfile, isAnonymousUser } = useUser();
  const { primaryColorsPalette, theme } = useBrandingColors(workspaceId, !isWorkspace);

  const hasIdentifiedUser = useRef(false);
  const hasScrollToSection = useRef(false);

  useEffect(() => {
    // disable Intercom on ReadOnly layout pages
    disable();
  }, [disable]);

  const scrollingStyle: Partial<GridItemProps> = {
    overflowY: 'auto',
    overflowX: 'hidden',
    style: { scrollbarGutter: 'stable' },
  };

  useEffect(() => {
    if (isNil(userProfile) || !analytics || !shouldTrackAnalytics) {
      return;
    }
    if (hasIdentifiedUser.current) {
      return;
    }
    const userProfileId = isAnonymousUser(userProfile) ? userProfile.anonymousId : userProfile.id;
    // Allow anonymous users to be tracked for snowplow analytics
    void analytics.identify({
      userProfileId: userProfileId,
      shouldTrackAnonymousUser: true,
      plugins: {
        snowplow: true,
        segment: false,
      },
    });
    hasIdentifiedUser.current = true;
  }, [userProfile, isAnonymousUser, analytics, shouldTrackAnalytics]);

  useEffect(() => {
    if (!isGuestSectionComments || hasScrollToSection.current) {
      return;
    }
    const sectionId = Array.isArray(query.tab) ? query.tab[2] : null;
    if (!sectionId) {
      return;
    }
    setTimeout(() => {
      scrollToSection(sectionId);
      hasScrollToSection.current = true;
    }, 2000);
  }, [isGuestSectionComments, query.tab]);

  useWorkspaceActivityTracking({
    skip: !shouldTrackAnalytics,
    organizationId: userProfile?.organizationId,
  });

  useWorkspaceTabActivityTracking({
    skip: !shouldTrackAnalytics,
    organizationId: userProfile?.organizationId,
  });

  return (
    <BaseLayout>
      <SavingStatusProvider>
        <CustomThemeOverride colors={primaryColorsPalette} contrastColor={theme.contrastTextColor}>
          <Grid
            templateAreas='"nav nav" "main content"'
            gridTemplateRows={`${NAVBAR_HEIGHT} 1fr`}
            gridTemplateColumns={`1fr ${templateLibraryWidth}`}
            h="100vh"
            w="100vw"
            left="0"
            right="0"
            top="0"
            bottom="0"
            position="fixed"
          >
            <GridItem area="nav">
              <Navbar />
            </GridItem>
            <GridItem
              area="main"
              position="relative"
              h={`calc(100vh - ${NAVBAR_HEIGHT})`}
              ref={overviewRef}
              {...scrollingStyle}
            >
              {children}
            </GridItem>
            <GridItem
              area="content"
              display={shouldShowTableOfContent ? undefined : 'none'}
              h={`calc(100vh - ${NAVBAR_HEIGHT})`}
            >
              <TableOfContent />
            </GridItem>
          </Grid>
        </CustomThemeOverride>
      </SavingStatusProvider>
    </BaseLayout>
  );
};
