import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useCreateTemplateMutation } from '@topo-io/graphql';
import type { NewTemplateFormValues } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { workspaceTemplateUrl } from '@/config';

export const useSubmitNewWorkspaceTemplate = () => {
  const router = useRouter();
  const [createTemplate] = useCreateTemplateMutation();

  const onSubmit = useCallback(
    async (values: NewTemplateFormValues) => {
      const { data: template } = await createTemplate({ variables: { data: values } });
      const id = template?.createTemplate?.id;
      if (!id) return;

      await router.push(workspaceTemplateUrl(id));
    },
    [createTemplate, router]
  );

  return onSubmit;
};
