export const getNewPosition = (index: number, direction: 'up' | 'down') => {
  if (direction === 'up') {
    const UP = -1;
    return index + UP;
  } else {
    const DOWN = 1;
    return index + DOWN;
  }
};

export const extractValueFromIndex = <T>(array: T[], index: number): T => {
  const DELETE_COUNT = 1;
  return array.splice(index, DELETE_COUNT)[0];
};

export const insertValueAtIndex = <T>(array: T[], index: number, value: T) => {
  const DELETE_COUNT = 0;
  array.splice(index, DELETE_COUNT, value);
};

export const removeValueAtIndex = <T>(array: T[], index: number) => {
  const DELETE_COUNT = 0;
  array.splice(index, DELETE_COUNT);
};
