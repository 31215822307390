import type { FC } from 'react';
import { NewTemplateModal } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { useSubmitNewSectionTemplate } from '@/components/workspace/common/new-template-modal/use-submit-new-section-template';

interface SaveSectionAsTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  sectionId: string;
  defaultTitle: string;
}

export const SaveSectionAsTemplateModal: FC<SaveSectionAsTemplateModalProps> = ({
  sectionId,
  onClose,
  isOpen,
  defaultTitle,
}) => {
  const { getSubmitSaveSectionAsTemplate } = useSubmitNewSectionTemplate();

  return (
    <NewTemplateModal
      title="New section template"
      defaultTitle={defaultTitle}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={getSubmitSaveSectionAsTemplate(sectionId)}
    />
  );
};
