import { Trans, t } from '@lingui/macro';
import { Field, Form, FormLayout } from '@saas-ui/forms';
import produce from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  CustomSwitch,
  DropdownButton,
  DropdownItem,
  Flex,
  H6,
  ModalSection,
  ModalTabPanel,
  SlideFade,
  Text,
  useToast,
} from '@topo-io/design-system';
import { useSendWorkspaceUpdateMutation, WorkspaceDocument } from '@topo-io/graphql';
import type { Member, Workspace } from '@topo-io/graphql';
import { isNil, isNotNil } from '@topo-io/utils';
import { BackButton } from '@/components/common/back-button';
import { InlineField } from '../inline-field';

enum WorkspaceUpdateStep {
  NOTIFY_MEMBERS = 1,
  CUSTOMIZE_EMAIL = 2,
}

interface InvitationWithNotify extends Pick<Member, 'id' | 'email' | 'userProfileId'> {
  notify?: boolean;
}

export const WorkspaceUpdateTab = ({
  workspaceId,
  workspaceMembers,
}: {
  workspaceId: Workspace['id'];
  workspaceMembers: Pick<Member, 'id' | 'email' | 'userProfileId'>[];
}) => {
  const [step, setStep] = useState(WorkspaceUpdateStep.NOTIFY_MEMBERS);
  const [sendWorkspaceUpdate, { loading }] = useSendWorkspaceUpdateMutation({
    refetchQueries: [WorkspaceDocument],
  });
  const [membersToNotify, setMembersToNotify] = useState<InvitationWithNotify[]>([]);
  const ref = useRef(null);
  const { successToast, errorToast } = useToast();

  useEffect(() => {
    setMembersToNotify(workspaceMembers);
  }, [workspaceMembers]);

  const handleToggle = useCallback((id) => {
    setMembersToNotify(
      produce((draft) => {
        const member = draft.find((member) => member.id === id);
        if (isNil(member)) {
          return;
        }
        member.notify = !member.notify;
      })
    );
  }, []);

  const handleReset = useCallback(() => {
    setMembersToNotify(
      produce((draft) => {
        draft.forEach((member) => (member.notify = false));
      })
    );
  }, []);

  const createUpdateNotification = async ({ ...workspaceProps }) => {
    const { data } = await sendWorkspaceUpdate({
      variables: {
        ...workspaceProps,
        membersIds: membersToNotify
          .filter((member) => member.notify)
          .map((member) => member.userProfileId),
        id: workspaceId,
      },
    });

    if (data?.sendWorkspaceUpdate.messageQueued) {
      successToast({ title: 'Email sent' });
    } else {
      errorToast({ title: 'Something went wrong' });
    }
    handleReset();
    goToStart();
  };

  const sendUpdate = (formPayload) => {
    delete formPayload.subject;
    delete formPayload.body;
    createUpdateNotification(formPayload);
  };

  const sendPersonalizedUpdate = (formPayload) => {
    createUpdateNotification(formPayload);
  };

  const goBack = () => {
    setStep((step) => step - 1);
  };
  const goForward = () => {
    setStep((step) => step + 1);
  };

  const goToStart = () => {
    setStep(1);
  };
  return (
    <ModalTabPanel>
      <Box position="relative">
        <Form onSubmit={() => true}>
          {(form) => (
            <>
              {step === WorkspaceUpdateStep.NOTIFY_MEMBERS && (
                <>
                  <ModalSection title={t`Send update`}>
                    <Text>
                      <Trans>
                        Notify members by email on the last changes you made on the workspace
                      </Trans>{' '}
                    </Text>
                  </ModalSection>

                  {isNotNil(workspaceMembers) && (
                    <ModalSection title="">
                      <Flex>
                        <H6>
                          <Trans>Notify members</Trans>
                        </H6>
                        <Badge
                          bgColor="gray.20"
                          color="gray.100"
                          px="2"
                          py="1"
                          borderRadius="md"
                          ml="2"
                        >
                          <Text fontSize="sm" fontWeight="normal">
                            {workspaceMembers?.length}
                          </Text>
                        </Badge>
                      </Flex>
                      <Box my="2" maxHeight="400" overflowY="auto">
                        {workspaceMembers?.length === 0 ? (
                          <Text color="gray.500">
                            <Trans>No members invited yet</Trans>
                          </Text>
                        ) : (
                          membersToNotify.map((member, index) => (
                            <InlineField label={member.email} py="1" pr="1" key={index}>
                              <CustomSwitch
                                name="notifyMember"
                                isChecked={member.notify}
                                onChange={() => handleToggle(member.id)}
                              />
                            </InlineField>
                          ))
                        )}
                      </Box>
                      <Flex justifyContent="end">
                        <DropdownButton
                          onClick={form.handleSubmit(sendUpdate)}
                          label={t`Send email`}
                        >
                          <DropdownItem onClick={form.handleSubmit(sendUpdate)}>
                            <Trans>Send email</Trans>
                          </DropdownItem>
                          <DropdownItem onClick={() => goForward()}>
                            <Trans>Personalize email</Trans>
                          </DropdownItem>
                        </DropdownButton>
                      </Flex>
                    </ModalSection>
                  )}
                </>
              )}

              {step === WorkspaceUpdateStep.CUSTOMIZE_EMAIL && (
                <SlideFade
                  in={step === WorkspaceUpdateStep.CUSTOMIZE_EMAIL}
                  offsetX={10}
                  offsetY={0}
                  ref={ref}
                >
                  <BackButton onClick={goBack} text={t`go back`} />
                  <FormLayout my="4">
                    <Field
                      type="text"
                      name="subject"
                      label={t`Subject`}
                      placeholder={t`Your subject`}
                      isRequired
                    />
                    <Field
                      type="textarea"
                      name="body"
                      label={t`Email`}
                      placeholder={t`Your email body`}
                      isRequired
                    />
                  </FormLayout>

                  <Button onClick={form.handleSubmit(sendPersonalizedUpdate)} isLoading={loading}>
                    <Trans>Send email</Trans>
                  </Button>
                </SlideFade>
              )}
            </>
          )}
        </Form>
      </Box>
    </ModalTabPanel>
  );
};
