import { Trans, t } from '@lingui/macro';
import { Field, Form, FormLayout, SubmitButton } from '@saas-ui/forms';
import type { FC } from 'react';
import { Avatar, H6, HStack, Text, useToast } from '@topo-io/design-system';
import type { TemplateQuery } from '@topo-io/graphql';
import { formatReadableDateDistance, getDisplayName } from '@topo-io/utils';
import type { TemplateWorkspaceDetailsFormValues } from './form-values.types';

export const TemplateWorkspaceDetailsForm: FC<{
  template: TemplateQuery['template'];
  updateTemplateDetails: (data: TemplateWorkspaceDetailsFormValues) => Promise<void>;
  readOnly?: boolean;
}> = ({ template, updateTemplateDetails, readOnly = false }) => {
  const { owner, updatedAt } = template;
  const { successToast } = useToast();
  // eslint-disable-next-line @typescript-eslint/unbound-method

  const onSubmit = async (data: TemplateWorkspaceDetailsFormValues) => {
    await updateTemplateDetails(data);
    successToast({ title: `Template details updated` });
  };

  const now = new Date().toISOString();
  const ownerDisplayName = getDisplayName(owner, owner.user.email);

  return (
    <>
      <H6 pb={1}>
        <Trans>Information</Trans>
      </H6>

      <HStack pb={1}>
        <Text w={20}>
          <Trans>Created by</Trans>
        </Text>
        <HStack>
          <Avatar size="sm" key={owner.user.email} name={ownerDisplayName} src={owner.picture} />
          <Text>{ownerDisplayName}</Text>
        </HStack>
      </HStack>

      <HStack pb={4}>
        <Text w={20}>
          <Trans>Last edited</Trans>
        </Text>
        <Text>{formatReadableDateDistance(updatedAt, now)}</Text>
      </HStack>

      <Form<TemplateWorkspaceDetailsFormValues>
        defaultValues={{
          title: template.title,
          notes: template.notes,
        }}
        mode="all"
        criteriaMode="all"
        onSubmit={onSubmit}
      >
        <FormLayout columns={2}>
          <Field<TemplateWorkspaceDetailsFormValues>
            name="title"
            label={t`Template title`}
            rules={{ required: true }}
            isRequired
            isDisabled={readOnly}
          />
        </FormLayout>
        <FormLayout mt="4">
          <Field<TemplateWorkspaceDetailsFormValues>
            type="textarea"
            name="notes"
            label={t`Internal notes`}
            isDisabled={readOnly}
          />
        </FormLayout>
        <FormLayout mt="4" justifyContent="flex-end">
          <SubmitButton isDisabled={readOnly}>
            <Trans>Save</Trans>
          </SubmitButton>
        </FormLayout>
      </Form>
    </>
  );
};
