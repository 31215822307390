import type { FC } from 'react';
import { Box } from '@topo-io/design-system';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { WorkspaceContentEditorMode } from './workspace-content-editor-mode';
import { WorkspaceContentReadonlyPreviewMode } from './workspace-content-readonly-preview-mode';

interface WorkspaceContentProps {
  content: SectionJSON[];
  readOnly: boolean;
}

export const WorkspaceContent: FC<WorkspaceContentProps> = ({ content, readOnly = false }) => {
  return (
    <Box className="all-sections">
      {readOnly ? (
        <WorkspaceContentReadonlyPreviewMode content={content} />
      ) : (
        <WorkspaceContentEditorMode content={content} />
      )}
    </Box>
  );
};
