import { t } from '@lingui/macro';
import { Icon } from '@topo-io/design-system';
import {
  ZERO,
  calculateDateRanges,
  formatDiff,
  getFirstElement,
  getLastElement,
  isEmptyArray,
  isNil,
} from '@topo-io/utils';
import type { EngagementScoreForPeriod } from '@/components/engagement-score/types';
import { EngagementScoreDateRange } from './types';

export const TREND_GRAPH_HEIGHT = '40px';
const AT_RISK = 40;
const ACTIVE = 70;

export const getEngagementScoreStyle = (score: number) => {
  if (score < AT_RISK) {
    return {
      backgroundColor: 'red.50',
      color: 'red.700',
      hover: {
        backgroundColor: 'red.700',
        color: 'white',
      },
    };
  }
  if (score < ACTIVE) {
    return {
      backgroundColor: 'orange.50',
      color: 'orange.700',
      hover: {
        backgroundColor: 'orange.700',
        color: 'white',
      },
    };
  }
  return {
    backgroundColor: 'green.50',
    color: 'green.700',
    hover: {
      backgroundColor: 'green.700',
      color: 'white',
    },
  };
};

export const getEngagementScoreText = (score: number) => {
  if (score < AT_RISK) {
    return t`At Risk`;
  }
  if (score < ACTIVE) {
    return t`Active`;
  }
  return t`Hot`;
};

export const getScoreDiffForPeriod = (data: EngagementScoreForPeriod[], locale: string) => {
  if (isEmptyArray(data)) {
    return null;
  }

  const firstScoreOfPeriod = getFirstElement(data);
  const lastScoreOfPeriod = getLastElement(data);
  if (isNil(firstScoreOfPeriod) || isNil(lastScoreOfPeriod)) {
    return null;
  }

  const { score: firstScore } = firstScoreOfPeriod;
  const { score: lastScore } = lastScoreOfPeriod;

  const diff = lastScore - firstScore;

  const formattedDiff = isNil(diff)
    ? null
    : formatDiff({
        diff,
        locale,
      });

  return {
    diff,
    formattedDiff,
  };
};

export const getIconForScoreDiff = (scoreDiff: number, size: 'small' | 'big' = 'small') => {
  const width = size === 'small' ? '16px' : '24px';
  const height = size === 'small' ? '16px' : '24px';

  if (scoreDiff > ZERO) {
    return <Icon icon="TrendUp" color="green.600" w={width} h={height} aria-label="TrendUp icon" />;
  }

  if (scoreDiff < ZERO) {
    return (
      <Icon icon="TrendDown" color="red.500" w={width} h={height} aria-label="TrendDown icon" />
    );
  }

  return <Icon icon="Equal" color="orange.600" w={width} h={height} aria-label="Equal icon" />;
};

export const mapDateRangeToLabel = (dateRange: EngagementScoreDateRange) => {
  const mapping = {
    [EngagementScoreDateRange.Last7Days]: t`Last 7 days`,
    [EngagementScoreDateRange.Last30Days]: t`Last 30 days`,
    [EngagementScoreDateRange.Last90Days]: t`Last 90 days`,
  };
  return mapping[dateRange];
};

export const mapDateRangeToDates = (
  dateRange: EngagementScoreDateRange
): {
  currentRange: string[];
  previousRange: string[];
} => {
  switch (dateRange) {
    case EngagementScoreDateRange.Last7Days:
      return calculateDateRanges(7, new Date());
    case EngagementScoreDateRange.Last30Days:
      return calculateDateRanges(30, new Date());
    case EngagementScoreDateRange.Last90Days:
      return calculateDateRanges(90, new Date());
    default:
      return calculateDateRanges(7, new Date());
  }
};
