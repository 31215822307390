import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useWorkspace } from '@/components/workspace/hooks/use-workspace';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useWorkspaceTabs } from '@/components/workspace/hooks/use-workspace-tabs';
import { ANALYTICS_PING_INTERVAL, ANALYTICS_WAITING_TIME } from '@/config';
import type { SectionTrackingAction } from './use-track-section';
import { useTrackSection } from './use-track-section';

interface Args {
  sectionId: string;
  sectionTemplateId?: string;
  organizationId: string | undefined;
  skip?: boolean;
}

export const useSectionActivityTracking = ({
  sectionId,
  sectionTemplateId,
  organizationId,
  skip = false,
}: Args) => {
  const {
    visibleSections,
    isActivityTrackingEnabled,
    handleAfterPing,
    id: workspaceId,
    workspaceViewId,
    workspaceTabViewId,
  } = useWorkspaceContext();

  const { workspace } = useWorkspace(workspaceId!, { skip: !workspaceId });
  const { tabId } = useWorkspaceTabs();

  const sectionViewId = useRef<string | undefined>(undefined);
  const [lastSeenAt, setLastSeenAt] = useState<number | null>(null);
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const isOnScreen = visibleSections.includes(sectionId);

  const { trackSection } = useTrackSection({
    skip,
    workspaceId,
    workspaceTabId: tabId,
    workspaceTemplateId: workspace?.workspaceTemplateId,
    workspaceTabViewId,
    workspaceViewId,
    organizationId,
  });

  const trackSectionWithParams = useCallback(
    (action: SectionTrackingAction) => {
      trackSection({
        sectionId,
        sectionTemplateId,
        action,
        sectionViewId: sectionViewId.current,
      });
    },
    [sectionId, sectionTemplateId, trackSection]
  );

  useEffect(() => {
    if (isOnScreen) {
      sectionViewId.current = uuid();
    } else {
      sectionViewId.current = undefined;
    }
  }, [isOnScreen]);

  // ping immediately when the section appears on the screen
  useEffect(() => {
    if (skip || !isOnScreen) {
      return;
    }
    trackSectionWithParams('ping');
  }, [isOnScreen, skip, trackSectionWithParams]);

  // ping every ANALYTICS_PING_INTERVAL seconds if the section is on screen
  useEffect(() => {
    if (skip || !isActivityTrackingEnabled || !isOnScreen) {
      return;
    }
    trackSectionWithParams('ping');
    handleAfterPing();
  }, [isOnScreen, skip, isActivityTrackingEnabled, handleAfterPing, trackSectionWithParams]);

  // Handle logic for tracking view event after the section has been on screen for ANALYTICS_PING_INTERVAL
  useEffect(() => {
    if (skip) {
      return;
    }
    if (isOnScreen && !lastSeenAt) {
      setLastSeenAt(Date.now());
    }
    if (!isOnScreen && lastSeenAt) {
      setLastSeenAt(null);
    }
    if (!isOnScreen && hasBeenViewed) {
      setHasBeenViewed(false);
    }
  }, [isOnScreen, lastSeenAt, hasBeenViewed, skip]);

  // Track view event one time only after ANALYTICS_PING_INTERVAL seconds
  useEffect(() => {
    if (skip) {
      return;
    }
    const shouldSendViewEvent = isOnScreen && lastSeenAt && !hasBeenViewed;
    if (!shouldSendViewEvent) {
      return;
    }
    const timer = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastSeenAt >= ANALYTICS_PING_INTERVAL) {
        trackSectionWithParams('view');
        setHasBeenViewed(true);
      }
    }, ANALYTICS_WAITING_TIME);

    return () => {
      clearInterval(timer);
    };
  }, [isOnScreen, lastSeenAt, hasBeenViewed, skip, trackSectionWithParams]);
};
