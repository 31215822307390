import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useEffect } from 'react';
import {
  Banner,
  BannerIcon,
  BannerContent,
  BannerTitle,
  BannerDescription,
  BannerCloseButton,
  useDisclosure,
} from '@topo-io/design-system';

export const WorkspaceDetailsBanner: FC<{ readOnly?: boolean }> = ({ readOnly = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Banner status="info" bg="white" mt="4">
      <BannerIcon />
      <BannerContent>
        <BannerTitle>
          <Trans>Information</Trans>
        </BannerTitle>
        <BannerDescription data-testid="workspace-details-banner">
          {readOnly ? (
            <Trans>This template was created by someone else. You cannot edit its details.</Trans>
          ) : (
            <Trans>Your customers cannot see this page</Trans>
          )}
        </BannerDescription>
      </BannerContent>
      <BannerCloseButton onClick={onClose} />
    </Banner>
  );
};
