import { Trans } from '@lingui/macro';
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, H5, Icon, Text, useDisclosure } from '@topo-io/design-system';
import {
  CountWorkspaceLegacyActivitiesDocument,
  useSetWorkspaceLegacyActivitiesAsSeenMutation,
  useWorkspaceLegacyActivitiesQuery,
} from '@topo-io/graphql';
import { isEmptyArray, isNil } from '@topo-io/utils';
import { ActivitiesTimeline } from '@/components/timeline';
import { ShareWorkspaceModal } from '@/components/workspace';
import { useQueryParamId } from '@/hooks';
import { WORKSPACE_DEFAULT_TAKE_ACTIVITIES } from './constants';

export const WorkspaceActivities = () => {
  const workspaceId = useQueryParamId();
  const shareDisclosure = useDisclosure();

  const [activities, setActivities] = useState<ActivitiesTimeline>([]);

  const [markAsSeen] = useSetWorkspaceLegacyActivitiesAsSeenMutation({
    refetchQueries: [CountWorkspaceLegacyActivitiesDocument],
  });

  const { data, loading, refetch } = useWorkspaceLegacyActivitiesQuery({
    variables: { workspaceId, skip: 0, take: WORKSPACE_DEFAULT_TAKE_ACTIVITIES },
    skip: isNil(workspaceId),
  });

  const totalActivities = useMemo(() => {
    if (!loading) {
      return data?.workspaceLegacyActivities?.count ?? 0;
    }
  }, [data?.workspaceLegacyActivities?.count, loading]);

  const onClickMore = () => {
    if (isNil(activities)) {
      return;
    }
    void refetch({ skip: activities.length, take: WORKSPACE_DEFAULT_TAKE_ACTIVITIES });
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    setActivities((prevActivities) => [
      ...prevActivities,
      ...(data?.workspaceLegacyActivities?.activities ?? []),
    ]);
  }, [loading, data?.workspaceLegacyActivities?.activities]);

  useEffect(() => {
    if (isNil(workspaceId)) {
      return;
    }
    void markAsSeen({
      variables: { workspaceId },
    });
  }, [markAsSeen, workspaceId]);

  return (
    <Box bg="cards.bg.color" p="6" h="full" w="full" overflowY="auto" borderRadius="md">
      <H5 mb="4">
        <Trans>Recent activity</Trans>
      </H5>
      {totalActivities === 0 && isEmptyArray(activities) ? (
        <Box>
          <Flex w="60%" m="0 auto" mt="16" direction="column" alignItems="center">
            <Text fontSize="lg" fontWeight="600" lineHeight="24px" align="center">
              <Trans>Invite guests to your workspace to start viewing some activity!</Trans>
            </Text>
            <Button
              mt="10"
              leftIcon={<Icon icon="send" />}
              minWidth="none"
              onClick={shareDisclosure.onOpen}
            >
              <Trans>Share workspace</Trans>
            </Button>
          </Flex>
        </Box>
      ) : (
        <Box w="full">
          <ActivitiesTimeline
            everyActivityDisplayed={totalActivities === activities?.length}
            activities={activities}
            loading={loading}
            onClickMore={onClickMore}
            withBorder={false}
          />
        </Box>
      )}
      <ShareWorkspaceModal isOpen={shareDisclosure.isOpen} onClose={shareDisclosure.onClose} />
    </Box>
  );
};
