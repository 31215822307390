import { Trans } from '@lingui/macro';
import type { ColumnDef } from '@tanstack/react-table';
import type { FC } from 'react';
import { EngagementScoreTooltip } from '@/components/engagement-score/components/tooltip';
import {
  TrendLineGraph,
  TrendLineGraphLoadingState,
} from '@/components/engagement-score/components/trend';
import { useWorkspaceScoresForPeriod } from '@/components/engagement-score/hooks';
import type {
  EngagementScoreDateRange,
  WorkspaceScoreWithCompany,
} from '@/components/engagement-score/types';
import { WorkspaceTitle } from '@/components/workspace/list/workspace-title';

type WorkspaceWithCompanyColumns = ColumnDef<WorkspaceScoreWithCompany>[];

export const buildWorkspaceRankedByScoreColumns = ({
  dateRange,
}: {
  dateRange: EngagementScoreDateRange;
}): WorkspaceWithCompanyColumns => {
  const columns: WorkspaceWithCompanyColumns = [
    {
      id: 'workspace',
      header: () => <Trans>Workspace</Trans>,
      cell: (props) => (
        <WorkspaceTitle
          title={props.row.original.workspaceTitle}
          company={props.row.original.company}
          withLogo={false}
        />
      ),
    },
    {
      id: 'engagementScore',
      header: () => <Trans>Scoring</Trans>,
      cell: (props) => (
        <EngagementScoreTooltip
          isScoreLoading={false}
          score={props.row.original.score}
          workspaceId={props.row.original.workspaceId}
          dateRange={dateRange}
          withGraph={false}
        />
      ),
    },
    {
      id: 'trend',
      header: () => <Trans>Trend</Trans>,
      cell: (props) => (
        <TableGraph workspaceId={props.row.original.workspaceId} dateRange={dateRange} />
      ),
    },
  ];

  return columns;
};

interface TableGraphProps {
  workspaceId: string;
  dateRange: EngagementScoreDateRange;
}

const TableGraph: FC<TableGraphProps> = ({ workspaceId, dateRange }) => {
  const { data: scoresForPeriod, isLoading } = useWorkspaceScoresForPeriod({
    workspaceId,
    dateRange,
  });

  return isLoading ? (
    <TrendLineGraphLoadingState />
  ) : (
    <TrendLineGraph scoresForPeriod={scoresForPeriod ?? []} />
  );
};
