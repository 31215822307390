import { useMemo } from 'react';
import { useWorkspaceSectionsQuery } from '@topo-io/graphql';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { isNil } from '@topo-io/utils';
import { useBaseRoutesContext } from '@/components/routing';

export const useWorkspaceSections = ({ workspaceId }: { workspaceId: string | undefined }) => {
  const { isSectionTemplatePage } = useBaseRoutesContext();
  const { data, loading } = useWorkspaceSectionsQuery({
    variables: {
      workspaceId: workspaceId!,
    },
    fetchPolicy: 'cache-and-network',
    skip: isSectionTemplatePage || isNil(workspaceId),
  });
  const sections: SectionJSON[] = useMemo(
    () => data?.workspaceSections?.content ?? [],
    [data?.workspaceSections?.content]
  );

  return {
    sections,
    loading,
  };
};
