import Link from 'next/link';
import { Flex } from '@topo-io/design-system';

export const SidebarLink = ({
  children,
  url,
  isExternal = false,
}: {
  children: React.ReactNode;
  url?: string;
  isExternal?: boolean;
}) => {
  if (url) {
    return (
      <Flex w="100%" align="center">
        {isExternal ? (
          <Link style={{ width: '100%' }} href={url} target="_blank" rel="noreferrer">
            {children}
          </Link>
        ) : (
          <Link style={{ width: '100%' }} href={url}>
            {children}
          </Link>
        )}
      </Flex>
    );
  } else {
    return (
      <Flex w="100%" align="center">
        {children}
      </Flex>
    );
  }
};
