import type { FC } from 'react';
import type { StackProps } from '@topo-io/design-system';
import { HStack, Icon, Text } from '@topo-io/design-system';
import { isZero } from '@topo-io/utils';

interface TaskCommentCountProps extends StackProps {
  count: number;
  onClick?: () => void;
}

export const TaskCommentCount: FC<TaskCommentCountProps> = ({ count, onClick, ...rest }) => {
  if (isZero(count)) {
    return null;
  }

  return (
    <HStack data-testid="task-comment-count" onClick={onClick} {...rest}>
      <Icon icon={'Comment'} color="gray.700" h={4} w={4} />
      <Text>{count}</Text>
    </HStack>
  );
};
