import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@topo-io/design-system';
import {
  useDeleteTaskMutationWithCache,
  useUpdateTaskMutationWithCache,
} from '@/components/roadmap/hooks';
import type { RoadmapTask } from '@/components/roadmap/types';
import { useWorkspaceContext } from '@/components/workspace';
import { onTaskDelete } from './on-task-delete';

interface TaskMenuProps {
  previousTaskId?: string;
  task: RoadmapTask;
}

export const TaskMenu: FC<TaskMenuProps> = ({ previousTaskId, task }) => {
  const { id: taskId, stepId, isVisible } = task;
  const { isPreview } = useWorkspaceContext();
  const { deleteTaskWithCache } = useDeleteTaskMutationWithCache(onTaskDelete(previousTaskId));
  const { updateTaskWithCache } = useUpdateTaskMutationWithCache();

  const onClickRemoveTask = () => {
    deleteTaskWithCache({ taskId, stepId });
  };

  const toggleVisibility = () => {
    updateTaskWithCache({
      stepId,
      taskId,
      task: {
        ...task,
        isVisible: !task.isVisible,
      },
    });
  };

  const displayVisibilityOption = !isPreview;

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="more-btn"
        icon={<Icon icon="more-horizontal" />}
        variant="ghost"
        colorScheme="gray"
      />
      <MenuList aria-label="task-menu-list">
        {displayVisibilityOption && (
          <>
            {isVisible ? (
              <MenuItem icon={<Icon icon="eye-off" />} color="gray.900" onClick={toggleVisibility}>
                <Trans>Hide task</Trans>
              </MenuItem>
            ) : (
              <MenuItem icon={<Icon icon="eye" />} color="gray.900" onClick={toggleVisibility}>
                <Trans>Show task</Trans>
              </MenuItem>
            )}
          </>
        )}
        <MenuItem
          icon={<Icon icon="trash" />}
          aria-label="delete-task-menu-item"
          color="red.500"
          onClick={onClickRemoveTask}
        >
          <Trans>Delete</Trans>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
