import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { Box, GridItem, Center } from '@topo-io/design-system';
import { WorkspaceTabFeatureName } from '@topo-io/graphql';
import { WorkspaceAnalytics } from '@/components/analytics/workspace-analytics';
import { Footer } from '@/components/common/footer';
import { WorkspaceOverview } from '@/components/workspace';
import { TemplateDetails, WorkspaceDetails } from '@/components/workspace/details';
import { useWorkspaceTabs, useWorkspaceContext } from '@/components/workspace/hooks';
import { WorkspaceMembers } from '@/components/workspace/members';
import { TemplateOverview } from '@/components/workspace/overview';
import { WorkspaceRoadmap, TemplateRoadmap } from '@/components/workspace/roadmap';
import {
  WORKSPACE_DEFAULT_TAB,
  WorkspaceTabNonEditableEnum,
} from '@/components/workspace/tabs/workspace-tab-type';

export const WorkspaceTabs = () => {
  const router = useRouter();
  const { isTemplate, isWorkspace, isGuest, isPreview, id } = useWorkspaceContext();
  const { tab, isTabShown, getFirstVisibleWorkspaceTabByType } = useWorkspaceTabs();
  const showFooter =
    isGuest &&
    (tab.type === WorkspaceTabFeatureName.CONTENT ||
      tab.type === WorkspaceTabFeatureName.MUTUAL_ACTION_PLAN);

  useEffect(() => {
    if (!id) {
      return;
    }
    const isWorkspaceTabRoute = router.query.tab?.length === 1;
    if (!isWorkspaceTabRoute) {
      return;
    }
    const isDefaultTabSlug = tab.slug === WORKSPACE_DEFAULT_TAB.slug;
    const isRealTab = 'id' in tab;
    if (!isDefaultTabSlug || isRealTab) {
      return;
    }
    const firstContentTab = getFirstVisibleWorkspaceTabByType({
      type: WorkspaceTabFeatureName.CONTENT,
      displayedTabsOnly: isGuest || isPreview,
    });
    if (firstContentTab) {
      void router.replace(
        {
          query: {
            id,
            tab: [firstContentTab.slug],
          },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [tab, getFirstVisibleWorkspaceTabByType, isGuest, isPreview, router, id]);

  const getWorkspaceContent = useCallback(() => {
    const isContentTab = tab.type === WorkspaceTabFeatureName.CONTENT;
    const shouldDisplayTab = 'isDisplayed' in tab ? tab.isDisplayed : false;
    if (!isTabShown({ type: tab.type, isDisplayed: isContentTab || shouldDisplayTab })) {
      return null;
    }

    if (tab.type === WorkspaceTabFeatureName.CONTENT) {
      if (isWorkspace) {
        return <WorkspaceOverview />;
      }
      if (isTemplate) {
        return <TemplateOverview />;
      }
    }
    if (tab.type === WorkspaceTabFeatureName.MUTUAL_ACTION_PLAN) {
      if (isWorkspace) {
        return <WorkspaceRoadmap />;
      }
      if (isTemplate) {
        return <TemplateRoadmap />;
      }
    }
    if (tab.type === WorkspaceTabFeatureName.MEMBERS) {
      return <WorkspaceMembers />;
    }

    if (tab.type === WorkspaceTabNonEditableEnum.ANALYTICS) {
      return <WorkspaceAnalytics />;
    }

    if (tab.type === WorkspaceTabNonEditableEnum.DETAILS) {
      if (isWorkspace) {
        return <WorkspaceDetails />;
      }
      if (isTemplate) {
        return <TemplateDetails />;
      }
    }
  }, [tab, isWorkspace, isTemplate, isTabShown]);

  return (
    <Box display="block" my="4" w="full">
      <GridItem>{getWorkspaceContent()}</GridItem>
      {showFooter && (
        <Box>
          <Center>
            <Footer />
          </Center>
        </Box>
      )}
    </Box>
  );
};
