import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text } from '@topo-io/design-system';
import type { TaskQuery } from '@topo-io/graphql';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';

interface TaskVisibilityProps {
  task: TaskQuery['task'];
}

const PublicVisiblity: FC = () => (
  <HStack>
    <Icon icon="eye" color="gray.700" h={6} w={6} />
    <Text color="gray.500">
      <Trans>Public</Trans>
    </Text>
  </HStack>
);

const PrivateVisiblity: FC = () => (
  <HStack>
    <Icon icon="eye-off" color="gray.700" h={6} w={6} />
    <Text color="gray.500">
      <Trans>Private</Trans>
    </Text>
  </HStack>
);

export const TaskVisibility: FC<TaskVisibilityProps> = ({ task }) => {
  const { updateTask } = useTaskContext();
  const { isVisible } = task;

  const updateVisibility = (newVisibility: boolean) =>
    void updateTask({ ...task, isVisible: newVisibility });

  return (
    <Menu>
      <MenuButton>{isVisible ? <PublicVisiblity /> : <PrivateVisiblity />}</MenuButton>
      <MenuList>
        <MenuItem onClick={() => updateVisibility(true)}>
          <PublicVisiblity />
        </MenuItem>
        <MenuItem onClick={() => updateVisibility(false)}>
          <PrivateVisiblity />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
