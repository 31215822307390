import { t } from '@lingui/macro';
import { useCallback } from 'react';
import { useBaseRoutesContext } from '@/components/routing';
import { useSectionTemplateContext } from '@/components/section/hooks';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { usePageTitle } from './use-page-title';

export const useNavbar = () => {
  const { isTagsSettingsPage } = useBaseRoutesContext();
  const { isWorkspace, isTemplate: isWorkspaceTemplate, isGuest } = useWorkspaceContext();

  const title = usePageTitle();

  const { isSectionTemplate } = useSectionTemplateContext();

  const getBackButtonLabel = useCallback(() => {
    const canHaveBackButton =
      isSectionTemplate || isWorkspaceTemplate || isWorkspace || isTagsSettingsPage;
    if (!canHaveBackButton || isGuest) {
      return null;
    }

    return t`Back`;
  }, [isSectionTemplate, isWorkspaceTemplate, isWorkspace, isGuest, isTagsSettingsPage]);

  return {
    title,
    backButtonLabel: getBackButtonLabel(),
  };
};
