import { t } from '@lingui/macro';
import type { FC } from 'react';
import { Box, Checkbox, Flex, Spinner, Text } from '@topo-io/design-system';
import { useWorkspacesStagesQuery } from '@topo-io/graphql';
import { isNotEmptyArray } from '@topo-io/utils';
import { useWorkspaceFiltersContext } from '@/components/workspace/list/filters/workspace-filters-context';
import { useSearchFilters } from '@/hooks';

export const StageFilter: FC = () => {
  const { data, loading } = useWorkspacesStagesQuery({
    fetchPolicy: 'cache-first',
  });
  const { filtersState, addFilter, updateSelectedFilter } = useWorkspaceFiltersContext();
  const { updateFilter, updateOperator } = useSearchFilters();
  const stages = data?.workspacesStages ?? [];

  const handleChange = (name: string, checked: boolean) => {
    const updatedStages = checked
      ? [...(filtersState?.stage?.in ?? []), name]
      : (filtersState?.stage?.in ?? []).filter((item) => item !== name);
    addFilter('stage', {
      in: updatedStages,
    });
    updateOperator({
      key: 'stageOperator',
      operator: 'AND',
      enabled: isNotEmptyArray(updatedStages),
    });
    updateFilter({
      attribute: 'stage',
      value: updatedStages,
      operator: 'in',
      parentNodeKey: 'stageOperator',
      enabled: isNotEmptyArray(updatedStages),
    });
    updateSelectedFilter(null);
  };

  return (
    <Box padding="4" w="350px">
      {loading ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Text color="gray.900" fontWeight="500">{t`Select a stage`}</Text>
          <Flex mt="4" flexDirection="column" gap="5px">
            {stages.map(({ name }) => (
              <Checkbox
                defaultChecked={filtersState?.stage?.in?.includes(name)}
                onChange={(e) => handleChange(name, e.target.checked)}
                key={name}
              >
                {name}
              </Checkbox>
            ))}
          </Flex>
        </>
      )}
    </Box>
  );
};
