import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { CustomSwitch } from '@topo-io/design-system';
import { InlineField } from '@/components/workspace/common/share-workspace-modal/inline-field';

export const NotificationWorkspaceOpenedSettings = ({
  defaultSettingsNotifyWorkspaceOpened,
  updateSettingsNotifyWorkspaceOpened,
}: {
  defaultSettingsNotifyWorkspaceOpened: boolean;
  updateSettingsNotifyWorkspaceOpened: (value: boolean) => void;
}) => {
  const [shouldNotifyWorkspaceOpened, setShouldNotifyWorkspaceOpened] = useState<boolean>(
    defaultSettingsNotifyWorkspaceOpened
  );

  useEffect(() => {
    setShouldNotifyWorkspaceOpened(defaultSettingsNotifyWorkspaceOpened);
  }, [defaultSettingsNotifyWorkspaceOpened]);

  const onChangeSwitchSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isCheckedValue = event.currentTarget.checked;
    setShouldNotifyWorkspaceOpened(isCheckedValue);
    updateSettingsNotifyWorkspaceOpened(isCheckedValue);
  };

  return (
    <InlineField label={t`Notify me when workspace is opened`} my="2">
      <CustomSwitch
        name="notifyWorkspaceOpened"
        aria-label="settings-notify-workspace-opened-checkbox"
        isChecked={shouldNotifyWorkspaceOpened}
        onChange={onChangeSwitchSettings}
      />
    </InlineField>
  );
};
