import { t } from '@lingui/macro';
import { FormModal } from '@/components/common';
import { TemplateField } from '@/components/common/fields/template-field';
import { AccountAndTitleFields } from '@/components/workspace/common/new-workspace-modal/account-and-title-fields';
import { WorkspaceCRMFields } from '@/components/workspace/common/new-workspace-modal/workspace-crm-fields';
import { useSubscription } from '@/hooks/use-subscription';
import { useNewWorkspaceModal } from './use-new-workspace-modal';

interface NewWorkspaceFromTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  templateId?: string;
}

export const NewWorkspaceFromTemplateModal = ({
  isOpen,
  onClose,
  templateId,
}: NewWorkspaceFromTemplateModalProps): JSX.Element => {
  const { formRef, onSubmitCreateWorkspaceFromTemplate } = useNewWorkspaceModal({
    templateId,
    closeModal: onClose,
  });
  const { hasCRMFeatures } = useSubscription();

  return (
    <FormModal
      formRef={formRef}
      title={t`New workspace from template`}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmitCreateWorkspaceFromTemplate}
    >
      {!templateId && (
        <TemplateField
          name="template"
          label={t`Template`}
          placeholder={t`e.g Template Sales`}
          isRequired
        />
      )}
      <AccountAndTitleFields />
      {hasCRMFeatures && <WorkspaceCRMFields formRef={formRef} />}
    </FormModal>
  );
};
