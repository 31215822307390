import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useInviteMember } from './hooks/use-invite-member';
import { useUpdateMember } from './hooks/use-update-member';
import type { InviteMemberFormValues, UpdateMemberFormValues } from './types';

export const useInviteMembersSubmit = ({
  workspaceId,
  editingMemberId,
  onSubmitSuccess,
  onSubmitFailure,
  onSubmitCompleted,
}: {
  workspaceId: string;
  editingMemberId: string | undefined;
  onSubmitSuccess: () => void;
  onSubmitFailure: () => void;
  onSubmitCompleted: () => void;
}): {
  sendInvitation: (formPayload: InviteMemberFormValues) => void;
  sendPersonalizedInvitation: (formPayload: InviteMemberFormValues) => void;
  updateMember: (formPayload: UpdateMemberFormValues) => void;
  setWithEmail: Dispatch<SetStateAction<boolean>>;
} => {
  const [withEmail, setWithEmail] = useState(true);
  const { inviteMember } = useInviteMember({
    workspaceId,
    onSubmitSuccess,
    onSubmitFailure,
    onSubmitCompleted,
  });

  const { updateMember } = useUpdateMember({
    workspaceId,
    editingMemberId,
    onSubmitSuccess,
    onSubmitFailure,
    onSubmitCompleted,
  });

  const sendPersonalizedInvitation = (
    formPayload: InviteMemberFormValues &
      NonNullable<Pick<InviteMemberFormValues, 'subject' | 'body'>>
  ) => {
    const company = formPayload.company?.label
      ? {
          id: formPayload.company?.id,
          name: formPayload.company?.label,
          domainUrl: formPayload.company?.domainUrl,
          logoUrl: formPayload.company?.logoUrl,
        }
      : undefined;
    void inviteMember({
      ...formPayload,
      company,
    });
  };

  const sendInvitation = (formPayload: InviteMemberFormValues) => {
    delete formPayload.subject;
    delete formPayload.body;
    const company = formPayload.company?.label
      ? {
          id: formPayload.company?.id,
          name: formPayload.company?.label,
          domainUrl: formPayload.company?.domainUrl,
          logoUrl: formPayload.company?.logoUrl,
        }
      : undefined;
    void inviteMember({
      withEmail,
      ...formPayload,
      company,
    });
  };

  return {
    sendInvitation,
    sendPersonalizedInvitation,
    updateMember,
    setWithEmail,
  };
};
