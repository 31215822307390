import { useContext } from 'react';
import { isNil } from '@topo-io/utils';
import { SearchContext } from '@/contexts/search-context/search-context';

export const useSearchContext = () => {
  const searchContext = useContext(SearchContext);

  if (isNil(searchContext)) {
    throw new Error('Context used outside of its Provider!');
  }

  return searchContext;
};
