import type { FC, ReactElement } from 'react';
import { useMemo } from 'react';
import {
  MenuButton,
  Menu,
  Icon,
  MenuList,
  MenuItem,
  Avatar,
  Spacer,
  HStack,
} from '@topo-io/design-system';
import { getDisplayName, isEmptyArray } from '@topo-io/utils';
import { MemberGroup } from './member-group';

export interface MemberMenuItem {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  picture?: string;
}

interface MemberMenuProps {
  selectedMembers: MemberMenuItem[];
  allMembers: MemberMenuItem[];
  readOnly?: boolean;
  onMemberSelect: (m: MemberMenuItem) => void;
  menuCTA?: ReactElement;
}

export const MemberMenu: FC<MemberMenuProps> = ({
  selectedMembers,
  allMembers,
  onMemberSelect,
  readOnly = false,
  menuCTA,
}) => {
  const assignedMemberIds = useMemo(() => selectedMembers.map((m) => m.id), [selectedMembers]);
  const isMemberAssigned = (member: MemberMenuItem) => assignedMemberIds.includes(member.id);

  // Depending on wether we're display the empty state or the member group,
  // the `<MenuButton>` applies a different style. To make sure the alignment is the same in both
  // case, we force the inline start.
  const menuStyle = isEmptyArray(selectedMembers) ? {} : { marginInlineStart: 3 };

  return (
    <Menu>
      <MenuButton style={menuStyle}>
        {isEmptyArray(selectedMembers) ? (
          <HStack>
            <Icon icon="UserPlus" height="20px" width="20px" />
            {menuCTA}
          </HStack>
        ) : (
          <MemberGroup members={selectedMembers} />
        )}
      </MenuButton>
      {!readOnly && (
        <MenuList>
          {allMembers.map((member, index) => {
            const name = getDisplayName(member, member.email);
            return (
              <MenuItem onClick={() => onMemberSelect(member)} key={index}>
                <Avatar size="sm" key={member.email} name={name} mr="2" src={member.picture} />
                {name}
                <Spacer />
                {isMemberAssigned(member) && <Icon icon="x" color="gray.100" />}
              </MenuItem>
            );
          })}
        </MenuList>
      )}
    </Menu>
  );
};
