import { useRouter } from 'next/router';
import type { FC } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@topo-io/design-system';
import { SectionComments } from '@/components/section/components/section-comments/section-comments';
import { useIsSection } from '@/components/section/hooks';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';
import { workspaceUrl } from '@/config';
import { useDevice, useQueryParamId } from '@/hooks';

export const SectionCommentsDrawer: FC = () => {
  const workspaceId = useQueryParamId();
  const { isMobile } = useDevice();
  const { isPreview, isGuest } = useWorkspaceContext();
  const { replace } = useRouter();
  const isSectionPage = useIsSection();
  const { tab } = useWorkspaceTabs();

  const { isOpen, onClose: closeDrawer } = useDisclosure({ isOpen: isSectionPage });

  const closeDrawerAndRedirect = () => {
    closeDrawer();
    const url = workspaceUrl(workspaceId, tab.slug, { isPreview, isGuest });
    void replace(url, undefined, {
      shallow: true,
    });
  };

  return (
    <Drawer isOpen={isOpen} onClose={closeDrawerAndRedirect} size={isMobile ? 'full' : 'lg'}>
      <DrawerOverlay data-testid="drawer-overview-overlay" />
      <DrawerContent
        pt={3}
        pb={8}
        overflowY="auto"
        overflowX="hidden"
        style={{ scrollbarGutter: 'stable' }}
      >
        <DrawerCloseButton aria-label="close-drawer-btn" />
        <DrawerBody>
          <SectionComments />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
