import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { SavingStatusEnum } from '@topo-io/design-system';
import { statusAtom, showAtom } from '@/state/atoms/saving-status.atom';

interface UseSavingStatus {
  show: boolean;
  status: SavingStatusEnum;
  reset: () => void;
  showSavingStatus: () => void;
  showSavedStatus: () => void;
  showNotSavedStatus: () => void;
}

export const useSavingStatus: () => UseSavingStatus = () => {
  const [status, setStatus] = useAtom(statusAtom);
  const [show, setShow] = useAtom(showAtom);

  const reset = useCallback(() => {
    setStatus(SavingStatusEnum.NOT_SAVED);
    setShow(false);
  }, [setShow, setStatus]);

  const showStatus = useCallback(
    (status: SavingStatusEnum) => {
      setStatus(status);
      setShow(true);
    },
    [setShow, setStatus]
  );

  const showSavingStatus = useCallback(() => {
    showStatus(SavingStatusEnum.SAVING);
  }, [showStatus]);

  const showSavedStatus = useCallback(() => {
    showStatus(SavingStatusEnum.SAVED);
  }, [showStatus]);

  const showNotSavedStatus = useCallback(() => {
    showStatus(SavingStatusEnum.NOT_SAVED);
  }, [showStatus]);

  return {
    status,
    show,
    reset,
    showSavingStatus,
    showSavedStatus,
    showNotSavedStatus,
  };
};
