import type { FC, ReactNode } from 'react';
import { Text, Tooltip } from '@topo-io/design-system';

interface AnalyticsTooltipProps {
  label: ReactNode;
}

export const AnalyticsTooltip: FC<AnalyticsTooltipProps> = ({ label }) => {
  return (
    <Tooltip label={label} hasArrow placement="top" isOpen>
      {/* Hack to display tooltip only, children is required for Tooltip component */}
      <Text visibility="hidden" opacity="0" height="0" width="0">
        Not displayed text
      </Text>
    </Tooltip>
  );
};
