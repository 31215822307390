import type { FC, PropsWithChildren } from 'react';
import { useCallback } from 'react';
import { DayPicker } from 'react-day-picker';
import {
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  PopoverBody,
} from '@topo-io/design-system';

interface EditableDateProps {
  date?: Date;
  setDate: (date: Date | undefined) => void;
  readOnly?: boolean;
}

export const EditableDate: FC<PropsWithChildren<EditableDateProps>> = ({
  date,
  setDate,
  readOnly = false,
  children,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSelect = useCallback(
    (date: Date | undefined): void => {
      setDate(date);
      onClose();
    },
    [onClose, setDate]
  );

  if (readOnly) {
    return <>{children}</>;
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-end">
      <PopoverTrigger>
        <Box pos="relative">{children}</Box>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody data-testid="daypicker">
          <DayPicker mode="single" selected={date} onSelect={onSelect} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
