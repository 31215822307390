/* eslint-disable complexity */
import { Trans, t } from '@lingui/macro';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useCallback } from 'react';
import { MENU_BUTTON_WORKSPACE_Z_INDEX } from '@topo-io/constants';
import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useMediaQuery,
  appTheme,
} from '@topo-io/design-system';
import { WorkspaceBrandingModal } from '@/components/branding';
import { ShareWorkspaceModal } from '@/components/workspace';
import {
  DeleteWorkspaceOrTemplateModal,
  NewWorkspaceFromTemplateModal,
  NewWorkspaceModal,
} from '@/components/workspace/common';
import { NewTemplateModal } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { useSubmitDuplicateWorkspaceTemplate } from '@/components/workspace/common/new-template-modal/use-submit-duplicate-workspace-template';
import { useWorkspaceContext, useTemplate } from '@/components/workspace/hooks';
import {
  workspacePreviewUrl,
  workspaceTemplatePreviewUrl,
  workspaceTemplatesUrl,
  workspacesUrl,
} from '@/config';
import { useUser } from '@/hooks';

interface CTAConnectedProps {
  id: string;
}

export const CTAConnected: FC<CTAConnectedProps> = ({ id }) => {
  const router = useRouter();

  const shareDisclosure = useDisclosure();
  const newWorkspaceDisclosure = useDisclosure();
  const newTemplateDisclosure = useDisclosure();
  const newWorkspaceFromTemplateDisclosure = useDisclosure();
  const workspaceBrandingDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();

  const { readOnly, isTemplate, isWorkspace } = useWorkspaceContext();
  const onSubmit = useSubmitDuplicateWorkspaceTemplate();
  const { template } = useTemplate(id, { skip: isWorkspace });
  const { user, isAnonymousUser } = useUser();

  const canDelete = !isAnonymousUser(user) && (isWorkspace || template?.owner.id === user?.id);

  const [isLargerThanSmBreakpoint] = useMediaQuery(
    `(min-width: ${appTheme?.breakpoints?.sm ?? 0})`
  );

  const goToWorkspacePreview = useCallback(() => {
    void router.push(workspacePreviewUrl(id));
  }, [router, id]);

  const goToTemplatePreview = useCallback(
    () => router.push(workspaceTemplatePreviewUrl(id)),
    [router, id]
  );

  const onDeleteSuccess = useCallback(async () => {
    if (isWorkspace) {
      await router.push(workspacesUrl());
    } else if (isTemplate) {
      await router.push(workspaceTemplatesUrl());
    }
  }, [isWorkspace, isTemplate, router]);

  return (
    <>
      {!readOnly && isWorkspace && (
        <Button
          colorScheme="gray"
          mr="2"
          leftIcon={<Icon icon="eye" />}
          onClick={goToWorkspacePreview}
          minWidth="unset"
        >
          <Trans>Preview</Trans>
        </Button>
      )}

      {!readOnly && isTemplate && (
        <Button
          colorScheme="gray"
          mr="2"
          leftIcon={<Icon icon="eye" />}
          onClick={goToTemplatePreview}
          minWidth="unset"
        >
          <Trans>Preview</Trans>
        </Button>
      )}

      {!isTemplate &&
        (isLargerThanSmBreakpoint ? (
          <Button
            mr="2"
            leftIcon={<Icon icon="send" />}
            onClick={shareDisclosure.onOpen}
            minWidth="none"
          >
            <Trans>Share</Trans>
          </Button>
        ) : (
          <IconButton
            ml={2}
            aria-label="Share"
            icon={<Icon icon="send" />}
            onClick={shareDisclosure.onOpen}
          />
        ))}

      {isTemplate && (
        <Button
          mr="2"
          leftIcon={<Icon icon="file-text" />}
          onClick={newWorkspaceFromTemplateDisclosure.onOpen}
          minWidth="none"
        >
          <Trans>Create a workspace</Trans>
        </Button>
      )}

      {((isWorkspace && !readOnly) || isTemplate) && (
        <Menu variant="default">
          <MenuButton
            as={IconButton}
            colorScheme="gray"
            icon={<Icon icon="more-horizontal" />}
            data-testid="cta-connected-menu"
          >
            <Box position="relative" top="-3px">
              ...
            </Box>
          </MenuButton>
          <MenuList zIndex={MENU_BUTTON_WORKSPACE_Z_INDEX}>
            <MenuItem
              data-testid="cta-connected-menu-duplicate"
              icon={<Icon icon="copy" position="relative" top="-1px" w="16px" h="16px" />}
              onClick={isTemplate ? newTemplateDisclosure.onOpen : newWorkspaceDisclosure.onOpen}
            >
              <Trans>Duplicate</Trans>
            </MenuItem>

            {isWorkspace && (
              <MenuItem
                icon={<Icon icon="PaintPour" position="relative" top="-1px" w="16px" h="16px" />}
                onClick={() => workspaceBrandingDisclosure.onOpen()}
              >
                <Trans>Customize workspace</Trans>
              </MenuItem>
            )}

            {canDelete && (
              <MenuItem
                data-testid="cta-connected-menu-delete"
                icon={<Icon icon="trash" position="relative" top="-1px" w="16px" h="16px" />}
                color="red.500"
                onClick={() => deleteModalDisclosure.onOpen()}
              >
                <Trans>Delete</Trans>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      {isWorkspace && (
        <>
          <ShareWorkspaceModal isOpen={shareDisclosure.isOpen} onClose={shareDisclosure.onClose} />
          <NewWorkspaceModal
            title={t`Duplicate workspace`}
            duplicate
            originalWorkspaceId={id}
            isOpen={newWorkspaceDisclosure.isOpen}
            onClose={newWorkspaceDisclosure.onClose}
          />
          <WorkspaceBrandingModal
            isOpen={workspaceBrandingDisclosure.isOpen}
            onClose={workspaceBrandingDisclosure.onClose}
            workspaceId={id}
          />
        </>
      )}
      {isTemplate && (
        <>
          <NewTemplateModal
            title={t`Duplicate template`}
            duplicate
            onSubmit={onSubmit}
            originalTemplateId={id}
            isOpen={newTemplateDisclosure.isOpen}
            onClose={newTemplateDisclosure.onClose}
          />
          <NewWorkspaceFromTemplateModal
            isOpen={newWorkspaceFromTemplateDisclosure.isOpen}
            onClose={newWorkspaceFromTemplateDisclosure.onClose}
            templateId={id}
          />
        </>
      )}

      <DeleteWorkspaceOrTemplateModal
        isOpen={deleteModalDisclosure.isOpen}
        onClose={deleteModalDisclosure.onClose}
        onDeleteSuccess={onDeleteSuccess}
        isWorkspace={isWorkspace}
        id={id}
      />
    </>
  );
};
