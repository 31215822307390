import { t } from '@lingui/macro';
import { useMemo } from 'react';
import type { MultiSelectItem } from '@topo-io/design-system';
import { Avatar, Box, Flex, Text } from '@topo-io/design-system';
import { MultiSelect } from '@topo-io/design-system';
import { UserRole, useUserProfilesForRoleQuery } from '@topo-io/graphql';
import { getDisplayName, isNil } from '@topo-io/utils';
import { useSearchFilters, useUser } from '@/hooks';

const TOPO_ITEM_VALUE = 'topo';
const renderItem = (item: MultiSelectItem) => (
  <Box>
    <Flex alignItems="center" gap="2">
      <Avatar size="xs" name={item.label} src={item.picture} />
      <Text>{item.label}</Text>
    </Flex>
  </Box>
);

const TopoTemplateSelectItem: MultiSelectItem = {
  label: 'Topo',
  value: TOPO_ITEM_VALUE,
  // add Topo logo here
  renderItem,
};

interface OwnerFilterProps {
  withoutTopoTemplate?: boolean;
  width?: string;
  isInitialOpen?: boolean;
  withControl?: boolean;
  withBorder?: boolean;
  initialValue?: string[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const OwnersFilter = ({
  withoutTopoTemplate: withoutTopoTemplates = false,
  width = '30%',
  isInitialOpen = false,
  withControl = true,
  withBorder = true,
  initialValue,
  onChange,
}: OwnerFilterProps) => {
  const { user } = useUser();
  const { data, loading } = useUserProfilesForRoleQuery({
    variables: {
      role: UserRole.EDITOR,
      organizationId: user?.organizationId,
    },
    skip: isNil(user?.organizationId),
  });
  const { updateFilter, updateOperator } = useSearchFilters();

  const owners: MultiSelectItem[] = useMemo(() => {
    const items: MultiSelectItem[] =
      data?.userProfilesForRole.map((owner) => ({
        label: getDisplayName(owner, owner.user.email),
        value: owner.id,
        picture: owner.picture,
        renderItem,
      })) ?? [];

    if (withoutTopoTemplates) {
      return items;
    }

    return [TopoTemplateSelectItem, ...items];
  }, [data, withoutTopoTemplates]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (onChange) {
      onChange(event);
    }
    if (Array.isArray(value)) {
      updateOperator({
        operator: 'OR',
        key: 'owner',
        enabled: value.length > 0,
      });

      const ids = value.filter((id) => id !== TOPO_ITEM_VALUE);
      updateFilter({
        attribute: 'ownerId',
        operator: 'in',
        value: ids,
        enabled: ids.length > 0,
        parentNodeKey: 'owner',
      });

      const isTopoSelected = value.includes(TOPO_ITEM_VALUE);
      updateFilter({
        attribute: 'shouldBypassRLS',
        operator: 'equals',
        value: true,
        enabled: isTopoSelected,
        parentNodeKey: 'owner',
      });
    }
  };

  if (loading) {
    return <></>;
  }

  return (
    <Box bgColor="white" borderRadius="md" w={width} whiteSpace="nowrap">
      <MultiSelect
        name="owner"
        items={owners}
        placeholder={t`All creators`}
        searchInputPlaceholder={t`Search for creators`}
        onChange={handleChange}
        selectedItemsBehavior={{
          type: 'grouped',
          oneItemLabel: t`Creator`,
          multipleItemsLabel: t`creators`,
        }}
        closeOnSelect
        isSearchable
        isInitialOpen={isInitialOpen}
        withControl={withControl}
        withBorder={withBorder}
        initialValue={initialValue}
      />
    </Box>
  );
};
