import { Box } from '@topo-io/design-system';
import type { EngagementScoreForPeriod } from '@/components/engagement-score/types';
import { TREND_GRAPH_HEIGHT } from '@/components/engagement-score/utils';
import { TrendLineGraph } from './trend-line-graph';

const FAKE_DATA: EngagementScoreForPeriod[] = [
  {
    date: '2021-09-01',
    score: 20,
  },
  {
    date: '2021-09-02',
    score: 25,
  },
  {
    date: '2021-09-03',
    score: 30,
  },
  {
    date: '2021-09-04',
    score: 40,
  },
  {
    date: '2021-09-05',
    score: 70,
  },
  {
    date: '2021-09-06',
    score: 80,
  },
  {
    date: '2021-09-07',
    score: 60,
  },
];

export const TrendLineGraphLoadingState = () => {
  return (
    <Box opacity="0.4" pointerEvents="none" height={TREND_GRAPH_HEIGHT} filter="grayscale(1)">
      <TrendLineGraph scoresForPeriod={FAKE_DATA} />
    </Box>
  );
};
