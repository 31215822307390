import { IntegrationName } from '@topo-io/graphql';

const integrationNameDisplayMapper: {
  [key in IntegrationName]: string;
} = {
  [IntegrationName.HUBSPOT]: 'HubSpot',
  [IntegrationName.SALESFORCE]: 'Salesforce',
  [IntegrationName.PIPEDRIVE]: 'Pipedrive',
};

const integrationNameIconMapper: { [key in IntegrationName]: string } =
  integrationNameDisplayMapper;

export const getDisplayedIntegrationName = (integrationName: IntegrationName): string => {
  return integrationNameDisplayMapper[integrationName];
};

export const getIntegrationIcon = (integrationName: IntegrationName): string => {
  return integrationNameIconMapper[integrationName];
};
