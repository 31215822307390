import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { usePrevious } from '@topo-io/hooks';

export const useOnRouteChanged = ({
  callback,
  isPathEquals,
  callbackRefreshKey,
}: {
  callback: () => void;
  isPathEquals?: string;
  callbackRefreshKey?: string;
}) => {
  const router = useRouter();
  // pathname is the path declared in NextRouter: ex: /w/[id]/[...tab];
  // asPath is the path in the browser: ex: /w/1234/overview
  const { events, pathname } = router;

  const previousCallbackRefreshKey = usePrevious(callbackRefreshKey);

  const page = useCallback(() => {
    // compare the path declared in NextRouter with the path received as props
    if (isPathEquals && pathname !== isPathEquals) {
      return;
    }
    callback();
  }, [callback, pathname, isPathEquals]);

  useEffect(() => {
    if (callbackRefreshKey && callbackRefreshKey !== previousCallbackRefreshKey) {
      page();
    }
  }, [page, callbackRefreshKey, previousCallbackRefreshKey, callback]);

  useEffect(() => {
    events?.on('routeChangeComplete', page);

    return () => {
      events?.off('routeChangeComplete', page);
    };
  }, [events, page]);

  return page;
};
