import { WorkspaceTabFeatureName } from '@topo-io/graphql';
import { useWorkspaceTabs } from '@/components/workspace/hooks';
import { TEMPLATE_LIBRARY_WIDTH } from '@/config';
import { useDevice } from '@/hooks';

export const useTableOfContent = () => {
  const { isDesktop } = useDevice();
  const { tab } = useWorkspaceTabs();

  const shouldShowTableOfContent = isDesktop && tab.type === WorkspaceTabFeatureName.CONTENT;
  const templateLibraryWidth = shouldShowTableOfContent ? TEMPLATE_LIBRARY_WIDTH : '0px';

  return {
    templateLibraryWidth,
    shouldShowTableOfContent,
  };
};
