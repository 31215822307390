import { Trans, t } from '@lingui/macro';
import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@topo-io/design-system';
import { DeleteWorkspaceOrTemplateModal, NewWorkspaceModal } from '@/components/workspace/common';

interface ActionProps {
  id: string;
  onDeleteSuccess: () => void;
}

export const Actions = ({ id, onDeleteSuccess }: ActionProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const deleteWorkspaceDisclosure = useDisclosure();

  return (
    <>
      <Flex m="0" alignItems="center" alignContent="flex-end" justifyContent="flex-end">
        <Menu variant="default">
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                variant="ghost"
                colorScheme="gray"
                icon={<Icon icon="more-vertical" h="16px" w="16px" color="gray.100" />}
              />
              <MenuList>
                <MenuItem
                  icon={<Icon icon="copy" position="relative" top="-1px" />}
                  onClick={onOpen}
                >
                  <Trans>Duplicate</Trans>
                </MenuItem>
                <MenuItem
                  icon={<Icon icon="trash" position="relative" top="-1px" />}
                  color="red.500"
                  onClick={deleteWorkspaceDisclosure.onOpen}
                >
                  <Trans>Delete</Trans>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>

      <NewWorkspaceModal
        title={t`Duplicate workspace`}
        duplicate
        originalWorkspaceId={id}
        isOpen={isOpen}
        onClose={onClose}
      />

      <DeleteWorkspaceOrTemplateModal
        isOpen={deleteWorkspaceDisclosure.isOpen}
        onClose={deleteWorkspaceDisclosure.onClose}
        onDeleteSuccess={onDeleteSuccess}
        isWorkspace
        id={id}
      />
    </>
  );
};
