import { Trans } from '@lingui/macro';
import { useCallback } from 'react';
import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@topo-io/design-system';
import { useDeleteMemberMutation, WorkspaceRole } from '@topo-io/graphql';
import { ShareWorkspaceModal } from '@/components/workspace/common';
import { useUser, useQueryParamId } from '@/hooks';

export const MemberListActions = ({ id: invitedMemberId, role }): JSX.Element => {
  const workspaceId = useQueryParamId();
  const { isConnected } = useUser();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleteMemberMutation] = useDeleteMemberMutation({
    update: (cache) => {
      cache.modify({
        fields: {
          members(existingMembers = [], { readField }) {
            return existingMembers.filter((member) => readField('id', member) !== invitedMemberId);
          },
        },
      });
    },
  });

  const deleteMember = useCallback(async () => {
    await deleteMemberMutation({ variables: { id: invitedMemberId } });
  }, [invitedMemberId, workspaceId, deleteMemberMutation]);

  if (!isConnected || role === WorkspaceRole.ADMIN) {
    return <></>;
  }

  return (
    <>
      <ShareWorkspaceModal
        isOpen={isOpen}
        onClose={onClose}
        initialTab={1}
        editingMemberId={invitedMemberId}
      />
      <Flex m="0" alignItems="center" alignContent="flex-end" justifyContent="flex-end">
        <Menu variant="default">
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                variant="ghost"
                colorScheme="gray"
                data-testid="member-list-actions"
                icon={<Icon icon="more-horizontal" />}
              />
              <MenuList>
                <MenuItem
                  icon={<Icon icon="edit-2" position="relative" top="-1px" />}
                  color="black.500"
                  onClick={onOpen}
                >
                  <Trans>Edit</Trans>
                </MenuItem>
                <MenuItem
                  icon={<Icon icon="trash" position="relative" top="-1px" />}
                  color="red.500"
                  onClick={deleteMember}
                >
                  <Trans>Delete</Trans>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    </>
  );
};
