import { useContext } from 'react';
import { TaskContext } from '@/components/roadmap/contexts/task-context';

export const useTaskContext = () => {
  const taskContext = useContext(TaskContext);

  if (!taskContext) {
    throw new Error('Context used outside of its Provider!');
  }

  return taskContext;
};
