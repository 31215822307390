import type { TabsQuery } from '@topo-io/graphql';
import { WorkspaceTabFeatureName } from '@topo-io/graphql';

export const enum WorkspaceTabNonEditableEnum {
  ANALYTICS = 'ANALYTICS',
  DETAILS = 'DETAILS',
}

export type WorkspaceTab = TabsQuery['tabs'][number];
export type TabType = WorkspaceTabFeatureName | WorkspaceTabNonEditableEnum;

export const WORKSPACE_TAB_ANALYTICS = {
  type: WorkspaceTabNonEditableEnum.ANALYTICS,
  slug: 'analytics',
};

export const WORKSPACE_TAB_DETAILS = {
  type: WorkspaceTabNonEditableEnum.DETAILS,
  slug: 'details',
};

export const WORKSPACE_DEFAULT_TAB_SLUG = 'overview';
export const WORKSPACE_DEFAULT_TAB = {
  type: WorkspaceTabFeatureName.CONTENT,
  slug: WORKSPACE_DEFAULT_TAB_SLUG,
};

export type WorkspaceCurrentTab =
  | WorkspaceTab
  | typeof WORKSPACE_TAB_ANALYTICS
  | typeof WORKSPACE_TAB_DETAILS
  | typeof WORKSPACE_DEFAULT_TAB;

export const WORKSPACE_RESERVED_SLUG_TO_TAB: Record<string, WorkspaceCurrentTab> = {
  [WORKSPACE_TAB_ANALYTICS.slug]: WORKSPACE_TAB_ANALYTICS,
  [WORKSPACE_TAB_DETAILS.slug]: WORKSPACE_TAB_DETAILS,
};

export const isTabFromDB = (tab: WorkspaceCurrentTab): tab is WorkspaceTab => {
  return 'id' in tab;
};

export const isReservedSlug = (slug: string) => {
  return slug === WORKSPACE_TAB_ANALYTICS.slug || slug === WORKSPACE_TAB_DETAILS.slug;
};
