import type { UpdateStepInput } from '@topo-io/graphql';
import { RoadmapType, useUpdateStepMutation } from '@topo-io/graphql';
import { getUpdatedStepCache } from '@/components/roadmap/utils/cache-utils';
import { useWorkspaceContext } from '@/components/workspace/hooks';

export const useUpdateStepMutationWithCache = () => {
  const { isWorkspace } = useWorkspaceContext();
  const [updateStep] = useUpdateStepMutation();

  const updateStepWithCache = ({
    stepId,
    stepData,
  }: {
    stepId: string;
    stepData: Omit<UpdateStepInput, 'roadmapType'>;
  }) => {
    void updateStep({
      variables: {
        stepData: {
          ...stepData,
          roadmapType: isWorkspace ? RoadmapType.Workspace : RoadmapType.WorkspaceTemplate,
        },
        stepId,
      },
      update: (cache) => {
        cache.modify(getUpdatedStepCache({ updateStepData: stepData, stepId }));
      },
    });
  };
  return { updateStepWithCache };
};
