import { t } from '@lingui/macro';
import { useRouter } from 'next/router';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import {
  Box,
  Checkbox,
  DragHandle,
  HStack,
  Spacer,
  ToggleableInput,
  appTheme,
  useMediaQuery,
} from '@topo-io/design-system';
import { TaskStatus, WorkspaceTabFeatureName } from '@topo-io/graphql';
import { useDebouncedCallback, useHoverDirty, useParty } from '@topo-io/hooks';
import { formatDate, isNil, parseDate } from '@topo-io/utils';
import { EditableDate } from '@/components/common/editable-date';
import { DueDate } from '@/components/roadmap/due-date';
import {
  useCreateOnKeypress,
  useCreateTaskMutationWithCache,
  useDeleteOnKeypress,
  useDeleteTaskMutationWithCache,
  useUpdateTaskMutationWithCache,
  useRoadmapContext,
} from '@/components/roadmap/hooks';
import { TaskProgress } from '@/components/roadmap/roadmap-task-drawer/task-progress';
import { TaskStatusTag } from '@/components/roadmap/roadmap-task-drawer/task-status-tag';
import type { RoadmapTask } from '@/components/roadmap/types';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';
import { roadmapTaskUrl } from '@/config';
import { useQueryParamId } from '@/hooks';
import { useSavingStatus } from '@/state/hooks/use-saving-status';
import { Assignees } from './assignees';
import { onTaskDelete } from './on-task-delete';
import { TaskActions } from './task-actions';
import { TaskCommentCount } from './task-comment-count';
import { TaskMenu } from './task-menu';

export interface TaskProps {
  task: RoadmapTask;
  stepId: string;
  previousTaskId?: string;
  isFocused: boolean;
}

const DEBOUNCE_THROTTLE_TIME = 500;
const NEXT_TASK_POSITION = 1;

export const Task: FC<TaskProps> = ({ task, stepId, previousTaskId, isFocused }) => {
  const workspaceId = useQueryParamId();
  const { isPreview, isGuest, isTemplate } = useWorkspaceContext();
  const { getFirstVisibleWorkspaceTabByType } = useWorkspaceTabs();
  const { canEditRoadmap } = useRoadmapContext();
  const { showNotSavedStatus } = useSavingStatus();
  const { updateTaskWithCache } = useUpdateTaskMutationWithCache();
  const { deleteTaskWithCache } = useDeleteTaskMutationWithCache(onTaskDelete(previousTaskId));
  const { createTaskWithCache } = useCreateTaskMutationWithCache();
  const [isLargerThanSmBreakpoint] = useMediaQuery(`(min-width: ${appTheme.breakpoints?.sm ?? 0})`);
  const taskRef = useRef(null);
  const taskInputRef = useRef<HTMLInputElement>(null);
  const checkboxRef = useRef(null!);
  const party = useParty(checkboxRef);
  const isHovering = useHoverDirty(taskRef);
  const [updatedName, setUpdatedName] = useState(task.name ?? '');
  const { replace } = useRouter();

  const date: Date | undefined = useMemo(
    () => (task.dueDate ? parseDate(task.dueDate) : undefined),
    [task.dueDate]
  );
  const onDelete = () => {
    if (updatedName === '') {
      updateDebouncedTask.cancel();
      deleteTaskWithCache({ taskId: task.id, stepId });
    }
  };

  const onCreate = () =>
    createTaskWithCache({ stepId, position: task.position + NEXT_TASK_POSITION });

  useDeleteOnKeypress({
    key: 'Backspace',
    ref: taskInputRef,
    onKeypress: onDelete,
    target: updatedName,
  });

  useCreateOnKeypress({
    key: 'Enter',
    ref: taskInputRef,
    onKeypress: onCreate,
  });

  const updateDebouncedTask = useDebouncedCallback((taskData: RoadmapTask) => {
    updateTaskWithCache({
      stepId,
      taskId: task.id,
      task: {
        ...taskData,
        assignees: task.assignees,
      },
    });
  }, DEBOUNCE_THROTTLE_TIME);

  useEffect(() => {
    // eslint-disable-next-line @topo-io/no-dom-manual-manipulation
    const taskNode = document.getElementById(task.id);
    if (taskNode) {
      taskNode.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTaskStatus = (done: boolean) => {
    if (done) {
      party.trigger();
    }
    updateTaskWithCache({
      stepId,
      taskId: task.id,
      task: {
        ...task,
        done,
      },
    });
  };
  const setTaskName = (name: string) => {
    showNotSavedStatus();
    setUpdatedName(name);
    updateDebouncedTask({
      ...task,
      name,
    });
  };

  const setTaskDueDate = (date: Date | undefined) => {
    showNotSavedStatus();
    updateTaskWithCache({
      stepId,
      taskId: task.id,
      task: {
        ...task,
        assignees: task.assignees,
        dueDate: date ? formatDate(date) : null!,
      },
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTaskName(e.target.value);
  };

  const openDrawer = () => {
    const mutualActionPlanTab = getFirstVisibleWorkspaceTabByType({
      type: WorkspaceTabFeatureName.MUTUAL_ACTION_PLAN,
    });
    if (isNil(mutualActionPlanTab)) {
      return;
    }
    void replace(
      roadmapTaskUrl({
        workspaceId,
        taskId: task.id,
        tabSlug: mutualActionPlanTab.slug,
        mode: { isPreview, isGuest, isTemplate },
      }),
      undefined,
      {
        shallow: true,
      }
    );
  };

  const isTaskHidden = !task.isVisible;

  return (
    <HStack w="full" minH="40px" ref={taskRef} position="relative" data-testid={`task-${task.id}`}>
      {canEditRoadmap && <DragHandle isHovering={isHovering} />}
      <Checkbox
        isDisabled={!canEditRoadmap && !isGuest}
        isChecked={task.done}
        onChange={(state) => setTaskStatus(state.target.checked)}
        ref={checkboxRef}
        {...(isTaskHidden && { opacity: '0.5' })}
      />
      <Box flexGrow={1}>
        <ToggleableInput
          key={`${task.id}-${isFocused}`}
          as="p"
          defaultValue={task.name ?? ''}
          placeholder={t`Task name`}
          readOnly={!canEditRoadmap}
          onChange={onChange}
          onBlur={onChange}
          autoComplete="off"
          data-form-type="other"
          id={task.id}
          data-testid="map-task"
          isHovering={isHovering}
          onClick={openDrawer}
          isEditableByDefault={isFocused}
          ref={taskInputRef}
          readOnlyCursor="pointer"
          {...(isTaskHidden && { opacity: '0.5' })}
        />
      </Box>
      {isLargerThanSmBreakpoint && (
        <>
          <Spacer cursor="pointer" onClick={openDrawer} />
          <HStack>
            {task.status === TaskStatus.ONGOING && (
              <TaskStatusTag
                status={task.status}
                onClick={openDrawer}
                {...(isTaskHidden && { opacity: '0.5' })}
              />
            )}
            <TaskProgress
              completedCount={task.completedSubtasksCount}
              count={task.subtasksCount}
              backgroundColor="gray.30"
              w={8}
              h={2}
              data-testid="task-progress"
              onClick={openDrawer}
              {...(isTaskHidden && { opacity: '0.5' })}
            />
            <TaskActions
              taskId={task.id}
              actions={task.actions}
              {...(isTaskHidden && { opacity: '0.5' })}
            />
            <TaskCommentCount
              count={task.commentCount}
              onClick={openDrawer}
              {...(isTaskHidden && { opacity: '0.5' })}
            />
            <Assignees task={task} stepId={stepId} {...(isTaskHidden && { opacity: '0.5' })} />
            <EditableDate
              date={date}
              setDate={setTaskDueDate}
              readOnly={!canEditRoadmap}
              {...(isTaskHidden && { opacity: '0.5' })}
            >
              <DueDate date={date} done={task.done} />
            </EditableDate>
          </HStack>
        </>
      )}
      {canEditRoadmap && <TaskMenu previousTaskId={previousTaskId} task={task} />}
    </HStack>
  );
};
