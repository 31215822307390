import { t } from '@lingui/macro';
import { useCallback, useState } from 'react';
import type { ComponentProps, FC } from 'react';
import {
  FilesPreview,
  Avatar,
  Block,
  Box,
  HStack,
  Spacer,
  Text,
  VStack,
  useToast,
} from '@topo-io/design-system';
import type { CommentsQuery } from '@topo-io/graphql';
import { useFilesUsagesQuery, useUpdateCommentMutation } from '@topo-io/graphql';
import type { ContentJSON } from '@topo-io/rich-text-editor';
import { formatLocalizedDateTime, isNotEmptyArray, isNotNil } from '@topo-io/utils';
import { CommentEditorProvider } from '@/components/rich-text-editor/providers/comment-editor-provider';
import { useUser } from '@/hooks';
import { CommentActions } from './comment-actions';
import type { CommentEditorBaseProps } from './comment-editor';
import { CommentEditor } from './comment-editor';

interface CommentProps {
  comment: CommentsQuery['comments'][number];
}

const CommentAuthorName = ({ name }: { name: string }) => (
  <Text aria-label="comment-author-name" color="gray.900">
    {name}
  </Text>
);
const CommentPublishedAt = ({ publishedAt }: { publishedAt: string }) => (
  <Text aria-label="comment-published-at" fontSize="sm" color="gray.500">
    {formatLocalizedDateTime(publishedAt)}
  </Text>
);
const CommentJobTitle = ({ jobTitle }: { jobTitle: string | undefined }) => {
  const jobTitleText = isNotNil(jobTitle) ? ` · ${jobTitle}` : '';

  return (
    <Text aria-label="comment-author-job-title" color="gray.900">
      {jobTitleText}
    </Text>
  );
};
export const Comment: FC<CommentProps> = ({ comment }) => {
  const { user, isIdentifiedUser } = useUser();
  const { errorToast } = useToast();
  const { data } = useFilesUsagesQuery({
    variables: { input: { commentId: comment.id } },
  });
  const [updateSectionComment] = useUpdateCommentMutation();
  const [isBeingEdited, setIsBeingEdited] = useState(false);

  const filesPreviews = (data?.filesUsages ?? []).map(({ id: fileId, url, type, name, size }) => ({
    fileId,
    url,
    type,
    name,
    size,
  }));

  const onCommentEdit = () => {
    setIsBeingEdited(true);
  };
  const onCommentCancelEdit = () => {
    setIsBeingEdited(false);
  };

  const onCommentSaveEdition = useCallback(
    async (content: ContentJSON) => {
      const { errors } = await updateSectionComment({
        variables: { input: { commentId: comment.id, content: [content] } },
      });

      if (errors) {
        errorToast({
          title: t`Could not save comment. Please try again`,
        });
      }

      setIsBeingEdited(false);
      return comment.id;
    },
    [comment.id, errorToast, updateSectionComment]
  );

  const isCurrentUserComment = isIdentifiedUser(user) && user.id === comment.author.id;

  const commentParentProps = {
    commentParentId: comment.taskId ?? comment.sectionId,
    commentParentType: isNotNil(comment.taskId) ? 'task' : 'section',
  } as CommentEditorBaseProps;

  const readonlyStyle = { bg: 'gray.10' } satisfies ComponentProps<typeof Block>;
  return (
    <HStack alignItems="start" w="full">
      <Avatar size="sm" name={comment.author.displayName} src={comment.author.picture} />
      <Block p={3} {...(!isBeingEdited && readonlyStyle)} w="full">
        <VStack alignItems="start">
          <Box w="full">
            <HStack>
              <CommentAuthorName name={comment.author.displayName} />
              <CommentJobTitle jobTitle={comment.author.jobTitle} />
              <CommentPublishedAt publishedAt={comment.publishedAt} />
              <Spacer />
              {isCurrentUserComment && (
                <CommentActions commentId={comment.id} onEdit={onCommentEdit} />
              )}
            </HStack>
          </Box>
          <Spacer />
          <CommentEditorProvider>
            <CommentEditor
              state={isBeingEdited ? 'edit' : 'readonly'}
              defaultComment={comment.content}
              onSaveEdition={onCommentSaveEdition}
              onCancelEdition={onCommentCancelEdit}
              {...commentParentProps}
            />
          </CommentEditorProvider>

          {filesPreviews && isNotEmptyArray(filesPreviews) && (
            <FilesPreview files={filesPreviews} />
          )}
        </VStack>
      </Block>
    </HStack>
  );
};
