import { useContext } from 'react';
import { BaseRoutesContext } from '@/components/routing/contexts';

export const useBaseRoutesContext = () => {
  const baseRoutesContext = useContext(BaseRoutesContext);

  if (!baseRoutesContext) {
    throw new Error('Context used outside of its Provider!');
  }

  return baseRoutesContext;
};
