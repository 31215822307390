import type { AvatarProps } from '@topo-io/design-system';
import { Avatar, AvatarGroup } from '@topo-io/design-system';
import type { Member } from '@topo-io/graphql';
import { getDisplayName, isNil } from '@topo-io/utils';

type MemberToDisplay = Partial<
  Pick<Member, 'id' | 'email' | 'firstName' | 'lastName' | 'picture'> & {
    image: string;
  }
>;
export const Members = ({
  members,
  size = 'sm',
}: {
  members: MemberToDisplay[];
  size?: AvatarProps['size'];
}) => {
  if (isNil(members)) {
    return <></>;
  }

  return (
    <AvatarGroup spacing="-10px" max={4} size={size ?? 'sm'}>
      {members.map((user: MemberToDisplay) => {
        const name = getDisplayName(user, user.email ?? '');
        return <Avatar size={size} key={user.id ?? user.email} name={name} src={user?.picture} />;
      })}
    </AvatarGroup>
  );
};
