import { useRouter } from 'next/router';

export const useDebug = () => {
  const { query } = useRouter();

  if (!query?.debug) {
    return false;
  }

  return true;
};
