import { CubeProvider } from '@cubejs-client/react';
import { useMemo, useRef } from 'react';
import { Container, Flex } from '@topo-io/design-system';
import { WorkspaceRole, useMembersQuery } from '@topo-io/graphql';
import { getDisplayName, isNil, isNotNil } from '@topo-io/utils';
import {
  TotalTimeSpent,
  EngagementOverTime,
  MostViewedSections,
  TotalViews,
} from '@/components/analytics/workspace';
import { WorkspaceEngagementScoreCard } from '@/components/engagement-score';
import { WorkspaceActivities } from '@/components/legacy-activity/workspace-activities';
import { useWorkspaceSections } from '@/components/section/hooks/use-workspace-sections';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { cubejsApi } from '@/config';
import type { GuestsMapping, SectionsMapping } from './types';

export const WorkspaceAnalytics = () => {
  const { id: workspaceId } = useWorkspaceContext();
  const analyticsContainerRef = useRef<HTMLDivElement>(null);

  const { data: membersData } = useMembersQuery({
    variables: { workspaceId: workspaceId! },
    skip: isNil(workspaceId),
  });

  const { sections } = useWorkspaceSections({ workspaceId });

  const guestsMapping: GuestsMapping | null = useMemo(() => {
    if (!membersData) return null;
    return membersData.members
      .filter((member) => member.role === WorkspaceRole.VIEWER)
      .reduce((acc: GuestsMapping, member) => {
        acc[member.userProfileId] = {
          id: member.userProfileId,
          displayName: getDisplayName(member, member.email),
          picture: member.picture,
        };
        return acc;
      }, {});
  }, [membersData]);

  const sectionsMapping: SectionsMapping | null = useMemo(() => {
    if (!sections) return null;
    return sections.reduce((acc: SectionsMapping, section) => {
      acc[section.id] = {
        id: section.id,
        title: section.title,
      };
      return acc;
    }, {});
  }, [sections]);

  const getActivitiesContainerHeight = () => {
    if (!analyticsContainerRef.current) return '92vh';
    return `${analyticsContainerRef.current.clientHeight}px`;
  };

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Container maxW="container.xl">
        <Flex gap="12px" mt="8px" flexWrap="wrap">
          <Flex flex="2" flexDirection="column" ref={analyticsContainerRef}>
            <Flex gap="16px" w="full">
              <TotalTimeSpent workspaceId={workspaceId} guestsMapping={guestsMapping} />
              <TotalViews workspaceId={workspaceId} guestsMapping={guestsMapping} />
            </Flex>
            <EngagementOverTime workspaceId={workspaceId} />
            <MostViewedSections
              workspaceId={workspaceId}
              sectionsMapping={sectionsMapping}
              guestsMapping={guestsMapping}
            />
          </Flex>
          <Flex flex="1" maxH={getActivitiesContainerHeight()} flexDirection="column" gap="2">
            {isNotNil(workspaceId) && <WorkspaceEngagementScoreCard workspaceId={workspaceId} />}
            <WorkspaceActivities />
          </Flex>
        </Flex>
      </Container>
    </CubeProvider>
  );
};
