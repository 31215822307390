import { Box } from '@topo-io/design-system';
import { useSidebarWidth } from '@/components/layout/hooks';
import { SidebarContent } from './sidebar-content';

export const Sidebar = ({ referralOpen }: { referralOpen: () => void }) => {
  const { sidebarWidth } = useSidebarWidth();

  return (
    <Box as="section">
      <SidebarContent
        display={{
          base: 'none',
          md: 'block',
        }}
        w={sidebarWidth}
        referralOpen={referralOpen}
      />
    </Box>
  );
};
