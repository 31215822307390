import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Box, Flex, Grid, GridItem } from '@topo-io/design-system';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { getFirstElement } from '@topo-io/utils';
import { useWorkspaceTabSections } from '@/components/section/hooks';
import { scrollToSection } from '@/components/section/utils';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';
import { SectionLibraryItem } from '@/components/workspace/overview/section-library-item';

const EMPTY_ARRAY_LENGTH = 0;

export const TableOfContent: FC = () => {
  const { visibleSections } = useWorkspaceContext();
  const activeSection = getFirstElement(visibleSections);
  const { tabId } = useWorkspaceTabs();
  const { sections } = useWorkspaceTabSections({ workspaceTabId: tabId });

  const sectionsFiltered = useMemo(() => sections.filter((section) => section.show), [sections]);

  const SectionsList = ({ sections }: { sections: SectionJSON[] }) => {
    const clickOnSection = (sectionId: string) => {
      scrollToSection(sectionId);
    };

    return (
      <Grid templateRows="1fr">
        <GridItem />
        <GridItem p={4} mt={8} w="full" h="full" overflowY="auto">
          <Box fontWeight="semibold" paddingBottom={4}>
            <Trans>TABLE OF CONTENT</Trans>
          </Box>
          {sections.length === EMPTY_ARRAY_LENGTH && (
            <Box color="gray.300">
              <Trans>No content</Trans>
            </Box>
          )}
          <Flex direction="column" gap="8px" w="90%" h="full">
            {sectionsFiltered.map((section) => (
              <SectionLibraryItem
                key={`table-of-content-${section.id}`}
                sectionId={section.id}
                title={section.title}
                isActive={activeSection === section.id}
                onClick={() => clickOnSection(section.id)}
                show
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
    );
  };

  return <SectionsList sections={sections} />;
};
