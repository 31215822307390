import { Trans } from '@lingui/macro';
import { Button, Flex, Icon } from '@topo-io/design-system';

interface CoverRepositionActionsProps {
  onCancel: () => void;
  onSave: () => void;
}

export const CoverRepositionActions: React.FC<CoverRepositionActionsProps> = ({
  onCancel,
  onSave,
}) => {
  return (
    <Flex
      position="absolute"
      right="8px"
      top="8px"
      bgColor="white"
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.20"
    >
      <Button variant="ghost" color="gray.300" size="sm" fontSize="md" onClick={onCancel}>
        <Trans>Cancel</Trans>
      </Button>
      <Button
        size="sm"
        variant="ghost"
        color="gray.500"
        fontSize="md"
        leftIcon={<Icon icon="Check" width="16px" height="16px" />}
        onClick={onSave}
      >
        <Trans>Save position</Trans>
      </Button>
    </Flex>
  );
};
