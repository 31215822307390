import { t } from '@lingui/macro';
import { useMemo } from 'react';
import type { FC } from 'react';
import { Ellipsis, appTheme, useMediaQuery } from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';

interface SectionTemplateTitleProps {
  title: string | undefined;
}

export const SectionTemplateTitle: FC<SectionTemplateTitleProps> = ({ title }) => {
  const [isLargerThanLBreakpoint] = useMediaQuery(`(min-width: ${appTheme.breakpoints?.lg ?? 0})`);
  const maxTitleLength = useMemo(
    () => (isLargerThanLBreakpoint ? 40 : 20),
    [isLargerThanLBreakpoint]
  );
  const displayedTitle = isNil(title) || title === '' ? t`Untitled section` : title;

  return <Ellipsis text={displayedTitle} maxTextLength={maxTitleLength} />;
};
