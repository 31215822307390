import { Tab } from '@topo-io/design-system';

export const WorkspaceTabTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Tab
      color="gray.500"
      fontWeight="500"
      fontSize="md"
      lineHeight="1"
      whiteSpace="nowrap"
      _active={{ backgroundColor: 'gray.20' }}
      _selected={{
        color: 'primary.500',
        backgroundColor: 'primary.50',
      }}
      borderRadius="md"
      py="3"
    >
      {children}
    </Tab>
  );
};
