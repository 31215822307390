import type { FC, PropsWithChildren } from 'react';
import { Box, Skeleton } from '@topo-io/design-system';

interface TooltipBoxProps extends PropsWithChildren {
  isLoading?: boolean;
}

export const TooltipBox: FC<TooltipBoxProps> = ({ children, isLoading }) => {
  return (
    <Skeleton isLoaded={!isLoading} startColor="gray.80" endColor="gray.200">
      <Box bgColor="rgba(244, 245, 247, 0.10)" paddingX="3" paddingY="1" borderRadius="8px">
        {children}
      </Box>
    </Skeleton>
  );
};
