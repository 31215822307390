import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { QuickStartMenu, TextToolbar, LinkToolbar } from '@topo-io/rich-text-editor';
import type { ContentJSON } from '@topo-io/rich-text-editor';
import { SectionEditorProvider } from '@/components/rich-text-editor/providers/section-editor-provider';
import { WorkspaceContext } from '@/components/workspace/contexts';
import { useOverviewContext } from '@/hooks';
import { useSavingStatus } from '@/state/hooks/use-saving-status';
import type { SimpleEditorProps } from './simple-editor';
import { SimpleEditor } from './simple-editor';

const MIN_WAIT = 1000;
const MAX_WAIT = 10000;

export interface SectionEditorProps extends Omit<SimpleEditorProps, 'onChange'> {
  setContent: (content: ContentJSON) => void;
  sectionContentId?: string;
  sectionId: string;
}

export const SectionEditor: FC<SectionEditorProps> = ({
  sectionId,
  initialContent,
  setContent,
  readOnly,
  shouldFocusContent,
  sectionContentId,
}) => {
  const { sectionIdToRerender } = useContext(WorkspaceContext);
  const { showNotSavedStatus } = useSavingStatus();
  const { variables, filesUsages } = useOverviewContext();
  const onChange = useDebouncedCallback(setContent, MIN_WAIT, { maxWait: MAX_WAIT });

  const [editorContent, setEditorContent] = useState(initialContent);

  // Hack to refresh the editor content when the content prop changes (when section is unsynced)
  useEffect(() => {
    if (sectionIdToRerender && sectionIdToRerender === sectionId) {
      setEditorContent(initialContent);
    }
  }, [sectionIdToRerender, sectionId, initialContent]);

  const onChangeJson = (json: ContentJSON) => {
    showNotSavedStatus();
    onChange(json);
  };

  const sectionFilesUsages = useMemo(
    () =>
      (filesUsages ?? []).filter((fileUsage) => fileUsage.sectionContentId === sectionContentId),
    [filesUsages, sectionContentId]
  );

  return (
    <SectionEditorProvider
      sectionContentId={sectionContentId}
      filesUsages={sectionFilesUsages}
      variables={variables}
    >
      <SimpleEditor
        key={JSON.stringify(editorContent)}
        initialContent={editorContent}
        readOnly={readOnly}
        onChange={onChangeJson}
        shouldFocusContent={shouldFocusContent}
      >
        <QuickStartMenu />

        <TextToolbar />
        <LinkToolbar />
      </SimpleEditor>
    </SectionEditorProvider>
  );
};
