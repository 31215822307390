import { Trans } from '@lingui/macro';
import type { ColumnDef } from '@tanstack/react-table';
import type { SectionTemplateSearchRecord } from '@topo-io/types';
import { formatLocalizedDate, getDisplayName } from '@topo-io/utils';
import { TemplateListTagCell } from '@/components/common';
import { SectionTemplateListActionCell } from './section-template-list-action-cell';
import { SectionTemplateTitle } from './section-template-title';

type SectionTemplateListColumns = ColumnDef<SectionTemplateSearchRecord>[];

interface TemplateListColumnsOptions {
  onTemplateDeletionSuccess: (id: string) => void;
}

export const buildSectionTemplateListColumns = (
  props: TemplateListColumnsOptions
): SectionTemplateListColumns => {
  const { onTemplateDeletionSuccess } = props;
  return [
    {
      id: 'title',
      header: () => <Trans>Template</Trans>,
      accessorFn: ({ title }) => title,
      cell: (cell) => <SectionTemplateTitle title={cell.row.original.title ?? undefined} />,
    },
    {
      id: 'owner',
      header: () => <Trans>Owner</Trans>,
      accessorFn: ({ owner }) => getDisplayName(owner),
    },
    {
      id: 'tags',
      header: () => <Trans>Tags</Trans>,
      meta: { hasTooltip: true },
      cell: (cell) => <TemplateListTagCell tags={cell.row.original.tags} />,
    },
    {
      id: 'used',
      header: () => <Trans>Used</Trans>,
      accessorFn: ({ usedInWorkspacesCount }) => usedInWorkspacesCount,
    },
    {
      id: 'updatedAt',
      header: () => <Trans>Last updated</Trans>,
      enableSorting: true,
      accessorFn: ({ updatedAt }) => (updatedAt ? formatLocalizedDate(updatedAt) : '-'),
    },
    {
      id: 'actions',
      meta: { isClickable: false },
      header: undefined,
      cell: (cell) => (
        <SectionTemplateListActionCell
          id={cell.row.original.id}
          ownerId={cell.row.original.owner.id}
          onDeleteSuccess={() => {
            onTemplateDeletionSuccess(cell.row.original.id);
          }}
        />
      ),
    },
  ];
};
