import { t } from '@lingui/macro';
import type { UserProfileObject } from '@topo-io/graphql';
import { ZERO, divide, getDisplayName, isNil } from '@topo-io/utils';
import type { UseGlobalAnalyticsValue } from '@/components/analytics/hooks';
import type {
  GuestsMapping,
  MetricsTotalAverages,
  UserMetricsWithAverage,
  UserMetricsWithoutAverage,
} from '@/components/analytics/types';

const getFixedValueOrZeroFromString = (value?: string): number => {
  if (isNil(value)) return ZERO;
  return Number(Number(value).toFixed(1));
};

const getUserDataForPeriod = ({
  userProfile,
  period,
}: {
  period: UseGlobalAnalyticsValue;
  userProfile: UserProfileObject;
}) => {
  const totalActiveWorkspaces = period.totalActiveWorkspaces[userProfile.id];
  const totalSharedWorkspaces = period.totalSharedWorkspaces[userProfile.id];
  const totalGuests = period.totalGuests[userProfile.id];
  const totalViews = period.totalViews[userProfile.id];
  const totalTimeSpent = period.totalTimeSpent[userProfile.id];

  return {
    totalActiveWorkspaces: getFixedValueOrZeroFromString(totalActiveWorkspaces),
    totalSharedWorkspaces: getFixedValueOrZeroFromString(totalSharedWorkspaces),
    totalGuests: getFixedValueOrZeroFromString(totalGuests),
    totalViews: getFixedValueOrZeroFromString(totalViews),
    totalTimeSpent: isNil(totalTimeSpent) ? ZERO : Number(totalTimeSpent),
  };
};

export const getUserMetricsWithoutAverage = ({
  userProfile,
  currentPeriodMetrics,
  previousPeriodMetrics,
}: {
  userProfile: UserProfileObject;
  currentPeriodMetrics: UseGlobalAnalyticsValue;
  previousPeriodMetrics?: UseGlobalAnalyticsValue;
}): UserMetricsWithoutAverage => {
  const currentPeriod = getUserDataForPeriod({
    userProfile,
    period: currentPeriodMetrics,
  });
  const previousPeriod = previousPeriodMetrics
    ? getUserDataForPeriod({
        userProfile,
        period: previousPeriodMetrics,
      })
    : null;

  const getMetric = (metricKey: keyof UseGlobalAnalyticsValue) => {
    return {
      currentPeriod: currentPeriod[metricKey],
      previousPeriod: previousPeriod?.[metricKey] ?? ZERO,
    };
  };

  return {
    totalActiveWorkspaces: getMetric('totalActiveWorkspaces'),
    totalSharedWorkspaces: getMetric('totalSharedWorkspaces'),
    totalGuests: getMetric('totalGuests'),
    totalViews: getMetric('totalViews'),
    totalTimeSpent: getMetric('totalTimeSpent'),
  };
};

export const getUserMetricsWithAverage = ({
  userProfile,
  currentPeriodMetrics,
  previousPeriodMetrics,
}: {
  userProfile: UserProfileObject;
  currentPeriodMetrics: UseGlobalAnalyticsValue;
  previousPeriodMetrics?: UseGlobalAnalyticsValue;
}): UserMetricsWithAverage => {
  const currentPeriod = getUserDataForPeriod({
    userProfile,
    period: currentPeriodMetrics,
  });
  const previousPeriod = previousPeriodMetrics
    ? getUserDataForPeriod({
        userProfile,
        period: previousPeriodMetrics,
      })
    : null;

  return {
    userDisplayName: getDisplayName(userProfile, userProfile.user.email),
    totalActiveWorkspaces: {
      currentPeriod: currentPeriod.totalActiveWorkspaces,
      previousPeriod: previousPeriod?.totalActiveWorkspaces ?? ZERO,
    },
    totalSharedWorkspaces: {
      currentPeriod: currentPeriod.totalSharedWorkspaces,
      previousPeriod: previousPeriod?.totalSharedWorkspaces ?? ZERO,
    },
    averageGuests: {
      currentPeriod: divide(currentPeriod.totalGuests, currentPeriod.totalActiveWorkspaces),
      previousPeriod: divide(
        previousPeriod?.totalGuests ?? ZERO,
        previousPeriod?.totalActiveWorkspaces ?? ZERO
      ),
    },
    averageViews: {
      currentPeriod: divide(currentPeriod.totalViews, currentPeriod.totalActiveWorkspaces),
      previousPeriod: divide(
        previousPeriod?.totalViews ?? ZERO,
        previousPeriod?.totalActiveWorkspaces ?? ZERO
      ),
    },
    averageTimeSpent: {
      currentPeriod: divide(currentPeriod.totalTimeSpent, currentPeriod.totalActiveWorkspaces),
      previousPeriod: divide(
        previousPeriod?.totalTimeSpent ?? ZERO,
        previousPeriod?.totalActiveWorkspaces ?? ZERO
      ),
    },
    picture: userProfile.picture,
  };
};

const getUsersMetricsSums = (metrics: UserMetricsWithoutAverage[]) => {
  const getMetricPeriodsTotal = (metricKey: keyof UserMetricsWithoutAverage) => {
    return metrics.reduce(
      (acc, metric) => {
        acc.currentPeriod += metric[metricKey].currentPeriod;
        acc.previousPeriod += metric[metricKey].previousPeriod ?? ZERO;
        return acc;
      },
      { currentPeriod: ZERO, previousPeriod: ZERO }
    );
  };

  const totalWorkspacesShared = getMetricPeriodsTotal('totalSharedWorkspaces');
  const totalActivesWorkspaces = getMetricPeriodsTotal('totalActiveWorkspaces');
  const totalGuests = getMetricPeriodsTotal('totalGuests');
  const totalViews = getMetricPeriodsTotal('totalViews');
  const totalTimeSpent = getMetricPeriodsTotal('totalTimeSpent');

  return {
    totalWorkspacesShared,
    totalActivesWorkspaces,
    totalGuests,
    totalViews,
    totalTimeSpent,
  };
};

export const getMetricsTotalAverages = ({
  usersMetricsWithoutAverage,
  displayedEditorsCount,
}: {
  usersMetricsWithoutAverage: UserMetricsWithoutAverage[];
  displayedEditorsCount: number;
}): MetricsTotalAverages => {
  const sums = getUsersMetricsSums(usersMetricsWithoutAverage);

  const { totalActivesWorkspaces, totalGuests, totalTimeSpent, totalViews, totalWorkspacesShared } =
    sums;

  return {
    averageSharedWorkspaces: {
      currentPeriod: divide(totalWorkspacesShared.currentPeriod, displayedEditorsCount),
      previousPeriod: divide(totalWorkspacesShared.previousPeriod, displayedEditorsCount),
    },
    averageActiveWorkspaces: {
      currentPeriod: divide(totalActivesWorkspaces.currentPeriod, displayedEditorsCount),
      previousPeriod: divide(totalActivesWorkspaces.previousPeriod, displayedEditorsCount),
    },
    averageGuests: {
      currentPeriod: divide(totalGuests.currentPeriod, totalActivesWorkspaces.currentPeriod),
      previousPeriod: divide(totalGuests.previousPeriod, totalActivesWorkspaces.previousPeriod),
    },
    averageViews: {
      currentPeriod: divide(totalViews.currentPeriod, totalActivesWorkspaces.currentPeriod),
      previousPeriod: divide(totalViews.previousPeriod, totalActivesWorkspaces.previousPeriod),
    },
    averageTimeSpent: {
      currentPeriod: divide(totalTimeSpent.currentPeriod, totalActivesWorkspaces.currentPeriod),
      previousPeriod: divide(totalTimeSpent.previousPeriod, totalActivesWorkspaces.previousPeriod),
    },
  };
};

export const ALL_ANONYMOUS_USERS_ID = 'all_anonymous_users';
export const getGuestOrMergedAnonymousUser = ({
  guestsMapping,
  userId,
}: {
  guestsMapping: GuestsMapping | null;
  userId?: string;
}) => {
  const anonymousUser = {
    id: ALL_ANONYMOUS_USERS_ID,
    displayName: t`Anonymous users`,
    picture: '',
  };
  if (isNil(userId)) {
    return anonymousUser;
  }
  const user = guestsMapping?.[userId];
  if (isNil(user)) {
    return anonymousUser;
  }
  return user;
};
