import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { WorkspaceTabFeatureName } from '@topo-io/graphql';
import { useWorkspaceTabs } from '@/components/workspace/hooks';

export const useIsTask = () => {
  const { query } = useRouter();
  const { getFirstVisibleWorkspaceTabByType } = useWorkspaceTabs();

  const { isTaskPage, taskId } = useMemo(() => {
    const workspaceTab = getFirstVisibleWorkspaceTabByType({
      type: WorkspaceTabFeatureName.MUTUAL_ACTION_PLAN,
    });
    const isTaskPage = query?.tab?.[0] === workspaceTab?.slug && query?.tab?.[1] === 'tasks';
    const taskId = isTaskPage ? query?.tab?.[2] : undefined;

    return {
      isTaskPage,
      taskId,
    };
  }, [query?.tab, getFirstVisibleWorkspaceTabByType]);

  return {
    isTaskPage,
    taskId,
  };
};
