import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  useDisclosure,
} from '@topo-io/design-system';

interface TaskDeletionConfirmationModalProps {
  onDeletion: () => void;
}

export const TaskDeletionButton: FC<TaskDeletionConfirmationModalProps> = ({ onDeletion }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Icon
        icon="trash"
        cursor="pointer"
        h={6}
        w={6}
        onClick={onOpen}
        aria-label="Delete task button"
        strokeWidth={1.5}
      />

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Trans>Delete task</Trans>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Trans>
                By deleting this task, you will also lose its description, subtasks, files and
                comments.
              </Trans>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" ref={cancelRef} onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button colorScheme="red" onClick={onDeletion} ml={3} data-testid="confirm-deletion">
                <Trans>Delete</Trans>
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
