import type { UpdateWorkspaceTabInput } from '@topo-io/graphql';
import { useUpdateWorkspaceTabMutation } from '@topo-io/graphql';
import { getNextPositionForCache, isNil } from '@topo-io/utils';
import type { WorkspaceTab } from '@/components/workspace/tabs/workspace-tab-type';

export const useUpdateWorkspaceTabWithCache = () => {
  const [updateWorkspaceTabMutation] = useUpdateWorkspaceTabMutation();

  const updateWorkspaceTabWithCache = ({
    workspaceTab,
    input,
    workspaceTabs,
  }: {
    workspaceTab: WorkspaceTab;
    input: UpdateWorkspaceTabInput;
    workspaceTabs: WorkspaceTab[];
  }) => {
    void updateWorkspaceTabMutation({
      variables: {
        id: workspaceTab.id,
        input,
      },
      optimisticResponse: {
        updateWorkspaceTab: {
          ...workspaceTab,
          ...input,
        },
      },
      update: (cache) => {
        cache.modify({
          fields: {
            tabs() {
              const modifiedTab = workspaceTabs.find((tab) => tab.id === workspaceTab.id);
              if (isNil(modifiedTab)) {
                return;
              }
              const nextPosition = getNextPositionForCache({
                previousPosition: modifiedTab.position,
                newPosition: input.position ?? modifiedTab.position,
              });
              const updatedTabs = [...workspaceTabs]
                .map((tab, index) => {
                  if (tab.id === workspaceTab.id) {
                    return {
                      ...tab,
                      name: input.name ?? tab.name,
                      isDisplayed: input.isDisplayed ?? tab.isDisplayed,
                      position: nextPosition,
                      hasRestrictedAccess: input.hasRestrictedAccess ?? tab.hasRestrictedAccess,
                    };
                  }
                  return {
                    ...tab,
                    position: index,
                  };
                })
                .sort((a, b) => a.position - b.position)
                .map((category, index) => ({ ...category, position: index }));

              return updatedTabs;
            },
          },
        });
      },
    });
  };
  return { updateWorkspaceTabWithCache };
};
