import { useMemo } from 'react';
import { Tag, Icon, forwardRef } from '@topo-io/design-system';
import { formatShortDate } from '@topo-io/utils';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';

const ONE_WEEK = 7;

const dateColor = (date: Date, done: boolean): string => {
  const today = new Date();
  const nextWeek = new Date();
  nextWeek.setDate(today.getDate() + ONE_WEEK);

  if (done) {
    return 'green.100';
  } else if (date < today && !done) {
    return 'red.100';
  } else if (date < nextWeek && !done) {
    return 'orange.100';
  } else {
    return 'gray.20';
  }
};

interface DueDateProps {
  date?: Date;
  done: boolean;
}

export const DueDate = forwardRef<DueDateProps, 'div'>(function DueDate(props, ref) {
  const { date, done, ...otherProps } = props;
  const { isGuest } = useWorkspaceContext();

  const tagContent = useMemo(() => {
    if (date) {
      return formatShortDate(date);
    }
    return (
      <Icon
        icon={isGuest ? 'CalendarMinus' : 'CalendarPlus'}
        height="20px"
        width="20px"
        cursor="pointer"
      />
    );
  }, [date, isGuest]);

  return (
    <Tag ref={ref} bgColor={date ? dateColor(date, done) : 'unset'} tabIndex={0} {...otherProps}>
      {tagContent}
    </Tag>
  );
});
