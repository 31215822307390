/* eslint-disable @typescript-eslint/no-explicit-any,complexity,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access */
import { produce } from 'immer';
import type { SearchGraph, SearchNode } from './types';
import { isSearchFilter } from './types';

const isArray = (value: unknown): value is unknown[] => Array.isArray(value);
const isEmptyArray = (value: unknown[]): boolean => value.length === 0;
const isOnlyChildArray = (value: unknown[]): boolean => value.length === 1;
const isObject = (value: unknown): value is Record<string, unknown> =>
  typeof value === 'object' && Boolean(value);
const isEmptyObject = (value: Record<string, unknown>): boolean =>
  !isObject(value) || isEmptyArray(Object.keys(value));

/**
 * Recursively cleans empty logical operators (OR, AND, NOT) from an object.
 * If an operator has no children or a single child, it is removed or replaced by the child, respectively.
 *
 * @param obj The object to clean.
 * @returns The cleaned object with unnecessary operators removed.
 */
export const cleanEmptyOperators = (obj: any): any => {
  if (!isObject(obj)) {
    // Return non-object values unchanged
    return obj;
  }

  const newObj = produce(obj, (draft) => {
    Object.entries(draft).forEach(([key, value]) => {
      if (isArray(value)) {
        // Clean array values recursively and filter out empty objects
        const newValue = value.map(cleanEmptyOperators).filter((child) => !isEmptyObject(child));

        if (['OR', 'AND', 'NOT'].includes(key)) {
          if (isEmptyArray(newValue)) {
            // Remove the operator if its array is empty after cleaning
            delete draft[key];
          } else if (isOnlyChildArray(newValue)) {
            // If the operator has a single child, replace the operator with that child
            // First, clean the single child recursively
            const cleanedChild = cleanEmptyOperators(newValue[0]);
            delete draft[key]; // Remove the original operator key

            if (!isEmptyObject(cleanedChild)) {
              // Assign the cleaned child's properties to the current level if it's not empty
              Object.entries(cleanedChild).forEach(([childKey, childValue]) => {
                draft[childKey] = childValue;
              });
            }
          } else {
            // Assign cleaned non-empty children back to the operator
            draft[key] = newValue;
          }
        }
      } else if (isObject(value)) {
        // Recursively clean nested objects
        draft[key] = cleanEmptyOperators(value);
      }
    });
  });

  // Return an empty object if the result is an empty object, otherwise return the cleaned object
  return isEmptyObject(newObj) ? {} : newObj;
};

const transformFilterWithOperators = (graph: SearchGraph): Record<string, any> => {
  const processNode = (node: SearchNode): Record<string, any> | undefined => {
    if (!node.enabled) return undefined;

    if (isSearchFilter(node)) {
      const attributePath = node.attribute.split('.');
      const lastAttribute = attributePath.pop(); // Retire et garde le dernier élément pour l'opérateur
      const nestedObject = attributePath.reduceRight((acc, curr) => ({ [curr]: acc }), {
        [lastAttribute!]: { [node.operator]: node.value },
      });
      return nestedObject;
    } else {
      const queryPart: Record<string, any[]> = { [node.operator]: [] };
      const children = graph.filter((child) => child.parentNodeKey === node.key);
      children.forEach((child) => {
        const childQuery = processNode(child);
        if (childQuery) {
          queryPart[node.operator].push(childQuery);
        }
      });
      return queryPart;
    }
  };

  const rootNodes = graph.filter((node) => !node.parentNodeKey);
  if (rootNodes.length === 1) {
    return processNode(rootNodes[0]) ?? {};
  } else {
    const processedNodes = rootNodes
      .map(processNode)
      .filter((node): node is Record<string, any> => node !== undefined);
    if (processedNodes.every((node) => Object.keys(node).length === 1 && (node.OR || node.AND))) {
      return { AND: processedNodes };
    } else {
      return processedNodes.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    }
  }
};

export const transformFilters = (graph: SearchGraph): Record<string, any> => {
  return cleanEmptyOperators(transformFilterWithOperators(graph));
};
