import { t } from '@lingui/macro';
import { useState } from 'react';
import { Flex, Icon, Input, Tooltip } from '@topo-io/design-system';
import { getLastElement, isBlank, isNil } from '@topo-io/utils';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';

export const NavbarAddWorkspaceTab = () => {
  const { createTab, workspaceTabs } = useWorkspaceTabs();
  const { id, isTemplate, isWorkspace } = useWorkspaceContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [tabName, setTabName] = useState('');

  const toggleEditMode = () => {
    setIsEditMode((previous) => !previous);
  };

  const handleBlur = async () => {
    if (isNil(tabName) || isBlank(tabName)) {
      setIsEditMode(false);
      return;
    }
    const lastPosition = getLastElement(workspaceTabs)?.position ?? 0;

    await createTab({
      name: tabName,
      position: lastPosition + 1,
      ...(isWorkspace && { workspaceId: id }),
      ...(isTemplate && { workspaceTemplateId: id }),
    });
    setIsEditMode(false);
    setTabName('');
  };

  return (
    <Flex display="flex" alignItems="center">
      {isEditMode ? (
        <Input
          w="full"
          variant="unstyled"
          size="md"
          autoFocus
          onChange={(event) => setTabName(event.target.value)}
          onBlur={handleBlur}
        />
      ) : (
        <Tooltip label={t`Add tab`} fontSize="md" placement="bottom">
          <Flex alignItems="center" cursor="pointer" mx="16px" onClick={toggleEditMode}>
            <Icon
              data-testid="add-tab-icon"
              icon="plus-circle"
              color="primary.500"
              w="24px"
              h="24px"
            />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};
