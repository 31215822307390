import type { FC } from 'react';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { isNil } from '@topo-io/utils';
import { SectionReadonlyPreviewMode } from '@/components/section';

interface WorkspaceContentReadonlyPreviewModeProps {
  content: SectionJSON[];
}

export const WorkspaceContentReadonlyPreviewMode: FC<WorkspaceContentReadonlyPreviewModeProps> = ({
  content,
}) => {
  return (
    <>
      {content.map((section) => {
        if (isNil(section.content)) {
          return null;
        }
        return (
          <SectionReadonlyPreviewMode
            key={section.id}
            id={section.id}
            templateId={section.templateId}
            show={section.show}
            title={section.title}
            sectionContent={section.content[0]}
            numberOfComments={section.numberOfComments}
            sectionContentId={section.sectionContentId}
          />
        );
      })}
    </>
  );
};
