import { Trans, t } from '@lingui/macro';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Button, SimpleModal } from '@topo-io/design-system';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';

interface DesynchronizeSectionFromTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  sectionId: string;
  sectionTemplateId: string;
}

export const DesynchronizeSectionFromTemplateModal: FC<
  DesynchronizeSectionFromTemplateModalProps
> = ({ sectionTemplateId, sectionId, onClose, isOpen }) => {
  const { tabId } = useWorkspaceTabs();
  const { desynchronizeSection } = useContent(tabId);
  const onClickDesynchronize = useCallback(() => {
    desynchronizeSection({ templateId: sectionTemplateId, sectionId });
    onClose();
  }, [desynchronizeSection, onClose, sectionId, sectionTemplateId]);

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title={t`Unsync section template?`}
      footer={
        <Button colorScheme="red" onClick={onClickDesynchronize}>
          <Trans>Unsync</Trans>
        </Button>
      }
    >
      <Trans>
        By unsyncing this section, you will lose the link with the template. This section will
        become editable.
      </Trans>
    </SimpleModal>
  );
};
