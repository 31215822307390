import { useCallback } from 'react';
import { TemplatesDocument, useDeleteWorkspaceTemplateMutation } from '@topo-io/graphql';

export const useDeleteWorkspaceTemplate = (id: string) => {
  const [deleteWorkspaceTemplate, { loading }] = useDeleteWorkspaceTemplateMutation();

  const callback = useCallback(() => {
    return deleteWorkspaceTemplate({
      variables: { id },
      refetchQueries: [TemplatesDocument],
    });
  }, [deleteWorkspaceTemplate, id]);

  return { deleteWorkspaceTemplate: callback, loading };
};
