import { Avatar } from '@topo-io/design-system';
import { getDisplayName } from '@topo-io/utils';
import type { Person } from '@topo-io/utils';

interface SidebarProfilePictureProps {
  user: Person | null;
  isExpanded?: boolean;
}

export const SidebarProfilePicture = ({ user, isExpanded }: SidebarProfilePictureProps) => {
  const name = getDisplayName(user, 'Anonymous');
  const picture = user?.picture ?? undefined;

  return (
    <Avatar
      name={name}
      bg="primary.500"
      color={'white'}
      fontSize={'small'}
      size={isExpanded ? 'sm' : 'md'}
      src={picture}
    />
  );
};
