import { Trans, t } from '@lingui/macro';
import {
  Menu,
  useDisclosure,
  Flex,
  MenuButton,
  IconButton,
  Icon,
  MenuList,
  MenuItem,
} from '@topo-io/design-system';
import { DeleteSectionTemplateModal } from '@/components/section/modals';
import { NewTemplateModal } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { useSubmitNewSectionTemplate } from '@/components/workspace/common/new-template-modal/use-submit-new-section-template';
import { useUser } from '@/hooks';

interface ActionProps {
  id: string;
  ownerId: string;
  onDeleteSuccess?: () => void;
}

export const SectionTemplateListActionCell = ({ id, ownerId, onDeleteSuccess }: ActionProps) => {
  const {
    isOpen: isOpenDuplicate,
    onClose: onCloseDuplicate,
    onOpen: onOpenDuplicate,
  } = useDisclosure();
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const { getSubmitDuplicateSectionTemplate } = useSubmitNewSectionTemplate();
  const { user, isAnonymousUser } = useUser();

  const canDeleteSectionTemplate = !isAnonymousUser(user) && user?.id === ownerId;

  return (
    <>
      <Flex m="0" alignItems="center" alignContent="flex-end" justifyContent="flex-end">
        <Menu variant="default">
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                variant="ghost"
                colorScheme="gray"
                icon={<Icon icon="more-vertical" h="16px" w="16px" color="gray.100" />}
              />
              <MenuList>
                <MenuItem
                  icon={<Icon icon="copy" position="relative" top="-1px" />}
                  onClick={onOpenDuplicate}
                >
                  <Trans>Duplicate</Trans>
                </MenuItem>
                {canDeleteSectionTemplate && (
                  <MenuItem
                    icon={<Icon icon="trash" position="relative" top="-1px" />}
                    color="red.500"
                    onClick={onOpenDelete}
                  >
                    <Trans>Delete</Trans>
                  </MenuItem>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
      <NewTemplateModal
        title={t`Duplicate section template`}
        duplicate
        originalTemplateId={id}
        isOpen={isOpenDuplicate}
        onClose={onCloseDuplicate}
        onSubmit={getSubmitDuplicateSectionTemplate(id)}
      />
      <DeleteSectionTemplateModal
        sectionTemplateId={id}
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onSuccess={onDeleteSuccess}
      />
    </>
  );
};
