import type { MutableRefObject } from 'react';
import type { KeyFilter } from '@topo-io/hooks';
import { useKey } from '@topo-io/hooks';

export const useCreateOnKeypress = ({
  key,
  ref,
  onKeypress,
}: {
  key: KeyFilter;
  ref: MutableRefObject<unknown>;
  onKeypress: () => void;
}) => {
  useKey(key, (event) => handleKeypress(event), {}, [ref?.current]);

  const handleKeypress = (event: KeyboardEvent) => {
    const isSameElement = event.target === ref?.current;
    if (isSameElement) {
      onKeypress();
    }
  };
};
