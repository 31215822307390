import { Trans } from '@lingui/macro';
import type { ColumnDef } from '@tanstack/react-table';
import type { WorkspaceTemplateSearchRecord } from '@topo-io/types';
import { formatLocalizedDate, getDisplayName } from '@topo-io/utils';
import { TemplateListTagCell } from '@/components/common';
import { WorkspaceTitle } from '@/components/workspace/list/workspace-title';
import { TemplateListActionsCell } from './template-list-actions-cell';

type WorkspaceTemplateListColumns = ColumnDef<WorkspaceTemplateSearchRecord>[];

interface TemplateListColumnsOptions {
  onTemplateDeletionSuccess: (id: string) => void;
}

export const buildTemplateListColumns = ({
  onTemplateDeletionSuccess,
}: TemplateListColumnsOptions): WorkspaceTemplateListColumns => {
  const columns: WorkspaceTemplateListColumns = [
    {
      id: 'title',
      header: () => <Trans>Template</Trans>,
      enableSorting: true,
      accessorFn: ({ title }) => title,
      cell: (props) => <WorkspaceTitle<true> {...props.row.original} isTemplate />,
    },
    {
      id: 'owner',
      header: () => <Trans>Owner</Trans>,
      accessorFn: ({ owner }) => getDisplayName(owner),
    },
    {
      id: 'tags',
      header: () => <Trans>Tags</Trans>,
      meta: { hasTooltip: true },
      cell: (cell) => <TemplateListTagCell tags={cell.row.original.tags} />,
    },
    {
      id: 'used',
      header: () => <Trans>Used</Trans>,
      accessorFn: ({ usedInWorkspacesCount }) => usedInWorkspacesCount,
    },
    {
      id: 'updatedAt',
      header: () => <Trans>Last updated</Trans>,
      enableSorting: true,
      accessorFn: ({ updatedAt }) => (updatedAt ? formatLocalizedDate(updatedAt) : '-'),
    },
    {
      id: 'actions',
      meta: { isClickable: false },
      header: undefined,
      cell: (cell) => (
        <TemplateListActionsCell
          id={cell.row.original.id}
          ownerId={cell.row.original.owner.id}
          tagIds={cell.row.original.tags.map((t) => t.id)}
          onDeleteSuccess={() => onTemplateDeletionSuccess(cell.row.original.id)}
        />
      ),
    },
  ];

  return columns;
};
