import { Trans, t } from '@lingui/macro';
import { useState } from 'react';
import { Button, H6, Input, SimpleModal, Text } from '@topo-io/design-system';
import { TabsDocument, useDeleteWorkspaceTabMutation } from '@topo-io/graphql';
import type { WorkspaceTab } from '@/components/workspace/tabs';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workspaceTab: WorkspaceTab | null;
}

export const DeleteWorkspaceTabModal: React.FC<Props> = ({ isOpen, onClose, workspaceTab }) => {
  const [confirmTabName, setConfirmTabName] = useState('');
  const [deleteWorkspaceTabMutation, { loading }] = useDeleteWorkspaceTabMutation({
    refetchQueries: [TabsDocument],
  });

  const handleDeleteTab = async () => {
    if (!workspaceTab) {
      return;
    }
    await deleteWorkspaceTabMutation({
      variables: {
        id: workspaceTab.id,
      },
    });
    onClose();
  };

  if (!workspaceTab) {
    return null;
  }

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      footer={
        <Button
          isDisabled={confirmTabName !== workspaceTab.name}
          isLoading={loading}
          colorScheme="red"
          onClick={handleDeleteTab}
        >
          <Trans>Delete</Trans>
        </Button>
      }
    >
      <H6 mt="1">
        <Trans>Delete tab?</Trans>
      </H6>
      <Text mt="2" color="gray.500">
        <Trans>By deleting this tab, you will also delete the sections in it.</Trans>
      </Text>
      <Text mt="6">
        <Trans>Type &quot;{workspaceTab.name}&quot; to delete</Trans>
      </Text>
      <Input
        mt="2"
        placeholder={t`Enter the name`}
        onChange={(event) => setConfirmTabName(event.target.value)}
        isInvalid={confirmTabName !== workspaceTab.name}
      />
    </SimpleModal>
  );
};
