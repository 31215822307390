import { useCreateTaskMutation } from '@topo-io/graphql';
import { getCreatedTaskCache } from '@/components/roadmap/utils/cache-utils';

export const useCreateTaskMutationWithCache = () => {
  const [createTask] = useCreateTaskMutation();

  const createTaskWithCache = ({ stepId, position }: { stepId: string; position: number }) => {
    void createTask({
      variables: {
        taskData: { stepId, position },
      },
      update: (cache) => {
        cache.modify(getCreatedTaskCache({ stepId }));
      },
    });
  };
  return { createTaskWithCache };
};
