import { t } from '@lingui/macro';
import router, { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { isString } from '@topo-io/utils';
import type { SectionTemplateTabType } from '@/components/section/tabs/section-tab-types';
import {
  SECTION_TAB_TO_SLUG,
  SECTION_TEMPLATE_TAB_NAMES,
} from '@/components/section/tabs/section-tab-types';
import { SectionTab } from '@/components/section/tabs/section-tab-types';
import { sectionTemplateUrl } from '@/config';

const DEFAULT_TAB: SectionTemplateTabType = SECTION_TEMPLATE_TAB_NAMES[0];

export const useSectionTemplateTabs = () => {
  const { query } = useRouter();
  const { id } = query;

  const queryTab = useMemo(
    () => (query.tab instanceof Array ? query.tab[0] : query.tab),
    [query.tab]
  );

  const tab = useMemo(() => {
    const currentQueryTab = Object.keys(SECTION_TAB_TO_SLUG).find(
      (key) => SECTION_TAB_TO_SLUG[key] === queryTab
    );
    return currentQueryTab ? (currentQueryTab as SectionTemplateTabType) : DEFAULT_TAB;
  }, [queryTab]);

  const onChangeTab = useCallback(
    (index: number) => {
      if (!id || !isString(id)) {
        return;
      }
      const nextTab = SECTION_TEMPLATE_TAB_NAMES[index] ?? DEFAULT_TAB;
      const nextTabSlug = SECTION_TAB_TO_SLUG[nextTab];
      const pathname = sectionTemplateUrl(id, nextTabSlug);
      void router.replace({ pathname });
    },
    [id]
  );

  const getTabFormattedMessage = useCallback((tabName: SectionTemplateTabType) => {
    switch (tabName) {
      case SectionTab.CONTENT:
        return t`Overview`;
      case SectionTab.DETAILS:
        return t`Template details`;
      default:
        return '';
    }
  }, []);

  const visibleTabs = SECTION_TEMPLATE_TAB_NAMES;

  return { tab, onChangeTab, getTabFormattedMessage, visibleTabs };
};
