import { t } from '@lingui/macro';
import { VariableType } from '@topo-io/constants';

export type VariableMapping = Record<
  string,
  {
    type: VariableType;
    value: string | null;
    name: string;
    label: string;
  }
>;

export const getVariableName = (type: VariableType) => {
  const mapping = {
    [VariableType.GuestFirstName]: t`guest.firstname`,
    [VariableType.GuestLastName]: t`guest.lastname`,
    [VariableType.GuestCompany]: t`guest.company`,
    [VariableType.OwnerFirstName]: t`owner.firstname`,
    [VariableType.OwnerLastName]: t`owner.lastname`,
    [VariableType.OwnerCompany]: t`owner.company`,
    [VariableType.OwnerJobTitle]: t`owner.jobtitle`,
    [VariableType.OwnerEmail]: t`owner.email`,
  };
  return mapping[type];
};

export const getVariableLabel = (type: VariableType) => {
  const mapping = {
    [VariableType.GuestFirstName]: t`Guest first name`,
    [VariableType.GuestLastName]: t`Guest last name`,
    [VariableType.GuestCompany]: t`Guest company`,
    [VariableType.OwnerFirstName]: t`Your first name`,
    [VariableType.OwnerLastName]: t`Your last name`,
    [VariableType.OwnerCompany]: t`Your company`,
    [VariableType.OwnerJobTitle]: t`Your job title`,
    [VariableType.OwnerEmail]: t`Your email`,
  };
  return mapping[type];
};
