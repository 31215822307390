import type { ToastFn } from '@topo-io/design-system';
import { getApolloClient } from '@topo-io/graphql';
import type { ApolloOptions } from '@topo-io/graphql';
import { getOrganizationSlug } from './organization';
import { all } from './secrets';

const { ENV } = all;
const isTestingEnvironment = ['development', 'staging'].includes(ENV);

export const initApolloClient = (
  url: string,
  errorToast?: ToastFn,
  jwtToken?: string,
  slug?: string
) => {
  const organizationSlug = slug ?? getOrganizationSlug();

  const options: ApolloOptions = {
    connectToDevTools: isTestingEnvironment,
    errorHandler: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions, message, path }) => {
          if (extensions.errorKey === 'unauthorized-error') {
            // TODO: should be fixed in the future
            // errorToast?.({
            //   title:
            //     'You are seem to have connection problems. Please disconnect and connect again.',
            //   duration: 10000,
            // });
          } else {
            console.warn(
              `[GraphQL error]: Message: ${message}, Path: ${path?.toString()}, ErrorKey: ${extensions.errorKey?.toString()}`
            );
          }
        });
      }

      if (networkError) {
        console.warn(`[Network error]: ${networkError?.message}`);
      }
    },
    headers: {
      ...(organizationSlug && { 'X-ORG': organizationSlug }),
      ...(jwtToken && { Authorization: `Bearer ${jwtToken}` }),
    },
  };
  try {
    return getApolloClient(url, options);
  } catch (error) {
    console.error(error);
    return null;
  }
};
