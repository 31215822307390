import { Trans } from '@lingui/macro';
import { Flex, Icon, Text } from '@topo-io/design-system';
import { useLocalStorage } from '@topo-io/hooks';

export const SectionsSummaryInfoBox = () => {
  const [value, setValue] = useLocalStorage('sections-summary-info-box', true);
  if (!value) return null;

  return (
    <Flex
      backgroundColor="primary.50"
      borderRadius="8px"
      padding="8px"
      fontSize="sm"
      gap="5px"
      alignItems="flex-start"
    >
      <Icon color="primary.600" icon="InfoCircle" w="24px" h="24px" />
      <Text lineHeight="16px" color="primary.600">
        <Trans>You can show/hide the sections, or change their order.</Trans>
      </Text>
      <Icon icon="Close" w="16px" h="16px" cursor="pointer" onClick={() => setValue(false)} />
    </Flex>
  );
};
