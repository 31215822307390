import type { ChakraComponent } from '@topo-io/design-system';
import { SidebarLink } from './sidebar-link';
import { SidebarMenuLabel } from './sidebar-menu-icon';
import { SidebarRow } from './sidebar-row';

interface SidebarItemProps {
  icon: string;
  url?: string;
  label: string;
  active?: boolean;
  expanded?: boolean;
  badgeNumber?: number;
  isExternalLink?: boolean;
}

export const SidebarItem: ChakraComponent<'div', SidebarItemProps> = ({
  icon,
  label,
  url,
  active,
  expanded,
  badgeNumber,
  isExternalLink = false,
  ...rest
}) => {
  return (
    <SidebarLink url={url} isExternal={isExternalLink}>
      <SidebarRow
        {...rest}
        height="40px"
        px={expanded ? '4px' : '0px'}
        my="4px"
        w="full"
        bg={active ? 'gray.800' : 'transparent'}
        color={active ? 'gray.10' : 'gray.50'}
        justifyContent="flex-start"
        gap="1"
      >
        <SidebarMenuLabel
          icon={icon}
          label={label}
          active={false}
          expanded={expanded}
          badgeNumber={badgeNumber}
        />
      </SidebarRow>
    </SidebarLink>
  );
};
