import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useRef, useState, useCallback } from 'react';
import { Box, HStack, Icon, Text, Tooltip } from '@topo-io/design-system';
import { useIsTextOverflow } from '@topo-io/hooks';
import { useContent, useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';

const activeStyle = {
  borderColor: 'primary.500',
  color: 'primary.500',
  backgroundColor: 'primary.50',
};

const hoverStyle = {
  backgroundColor: 'gray.30',
  borderColor: 'gray.30',
  color: 'gray.900',
};
const dragActiveStyle = {
  transform: 'rotate(-4deg)',
};

export const SectionLibraryItem: FC<{
  sectionId: string;
  title: string;
  show: boolean;
  onClick: () => void;
  isActive: boolean;
  isDragging?: boolean;
}> = ({ sectionId, title, show, onClick, isActive, isDragging }) => {
  const { readOnly } = useWorkspaceContext();
  const { tabId } = useWorkspaceTabs();
  const { toggleSectionVisibility } = useContent(tabId);

  const boxRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const isOverflowing = useIsTextOverflow({ containerRef: boxRef, text: title });

  const onClickToggleVisibility = useCallback(
    () => toggleSectionVisibility({ sectionId, show }),
    [show, sectionId, toggleSectionVisibility]
  );

  return (
    <HStack
      w="full"
      borderRadius="md"
      p="2"
      cursor="pointer"
      opacity={!show ? '0.4' : '1'}
      {...(isActive && activeStyle)}
      {...(isHovering && hoverStyle)}
      {...(isActive && isDragging && dragActiveStyle)}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      aria-label={`section-library-item-${title}`}
    >
      <Box
        ref={boxRef}
        flexGrow="1"
        textOverflow={!readOnly ? 'ellipsis' : 'initial'}
        whiteSpace={!readOnly ? 'nowrap' : 'initial'}
        overflow="hidden"
        userSelect="none"
      >
        {isOverflowing && !readOnly ? (
          <Tooltip label={title} placement="top">
            <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              {title}
            </Text>
          </Tooltip>
        ) : (
          title || <Trans>Untitled section</Trans>
        )}
      </Box>
      {(isHovering || !show) && !readOnly && (
        <Box>
          <Icon
            icon={show ? 'eye' : 'eye-off'}
            aria-label={`icon-show-${show ? 'eye' : 'eye-off'}`}
            position="relative"
            w="16px"
            h="16px"
            onClick={(event) => {
              event.stopPropagation();
              onClickToggleVisibility();
            }}
          />
        </Box>
      )}
    </HStack>
  );
};
