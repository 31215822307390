import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { matchRules } from '@topo-io/utils';
import { sectionTemplateRoutes } from '@/config';

export const useIsSectionTemplate = () => {
  const { pathname } = useRouter();
  const isSection = useMemo(() => matchRules(pathname, sectionTemplateRoutes), [pathname]);
  return isSection;
};
