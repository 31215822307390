import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  useDisclosure,
} from '@topo-io/design-system';
import {
  RoadmapType,
  WorkspaceTabFeatureName,
  useCommentsQuery,
  useRoadmapContentLazyQuery,
} from '@topo-io/graphql';
import { isNil, isNotNil } from '@topo-io/utils';
import { WriteComment } from '@/components/comments';
import { TaskProvider } from '@/components/roadmap/contexts/task-context';
import { useIsTask } from '@/components/roadmap/hooks/use-is-task'; // Avoiding circular dep by directly importing the file defining the hook.
import { useTask } from '@/components/roadmap/hooks/use-task';
import { TaskFiles } from '@/components/roadmap/roadmap-task-drawer/task-files';
import { useWorkspaceTabs } from '@/components/workspace/hooks';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context'; // Avoiding circular dep by directly importing the file defining the hook.
import { workspaceTemplateUrl, workspaceUrl } from '@/config';
import { useDevice, useQueryParamId } from '@/hooks';
import { SavingStatusProvider } from '@/state/providers';
import { TaskCommentList } from './task-comment-list';
import { TaskDescription } from './task-description';
import { TaskHeader } from './task-header';
import { TaskInformation } from './task-information';
import { TaskSubtaskList } from './task-subtask-list';

export const RoadmapTaskDrawer: FC = () => {
  const workspaceId = useQueryParamId();
  const { isMobile } = useDevice();
  const { isPreview, isGuest, isTemplate, isWorkspace } = useWorkspaceContext();
  const { getFirstVisibleWorkspaceTabByType } = useWorkspaceTabs();
  const { replace } = useRouter();
  const { isTaskPage, taskId } = useIsTask();
  const { task } = useTask(taskId);
  const [key, setKey] = useState(uuid());
  const { isOpen, onClose: closeDrawer } = useDisclosure({ isOpen: isTaskPage });
  const [fetchRoadmapContent] = useRoadmapContentLazyQuery();

  const { data } = useCommentsQuery({
    variables: { input: { taskId: taskId! } },
    skip: isNil(taskId),
  });

  const handleCloseDrawer = () => {
    void fetchRoadmapContent({
      variables: {
        input: {
          workspaceId,
          type: isWorkspace ? RoadmapType.Workspace : RoadmapType.WorkspaceTemplate,
        },
      },
      fetchPolicy: 'network-only',
    });
    closeDrawer();
    if (isTemplate) {
      const tab = getFirstVisibleWorkspaceTabByType({
        type: WorkspaceTabFeatureName.MUTUAL_ACTION_PLAN,
        displayedTabsOnly: isPreview,
      });
      const url = workspaceTemplateUrl(workspaceId, tab?.slug, { isPreview });
      void replace(url, undefined, {
        shallow: true,
      });
    } else {
      const tab = getFirstVisibleWorkspaceTabByType({
        type: WorkspaceTabFeatureName.MUTUAL_ACTION_PLAN,
        displayedTabsOnly: isPreview || isGuest,
      });
      const url = workspaceUrl(workspaceId, tab?.slug, { isPreview, isGuest });
      void replace(url, undefined, {
        shallow: true,
      });
    }
  };

  const comments = data?.comments ?? [];
  const shouldShowComments = isWorkspace;

  const onCreateComment = () => {
    setKey(uuid());
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleCloseDrawer}
      size={isMobile ? 'full' : 'lg'}
      autoFocus={false}
    >
      {isNotNil(taskId) && isNotNil(task) && (
        <SavingStatusProvider>
          <TaskProvider taskId={taskId}>
            <DrawerOverlay data-testid="drawer-task-overlay" />
            <DrawerContent pt={6} overflowY="auto" overflowX="hidden">
              <TaskHeader task={task} onClose={handleCloseDrawer} key={task.id} />
              <DrawerBody>
                <TaskInformation task={task} />
                <TaskDescription task={task} />
                <TaskFiles task={task} />
                <TaskSubtaskList task={task} />
                {shouldShowComments && <TaskCommentList comments={comments} />}
              </DrawerBody>
              {shouldShowComments && (
                <DrawerFooter bg="gray.20">
                  <WriteComment
                    key={key}
                    onCreate={onCreateComment}
                    commentParentId={taskId}
                    commentParentType="task"
                    shouldFocusContent={false}
                  />
                </DrawerFooter>
              )}
            </DrawerContent>
          </TaskProvider>
        </SavingStatusProvider>
      )}
    </Drawer>
  );
};
