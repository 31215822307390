import { Trans } from '@lingui/macro';
import { Box, Button, Flex, Icon, Text } from '@topo-io/design-system';

export const BrandingPreview = () => {
  return (
    <Box
      bgColor="gray.10"
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.30"
      padding="6"
      pointerEvents="none"
    >
      <Flex alignItems="center" bgColor="white" padding="4" gap="6">
        <Flex alignItems="center" gap="2">
          <Icon icon="chevron-left" w="14px" h="14px" />
          <Trans>Back</Trans>
        </Flex>
        <Text borderRadius="md" paddingY="2" paddingX="4" bgColor="primary.50" color="primary.600">
          <Trans>Overview</Trans>
        </Text>
        <Text>
          <Trans>Mutual Action plan</Trans>
        </Text>
      </Flex>

      <Button mt="4" leftIcon={<Icon icon="send" />}>
        <Trans>Share</Trans>
      </Button>

      <Box mt="4" bgColor="white" padding="4">
        <Text fontSize="large" fontWeight="medium">
          Introduction
        </Text>

        <Text mt="4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas
          vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum
          quisquam
        </Text>
      </Box>
    </Box>
  );
};
