import { Trans, t } from '@lingui/macro';
import type { UploadedFile, useDisclosure } from '@topo-io/design-system';
import {
  useToast,
  FileUploadModal,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@topo-io/design-system';
import {
  FileUsageUsage,
  useCreateFileMutation,
  useCreateFileUsageMutation,
} from '@topo-io/graphql';
import { isNil, isNotNil } from '@topo-io/utils';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useQueryParamId } from '@/hooks';

interface CoverHoverActionsProps {
  fileUploadModalDisclosure: ReturnType<typeof useDisclosure>;
  onRemove: () => void;
  onReposition: () => void;
  onReplace: () => void;
}

export const CoverHoverActions: React.FC<CoverHoverActionsProps> = ({
  fileUploadModalDisclosure,
  onRemove,
  onReposition,
  onReplace,
}) => {
  const { errorToast } = useToast();

  const { isTemplate } = useWorkspaceContext();
  const relatedId = useQueryParamId();

  const [createFileMutation] = useCreateFileMutation();
  const [createFileUsageMutation] = useCreateFileUsageMutation();

  const createFile = async ({
    ext,
    type,
    checksum,
    name,
    size,
  }: {
    ext: string;
    type: string;
    checksum: string;
    name: string;
    size: number;
  }) => {
    const { data, errors } = await createFileMutation({
      variables: {
        input: {
          type,
          checksum,
          ext,
          name,
          size,
        },
      },
    });
    if (isNotNil(errors) || isNil(data)) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }
    return data.createFile;
  };

  const createFileUsage = async (fileId: string) => {
    const { data, errors } = await createFileUsageMutation({
      variables: {
        input: {
          fileId,
          usage: FileUsageUsage.COVER,
          ...(isTemplate
            ? { relatedType: 'workspaceTemplate', relatedId }
            : { relatedType: 'workspace', relatedId }),
        },
      },
    });
    if (isNotNil(errors) || isNil(data)) {
      errorToast({
        title: t`An error occurred while uploading your file`,
      });
      return;
    }
    return data.createFileUsage.id;
  };

  const handleUploadCompleted = (uploadedFile: UploadedFile) => {
    void createFileUsage(uploadedFile.fileId);
    fileUploadModalDisclosure.onClose();
    onReplace();
  };

  return (
    <Box display="flex" position="absolute" right="8px" top="8px" bgColor="white" borderRadius="md">
      <Menu variant="default" placement="left-start">
        {({ isOpen }) => (
          <>
            <Flex alignItems="center">
              <FileUploadModal
                isOpen={fileUploadModalDisclosure.isOpen}
                onClose={fileUploadModalDisclosure.onClose}
                onUploadCompleted={handleUploadCompleted}
                accept="image"
                createFile={createFile}
              />
              <Button
                _hover={{ bg: 'gray.20' }}
                color="gray.300"
                bg="white"
                variant="ghost"
                gap={2}
                size="sm"
                fontSize="md"
                leftIcon={<Icon icon="Refresh" w="16px" h="16px" />}
                borderRadius="8"
                borderWidth="1px"
                borderColor="gray.20"
                borderRightRadius="0"
                onClick={fileUploadModalDisclosure.onOpen}
              >
                <Trans>Replace</Trans>
              </Button>

              <MenuButton
                isActive={isOpen}
                as={IconButton}
                size="sm"
                variant="outline"
                colorScheme="gray"
                border="none"
                borderLeftRadius="0"
                _hover={{ bg: 'white' }}
                px="3"
                icon={<Icon icon="more-horizontal" color="gray.500" w="16px" h="16px" />}
              />
            </Flex>
            <Portal>
              <MenuList borderRadius="12" p="2">
                <MenuItem
                  _hover={{ bg: 'gray.20' }}
                  p="1.5"
                  icon={<Icon icon="Reposition" position="relative" w="16px" h="16px" top="-1px" />}
                  onClick={onReposition}
                >
                  <Trans>Reposition</Trans>
                </MenuItem>

                <MenuItem
                  _hover={{ bg: 'gray.20' }}
                  p="1.5"
                  icon={<Icon icon="Trash" position="relative" w="16px" h="16px" top="-1px" />}
                  color="red.500"
                  onClick={onRemove}
                >
                  <Trans>Remove</Trans>
                </MenuItem>
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </Box>
  );
};
