import { Plural, t } from '@lingui/macro';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Box, Flex, Grid, Text } from '@topo-io/design-system';
import { useMembersQuery } from '@topo-io/graphql';
import type { MembersQuery } from '@topo-io/graphql';
import { isEmptyArray, isNotEmptyArray } from '@topo-io/utils';
import { DetailsLogo } from '@/components/workspace/common/details-logo';
import { useWorkspace } from '@/components/workspace/hooks/use-workspace';
import { useQueryParamId } from '@/hooks';
import { MemberCard } from './member-card';

const MEMBER_CARD_MIN_WIDTH = 220;

type Member = MembersQuery['members'][number];

interface MemberListByCompanyProps {
  members: Member[];
  logo: ReactNode;
}

const MembersListByCompany: FC<MemberListByCompanyProps> = ({ members, logo }) => {
  return (
    <>
      <Flex alignItems="center" gap="2" mt="8">
        {logo}
        <Text color="gray.500">
          <Plural value={members.length} one="# member" other="# members" />
        </Text>
      </Flex>

      <Grid
        mt="4"
        gap="2"
        gridTemplateColumns={`repeat(auto-fill, minmax(${MEMBER_CARD_MIN_WIDTH}px, 1fr))`}
      >
        {members.map((member) => (
          <MemberCard key={member.id} member={member} />
        ))}
      </Grid>
    </>
  );
};

export const MemberList = () => {
  const id = useQueryParamId();
  const { workspace } = useWorkspace(id);
  const { data, loading } = useMembersQuery({ variables: { workspaceId: id } });
  const members = useMemo(() => data?.members ?? [], [data]);

  const { owner, company } = workspace ?? {};

  const { companyMembers, accountMembers, externalMembers } = useMemo(() => {
    const companyMembers: Member[] = [];
    const accountMembers: Member[] = [];
    const externalMembers: Member[] = [];

    members.forEach((member) => {
      if (member.company?.id === company?.id) {
        accountMembers.push(member);
      } else if (member.company?.id === owner?.company?.id) {
        companyMembers.push(member);
      } else {
        externalMembers.push(member);
      }
    });

    return { companyMembers, accountMembers, externalMembers };
  }, [company, members, owner]);

  if (loading && isEmptyArray(members)) {
    return null;
  }

  return (
    <Box>
      <Box data-testid="company-members">
        <MembersListByCompany
          logo={
            <DetailsLogo
              companyName={owner?.company?.name}
              companyLogoUrl={owner?.company?.logoUrl}
              accountName=""
              showTextBetweenCompanies={false}
            />
          }
          members={companyMembers}
        />
      </Box>

      <Box data-testid="account-members">
        <MembersListByCompany
          logo={
            <DetailsLogo
              accountName={company?.name}
              accountLogoUrl={company?.logoUrl}
              showTextBetweenCompanies={false}
            />
          }
          members={accountMembers}
        />
      </Box>

      {isNotEmptyArray(externalMembers) && (
        <Box data-testid="external-members">
          <MembersListByCompany
            logo={<DetailsLogo accountName={t`Externals`} showTextBetweenCompanies={false} />}
            members={externalMembers}
          />
        </Box>
      )}
    </Box>
  );
};
