import type { FC, PropsWithChildren } from 'react';
import { Box, H6 } from '@topo-io/design-system';

interface PageSectionProps {
  title: string;
}

export const PageSection: FC<PropsWithChildren<PageSectionProps>> = ({ title, children }) => {
  return (
    <>
      <H6 mb="4">{title}</H6>
      <Box mb="8" gap="4">
        {children}
      </Box>
    </>
  );
};
