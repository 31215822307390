import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useTabsQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { WorkspaceTabsProvider } from '@/components/workspace/contexts/workspace-tabs-context';
import { useWorkspaceContext } from '@/components/workspace/hooks';

export const WorkspaceTabsContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { id, isTemplate } = useWorkspaceContext();
  const { data, loading } = useTabsQuery({
    variables: {
      input: {
        workspaceId: !isTemplate ? id : undefined,
        workspaceTemplateId: isTemplate ? id : undefined,
      },
    },
    skip: isNil(id),
  });

  const router = useRouter();

  const queryTab = useMemo(
    () => (router.query.tab instanceof Array ? router.query.tab[0] : router.query.tab),
    [router.query.tab]
  );

  const workspaceTabs = useMemo(() => {
    return data ? [...data.tabs] : [];
  }, [data]);

  return (
    <WorkspaceTabsProvider queryTab={queryTab} workspaceTabs={workspaceTabs} loading={loading}>
      {children}
    </WorkspaceTabsProvider>
  );
};
