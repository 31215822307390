import { useUpdateTaskAssigneesMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import type { MemberWithoutRelations } from '@/components/roadmap/task/assignees';
import { getUpdatedAssigneesTaskCache } from '@/components/roadmap/utils/cache-utils';

export const useUpdateTaskAssigneesMutationWithCache = () => {
  const [updateAssignees] = useUpdateTaskAssigneesMutation();

  const updateTaskAssigneesWithCache = ({
    taskId,
    stepId,
    assignee,
  }: {
    taskId: string;
    stepId: string;
    assignee: MemberWithoutRelations;
  }) => {
    if (isNil(assignee.userProfileId)) {
      return;
    }
    void updateAssignees({
      variables: {
        taskId,
        userProfileId: assignee.userProfileId,
      },
      update: (cache, { data }) => {
        const updatedTask = data?.updateTaskAssignees;
        if (isNil(updatedTask)) {
          return;
        }
        cache.modify(getUpdatedAssigneesTaskCache({ updatedTask, stepId, assignee }));
      },
    });
  };
  return { updateTaskAssigneesWithCache };
};
