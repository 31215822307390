import { Trans } from '@lingui/macro';
import { HStack, Icon, Text } from '@topo-io/design-system';

export const TaskActionMenuEmptyState = () => {
  return (
    <HStack cursor="pointer" data-testid="action-empty-state" style={{ marginInlineStart: -3 }}>
      <Icon icon={'ActionIcon'} color="gray.100" h={6} w={6} />
      <Text color="gray.100">
        <Trans>add action</Trans>
      </Text>
    </HStack>
  );
};
