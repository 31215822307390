import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import { Box, HStack, Icon, Text, Tooltip } from '@topo-io/design-system';
import type { MembersQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';

interface MemberSyncIndicatorProps {
  member: MembersQuery['members'][number];
}

export const MemberSyncIndicator = ({ member: { CRMId } }: MemberSyncIndicatorProps) => {
  if (isNil(CRMId)) {
    return (
      <Tooltip label={t`Not synced with CRM. Edit member to sync.`}>
        <Box data-testid="unsynced-indicator">
          <Icon icon="refresh-cw" color="gray.500" />
        </Box>
      </Tooltip>
    );
  }

  return (
    <HStack data-testid="synced-indicator">
      <Icon icon="refresh-cw" color="green.500" />
      <Text color="green.500" fontWeight="semibold" ml={1}>
        <Trans>CRM</Trans>
      </Text>
    </HStack>
  );
};
