import type { PropsWithChildren } from 'react';
import { useMemo, createContext } from 'react';
import type { Variable, FileUsage } from '@topo-io/graphql';

export interface OverviewContextReturnValue {
  filesUsages: FileUsage[];
  variables: Variable[];
}

export type OverviewProviderProps = PropsWithChildren<OverviewContextReturnValue>;

export const OverviewContext = createContext<OverviewContextReturnValue>({
  filesUsages: [],
  variables: [],
});

export const OverviewProvider: React.FC<OverviewProviderProps> = ({
  children,
  filesUsages,
  variables,
}) => {
  const value = useMemo(
    () => ({
      filesUsages,
      variables,
    }),
    [filesUsages, variables]
  );

  return <OverviewContext.Provider value={value}>{children}</OverviewContext.Provider>;
};
