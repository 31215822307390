export const ENGAGEMENT_OVER_TIME_HEIGHT = 230;

export const ENGAGEMENT_OVER_TIME_FAKE_DATA = [
  {
    id: 'engagement',
    color: '#066deb',
    data: [
      {
        x: '2023-07-31',
        y: 0,
      },
      {
        x: '2023-08-01',
        y: 20,
      },
      {
        x: '2023-08-02',
        y: 10,
      },
      {
        x: '2023-08-03',
        y: 15,
      },
      {
        x: '2023-08-04',
        y: 50,
      },
      {
        x: '2023-08-05',
        y: 30,
      },
      {
        x: '2023-08-06',
        y: 60,
      },
      {
        x: '2023-08-07',
        y: 40,
      },
      {
        x: '2023-08-08',
        y: 55,
      },
    ],
  },
];
