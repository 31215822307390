import type { BinaryOperator, Filter } from '@cubejs-client/core';
import { Trans, t } from '@lingui/macro';
import type { BarDatum } from '@nivo/bar';
import type { FC } from 'react';
import { useState, useMemo } from 'react';
import { CUBE_CONSTANTS } from '@topo-io/constants';
import {
  Box,
  Text,
  H5,
  HStack,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Button,
  Icon,
} from '@topo-io/design-system';
import { useTruthyOnce } from '@topo-io/hooks';
import { isEmptyArray, isNil, isNotEmptyArray, isNotNil, isString } from '@topo-io/utils';
import type { AnalyticsGuest, GuestsMapping, SectionsMapping } from '@/components/analytics/types';
import { getGuestOrMergedAnonymousUser, getSectionTitle } from '@/components/analytics/utils';
import { useAnalyticsQuery } from '@/hooks';
import { MostViewedSectionsEmptyState } from './empty-state';
import { MostViewedSectionsGraph } from './graph';
import { DEFAULT_SECTION_COUNT, MOST_VIEWED_SECTION_BASE_HEIGHT, SECTION_HEIGHT } from './utils';

const ALL_GUEST_ID = 'all-guests';

interface MostViewedSectionsProps {
  workspaceId: string | undefined;
  sectionsMapping: SectionsMapping | null;
  guestsMapping: GuestsMapping | null;
}

export const MostViewedSections: FC<MostViewedSectionsProps> = ({
  workspaceId,
  sectionsMapping,
  guestsMapping,
}) => {
  const [selectedGuestId, setSelectedGuestId] = useState<string | null>(null);

  const filters = useMemo(() => {
    const baseFilters: Filter[] = [
      {
        member: CUBE_CONSTANTS.SECTION_VIEWS_WORKSPACE_ID,
        operator: 'equals' as BinaryOperator,
        values: [workspaceId!],
      },
      {
        member: CUBE_CONSTANTS.SECTION_VIEWS_ENGAGED_TIME,
        operator: 'notEquals' as BinaryOperator,
        values: ['0'],
      },
    ];
    if (selectedGuestId) {
      baseFilters.push({
        member: CUBE_CONSTANTS.SECTION_VIEWS_USER_ID,
        operator: 'equals' as BinaryOperator,
        values: [selectedGuestId],
      });
    }
    return baseFilters;
  }, [selectedGuestId, workspaceId]);

  const { tableData } = useAnalyticsQuery<Record<string, string>>({
    query: {
      measures: [CUBE_CONSTANTS.SECTION_VIEWS_ENGAGED_TIME],
      dimensions: [
        CUBE_CONSTANTS.SECTION_VIEWS_SECTION_ID,
        CUBE_CONSTANTS.SECTION_VIEWS_WORKSPACE_TAB_ID,
        CUBE_CONSTANTS.SECTION_VIEWS_USER_ID,
      ],
      filters,
      order: {
        [CUBE_CONSTANTS.SECTION_VIEWS_ENGAGED_TIME]: 'asc',
      },
    },
    options: {
      skip: isNil(workspaceId),
    },
  });

  const chartData = useMemo(() => {
    if (isEmptyArray(tableData) || !sectionsMapping) {
      return {};
    }
    const userIds = new Set<string>();
    const dataBySection = {};
    tableData.forEach((row) => {
      const sectionId = row[CUBE_CONSTANTS.SECTION_VIEWS_SECTION_ID];
      const userId = row[CUBE_CONSTANTS.SECTION_VIEWS_USER_ID];
      const measure = parseInt(row[CUBE_CONSTANTS.SECTION_VIEWS_ENGAGED_TIME]);
      if (!isString(sectionId) || !isString(userId)) {
        return;
      }
      const user = getGuestOrMergedAnonymousUser({ guestsMapping, userId });
      userIds.add(user.id);
      if (!dataBySection[sectionId]) {
        dataBySection[sectionId] = {
          sectionId,
          sectionTitle: getSectionTitle(sectionsMapping, sectionId),
        };
      }
      if (!dataBySection[sectionId][user.id]) {
        dataBySection[sectionId][user.id] = 0;
      }
      dataBySection[sectionId][user.id] += measure;
    });
    return {
      data: Object.values(dataBySection),
      userIds: Array.from(userIds),
    };
  }, [tableData, sectionsMapping, guestsMapping]);

  const guestFilterOptions = useMemo(() => {
    if (isNil(guestsMapping)) {
      return [];
    }
    const guests: AnalyticsGuest[] = [];
    if (selectedGuestId) {
      guests.push({
        id: ALL_GUEST_ID,
        displayName: t`All guests`,
        picture: '',
      });
    }
    guests.push(...Object.values(guestsMapping));
    return guests;
  }, [guestsMapping, selectedGuestId]);

  const handleOptionClicked = (id: string) => {
    if (id === ALL_GUEST_ID) {
      setSelectedGuestId(null);
      return;
    }
    setSelectedGuestId(id);
  };

  const { data, userIds } = chartData;

  const graphHeight = useMemo(() => {
    const baseHeight = MOST_VIEWED_SECTION_BASE_HEIGHT;
    if (!data?.length || data.length <= DEFAULT_SECTION_COUNT) {
      return baseHeight;
    }
    const extraLines = data.length - DEFAULT_SECTION_COUNT;
    const totalHeight = baseHeight + extraLines * SECTION_HEIGHT;
    return totalHeight;
  }, [data]);

  const shouldDisplayData = useTruthyOnce(
    isNotNil(data) && isNotNil(userIds) && !isEmptyArray(data) && !isEmptyArray(userIds)
  );
  const shouldDisplayGuestFilter = isNotEmptyArray(guestFilterOptions);

  return (
    <Box mt="16px" borderWidth="1px" borderRadius="md" bg="cards.bg.color" padding="6" width="full">
      <HStack w="full">
        <H5>
          <Trans>Most viewed sections</Trans>
        </H5>
        <Spacer />
        {shouldDisplayGuestFilter && (
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<Icon icon="chevron-down" />}
              colorScheme="gray"
              variant="outline"
              disabled={!shouldDisplayData}
            >
              {selectedGuestId ? (
                <Text>
                  {
                    getGuestOrMergedAnonymousUser({
                      guestsMapping,
                      userId: selectedGuestId,
                    }).displayName
                  }
                </Text>
              ) : (
                <Trans>All guests</Trans>
              )}
            </MenuButton>
            <MenuList>
              {guestFilterOptions.map(({ id, displayName, picture }, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => handleOptionClicked(id)}
                    _hover={{ bg: 'gray.20' }}
                    bg={selectedGuestId === id ? 'gray.20' : 'white'}
                  >
                    <Avatar
                      size="sm"
                      key={id}
                      name={displayName}
                      mr="2"
                      bg="primary.500"
                      color="white"
                      src={picture}
                    />
                    {displayName}
                    <Spacer />
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        )}
      </HStack>
      {!shouldDisplayData ? (
        <MostViewedSectionsEmptyState />
      ) : (
        <Box h={`${graphHeight}px`}>
          <MostViewedSectionsGraph
            data={data as BarDatum[] | undefined}
            keys={userIds}
            guestsMapping={guestsMapping}
            sectionsMapping={sectionsMapping}
          />
        </Box>
      )}
    </Box>
  );
};
