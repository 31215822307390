import type { Row } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { Box, DataTable } from '@topo-io/design-system';
import { TableEmptyState } from '@/components/common';
import type {
  EngagementScoreDateRange,
  WorkspaceScoreWithCompany,
} from '@/components/engagement-score/types';
import { workspaceUrl } from '@/config';
import { buildWorkspaceRankedByScoreColumns } from './table-columns';

interface WorkspaceRankedByScoreTableProps {
  data: WorkspaceScoreWithCompany[];
  isLoading: boolean;
  dateRange: EngagementScoreDateRange;
}

const loadingRowExample = {
  workspace: 'Google x Tesla',
  scoring: 99,
  trend: 'represents a graph',
};

export const WorkspaceRankedByScoreTable: FC<WorkspaceRankedByScoreTableProps> = ({
  data,
  isLoading,
  dateRange,
}) => {
  const router = useRouter();
  const columns = useMemo(() => buildWorkspaceRankedByScoreColumns({ dateRange }), [dateRange]);

  const goToWorkspace = useCallback(
    (e: Row<WorkspaceScoreWithCompany>) => {
      const url = workspaceUrl(e.original.workspaceId);
      void router.push(url);
    },
    [router]
  );

  return (
    <Box w="full" bgColor="white" borderRadius="8px">
      <DataTable
        data={data}
        columns={columns}
        loadingState={{
          isLoading,
          loadingRowExample,
          loadingRowsCount: 10,
        }}
        manualSorting={false}
        enableSortingRemoval={false}
        emptyState={<TableEmptyState />}
        withBorder={false}
        onClick={goToWorkspace}
      />
    </Box>
  );
};
