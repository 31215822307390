import type { TemplateQuery } from '@topo-io/graphql';
import { useTemplateQuery } from '@topo-io/graphql';

export const useTemplate = (
  templateId: string,
  options?: {
    skip?: boolean;
  }
): {
  isLoading: boolean;
  template: TemplateQuery['template'] | undefined;
} => {
  const { data, loading: isLoading } = useTemplateQuery({
    variables: { id: templateId },
    skip: options?.skip,
  });
  const template = data?.template;

  return {
    template,
    isLoading,
  };
};
