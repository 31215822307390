import type { FC, PropsWithChildren } from 'react';
import { OnChangeJSON, Placeholder } from '@topo-io/rich-text-editor';
import type { RichTextEditorProps, ContentJSON } from '@topo-io/rich-text-editor';
import { all } from '@/config';
import { RichTextEditorSSR } from './rich-text-editor-ssr';

const { IFRAMELY_API_KEY } = all;

export interface SimpleEditorProps extends RichTextEditorProps {
  initialContent: ContentJSON;
  onChange: (json: ContentJSON) => void;
  readOnly?: boolean;
  placeholder?: string;
  placeholderReadOnly?: string;
}

export const SimpleEditor: FC<PropsWithChildren<SimpleEditorProps>> = ({
  children,
  initialContent,
  onChange,
  readOnly,
  placeholder,
  placeholderReadOnly,
  shouldFocusContent,
}) => {
  return (
    <RichTextEditorSSR
      iframelyApiKey={IFRAMELY_API_KEY}
      initialContent={initialContent}
      readOnly={readOnly}
      shouldFocusContent={shouldFocusContent}
    >
      <OnChangeJSON onChange={onChange} />
      <Placeholder
        content={initialContent}
        placeholder={readOnly ? placeholderReadOnly : placeholder}
      />
      {children}
    </RichTextEditorSSR>
  );
};
