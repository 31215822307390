import { Trans, t } from '@lingui/macro';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  ModalSection,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@topo-io/design-system';
import { useShareSettingsQuery, useUpdateShareSettingsMutation } from '@topo-io/graphql';
import { WorkspacePasswordInput } from '@/components/workspace/common/workspace-password-input';

const MIN_WAIT = 300;
const MAX_WAIT = 10000;

export const ShareSettingsInput = ({ id }: { id: string }) => {
  const [isMagicLinkEnabled, setIsMagicLinkEnabled] = useState<boolean>(false);
  const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);
  const { data, loading } = useShareSettingsQuery({ variables: { workspaceId: id } });
  const [updateShareSettings] = useUpdateShareSettingsMutation();

  const [typeOfShare, setTypeOfShare] = useState('email');

  const debouncedUpdateShareSettings = useDebouncedCallback(
    async () => {
      const shareSettings = {
        isPasswordRequired: isPasswordRequired,
        isEmailRequired: typeOfShare === 'email',
        isMagicLinkEnabled: isMagicLinkEnabled,
        isEmailGateEnabled: typeOfShare === 'gate',
      };
      await updateShareSettings({ variables: { ...shareSettings, workspaceId: id } });
    },
    MIN_WAIT,
    { maxWait: MAX_WAIT }
  );

  useEffect(() => {
    if (!data?.shareSettings) {
      return;
    }

    if (data.shareSettings.isEmailRequired) {
      setTypeOfShare('email');
    } else if (data.shareSettings.isEmailGateEnabled) {
      setTypeOfShare('gate');
    } else {
      setTypeOfShare('none');
    }

    setIsMagicLinkEnabled(data.shareSettings.isMagicLinkEnabled ?? false);
    setIsPasswordRequired(data.shareSettings.isPasswordRequired ?? false);
  }, [data?.shareSettings]);

  const updateRadioButton = (value: string) => {
    setTypeOfShare(value);
    if (value === 'none') {
      setIsMagicLinkEnabled(false);
    }
    void debouncedUpdateShareSettings();
  };

  const radioButtonOptions = [
    {
      value: 'email',
      label: 'Restricted access',
      description: 'Only invited members can access the workspace.',
    },
    {
      value: 'gate',
      label: 'Ask for an email address to access',
      description: 'Ask viewer to enter their email to access the workspace, no invitation needed.',
    },
    {
      value: 'none',
      label: 'Anyone with the link can view',
      description: 'No email required. Comments and analytics are disabled.',
    },
  ];
  return (
    <>
      <ModalSection indent title={t`Share settings`}>
        <Skeleton isLoaded={!loading}>
          <RadioGroup onChange={updateRadioButton} value={typeOfShare}>
            <Stack direction="column" gap="0">
              {radioButtonOptions.map((option, index) => (
                <>
                  <Radio
                    key={index}
                    value={option.value}
                    _checked={{ bg: 'primary.500' }}
                    _focusVisible={{ boxShadow: 'none' }}
                  >
                    <Trans>{option.label}</Trans>
                  </Radio>
                  <Text pl="6" fontSize="sm" color="gray.100">
                    <Trans>{option.description}</Trans>
                  </Text>
                </>
              ))}
            </Stack>
          </RadioGroup>
        </Skeleton>
      </ModalSection>
      <ModalSection title={t`More options`}>
        <Skeleton isLoaded={!loading}>
          <Stack spacing={5} direction="row">
            <Checkbox
              isChecked={isPasswordRequired}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setIsPasswordRequired(event.target.checked);
                void debouncedUpdateShareSettings();
              }}
              data-testid="workspace-password-label"
            >
              <Trans>Require access code</Trans>
            </Checkbox>
            <Checkbox
              isChecked={isMagicLinkEnabled}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setIsMagicLinkEnabled(event.target.checked);
                void debouncedUpdateShareSettings();
              }}
              isDisabled={typeOfShare === 'none'}
            >
              <Flex alignItems="center">
                <Trans>Verify email</Trans>
                <Tooltip
                  label={t`Viewers will be required to confirm their email to access the workspace`}
                  placement="top"
                  shouldWrapChildren
                >
                  <Icon icon="InfoCircle" w="16px" h="16px" ml="1" />
                </Tooltip>
              </Flex>
            </Checkbox>
          </Stack>
          {isPasswordRequired && (
            <Box mt="2">
              <WorkspacePasswordInput id={id} />
            </Box>
          )}
        </Skeleton>
      </ModalSection>
    </>
  );
};
