import { t } from '@lingui/macro';
import { Icon } from '@topo-io/design-system';
import {
  ZERO,
  calculateDateRanges,
  calculatePercentageDiff,
  formatDiff,
  isZero,
} from '@topo-io/utils';
import type { PeriodMetrics } from '@/components/analytics/types';
import { TimePeriod } from '@/components/analytics/types';

export const mapTimePeriodToDateRange = (
  timePeriod?: TimePeriod
): {
  currentRange: string[];
  previousRange: string[];
} | null => {
  if (!timePeriod) return null;
  switch (timePeriod) {
    case TimePeriod.AllTime:
      return null;
    case TimePeriod.Last7Days:
      return calculateDateRanges(7, new Date());
    case TimePeriod.Last30Days:
      return calculateDateRanges(30, new Date());
    case TimePeriod.Last90Days:
      return calculateDateRanges(90, new Date());
    default:
      return null;
  }
};

const PERCENTAGE_DIFFERENCE_THRESHOLD = 20;
export const mapPeriodMetricsToIconAndColor = (
  periodMetrics: PeriodMetrics,
  timePeriod: TimePeriod
) => {
  if (timePeriod === TimePeriod.AllTime) {
    return null;
  }

  const { currentPeriod, previousPeriod } = periodMetrics;
  const percentageDifference = ((currentPeriod - previousPeriod) / previousPeriod) * 100;
  if (percentageDifference > PERCENTAGE_DIFFERENCE_THRESHOLD) {
    return {
      icon: <Icon icon="TrendUp" color="green.600" w="16px" h="16px" aria-label="TrendUp icon" />,
      color: 'green.600',
    };
  }

  if (percentageDifference < -PERCENTAGE_DIFFERENCE_THRESHOLD) {
    return {
      icon: <Icon icon="TrendDown" color="red.500" w="16px" h="16px" aria-label="TrendDown icon" />,
      color: 'red.500',
    };
  }

  return {
    icon: <Icon icon="Equal" color="orange.600" w="16px" h="16px" aria-label="Equal icon" />,
    color: 'orange.600',
  };
};

export const mapTimePeriodToLabel = (timePeriod: TimePeriod) => {
  const mapping = {
    [TimePeriod.Last7Days]: t`Last 7 days`,
    [TimePeriod.Last30Days]: t`Last 30 days`,
    [TimePeriod.Last90Days]: t`Last 90 days`,
    [TimePeriod.AllTime]: t`All the time`,
  };
  return mapping[timePeriod];
};

export const formatDiffBetweenPeriods = ({
  currentPeriod,
  previousPeriod,
  locale,
}: {
  currentPeriod: number;
  previousPeriod: number;
  locale: string;
}) => {
  const countDiff = currentPeriod - previousPeriod;
  const sign = countDiff >= ZERO ? '+' : '-';

  const formattedCountDiff = formatDiff({
    diff: countDiff,
    locale,
  });

  const percentageDiff = calculatePercentageDiff(previousPeriod, currentPeriod);
  const formattedPercentage = formatDiff({
    diff: percentageDiff,
    isPercentage: true,
    locale,
  });

  const formattedPercentageDiff = isZero(percentageDiff) ? '' : `(${formattedPercentage})`;

  return {
    sign,
    formattedCountDiff,
    formattedPercentageDiff,
    countDiff,
  };
};
