import { useDebouncedCallback } from '@topo-io/hooks';
import type { BasicSectionProps } from '@/components/section/types';

const THROTTLE_TIME = 700;

export const useDebouncedUpdateSectionTitle = ({
  id,
  updateSectionTitle,
}: Pick<BasicSectionProps, 'id' | 'updateSectionTitle'>) => {
  const debounced = useDebouncedCallback((value: string) => {
    updateSectionTitle({ sectionId: id, title: value });
  }, THROTTLE_TIME);

  return debounced;
};
