import { t } from '@lingui/macro';
import type { ChangeEvent, FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Icon, Input, InputGroup, InputLeftElement } from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';
import { useSearchQuery } from '@/hooks/use-search-query';

const DEBOUNCE_THROTTLE_TIME = 300;

interface ListSearchInputProps {
  placeholder?: string;
}

export const ListSearchInput: FC<ListSearchInputProps> = ({ placeholder }) => {
  const { search, clear } = useSearchQuery();

  const handleChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (isNil(value) || value === '') {
      clear();
    }

    search(value);
  }, DEBOUNCE_THROTTLE_TIME);

  return (
    <InputGroup borderRadius="md" borderWidth="1px" borderColor="gray.40">
      <InputLeftElement pointerEvents="none">
        <Icon icon="Search" />
      </InputLeftElement>
      <Input
        bg={'white'}
        onChange={handleChange}
        borderWidth={0}
        placeholder={placeholder ?? t`Search for a content...`}
      />
    </InputGroup>
  );
};
