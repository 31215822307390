import { Trans } from '@lingui/macro';
import Image from 'next/image';
import Link from 'next/link';
import { Flex, Text } from '@topo-io/design-system';

export const Footer = () => {
  return (
    <Link href="https://www.topo.io/">
      <Flex gap="2" my="4" display="inline-flex">
        <Text fontWeight="medium">
          <Trans>Create engaging customer experiences with Topo.io</Trans>
        </Text>
        <Image src="/logo.svg" alt="Topo.io" height="20" width="20" />
      </Flex>
    </Link>
  );
};
