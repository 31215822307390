import type { FC } from 'react';
import type { HTMLChakraProps } from '@topo-io/design-system';
import { Divider, HStack, Icon } from '@topo-io/design-system';

interface DividerWithPlusCircleButtonProps extends HTMLChakraProps<'div'> {
  onClick: () => void;
}

export const DividerWithPlusCircleButton: FC<DividerWithPlusCircleButtonProps> = ({ onClick }) => {
  return (
    <HStack h="full" w="full" cursor="pointer" onClick={onClick}>
      <Divider flex="1" w="full" mx="2 !important" />
      <Icon icon="plus-circle" color="gray.50" boxSize="5" mx="0 !important" />
      <Divider flex="1" mx="2 !important" />
    </HStack>
  );
};
