import type { Query } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { useMemo } from 'react';

interface AnalyticsQueryProps {
  query: Query;
  options?: {
    skip?: boolean;
    shouldFillMissingDates?: boolean;
  };
}

export const useAnalyticsQuery = <T>({ query, options }: AnalyticsQueryProps) => {
  const { skip, shouldFillMissingDates } = options ?? {};
  const { resultSet, error, isLoading, refetch } = useCubeQuery(query, {
    skip,
  });

  const tableData = useMemo(() => {
    if (isLoading || error) {
      return [] as T[];
    }
    return (resultSet?.tablePivot({
      ...(shouldFillMissingDates && {
        fillMissingDates: shouldFillMissingDates,
      }),
    }) ?? []) as T[];
  }, [resultSet, isLoading, error, shouldFillMissingDates]);

  return { tableData, error, isLoading, refetch };
};
