import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useMembersQuery } from '@topo-io/graphql';
import { Mentions } from '@topo-io/rich-text-editor';
import { getDisplayName } from '@topo-io/utils';
import { useWorkspaceContext } from '@/components/workspace/hooks';

export const CommentMembersMentions: FC = () => {
  const { id } = useWorkspaceContext();
  const { data } = useMembersQuery({ variables: { workspaceId: id! }, skip: !id });

  const mentions = useMemo(
    () =>
      data?.members.map((member) => ({
        id: member.userProfileId,
        label: getDisplayName(member, member.email),
        picture: member.picture,
      })) ?? [],
    [data?.members]
  );

  return <Mentions hasAvatar mentions={mentions} wrapperTitle={<Trans>Members</Trans>} />;
};
