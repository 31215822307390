import { useEffect, useMemo, useState } from 'react';
import {
  FileUsageUsage,
  VariableParentType,
  WorkspaceTabFeatureName,
  useFilesUsagesQuery,
  useVariablesQuery,
  useWorkspaceQuery,
} from '@topo-io/graphql';
import { SectionCommentsDrawer } from '@/components/section/components/section-comments/section-comments-drawer';
import { useWorkspaceTabSections } from '@/components/section/hooks/use-workspace-tab-sections';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';
import type { Cover } from '@/components/workspace/overview/cover/cover-types';
import { OverviewProvider } from '@/contexts';
import { useQueryParamId } from '@/hooks/use-query-param-id';
import { BaseOverview } from './base-overview';
import { WorkspaceContent } from './workspace-content';

export const WorkspaceOverview = () => {
  const id = useQueryParamId();
  const { readOnly } = useWorkspaceContext();
  const { tab, tabId } = useWorkspaceTabs();
  const { data } = useWorkspaceQuery({ variables: { id } });
  const workspace = useMemo(() => data?.workspace, [data]);
  const [hasCoverError, setHasCoverError] = useState(false);

  const { sections: content } = useWorkspaceTabSections({ workspaceTabId: tabId });

  const { data: filesUsagesData, loading } = useFilesUsagesQuery({
    variables: {
      input: {
        workspaceId: id,
      },
    },
    skip: !id || tab.type !== WorkspaceTabFeatureName.CONTENT,
  });
  const filesUsages = filesUsagesData?.filesUsages;

  const { data: variablesData } = useVariablesQuery({
    variables: {
      parentId: id,
      parentType: VariableParentType.Workspace,
    },
  });
  const variables = variablesData?.variables;

  const cover = useMemo<Cover>(() => {
    if (loading) {
      return {
        url: null,
        metadata: {},
        id: null,
      };
    }
    const fileUsageCover = [...(filesUsages ?? [])]
      .reverse()
      .find((fileUsage) => fileUsage?.usage === FileUsageUsage.COVER);

    return {
      url: fileUsageCover?.url ?? null,
      metadata: fileUsageCover?.metadata ?? {},
      id: fileUsageCover?.id ?? null,
    };
  }, [loading, filesUsages]);

  // Reset state when cover changes
  useEffect(() => {
    setHasCoverError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cover]);

  return (
    <>
      <BaseOverview
        cover={cover}
        workspace={workspace}
        hasCoverError={hasCoverError}
        onCoverError={() => setHasCoverError(true)}
      >
        {content && (
          <OverviewProvider filesUsages={filesUsages ?? []} variables={variables ?? []}>
            <WorkspaceContent content={content} readOnly={readOnly} />
          </OverviewProvider>
        )}
        <SectionCommentsDrawer />
      </BaseOverview>
    </>
  );
};
