import { produce } from 'immer';
import type { WritableDraft } from 'immer/dist/internal';
import type { DeepPartial } from '@topo-io/design-system';
import type { Step, Task, User } from '@topo-io/graphql';
import { isNil, isNonNegative } from '@topo-io/utils';
import type { MemberWithoutRelations } from '@/components/roadmap/task/assignees';
import { removeAtPosition, replaceAtPosition } from './operations';

const getCurrentStepAndIndex = (draft: WritableDraft<Step>[], currentStepId: string) => {
  const currentStepIndex = draft.findIndex((step) => step.id === currentStepId);
  const currentStep = draft[currentStepIndex];

  return { currentStepIndex, currentStep };
};

export const addTaskToRoadmap = ({ state, stepId }: { state: Step[]; stepId: string }) => {
  return produce(state, (draft) => {
    const { currentStep, currentStepIndex } = getCurrentStepAndIndex(draft, stepId);
    if (isNil(currentStep)) {
      return;
    }
    currentStep.tasks.push({} as Task);
    replaceAtPosition(draft, currentStepIndex, currentStep);
  });
};

export const removeTaskFromRoadmap = ({
  state,
  stepId,
  taskId,
}: {
  state: Step[];
  stepId: string;
  taskId: string;
}) => {
  return produce(state, (draft) => {
    const { currentStep, currentStepIndex } = getCurrentStepAndIndex(draft, stepId);
    if (isNil(currentStep)) {
      return;
    }
    const deletedTaskIndex = currentStep.tasks.findIndex((task) => task.id === taskId);
    removeAtPosition(currentStep.tasks, deletedTaskIndex);
    replaceAtPosition(draft, currentStepIndex, currentStep);
  });
};

export const updateTaskInRoadmap = ({
  state,
  stepId,
  updateTaskData,
}: {
  state: Step[];
  stepId: string;
  updateTaskData: DeepPartial<Task>;
}) => {
  return produce(state, (draft) => {
    const { currentStep, currentStepIndex } = getCurrentStepAndIndex(draft, stepId);
    if (isNil(currentStep)) {
      return;
    }
    const updatedTaskIndex = currentStep.tasks.findIndex((task) => task.id === updateTaskData.id);
    const newTask = {
      ...currentStep.tasks[updatedTaskIndex],
      ...updateTaskData,
      assignees: updateTaskData.assignees ?? [],
    } as Task;
    replaceAtPosition(currentStep.tasks, updatedTaskIndex, newTask);
    replaceAtPosition(draft, currentStepIndex, currentStep);
  });
};

export const updateTaskAssigneesInRoadmap = ({
  state,
  stepId,
  updatedTask,
  assignee,
}: {
  state: Step[];
  stepId: string;
  updatedTask: Partial<Task>;
  assignee: MemberWithoutRelations;
}) => {
  return produce(state, (draft) => {
    const { currentStep, currentStepIndex } = getCurrentStepAndIndex(draft, stepId);
    if (isNil(currentStep)) {
      return;
    }
    const updatedTaskIndex = currentStep.tasks.findIndex((task) => task.id === updatedTask.id);
    const currentAssignees = currentStep.tasks[updatedTaskIndex].assignees;
    const assigneeIndex = currentAssignees.findIndex((a) => a.id === assignee.userProfileId);

    if (isNonNegative(assigneeIndex)) {
      removeAtPosition(currentAssignees, assigneeIndex);
    } else {
      const newAssignee = {
        firstName: assignee.firstName,
        lastName: assignee.lastName,
        id: assignee.id,
        email: assignee.email,
      } as WritableDraft<User>;
      currentAssignees.push(newAssignee);
    }

    const newTask = {
      ...updatedTask,
      assignees: currentAssignees,
    } as Task;
    replaceAtPosition(currentStep.tasks, updatedTaskIndex, newTask);
    replaceAtPosition(draft, currentStepIndex, currentStep);
  });
};
