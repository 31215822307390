import type { BaseFieldProps, FieldValues } from '@saas-ui/forms';
import { createField } from '@saas-ui/forms';
import type { ComboboxAutocompleteProps } from '@topo-io/design-system';
import { ComboboxAutocomplete } from '@topo-io/design-system';

export type ComboboxFieldProps = ComboboxAutocompleteProps &
  Omit<BaseFieldProps<FieldValues, string>, 'defaultValue'>;

export const ComboboxField = createField<ComboboxAutocompleteProps>(ComboboxAutocomplete, {
  isControlled: true,
});
