import { useMemo } from 'react';
import type { UserProfileObject } from '@topo-io/graphql';
import { useUserProfilesForRoleQuery, UserRole } from '@topo-io/graphql';
import { isNil, isNotNil } from '@topo-io/utils';
import { useUser } from '@/hooks';

export type EditorsMap = Record<string, UserProfileObject>;

export const useEditorUsers = (withArchived?: boolean) => {
  const { user } = useUser();
  const { data, loading, refetch } = useUserProfilesForRoleQuery({
    variables: {
      role: UserRole.EDITOR,
      organizationId: user?.organizationId,
      ...(isNotNil(withArchived) && { withArchived }),
    },
    skip: isNil(user?.organizationId),
  });

  const editors = useMemo(() => {
    return data?.userProfilesForRole ?? [];
  }, [data]);

  const editorsMap = useMemo<EditorsMap>(() => {
    return editors.reduce((acc, editor) => {
      acc[editor.id] = editor;
      return acc;
    }, {});
  }, [editors]);

  return {
    editors,
    loading,
    editorsMap,
    refetch,
  };
};
