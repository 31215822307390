import { useCallback } from 'react';
import { useDeleteSectionMutation } from '@topo-io/graphql';
import type { SectionJSON } from '@topo-io/rich-text-editor';
import { isNil } from '@topo-io/utils';
import type { RemoveSectionInput } from '@/components/section/types';
import { removeSectionFromSections } from '@/components/section/utils/adapters';
import { getCacheSections } from '@/components/section/utils/cache-utils';

export const useDeleteSection = ({
  workspaceTabId,
  sections,
}: {
  workspaceTabId: string | undefined;
  sections: SectionJSON[];
}) => {
  const [deleteSectionMutation] = useDeleteSectionMutation({
    update: (cache, { data }) => {
      const updatedSections = data?.deleteSection;
      if (isNil(updatedSections)) {
        return;
      }
      cache.modify(getCacheSections(updatedSections));
    },
  });

  const deleteSection = useCallback(
    ({ sectionId }: RemoveSectionInput) => {
      const { nextSectionsState } = removeSectionFromSections({
        sectionId,
        sections,
      });
      if (isNil(workspaceTabId)) {
        return;
      }
      void deleteSectionMutation({
        variables: {
          sectionId,
          workspaceTabId,
        },
        optimisticResponse: {
          deleteSection: { content: nextSectionsState },
        },
      });
    },
    [deleteSectionMutation, sections, workspaceTabId]
  );

  return { deleteSection };
};
