import { Trans, t } from '@lingui/macro';
import { useCallback } from 'react';
import {
  useDisclosure,
  Menu,
  Flex,
  MenuButton,
  IconButton,
  Icon,
  MenuList,
  MenuItem,
} from '@topo-io/design-system';
import { isNil } from '@topo-io/utils';
import { DeleteWorkspaceOrTemplateModal } from '@/components/workspace/common';
import type { DuplicateTemplateFormValues } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { NewTemplateModal } from '@/components/workspace/common/new-template-modal/new-template-modal';
import { useSubmitDuplicateWorkspaceTemplate } from '@/components/workspace/common/new-template-modal/use-submit-duplicate-workspace-template';
import { useDeleteWorkspaceTemplate } from '@/components/workspace/hooks';
import { useUser } from '@/hooks';

interface ActionsProps {
  id: string;
  ownerId: string;
  tagIds: string[];
  onDeleteSuccess: () => void;
}

export const TemplateListActionsCell = ({ id, ownerId, tagIds, onDeleteSuccess }: ActionsProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const deleteTemplateDisclosure = useDisclosure();
  const { user, isAnonymousUser } = useUser();
  const onSubmit = useSubmitDuplicateWorkspaceTemplate({ shouldRefetchQueries: true });
  const { deleteWorkspaceTemplate } = useDeleteWorkspaceTemplate(id);

  const handleDeleteWorkspaceTemplate = useCallback(async () => {
    const { errors } = await deleteWorkspaceTemplate();

    if (isNil(errors)) {
      onDeleteSuccess();
    }
  }, [deleteWorkspaceTemplate, onDeleteSuccess]);

  const canDeleteWorkspace = !isAnonymousUser(user) && user?.id === ownerId;

  return (
    <>
      <Flex m="0" alignItems="center" alignContent="flex-end" justifyContent="flex-end">
        <Menu variant="default">
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                variant="ghost"
                colorScheme="gray"
                icon={<Icon icon="more-vertical" h="16px" w="16px" color="gray.100" />}
              />
              <MenuList>
                <MenuItem
                  icon={<Icon icon="copy" position="relative" top="-1px" />}
                  onClick={onOpen}
                >
                  <Trans>Duplicate</Trans>
                </MenuItem>
                {canDeleteWorkspace && (
                  <MenuItem
                    icon={<Icon icon="trash" position="relative" top="-1px" />}
                    color="red.500"
                    onClick={() => void handleDeleteWorkspaceTemplate()}
                  >
                    <Trans>Delete</Trans>
                  </MenuItem>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>

      <NewTemplateModal
        title={t`Duplicate workspace template`}
        duplicate
        originalTemplateId={id}
        tagIds={tagIds}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={(values: DuplicateTemplateFormValues) => void onSubmit(values)}
      />

      <DeleteWorkspaceOrTemplateModal
        isOpen={deleteTemplateDisclosure.isOpen}
        onClose={deleteTemplateDisclosure.onClose}
        onDeleteSuccess={onDeleteSuccess}
        isWorkspace={false}
        id={id}
      />
    </>
  );
};
