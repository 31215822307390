import { Trans, t } from '@lingui/macro';
import { useState } from 'react';
import type { FC } from 'react';
import { v4 as uuid } from 'uuid';
import type { UploadedFile } from '@topo-io/design-system';
import { FilesPreview, Button, HStack, Spacer, useToast, Flex } from '@topo-io/design-system';
import { CommentsDocument, useCreateCommentMutation } from '@topo-io/graphql';
import type { ContentJSON } from '@topo-io/rich-text-editor';
import { isEmptyEditorContent, useRichTextEditorContext } from '@topo-io/rich-text-editor';
import type { Never } from '@topo-io/types';
import { isEmptyArray, isNil, isNotEmptyArray } from '@topo-io/utils';
import { SimpleEditor } from '@/components/rich-text-editor/simple-editor';
import { CommentMembersMentions } from './comment-members-mentions';
import { CommentToolbarEditor } from './comment-toolbar-editor';

interface EditStateProps {
  defaultComment: ContentJSON;
  onSaveEdition: (comment: ContentJSON) => Promise<string | undefined>;
  onCancelEdition: () => void;
}

export interface CommentEditorBaseProps {
  commentParentId: string;
  commentParentType: 'task' | 'section';
  shouldFocusContent?: boolean;
}

type CommentStateEdit = {
  state: 'edit';
} & EditStateProps & { onCreate?: () => never };

type CommentStateReadonly = {
  state: 'readonly';
} & Partial<EditStateProps> & { onCreate?: () => never };

type CommentStateCreate = {
  state: 'create';
  onCreate: () => void;
} & Never<EditStateProps>;

type CommentEditorProps = CommentEditorBaseProps &
  (CommentStateEdit | CommentStateReadonly | CommentStateCreate);

const newComment = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
};

export const CommentEditor: FC<CommentEditorProps> = ({
  state,
  defaultComment,
  onSaveEdition,
  onCancelEdition,
  onCreate,
  commentParentId,
  commentParentType,
  shouldFocusContent = true,
}) => {
  const [id, setId] = useState(uuid());
  const { errorToast } = useToast();
  const [createSectionComment] = useCreateCommentMutation({
    refetchQueries: [CommentsDocument],
    awaitRefetchQueries: true,
  });
  const [comment, setComment] = useState<ContentJSON>(
    defaultComment?.[0] ?? {
      type: 'doc',
      content: [{ type: 'paragraph' }],
    }
  );
  const { pendingFiles, linkFileToRecord, deleteFile } = useRichTextEditorContext();

  // No useDebounceCallback here after discussion with Robin. Could be re-challenged later.
  const onChange = (content: ContentJSON) => {
    setComment(content);
  };

  const associateFilesToComment = async (commentId: string, files: UploadedFile[]) => {
    await Promise.all(files.map((file) => linkFileToRecord(file.fileId, commentId)));
  };

  const onCommentSaveEdition = async () => {
    if (isNil(comment)) {
      return;
    }

    const commentId = await onSaveEdition?.(comment);

    if (!commentId) {
      return;
    }

    await associateFilesToComment(commentId, pendingFiles);
    setId(uuid());
  };

  const onCommentCancelEdition = () => {
    setComment(defaultComment?.[0]);
    onCancelEdition?.();
    setId(uuid());
  };

  const onSubmitCreateComment = async () => {
    const { data, errors } = await createSectionComment({
      variables: {
        input: {
          ...(commentParentType === 'task' ? { taskId: commentParentId } : {}),
          ...(commentParentType === 'section' ? { sectionId: commentParentId } : {}),
          content: [comment],
        },
      },
    });
    if (errors) {
      errorToast({
        title: t`Could not save comment. Please try again`,
      });
      return;
    }
    if (!data?.createComment?.id) {
      return;
    }
    await associateFilesToComment(data?.createComment?.id, pendingFiles);
    setComment(newComment);
    onCreate?.();
    setId(uuid());
  };

  return (
    <>
      <SimpleEditor
        readOnly={state === 'readonly'}
        initialContent={comment}
        key={`comment-editor-${state}-${id}`}
        onChange={onChange}
        placeholder={t`Write a comment...`}
        placeholderReadOnly={t`(no description)`}
        shouldFocusContent={shouldFocusContent}
      >
        <CommentMembersMentions />
        {pendingFiles && isNotEmptyArray(pendingFiles) && (
          <FilesPreview files={pendingFiles} deleteFile={deleteFile} editable pt="2" />
        )}

        <Flex w="full" display="contents">
          <HStack>
            {state !== 'readonly' && <CommentToolbarEditor />}

            <Spacer />

            {state === 'create' && (
              <Button
                type="submit"
                aria-label="write-comment-btn"
                alignSelf="end"
                isDisabled={isEmptyEditorContent(comment) && isEmptyArray(pendingFiles)}
                onClick={onSubmitCreateComment}
              >
                <Trans>Comment</Trans>
              </Button>
            )}

            {state === 'edit' && (
              <>
                <Button colorScheme="gray" onClick={onCommentCancelEdition}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button type="submit" onClick={onCommentSaveEdition}>
                  <Trans>Save</Trans>
                </Button>
              </>
            )}
          </HStack>
        </Flex>
      </SimpleEditor>
    </>
  );
};
