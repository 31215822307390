import { t } from '@lingui/macro';
import { ModalSection } from '@topo-io/design-system';
import type { NotificationSettings, RoadmapReminderRule } from '@topo-io/graphql';
import type { DebouncedState } from '@topo-io/hooks';
import { RoadmapDelayEmailReminderSettings } from './roadmap-delay-email-reminder-settings';
import { RoadmapRuleEmailReminderSettings } from './roadmap-rule-email-reminder-settings';

export const NotificationSettingsRoadmap = ({
  roadmapReminderRule,
  roadmapReminderDelay,
  updateNotificationSettings,
}: {
  roadmapReminderRule?: RoadmapReminderRule;
  roadmapReminderDelay?: number;
  updateNotificationSettings: DebouncedState<
    (data: Partial<NotificationSettings>) => Promise<void>
  >;
}) => {
  const updateRoadmapReminderRule = (value: RoadmapReminderRule) => {
    void updateNotificationSettings({
      roadmapReminderRule: value,
    });
  };
  const updateRoadmapReminderDelay = (value: number) => {
    void updateNotificationSettings({
      roadmapReminderDelay: value,
    });
  };

  return (
    <ModalSection title={t`Mutual Action plan`} indent>
      <RoadmapRuleEmailReminderSettings
        roadmapReminderRule={roadmapReminderRule}
        updateRoadmapReminderRule={updateRoadmapReminderRule}
      />
      <RoadmapDelayEmailReminderSettings
        roadmapReminderDelay={roadmapReminderDelay}
        updateRoadmapReminderDelay={updateRoadmapReminderDelay}
      />
    </ModalSection>
  );
};
