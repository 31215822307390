import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useQueryParamId = () => {
  const { query } = useRouter();

  const id = useMemo(() => {
    return query?.id instanceof Array ? query?.id[0] : query?.id;
  }, [query?.id]);

  if (!id) {
    throw new Error('id is required');
  }

  return id;
};
