import type { Active as DndActive } from '@dnd-kit/core';
import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { Trans } from '@lingui/macro';
import type { MutableRefObject } from 'react';
import { Box, Collapse, Text } from '@topo-io/design-system';
import type { SectionTemplateSearchRecord } from '@topo-io/types';
import { isNotEmptyArray, partition } from '@topo-io/utils';
import { ListSearchInput } from '@/components/common/search-filters/list-search-input';
import { TemplateLibraryItem } from '@/components/workspace/overview/template-library-item';
import { useSearchResults, useUser } from '@/hooks';
import { TemplateLibraryButton } from './template-library-button';
import { TemplateLibraryList } from './template-library-list';

type MyDataRef<T> = MutableRefObject<T>;
type Active<T> = Omit<DndActive, 'data'> & { data: MyDataRef<T> };
type UseDndContext<T> = Omit<ReturnType<typeof useDndContext>, 'active'> & {
  active: Active<T>;
};

export const TemplateLibrary = () => {
  const { user, isAnonymousUser } = useUser();
  const { results } = useSearchResults<SectionTemplateSearchRecord>();
  const { active } = useDndContext() as UseDndContext<{
    sectionTemplate: SectionTemplateSearchRecord;
  }>;

  if (isAnonymousUser(user)) {
    return null;
  }

  const [ownTemplates, teamTemplates] = partition(
    results,
    (template) => template.owner.id === user?.id
  );

  return (
    <>
      <ListSearchInput />
      <Box w="full" h="full" overflowY="auto">
        <Box py={2}>
          <TemplateLibraryButton />
        </Box>
        <Box pb={2}>
          <Collapse
            key={`team-templates-${isNotEmptyArray(teamTemplates) ? 'open' : 'closed'}`}
            defaultOpen={isNotEmptyArray(teamTemplates)}
            component={(isOpen: boolean) => (
              <Text as="span" color={isOpen ? 'primary.600' : 'gray.700'} fontWeight="medium">
                <Trans>Team templates ({teamTemplates.length})</Trans>
              </Text>
            )}
          >
            <TemplateLibraryList templates={teamTemplates} />
          </Collapse>
        </Box>
        <Collapse
          key={`team-templates-${isNotEmptyArray(ownTemplates) ? 'open' : 'closed'}`}
          defaultOpen={isNotEmptyArray(ownTemplates)}
          component={(isOpen: boolean) => (
            <Text as="span" color={isOpen ? 'primary.600' : 'gray.700'} fontWeight="medium">
              <Trans>My templates ({ownTemplates.length})</Trans>
            </Text>
          )}
        >
          <TemplateLibraryList templates={ownTemplates} />
        </Collapse>
        <DragOverlay>
          {active ? (
            <TemplateLibraryItem
              item={active.data.current.sectionTemplate}
              title={active.data.current.sectionTemplate.title ?? ''}
              onClick={() => undefined}
              isActive={true}
            />
          ) : null}
        </DragOverlay>
      </Box>
    </>
  );
};
