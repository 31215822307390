import { Trans } from '@lingui/macro';
import { useMemo } from 'react';
import type { FC } from 'react';
import { Avatar, Box, Card, Flex, Text } from '@topo-io/design-system';
import { WorkspaceRole } from '@topo-io/graphql';
import type { MembersQuery } from '@topo-io/graphql';
import { formatLocalizedDate, getDisplayName, isNil, isNotNil } from '@topo-io/utils';
import { TooltipParagraph } from '@/components/common';
import { useWorkspace } from '@/components/workspace/hooks';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useUser } from '@/hooks';
import { MemberListActions } from './member-list-actions';
import { MemberSyncIndicator } from './member-sync-indicator';

interface MemberCardProps {
  member: MembersQuery['members'][number];
}

export const MemberCard: FC<MemberCardProps> = ({ member }) => {
  const { id, isWorkspace, isGuest, isPreview } = useWorkspaceContext();
  const { workspace } = useWorkspace(id!, { skip: isNil(id) || !isWorkspace });
  const { user, isIdentifiedUser } = useUser();

  const { email, company, joinedDate, jobTitle, lastActivity, picture } = member;
  const name = getDisplayName(member, email);

  const hasJobPositionOrCompany = Boolean(jobTitle) || Boolean(company?.name);
  const jobPosition = `${jobTitle ?? ''} ${isNotNil(company?.name) ? `@${company?.name}` : ''}`;

  const hasJoinedDate = isNotNil(joinedDate);
  const isCurrentUser = useMemo(
    () => isIdentifiedUser(user) && user.id === member.userProfileId,
    [user, member, isIdentifiedUser]
  );

  const isMemberSameOrganizationThanOwner = useMemo(
    () => isIdentifiedUser(user) && member.company?.id === workspace?.owner.company?.id,
    [workspace?.owner.company, user, isIdentifiedUser, member.company]
  );

  const shouldDisplaySyncIndicator =
    isNotNil(workspace?.CRMAssociation?.CRMAccount.id) && !isPreview && !isGuest;

  return (
    <Card py="3.5" px="4" position="relative">
      {!isGuest && !isCurrentUser && (
        <>
          {shouldDisplaySyncIndicator && member.role === WorkspaceRole.VIEWER && (
            <Box position="absolute" top="3" left="3">
              <MemberSyncIndicator member={member} />
            </Box>
          )}
          <Box position="absolute" top="1" right="1">
            <MemberListActions id={member.id} role={member.role} />
          </Box>
        </>
      )}
      <Flex flexDirection="column" alignItems="center">
        <Avatar size="md" key={email} name={name} src={picture} />
        <TooltipParagraph text={name} fontWeight="500" mt="2" fontSize="lg" />
        {hasJobPositionOrCompany ? (
          <TooltipParagraph text={jobPosition} color="gray.500" />
        ) : (
          <Text opacity="0" visibility="hidden">
            Not displayed text
          </Text>
        )}
      </Flex>
      <Flex flexDirection="column" alignItems="center" mt="6">
        {hasJoinedDate ? (
          <>
            <Text lineHeight="16px" display="block" color="gray.500" fontSize="sm">
              <Trans>Joined</Trans> {formatLocalizedDate(joinedDate)}
            </Text>
            {!isGuest && !isPreview && (
              <Text lineHeight="16px" display="block" color="gray.300" fontSize="sm">
                <Trans>Last activity</Trans>:{' '}
                {isNotNil(lastActivity) ? formatLocalizedDate(lastActivity) : '-'}
              </Text>
            )}
          </>
        ) : (
          <>
            {!isMemberSameOrganizationThanOwner && (
              <Text color="gray.100">
                <Trans>Pending invitation</Trans>
              </Text>
            )}
          </>
        )}
      </Flex>
    </Card>
  );
};
