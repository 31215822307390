import { t } from '@lingui/macro';
import { Text } from '@topo-io/design-system';
import { getDisplayName } from '@topo-io/utils';
import type { Person as PersonType } from '@topo-io/utils';

interface PersonProps extends PersonType {
  email?: string;
  capitalized?: boolean;
}

export const Person = ({ firstName, lastName, email, capitalized = false }: PersonProps) => {
  const defaultName = t`Someone`;

  const displayName = getDisplayName({ firstName, lastName }, email ?? defaultName);

  const isBold = displayName !== defaultName;
  const isNotAnEmail = displayName !== email;

  return (
    <Text
      {...(isBold && { fontWeight: 'bold' })}
      {...(capitalized && isNotAnEmail && { textTransform: 'capitalize' })}
    >
      {displayName}
    </Text>
  );
};
