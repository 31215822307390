import { Flex, Skeleton, SkeletonCircle, Text } from '@topo-io/design-system';

export const GuestRawLoadingState = () => {
  return (
    <Flex justifyContent="space-between" paddingBottom="2">
      <Flex gap="3" alignItems="center">
        <SkeletonCircle size="6" />
        <Skeleton>
          <Text>john.doe@exemple.com</Text>
        </Skeleton>
      </Flex>

      <Skeleton>
        <Text>10 h 20 min</Text>
      </Skeleton>
    </Flex>
  );
};
