import { t } from '@lingui/macro';
import type { FC } from 'react';
import { useState, useMemo } from 'react';
import type { Tab } from '@topo-io/design-system';
import {
  Icon,
  SimpleModal,
  ModalTabs,
  ModalTabList,
  ModalTab,
  ModalTabPanels,
  Box,
  useMediaQuery,
  Tabs,
  TabList,
  appTheme,
  CustomThemeOverride,
} from '@topo-io/design-system';
import { useMembersQuery } from '@topo-io/graphql';
import { useBrandingColors } from '@/components/branding';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useQueryParamId, useUser } from '@/hooks';
import { InviteMembersTab } from './invite-members-tab/invite-members-tab';
import { NotificationSettingsTab } from './notification-settings-tab';
import { ShareSettingsTab } from './share-settings-tab';
import { WorkspaceUpdateTab } from './workspace-update-tab/workspace-update-tab';

export const shareWorkspaceModalId = 'shareWorkspace';

interface ShareWorkspaceModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialTab?: number;
  editingMemberId?: string | undefined;
}

interface Tab {
  id: string;
  title: string;
  tabIcon: string;
  component: React.ReactElement;
  isHidden: () => boolean;
}

export const ShareWorkspaceModal: FC<ShareWorkspaceModalProps> = ({
  isOpen,
  onClose,
  initialTab = 0,
  editingMemberId,
}) => {
  const id = useQueryParamId();
  const { readOnly, isWorkspace } = useWorkspaceContext();
  const { isAnonymousUser, user } = useUser();

  const [isLargerThanMdBreakpoint] = useMediaQuery(
    `(min-width: ${appTheme?.breakpoints?.md ?? 0})`
  );
  const { data } = useMembersQuery({ variables: { workspaceId: id } });
  const members = useMemo(() => data?.members ?? [], [data?.members]);
  const editingMember = members.find((member) => member.id === editingMemberId);

  const { primaryColorsPalette, theme } = useBrandingColors(id, !isWorkspace);

  const [tabIndex, setTabIndex] = useState(initialTab);

  const onChangeTabs = (index: number) => {
    setTabIndex(index);
  };

  const modalConfig: Tab[] = useMemo(
    () => [
      {
        id: 'share-settings',
        title: t`Share settings`,
        tabIcon: 'settings',
        component: <ShareSettingsTab />,
        isHidden: () => false,
      },
      {
        id: 'invite-members',
        isHidden: () => isAnonymousUser(user),
        title: t`Invite members`,
        tabIcon: 'mail',
        component: (
          <InviteMembersTab
            workspaceId={id}
            workspaceMembers={members}
            editingMember={editingMember}
          />
        ),
      },
      {
        id: 'notification-settings',
        isHidden: () => readOnly || isAnonymousUser(user),
        title: t`Notification settings`,
        tabIcon: 'settings',
        component: <NotificationSettingsTab workspaceId={id} />,
      },
      {
        id: 'workspace-update',
        isHidden: () => readOnly || isAnonymousUser(user),
        title: t`Workspace update`,
        tabIcon: 'info',
        component: <WorkspaceUpdateTab workspaceId={id} workspaceMembers={members} />,
      },
    ],
    [editingMember, id, members, isAnonymousUser, readOnly, user]
  );

  const modalTabListContent = useMemo(
    () =>
      modalConfig.map((tab) => {
        return (
          !tab.isHidden() && (
            <ModalTab key={tab.title} whiteSpace="nowrap" data-testid={`tab-${tab.id}`}>
              <Icon icon={tab.tabIcon} mr="2" />
              {tab.title}
            </ModalTab>
          )
        );
      }),
    [modalConfig]
  );

  const modalTabPanelsContent = useMemo(
    () =>
      modalConfig.map((tab) => {
        return !tab.isHidden() && <Box key={tab.title}>{tab.component}</Box>;
      }),
    [modalConfig]
  );

  const modalContent = useMemo(() => {
    if (isLargerThanMdBreakpoint) {
      return (
        <ModalTabs index={tabIndex} onChange={onChangeTabs} data-testid="workspace-sharing-modal">
          <ModalTabList minHeight="300px">
            <>{modalTabListContent}</>
          </ModalTabList>
          <ModalTabPanels>{modalTabPanelsContent}</ModalTabPanels>
        </ModalTabs>
      );
    }
    return (
      <Tabs data-testid="workspace-sharing-modal">
        <TabList>
          <>{modalTabListContent}</>
        </TabList>
        <ModalTabPanels>{modalTabPanelsContent}</ModalTabPanels>
      </Tabs>
    );
  }, [isLargerThanMdBreakpoint, modalTabListContent, modalTabPanelsContent, tabIndex]);

  return (
    <SimpleModal isOpen={isOpen} onClose={onClose} p="2" size="3xl">
      <CustomThemeOverride colors={primaryColorsPalette} contrastColor={theme.contrastTextColor}>
        {modalContent}
      </CustomThemeOverride>
    </SimpleModal>
  );
};
