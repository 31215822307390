var IndexSortDirection;
(function(IndexSortDirection) {
    IndexSortDirection["DESC"] = "desc";
    IndexSortDirection["ASC"] = "asc";
})(IndexSortDirection || (IndexSortDirection = {}));
const inverseSortDirection = (direction)=>{
    switch(direction){
        case "desc":
            return "asc";
        case "asc":
            return "desc";
    }
};

const isJwtToken = (token)=>{
    return typeof token === 'object' && token !== null && 'iat' in token && 'exp' in token;
};
const isUserAccessToken = (token)=>{
    if (!isJwtToken(token)) {
        return false;
    }
    return typeof token === 'object' && token !== null && 'organizationId' in token && 'userId' in token;
};
const isAnonymousAccessToken = (token)=>{
    if (!isJwtToken(token)) {
        return false;
    }
    return typeof token === 'object' && token !== null && 'organizationId' in token && 'anonymousId' in token;
};

export { IndexSortDirection, inverseSortDirection, isAnonymousAccessToken, isJwtToken, isUserAccessToken };
