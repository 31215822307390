import type { ChangeEvent } from 'react';
import { useMemo, useCallback } from 'react';
import type { ItemType } from '@topo-io/design-system/src/components';
import type { OpportunitiesQuery } from '@topo-io/graphql';
import { contains, getFirstNthElements, isNil } from '@topo-io/utils';
import { DefaultComboboxOptions } from '@/components/common/fields/default-combobox-options';
import { useOpportunities } from '@/components/workspace/hooks/use-opportunities';
import { ComboboxField } from './combobox-field';

export type OpportunityItemType = OpportunitiesQuery['opportunities'][number];

interface OpportunitiesFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  defaultOpportunity?: OpportunityItemType;
  CRMAccountId?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
const LIMIT_OPTIONS = 5;

export const OpportunitiesField = ({
  name,
  label,
  placeholder,
  defaultOpportunity,
  CRMAccountId,
  onChange,
}: OpportunitiesFieldProps) => {
  const { opportunities, isFieldDisabled } = useOpportunities({
    filters: { CRMAccountId },
    skip: isNil(CRMAccountId),
  });

  const suggestedOpportunities = useMemo(
    () =>
      opportunities.map((opportunity) => ({
        id: opportunity.id,
        label: opportunity.name,
        value: opportunity.id,
        key: opportunity.id,
      })) ?? [],
    [opportunities]
  );

  const sortedSuggestions = useMemo(
    () => [...suggestedOpportunities].sort(),
    [suggestedOpportunities]
  );

  const optionsFn = useCallback(
    async (value: string | undefined): Promise<ItemType[]> => {
      if (!value || value === defaultOpportunity?.name) {
        const options = getFirstNthElements(sortedSuggestions, LIMIT_OPTIONS);
        return Promise.resolve(options);
      }
      const matchingOpportunities = sortedSuggestions?.filter((opportunity) =>
        contains(opportunity.label, value, { mode: 'insensitive' })
      );

      const options = getFirstNthElements(matchingOpportunities, LIMIT_OPTIONS);
      return Promise.resolve(options);
    },
    [defaultOpportunity?.name, sortedSuggestions]
  );

  return (
    <ComboboxField
      showNewItem={false}
      options={optionsFn}
      render={DefaultComboboxOptions}
      name={name}
      label={label}
      placeholder={placeholder}
      isDisabled={isFieldDisabled}
      defaultValue={defaultOpportunity?.name}
      onChange={onChange}
    />
  );
};
