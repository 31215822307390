import axios from 'axios';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { ItemType } from '@topo-io/design-system';
import { ComboboxItem, Box, Flex, Text } from '@topo-io/design-system';
import { useCompaniesQuery } from '@topo-io/graphql';
import { compact, contains, unique } from '@topo-io/utils';
import { AccountLogo } from '@/components/common/account-logo';
import type { ComboboxFieldProps } from '@/components/common/fields/combobox-field';
import { ComboboxField } from '@/components/common/fields/combobox-field';
import { all } from '@/config';

const { CLEARBIT_TOKEN, CLEARBIT_URL } = all;
// we use a proxy to avoid being blocked by ad-blockers
// original url: https://autocomplete.clearbit.com

const MIN_RANGE_DISPLAYED_ITEMS = 0;
const MAX_RANGE_DISPLAYED_ITEMS = 5;

const ComboboxOptions = ({ items }: { items: CompanyItemType[] }) => {
  return items.map((item, index) => (
    <ComboboxItem key={`${item.value as string}-${index}`} item={item}>
      <Flex w="20px">
        <AccountLogo company={item} w="20px" h="20px" />
      </Flex>
      <Box fontWeight="400" flex="1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        {item.label}{' '}
        <Text as="span" color="gray.900">
          ({item.domainUrl})
        </Text>
      </Box>
    </ComboboxItem>
  ));
};

export interface CompanyItemType extends ItemType {
  logoUrl?: string;
  domainUrl?: string;
  id?: string;
}

interface ClearbitType {
  name: string;
  logo: string;
  domain: string;
}

type CompanyFieldProps = Omit<ComboboxFieldProps, 'options' | 'render'>;

export const CompanyField = (props: CompanyFieldProps) => {
  const { data: companiesFromOrganizationData } = useCompaniesQuery({});
  const optionsFn = useCallback(
    async (value: string): Promise<CompanyItemType[]> => {
      if (!value) {
        return [];
      }

      const { data = [] }: { data: ClearbitType[] } = await axios(
        `${CLEARBIT_URL}/v1/companies/suggest?query=${value}`,
        {
          headers: {
            Authorization: `Bearer ${CLEARBIT_TOKEN}`,
          },
        }
      );

      /*
       * A unique id is added to the value to identify uniquely the company
       * Because multiple results can have the same name
       * And the zag combobox find the first option with the same value
       */
      const clearbitCompanies: CompanyItemType[] = data.map((item) => ({
        label: item.name,
        key: uuid(),
        logoUrl: item.logo,
        domainUrl: item.domain,
      }));

      const companiesFromOrganization: CompanyItemType[] =
        companiesFromOrganizationData?.companies
          .filter((company) => contains(company.name, value, { mode: 'insensitive' }))
          .map((company) => ({
            label: company.name,
            key: company.id,
            id: company.id,
            logoUrl: company.logoUrl ?? undefined,
            domainUrl: company.domainUrl ?? undefined,
          })) ?? [];

      const allRelevantCompanies = companiesFromOrganization.concat(clearbitCompanies);
      // Avoid having duplicated companies from the organization and from Clearbit.
      const uniqueCompanies = unique(allRelevantCompanies, (company) =>
        compact([company.label, company.domainUrl]).join('-')
      );

      return uniqueCompanies.slice(MIN_RANGE_DISPLAYED_ITEMS, MAX_RANGE_DISPLAYED_ITEMS);
    },
    [companiesFromOrganizationData]
  );

  return (
    <ComboboxField
      {...props}
      placeholder="Google, Amazon, etc."
      options={optionsFn}
      render={ComboboxOptions}
      showNewItem
    />
  );
};
