import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useWorkspace, useWorkspaceContext } from '@/components/workspace/hooks';
import { ANALYTICS_PING_INTERVAL } from '@/config';
import { useOnRouteChanged } from '@/hooks';
import { useTrackWorkspaceTab } from './use-track-workspace-tab';

interface Args {
  organizationId: string | undefined;
  skip?: boolean;
}

export const useWorkspaceTabActivityTracking = ({ organizationId, skip = false }: Args) => {
  const {
    isActivityTrackingEnabled,
    handleAfterPing,
    id: workspaceId,
    workspaceViewId,
    workspaceTabViewId,
    setWorkspaceTabViewId,
  } = useWorkspaceContext();

  const { workspace } = useWorkspace(workspaceId!, { skip: !workspaceId });

  const { trackWorkspaceTab } = useTrackWorkspaceTab({
    skip,
    workspaceId,
    workspaceViewId,
    workspaceTemplateId: workspace?.workspaceTemplateId,
    organizationId,
  });
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [hasSentWorkspaceView, setHasSentWorkspaceView] = useState(false);
  const hasSentFirstPing = useRef(false);

  // set workspaceTabViewId on mount
  useEffect(() => {
    setWorkspaceTabViewId(uuid());
  }, [setWorkspaceTabViewId]);

  // send workspace ping one time on page load
  useEffect(() => {
    if (skip || !workspaceTabViewId || hasSentFirstPing.current) {
      return;
    }
    trackWorkspaceTab({ action: 'ping', workspaceTabViewId });
    hasSentFirstPing.current = true;
  }, [trackWorkspaceTab, skip, workspaceTabViewId]);

  // send workspace ping every time isActivityTrackingEnabled change (e.g. every ANALYTICS_PING_INTERVAL seconds)
  useEffect(() => {
    if (!isActivityTrackingEnabled || skip) {
      return;
    }
    trackWorkspaceTab({ action: 'ping', workspaceTabViewId });
    handleAfterPing();
  }, [isActivityTrackingEnabled, trackWorkspaceTab, handleAfterPing, skip, workspaceTabViewId]);

  // send workspace ping on page change
  // reset hasSentWorkspaceView on page change
  // change workspaceTabViewId on page change
  useOnRouteChanged({
    isPathEquals: '/w/[id]/[...tab]',
    callback: () => {
      const newTabViewId = uuid();
      trackWorkspaceTab({ action: 'ping', workspaceTabViewId: newTabViewId });
      setHasSentWorkspaceView(false);
      setWorkspaceTabViewId(newTabViewId);
    },
  });

  // send workspace view after 3 seconds
  useEffect(() => {
    if (skip || !workspaceTabViewId) {
      return;
    }
    if (hasSentWorkspaceView) {
      clearInterval(timer.current ?? undefined);
      timer.current = null;
      return;
    }
    timer.current = setInterval(() => {
      trackWorkspaceTab({ action: 'view', workspaceTabViewId });
      setHasSentWorkspaceView(true);
    }, ANALYTICS_PING_INTERVAL);

    return () => clearInterval(timer.current ?? undefined);
  }, [skip, hasSentWorkspaceView, trackWorkspaceTab, workspaceTabViewId]);
};
