import { ErrorBoundary } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { Divider, Flex, H6, SavingStatus, Spacer } from '@topo-io/design-system';
import { isNil, isNotNil } from '@topo-io/utils';
import { BackButton } from '@/components/common/back-button';
import { useNavbar } from '@/components/layout/hooks';
import { NavbarWorkspaceTabs } from '@/components/layout/navbar/workspace-tabs';
import { useBaseRoutesContext } from '@/components/routing';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { NAVBAR_HEIGHT } from '@/config';
import { useSavingStatus } from '@/state/hooks/use-saving-status';
import { CTAConnected, CTAMenu } from './cta';
import { NavbarSectionTemplateTabs } from './section-tabs/navbar-section-template-tabs';

export const Navbar: FC = () => {
  const router = useRouter();
  const { id } = useWorkspaceContext();
  const { isWorkspace, isTemplate: isWorkspaceTemplate } = useWorkspaceContext();
  const { isSectionTemplatePage, isTagsSettingsPage } = useBaseRoutesContext();
  const { title, backButtonLabel } = useNavbar();
  const { status, show } = useSavingStatus();

  const shouldRenderSavingStatus =
    isWorkspace || isWorkspaceTemplate || isSectionTemplatePage || isTagsSettingsPage;

  return (
    <ErrorBoundary>
      <Flex
        as="header"
        align="center"
        justify="space-between"
        h={NAVBAR_HEIGHT}
        px={backButtonLabel ? '4' : '8'}
        w="full"
        bg="navbar.bg.color"
        borderBottomWidth="1px"
        borderColor="gray.gray30"
      >
        {backButtonLabel && (
          <>
            <BackButton onClick={() => router.back()} text={backButtonLabel} />
            <Divider orientation="vertical" h="24px" mx="4" borderColor="gray.200" />
          </>
        )}
        <H6 color="gray.800" noOfLines={1} data-testid="workspace-title-header">
          {title}
        </H6>
        <NavbarWorkspaceTabs />
        <NavbarSectionTemplateTabs />
        <Spacer />
        <Flex>
          {shouldRenderSavingStatus && show && <SavingStatus status={status} mx={4} />}
          {isNil(id) && <CTAMenu />}
          {isNotNil(id) && <CTAConnected id={id} />}
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};
