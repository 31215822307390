import type { FC } from 'react';
import type { ProgressProps } from '@topo-io/design-system';
import { Progress, Text } from '@topo-io/design-system';
import { isZero } from '@topo-io/utils';

interface TaskProgressProps extends ProgressProps {
  count: number;
  completedCount: number;
}

export const TaskProgress: FC<TaskProgressProps> = ({ count, completedCount, ...rest }) => {
  if (isZero(count)) {
    return null;
  }

  return (
    <>
      <Progress
        colorScheme="blue"
        size="sm"
        value={completedCount}
        max={count}
        borderRadius="md"
        backgroundColor={'gray.20'}
        h={3}
        w={12}
        {...rest}
      />
      <Text color="gray.700" fontWeight="medium">
        {completedCount}/{count}
      </Text>
    </>
  );
};
