import { useEffect, useMemo, useState } from 'react';
import { generateColorPalette, useTheme } from '@topo-io/design-system';
import { useThemeQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';

export const useBrandingColors = (workspaceId?: string, skip?: boolean) => {
  const appTheme = useTheme();

  const [primaryColor, setPrimaryColor] = useState<string | undefined>(undefined);
  const [contrastTextColor, setContrastTextColor] = useState<string | undefined>(undefined);

  const FALLBACK_PRIMARY_COLOR = appTheme.colors.blue[500] as string;
  const FALLBACK_CONTRAST_TEXT_COLOR = appTheme.semanticTokens.colors['text.contrast.color']
    ._light as string;

  const { data, loading } = useThemeQuery({
    variables: {
      workspaceId,
    },
    skip,
    fetchPolicy: 'cache-first',
  });

  const clientTheme = data?.theme;

  useEffect(() => {
    const { primaryColor, contrastTextColor } = clientTheme ?? {};
    setPrimaryColor(primaryColor);
    setContrastTextColor(contrastTextColor);
  }, [clientTheme]);

  const primaryColorsPalette = useMemo(() => {
    if (isNil(primaryColor)) {
      return appTheme.colors!.blue as Record<string, string>;
    }
    return generateColorPalette(primaryColor, 'primary');
  }, [primaryColor, appTheme]);

  const reset = () => {
    setPrimaryColor(FALLBACK_PRIMARY_COLOR);
    setContrastTextColor(FALLBACK_CONTRAST_TEXT_COLOR);
  };

  return {
    theme: {
      ...clientTheme,
      primaryColor: primaryColor ?? FALLBACK_PRIMARY_COLOR,
      contrastTextColor: contrastTextColor ?? FALLBACK_CONTRAST_TEXT_COLOR,
    },
    primaryColorsPalette,
    setPrimaryColor,
    setContrastTextColor,
    loading,
    reset,
    FALLBACK_PRIMARY_COLOR,
    FALLBACK_CONTRAST_TEXT_COLOR,
  };
};
