import type { PropsWithChildren } from 'react';
import { useCallback, useEffect } from 'react';
import { SavingStatusEnum } from '@topo-io/design-system';
import { ObserveLink } from '@topo-io/graphql';
import { useDebouncedCallback } from '@topo-io/hooks';
import { SAVING_STATUS_MUTATIONS_TO_LISTEN } from '@/config';
import { useSavingStatus } from '@/state/hooks/use-saving-status';

const MIN_WAIT = 4000;
const MIN_SPINNER_WAIT = 1000;

export const SavingStatusProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { status, reset: resetSavingStatus, showSavingStatus, showSavedStatus } = useSavingStatus();

  const reset = () => {
    if (status === SavingStatusEnum.SAVED) {
      resetSavingStatus();
    }
  };
  /*
    Hide saving status after a delay when the state is saved
  */
  const resetDebounced = useDebouncedCallback(reset, MIN_WAIT);

  useEffect(() => {
    resetDebounced();

    return () => {
      resetDebounced.cancel();
    };
  }, [status, resetDebounced]);

  const delayShowSavedStatus = useCallback(() => {
    // Display spinner at least for MIN_SPINNER_WAIT
    setTimeout(() => {
      showSavedStatus();
    }, MIN_SPINNER_WAIT);
  }, [showSavedStatus]);

  useEffect(() => {
    const observeLinkInstance = ObserveLink.getInstance();

    observeLinkInstance.setOptions({
      operations: SAVING_STATUS_MUTATIONS_TO_LISTEN,
      onOperationStart: showSavingStatus,
      onOperationEnd: delayShowSavedStatus,
    });
  }, [delayShowSavedStatus, showSavingStatus]);

  return <>{children}</>;
};
