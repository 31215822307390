import { useContext } from 'react';
import { WorkspaceContext } from '@/components/workspace/contexts/workspace-context';

export const useWorkspaceContext = () => {
  const workspaceContext = useContext(WorkspaceContext);

  if (!workspaceContext) {
    throw new Error('Context used outside of its Provider!');
  }

  return workspaceContext;
};
