import type { FC } from 'react';
import { Box } from '@topo-io/design-system';
import type { SectionTemplateSearchRecord } from '@topo-io/types';
import { useContent, useWorkspaceTabs } from '@/components/workspace/hooks';
import { DraggableItem } from './draggable-item';
import type { SectionTemplateItem } from './template-library-item';
import { TemplateLibraryItem } from './template-library-item';

interface TemplateLibraryListProps {
  templates: SectionTemplateItem[] | SectionTemplateSearchRecord[];
}

export const TemplateLibraryList: FC<TemplateLibraryListProps> = ({ templates }) => {
  const { tabId } = useWorkspaceTabs();
  const { onClickAddSectionFromTemplate } = useContent(tabId);

  return (
    <Box w="full" py="2">
      {(templates as SectionTemplateItem[]).map((sectionTemplate) => (
        <DraggableItem key={sectionTemplate.id} id={sectionTemplate.id} data={{ sectionTemplate }}>
          <TemplateLibraryItem
            title={sectionTemplate.title ?? 'Untitled'}
            item={sectionTemplate}
            onClick={onClickAddSectionFromTemplate(sectionTemplate)}
            isActive={false}
          />
        </DraggableItem>
      ))}
    </Box>
  );
};
