import { t } from '@lingui/macro';
import { Field, useFormContext } from '@saas-ui/forms';
import { useEffect } from 'react';
import { isNotNil } from '@topo-io/utils';
import { CompanyField } from '@/components/common';
import { useOrganizationBySlug } from '@/components/organization';
import type { BaseWorkspaceFormValues } from '@/components/workspace/common/new-workspace-modal/types';

export const AccountAndTitleFields = () => {
  const { organization } = useOrganizationBySlug();

  const formContext = useFormContext<BaseWorkspaceFormValues>();
  const { watch, setValue } = formContext;
  const account = watch('account');

  useEffect(() => {
    const companyName = organization?.company?.name;
    const title =
      isNotNil(companyName) && isNotNil(account) ? `${companyName} x ${account.label}` : '';
    setValue('title', title);
  }, [account, organization, setValue]);

  return (
    <>
      <CompanyField
        name="account"
        label={t`Account name`}
        isRequired
        data-testid="account-name-input"
      />
      <Field<BaseWorkspaceFormValues>
        name="title"
        label={t`Workspace title`}
        type="text"
        placeholder={t`Partnership with Amazon`}
        isRequired
        data-testid="workspace-title-input"
      />
    </>
  );
};
