import { Trans, t } from '@lingui/macro';
import { Field, FormLayout } from '@saas-ui/forms';
import { Button, useToast } from '@topo-io/design-system';
import { BackButton } from '@/components/common';
import type { FormInviteMember } from '@/components/workspace/common/share-workspace-modal/invite-members-tab/types';
import { useInviteMembersSubmit } from '@/components/workspace/common/share-workspace-modal/invite-members-tab/use-invite-members-submit';

interface CustomizeEmailStepProps {
  form: FormInviteMember;
  workspaceId: string;
  goToStart: () => void;
  goBack: () => void;
}

export const CustomizeEmailStep = ({
  form,
  workspaceId,
  goToStart,
  goBack,
}: CustomizeEmailStepProps): JSX.Element => {
  const { successToast, errorToast } = useToast();
  const onSubmitSuccess = () => {
    successToast({
      title: t`Email sent`,
    });
  };
  const onSubmitFailure = () => {
    errorToast({ title: 'Something went wrong' });
  };
  const { sendPersonalizedInvitation } = useInviteMembersSubmit({
    workspaceId,
    editingMemberId: undefined,
    onSubmitSuccess,
    onSubmitFailure,
    onSubmitCompleted: goToStart,
  });

  return (
    <>
      <BackButton onClick={goBack} text={t`go back`} />
      <FormLayout my="4">
        <Field
          type="text"
          name="subject"
          label={t`Subject`}
          placeholder={t`Your subject`}
          isRequired
        />
        <Field
          type="textarea"
          name="body"
          label="Email"
          placeholder={t`Your email body`}
          isRequired
        />
      </FormLayout>

      <Button onClick={form.handleSubmit(sendPersonalizedInvitation)}>
        <Trans>Send invitation</Trans>
      </Button>
    </>
  );
};
