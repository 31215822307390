import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { ButtonProps, UploadedFile } from '@topo-io/design-system';
import { FileUploadModal, Button, Icon, useDisclosure } from '@topo-io/design-system';
import { TaskAction, useFilesUsagesQuery } from '@topo-io/graphql';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';
import { TaskDownloadFilesModal } from './task-download-files-modal';

export interface TaskActionButtonProps extends ButtonProps {
  taskId: string;
  withIcon?: boolean;
}

export const UploadFileActionItem: FC<TaskActionButtonProps> = ({
  taskId: _,
  withIcon = true,
  ...rest
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { createFile, createFileUsage } = useTaskContext();

  const handleUploadCompleted = (uploadedFile: UploadedFile) => {
    void createFileUsage(uploadedFile.fileId);
    onClose();
  };

  return (
    <>
      <Button
        {...rest}
        leftIcon={withIcon ? <Icon icon={'AddFile'} /> : undefined}
        size="sm"
        data-testid={`action-${TaskAction.UPLOAD_FILE}`}
        onClick={onOpen}
      >
        <Trans>Upload File</Trans>
      </Button>

      <FileUploadModal
        isOpen={isOpen}
        onClose={onClose}
        onUploadCompleted={handleUploadCompleted}
        accept="file"
        createFile={createFile}
      />
    </>
  );
};

export const DownloadFilesActionItem: FC<TaskActionButtonProps> = ({
  taskId,
  withIcon = true,
  ...rest
}) => {
  const { data } = useFilesUsagesQuery({
    variables: { input: { taskId: taskId } },
  });
  // Here we set the fileUsage's id as `fileId` because this is the attribute expected in the
  // `FileLike` interface that is expected below.
  // This `fileId` is used as key when we loop as well as argument when the `deleteFile` function is
  // called in the preview.
  // In our case, we only want to display the file so the `deleteFile` should never be called which
  // makes setting this value armless.
  const files = useMemo(
    () => (data?.filesUsages ?? []).map((file) => ({ ...file, fileId: file.id })),
    [data?.filesUsages]
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        {...rest}
        leftIcon={withIcon ? <Icon icon={'download'} /> : undefined}
        size="sm"
        data-testid={`action-${TaskAction.DOWNLOAD_FILES}`}
        onClick={onOpen}
      >
        <Trans>Download</Trans>
      </Button>
      <TaskDownloadFilesModal files={files} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
