import { Trans } from '@lingui/macro';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Button, Box, Text } from '@topo-io/design-system';
import type { LegacyActivitiesQuery, WorkspaceLegacyActivitiesQuery } from '@topo-io/graphql';
import { formatDate } from '@topo-io/utils';
import { Loader } from '@/components/common/loader';
import { TimelineDay } from './timeline-day';
import type { TimelineActivities } from './timeline.types';

const mapActivitiesByday = (activities: TimelineActivities) => {
  const activitiesByDay: Record<string, TimelineActivities> = {};
  activities.forEach((activity) => {
    const date = new Date(activity.createdAt);
    const day = formatDate(date);
    if (activitiesByDay[day]) {
      activitiesByDay[day].push(activity);
    } else {
      activitiesByDay[day] = [activity];
    }
  });
  return activitiesByDay;
};

const getActivitiesDayArray = (activities: TimelineActivities) => {
  const activitiesByDay = mapActivitiesByday(activities);
  const activitiesDayArray = Object.keys(activitiesByDay).map((day) => ({
    day,
    activities: activitiesByDay[day],
  }));
  return activitiesDayArray;
};

export type ActivitiesTimeline = (
  | LegacyActivitiesQuery['legacyActivities']
  | WorkspaceLegacyActivitiesQuery['workspaceLegacyActivities']
)['activities'];
export interface ActivitiesTimelineProps {
  onClickMore: () => void;
  loading: boolean;
  everyActivityDisplayed: boolean;
  activities: ActivitiesTimeline;
  withBorder?: boolean;
}

export const ActivitiesTimeline: FC<ActivitiesTimelineProps> = ({
  onClickMore,
  everyActivityDisplayed,
  loading,
  activities,
  withBorder = true,
}) => {
  const dailyActivities = useMemo(() => getActivitiesDayArray(activities), [activities]);

  return (
    <>
      {activities?.length > 0 && (
        <Box borderWidth={withBorder ? '1px' : '0px'} borderRadius="md" bg="cards.bg.color">
          {dailyActivities.map((dailyActivity) => (
            <TimelineDay dailyActivity={dailyActivity} key={dailyActivity.day} />
          ))}
        </Box>
      )}
      {everyActivityDisplayed && activities?.length === 0 && (
        <Text>
          <Trans>No activity yet</Trans>
        </Text>
      )}
      {!everyActivityDisplayed && loading && <Loader />}
      {!everyActivityDisplayed && !loading && (
        <Button my="4" onClick={onClickMore}>
          <Trans>Load more</Trans>
        </Button>
      )}
    </>
  );
};
