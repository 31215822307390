export const enum SectionTab {
  CONTENT = 'CONTENT',
  DETAILS = 'DETAILS',
}

export type SectionTemplateTabType = SectionTab.CONTENT | SectionTab.DETAILS;

export const SECTION_TEMPLATE_TAB_NAMES: SectionTemplateTabType[] = [
  SectionTab.CONTENT,
  SectionTab.DETAILS,
] satisfies SectionTemplateTabType[];

export const SECTION_TAB_TO_SLUG: Record<SectionTemplateTabType, string> = {
  [SectionTab.CONTENT]: 'overview',
  [SectionTab.DETAILS]: 'details',
};
