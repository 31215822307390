import { useCallback } from 'react';
import { EventTrackingEvents } from '@topo-io/constants';
import {
  useSignoutMutation,
  useSigninMutation,
  useSignupMutation,
  useViewerSigninMutation,
  useApolloClient,
} from '@topo-io/graphql';
import type {
  SignupMutationVariables,
  SigninMutationVariables,
  ApolloError,
  ViewerSigninMutationVariables,
  JwtUserInfoObject,
  SignupMutationResult,
  UserProfileAuthObject,
} from '@topo-io/graphql';
import { useAccessToken } from './use-access-token';
import { useAnalytics } from './use-analytics';
import { useSlug } from './use-slug';

type CallbackFn = (options?: { user?: JwtUserInfoObject; organizationSlug?: string }) => void;
type SignUpCallbackFn = (data: SignupMutationResult['data']) => void;
type SigninFn = (
  variables: SigninMutationVariables,
  callback?: CallbackFn,
  onMultiProfileCallback?: (profiles: UserProfileAuthObject[]) => void
) => Promise<void>;
type SigninWithProfileFn = (profile: UserProfileAuthObject, callback?: CallbackFn) => void;
type SignupFn = (variables: SignupMutationVariables, callback?: SignUpCallbackFn) => Promise<void>;
type SignoutFn = (callback?: CallbackFn) => Promise<void>;
type ViewerSigninFn = (
  variables: ViewerSigninMutationVariables,
  callback?: CallbackFn,
  profileSelectionCallback?: CallbackFn
) => Promise<void>;

interface UseAuthReturnType {
  signIn: SigninFn;
  signInWithProfile: SigninWithProfileFn;
  signUp: SignupFn;
  signOut: SignoutFn;
  viewerSignIn: ViewerSigninFn;
  error?: ApolloError;
  loading: boolean;
}

export const useAuth = (): UseAuthReturnType => {
  const apolloClient = useApolloClient();
  const { onUserChanged } = useAnalytics();
  const [signinMutation, signinData] = useSigninMutation();
  const [signupMutation, signupData] = useSignupMutation();
  const [signoutMutation, signoutData] = useSignoutMutation();
  const [viewerSigninMutation, viewerSigninData] = useViewerSigninMutation();
  const { setAccessTokenFromString, clearAccessToken } = useAccessToken();
  const { setSlug } = useSlug();

  const signInWithProfile: SigninWithProfileFn = useCallback(
    (profile, callback) => {
      onUserChanged(EventTrackingEvents.SIGN_IN);
      const { frontendToken } = profile;
      if (frontendToken) {
        setAccessTokenFromString(frontendToken);
      }

      if (frontendToken && callback) {
        callback({ organizationSlug: profile.organizationSlug });
      }
    },
    [onUserChanged, setAccessTokenFromString]
  );

  const signIn: SigninFn = useCallback(
    async (variables, callback, onMultiProfileCallback) => {
      const { data } = await signinMutation({ variables });
      if (!data?.signin) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (data.signin.profiles.length > 1) {
        onMultiProfileCallback?.(data.signin.profiles);
        return;
      }

      signInWithProfile(data.signin.profiles[0], callback);
    },
    [signInWithProfile, signinMutation]
  );

  const signUp: SignupFn = useCallback(
    async (variables, callback) => {
      const { data } = await signupMutation({ variables });
      if (!data?.signup) {
        return;
      }

      onUserChanged(EventTrackingEvents.SIGN_UP);
      const { frontendToken } = data.signup;
      if (frontendToken) {
        setAccessTokenFromString(frontendToken);
      }

      if (frontendToken && callback) {
        callback(data);
      }
    },
    [onUserChanged, setAccessTokenFromString, signupMutation]
  );

  const signOut: SignoutFn = useCallback(
    async (callback) => {
      await signoutMutation();
      await apolloClient.clearStore();
      setSlug(undefined);
      onUserChanged(EventTrackingEvents.SIGN_OUT);
      clearAccessToken();

      if (callback) {
        callback();
      }
    },
    [signoutMutation, apolloClient, setSlug, onUserChanged, clearAccessToken]
  );

  const viewerSignIn: ViewerSigninFn = useCallback(
    async (variables, callback) => {
      const { data } = await viewerSigninMutation({ variables });
      if (!data?.viewerSignin || !('frontendToken' in data.viewerSignin)) {
        return;
      }

      const { frontendToken } = data.viewerSignin;
      if (frontendToken) {
        setAccessTokenFromString(frontendToken);
      }

      if (frontendToken && callback) {
        const user = 'user' in data.viewerSignin ? data.viewerSignin.user : undefined;
        const slug =
          'organizationSlug' in data.viewerSignin ? data.viewerSignin.organizationSlug : undefined;
        callback({ user, organizationSlug: slug });
      }
    },
    [setAccessTokenFromString, viewerSigninMutation]
  );

  const error =
    signinData?.error ?? viewerSigninData?.error ?? signupData?.error ?? signoutData?.error;

  const loading = signinData?.loading || viewerSigninData?.loading || signupData?.loading;

  return { signIn, signUp, signOut, viewerSignIn, error, loading, signInWithProfile };
};
