import { useMemo } from 'react';
import { useOpportunityStagesQuery } from '@topo-io/graphql';
import { useIntegrations } from '@/components/integrations/hooks';

export const useOpportunityStages = () => {
  const { isConnectedToOneIntegration } = useIntegrations();
  const { data, loading } = useOpportunityStagesQuery({
    skip: !isConnectedToOneIntegration,
  });
  const stages = useMemo(() => data?.opportunityStages ?? [], [data?.opportunityStages]);

  return {
    isFieldDisabled: !isConnectedToOneIntegration,
    stages,
    loading,
  };
};
