import type { FC } from 'react';
import { Block } from '@topo-io/design-system';
import type { ActivityCommentSection } from '@topo-io/graphql';
import { isNotNil } from '@topo-io/utils';
import type { CommentEditorBaseProps } from '@/components/comments';
import { CommentEditor } from '@/components/comments';
import { CommentEditorProvider } from '@/components/rich-text-editor/providers/comment-editor-provider';

interface PreviewCommentProps {
  comment: Pick<NonNullable<ActivityCommentSection['comment']>, 'content' | 'taskId' | 'sectionId'>;
}

export const PreviewComment: FC<PreviewCommentProps> = ({ comment }) => {
  const commentParentProps = {
    commentParentId: comment.taskId ?? comment.sectionId,
    commentParentType: isNotNil(comment.taskId) ? 'task' : 'section',
  } as CommentEditorBaseProps;

  return (
    <Block w="full" bgColor="gray.20" borderColor="gray.30" p={2}>
      <CommentEditorProvider>
        <CommentEditor state="readonly" defaultComment={comment?.content} {...commentParentProps} />
      </CommentEditorProvider>
    </Block>
  );
};
