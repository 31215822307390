import NextLink from 'next/link';
import type { PropsWithChildren } from 'react';
import { Link as ChakraLink } from '@topo-io/design-system';

interface LinkProps extends PropsWithChildren {
  href: string;
}

export const Link = ({ href, children }: LinkProps) => {
  return (
    <NextLink href={href}>
      <ChakraLink as="span" color="primary.500">
        {children}
      </ChakraLink>
    </NextLink>
  );
};
