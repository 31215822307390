import type { FC, PropsWithChildren } from 'react';
import type { HTMLChakraProps } from '@topo-io/design-system';
import { Icon, HStack, Button } from '@topo-io/design-system';

type AddButtonProps = PropsWithChildren<HTMLChakraProps<'button'>> & {
  containerProps?: HTMLChakraProps<'div'>;
  isDisabled?: boolean;
};

export const AddButton: FC<AddButtonProps> = ({
  children,
  containerProps,
  isDisabled,
  ...props
}) => {
  return (
    <HStack h="40px" {...containerProps}>
      <Button
        leftIcon={<Icon icon="plus-circle" color="primary.500" />}
        variant="ghost"
        w="full"
        px="0"
        colorScheme="grey"
        justifyContent="left"
        fontWeight="normal"
        borderRadius="sm"
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </Button>
    </HStack>
  );
};
