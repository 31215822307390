import { useContext } from 'react';
import { RoadmapContext } from '@/components/roadmap/contexts/roadmap-context';

export const useRoadmapContext = () => {
  const roadmapContext = useContext(RoadmapContext);

  if (!roadmapContext) {
    throw new Error('Context used outside of its Provider!');
  }

  return roadmapContext;
};
