import type { ComponentProps, FC } from 'react';
import { Circle, useColorModeValue, Flex, Icon } from '@topo-io/design-system';
import { Action } from '@topo-io/graphql';
import type { TimelineActivity } from './timeline.types';

export const TimelineItemIcon: FC<{ activity: TimelineActivity; isLast: boolean }> = ({
  activity,
  isLast,
}) => {
  const textColor = useColorModeValue('gray.700', 'white.300');
  const iconMapper: { [key in Action]: { bgColor: string; color: string; iconName: string } } = {
    [Action.ShareWorkspace]: { bgColor: 'green.50', color: 'green.500', iconName: 'send' },
    [Action.OpenWorkspace]: { bgColor: 'blue.50', color: 'blue.500', iconName: 'eye' },
    [Action.CommentSection]: { bgColor: 'blue.50', color: 'blue.500', iconName: 'Comment' },
  };
  const { bgColor: bgIconColor, color: iconColor, iconName } = iconMapper[activity.action];
  const centerPseudoElementsStyle: ComponentProps<typeof Flex> = {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: `1px solid ${textColor}`,
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="transparent"
      color={iconColor}
      pe="6px"
      zIndex="auto"
      position="relative"
      _before={centerPseudoElementsStyle}
      {...(!isLast && { _after: { centerPseudoElementsStyle } })}
    >
      <Circle bg={bgIconColor} p="2.5">
        <Icon boxSize="4" height="14px" width="14px" icon={iconName} strokeWidth="3px" />
      </Circle>
    </Flex>
  );
};
