import type { FC } from 'react';
import { useMemo, useState } from 'react';
import type { useDisclosure } from '@topo-io/design-system';
import { Image, Overlay } from '@topo-io/design-system';
import { useRepositionVerticalAxis } from '@topo-io/hooks';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import type { Cover } from '@/components/workspace/overview/cover/cover-types';
import { CoverHoverActions } from './cover-hover-actions';
import { CoverRepositionActions } from './cover-reposition-actions';

interface CoverImageProps {
  cover: Cover;
  isHovering: boolean;
  fileUploadModalDisclosure: ReturnType<typeof useDisclosure>;
  onSavePosition: (position: number) => void;
  onRemove: () => void;
  onReplace: () => void;
  onCoverError?: () => void;
}

export const CoverImage: FC<CoverImageProps> = ({
  cover,
  isHovering,
  fileUploadModalDisclosure,
  onSavePosition,
  onRemove,
  onReplace,
  onCoverError,
}) => {
  const { readOnly } = useWorkspaceContext();
  const [loaded, setLoaded] = useState(false);
  const [isRepositioning, setIsRepositioning] = useState(false);
  const { elementRef, positionY, onMouseDown, onMouseMove, resetMouse, resetPosition } =
    useRepositionVerticalAxis({
      initialPositionY: cover?.metadata?.positionY,
      isRepositioning,
    });

  const handleSavePosition = () => {
    onSavePosition(positionY);
    setIsRepositioning(false);
    resetMouse();
  };

  const handleCancelReposition = () => {
    setIsRepositioning(false);
    resetMouse();
    resetPosition();
  };

  const imageCursor = useMemo(() => {
    if (readOnly) {
      return 'default';
    }
    return isRepositioning ? 'move' : 'inherit';
  }, [isRepositioning, readOnly]);

  return (
    <>
      {cover.url && (
        <Image
          ref={elementRef}
          h="200px"
          w="full"
          src={cover.url}
          objectFit="cover"
          alt="cover"
          borderRadius="md"
          opacity={loaded ? '1' : '0'}
          onLoad={() => setLoaded(true)}
          onError={() => (onCoverError ? onCoverError() : null)}
          objectPosition={`center ${positionY}%`}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          cursor={imageCursor}
        />
      )}

      {isRepositioning && (
        <CoverRepositionActions onSave={handleSavePosition} onCancel={handleCancelReposition} />
      )}

      {!isRepositioning && !readOnly && (
        <Overlay isOpen={isHovering} backgroundColor="gray.1000" opacity="0.5" />
      )}

      {!readOnly && !isRepositioning && isHovering && (
        <CoverHoverActions
          onRemove={onRemove}
          onReposition={() => setIsRepositioning(true)}
          onReplace={onReplace}
          fileUploadModalDisclosure={fileUploadModalDisclosure}
        />
      )}
    </>
  );
};
