import type { FunctionComponent, ReactNode } from 'react';
import { useEffect } from 'react';
import { Grid, GridItem, useDisclosure } from '@topo-io/design-system';
import { useIntercom } from '@topo-io/hooks';
import { ReferralModal } from '@/components/layout/modal/referral-modal';
import { NAVBAR_HEIGHT } from '@/config';
import { SavingStatusProvider } from '@/state/providers';
import { BaseLayout } from './base-layout';
import { useSidebarWidth } from './hooks';
import { Navbar } from './navbar';
import { Sidebar } from './sidebar';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const { sidebarWidth, show } = useSidebarWidth();
  const { enable } = useIntercom();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    enable();
  }, [enable]);

  return (
    <BaseLayout>
      <SavingStatusProvider>
        <Grid
          templateAreas={'"side nav" "side main"'}
          gridTemplateColumns={`${sidebarWidth} 1fr`}
          gridTemplateRows={`${NAVBAR_HEIGHT} 1fr`}
          h="100vh"
          w="100vw"
          left="0"
          right="0"
          top="0"
          bottom="0"
          position="fixed"
        >
          <GridItem area="nav">
            <Navbar />
          </GridItem>
          <GridItem area="side" borderRightWidth="1px" display={show ? undefined : 'none'}>
            <Sidebar referralOpen={onOpen} />
          </GridItem>
          <GridItem area="main" overflowX="hidden" overflowY="auto" position="relative">
            {children}
          </GridItem>
        </Grid>
      </SavingStatusProvider>
      <ReferralModal isOpen={isOpen} onClose={onClose} />
    </BaseLayout>
  );
};
