import { Trans, t } from '@lingui/macro';
import { Box, HStack, Icon, useDisclosure, useToast } from '@topo-io/design-system';
import { useGenerateSectionMutation } from '@topo-io/graphql';
import { useWorkspaceTabs } from '@/components/workspace/hooks';
import { AISectionModal } from './ai-section-modal';
import type { NewSectionFormValues } from './ai-section-modal';

export const AISectionButton = () => {
  const { errorToast } = useToast();
  const { tabId } = useWorkspaceTabs();

  const modalAISectionDisclosure = useDisclosure();
  const [generateSectionMutation, { loading }] = useGenerateSectionMutation();

  const onSubmit = async (props: NewSectionFormValues) => {
    try {
      if (!tabId) {
        throw new Error('No tabId found');
      }
      await generateSectionMutation({
        variables: {
          data: {
            workspaceTabId: tabId,
            sectionType: props.type,
            userInput: props.input,
          },
        },
      });
      modalAISectionDisclosure.onClose();
    } catch (error) {
      errorToast({
        title: t`An error occurred during generation of your section`,
      });
    }
  };

  return (
    <>
      <HStack
        h="40px"
        w="full"
        backgroundColor="gray.10"
        borderRadius="md"
        borderWidth="1px"
        onClick={modalAISectionDisclosure.onOpen}
        cursor="pointer"
      >
        <Box p="2">
          <Icon icon="Sparkle" mr="-5px" w="12px" h="12px" />
        </Box>
        <Box>
          <Trans>AI section</Trans>
        </Box>
      </HStack>
      <>
        <AISectionModal
          onSubmit={onSubmit}
          loading={loading}
          isOpen={modalAISectionDisclosure.isOpen}
          onClose={modalAISectionDisclosure.onClose}
        />
      </>
    </>
  );
};
