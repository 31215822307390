import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Box, useToken } from '@topo-io/design-system';
import { isEmptyArray, isNotNil, isString, parseDate } from '@topo-io/utils';
import type { EngagementScoreForPeriod } from '@/components/engagement-score/types';
import { TREND_GRAPH_HEIGHT } from '@/components/engagement-score/utils';

const ResponsiveLine = dynamic(() => import('@nivo/line').then((m) => m.ResponsiveLine), {
  ssr: false,
});

const PRECISION = 'day';
const XFORMAT = 'time:%b %d';

interface TrendLineGraphProps {
  scoresForPeriod: EngagementScoreForPeriod[];
}

interface ScoreForDayData {
  x: Date;
  y: number | null;
}

export const TrendLineGraph: FC<TrendLineGraphProps> = ({ scoresForPeriod }) => {
  const [blue300] = useToken('colors', ['primary.300']);

  const chartData = useMemo(() => {
    if (isEmptyArray(scoresForPeriod)) {
      return null;
    }

    const data: ScoreForDayData[] = scoresForPeriod
      .map(({ score, date }) => {
        if (!isString(date)) {
          return;
        }

        const formatedDate = parseDate(date);
        return {
          x: formatedDate,
          y: score,
        };
      })
      .filter(isNotNil);
    return [
      {
        id: 'score-trend',
        color: blue300,
        data,
      },
    ];
  }, [blue300, scoresForPeriod]);

  return (
    <Box h={TREND_GRAPH_HEIGHT}>
      <ResponsiveLine
        isInteractive={false}
        data={chartData ?? []}
        curve="cardinal"
        margin={{ top: 10, right: 2, bottom: 2, left: 2 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: PRECISION,
          nice: true,
          useUTC: false,
        }}
        xFormat={XFORMAT}
        axisBottom={{
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          renderTick: () => <></>,
        }}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        enableCrosshair={false}
        enableArea={true}
        axisLeft={{
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          tickValues: 0,
          legend: '',
          legendPosition: 'end',
          legendOffset: -8,
        }}
        yFormat=" >-.0d"
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        colors={[blue300]}
        lineWidth={2}
        areaBaselineValue={0}
        useMesh={true}
      />
    </Box>
  );
};
