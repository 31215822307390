import { useMemo } from 'react';
import type { Member } from '@topo-io/graphql';
import { useMembersQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { MemberMenu } from '@/components/common/member-menu';
import { useRoadmapContext } from '@/components/roadmap/hooks';
import { useUpdateTaskAssigneesMutationWithCache } from '@/components/roadmap/hooks/use-update-task-assignees-mutation-with-cache';
import type { RoadmapTask } from '@/components/roadmap/types';
import { useTemplate } from '@/components/workspace/hooks/use-template';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import 'react-day-picker/dist/style.css';

export type MemberWithoutRelations = Omit<Partial<Member>, 'company'>;

export const Assignees = ({ task, stepId }: { task: RoadmapTask; stepId: string }) => {
  const { id, isWorkspace } = useWorkspaceContext();
  const { canEditRoadmap } = useRoadmapContext();

  const { template } = useTemplate(id!, { skip: isNil(id) || isWorkspace });
  const { updateTaskAssigneesWithCache } = useUpdateTaskAssigneesMutationWithCache();

  const { data: workspaceMembersData } = useMembersQuery({
    variables: { workspaceId: id! },
    skip: isNil(id),
  });

  const members = useMemo(
    () =>
      (workspaceMembersData?.members ?? template?.members ?? []).map(
        ({ userProfileId, firstName, lastName, email }) => ({
          id: userProfileId,
          userProfileId,
          firstName,
          lastName,
          email,
        })
      ),
    [template?.members, workspaceMembersData?.members]
  );

  const editAssignees = (member: MemberWithoutRelations) => {
    if (member.id) {
      updateTaskAssigneesWithCache({ taskId: task.id, assignee: member, stepId });
    }
  };

  return (
    <MemberMenu
      allMembers={members}
      selectedMembers={task.assignees}
      onMemberSelect={editAssignees}
      readOnly={!canEditRoadmap}
    />
  );
};
