import { useCallback } from 'react';
import { useDeleteWorkspaceMutation, WorkspacesDocument } from '@topo-io/graphql';

export const useDeleteWorkspace = (id: string) => {
  const [deleteWorkspace, { loading }] = useDeleteWorkspaceMutation();

  const callback = useCallback(async () => {
    await deleteWorkspace({ variables: { id }, refetchQueries: [WorkspacesDocument] });
  }, [deleteWorkspace, id]);

  return { deleteWorkspace: callback, loading };
};
