import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useWorkspace } from '@/components/workspace/hooks/use-workspace';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { ANALYTICS_PING_INTERVAL } from '@/config';
import { useTrackWorkspace } from './use-track-workspace';

interface Args {
  organizationId: string | undefined;
  skip?: boolean;
}

export const useWorkspaceActivityTracking = ({ organizationId, skip = false }: Args) => {
  const { workspaceViewId, id: workspaceId, setWorkspaceViewId } = useWorkspaceContext();
  const { workspace } = useWorkspace(workspaceId!, { skip: !workspaceId });

  const { trackWorkspace } = useTrackWorkspace({
    skip,
    workspaceId,
    workspaceTemplateId: workspace?.workspaceTemplateId,
    organizationId,
  });
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [hasSentWorkspaceView, setHasSentWorkspaceView] = useState(false);

  // set workspaceViewId on mount
  useEffect(() => {
    setWorkspaceViewId(uuid());
  }, [setWorkspaceViewId]);

  // send workspace view after 3 seconds
  useEffect(() => {
    if (skip) {
      return;
    }
    if (hasSentWorkspaceView) {
      clearInterval(timer.current ?? undefined);
      timer.current = null;
      return;
    }
    timer.current = setInterval(() => {
      trackWorkspace({
        action: 'view',
        workspaceViewId,
      });
      setHasSentWorkspaceView(true);
    }, ANALYTICS_PING_INTERVAL);

    return () => clearInterval(timer.current ?? undefined);
  }, [skip, hasSentWorkspaceView, trackWorkspace, workspaceViewId]);
};
