import { DndContext } from '@dnd-kit/core';
import { Box } from '@topo-io/design-system';
import { isEmptyArray } from '@topo-io/utils';
import {
  useDragAndDropSectionTemplateContext,
  useWorkspaceTabSections,
} from '@/components/section/hooks';
import { useWorkspaceTabs } from '@/components/workspace/hooks';
import { SectionsSummaryEmptyState } from '@/components/workspace/overview/sections-summary-empty-state';
import { SectionLibraryList } from './section-library-list';

export const SectionsSummary = () => {
  const { tabId } = useWorkspaceTabs();
  const { sections } = useWorkspaceTabSections({ workspaceTabId: tabId });
  const { handleSectionDragEnd, sensors } = useDragAndDropSectionTemplateContext();

  return (
    <Box w="full" h="full" overflowY="auto">
      {isEmptyArray(sections) ? (
        <SectionsSummaryEmptyState />
      ) : (
        <DndContext onDragEnd={handleSectionDragEnd} sensors={sensors}>
          <SectionLibraryList sections={sections} />
        </DndContext>
      )}
    </Box>
  );
};
