import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Block, Box, HStack, Spacer, useDisclosure } from '@topo-io/design-system';
import { isNotNil } from '@topo-io/utils';
import { SectionEditor } from '@/components/rich-text-editor/section-editor';
import { SectionCtaComment } from '@/components/section/section-cta-comment';
import { useWorkspaceContext } from '@/components/workspace/hooks';
import { useDebouncedUpdateSectionContent } from './hooks/use-debounced-update-section-content';
import { DesynchronizeSectionFromTemplateModal, SaveSectionAsTemplateModal } from './modals';
import { OverlayTemplateSection } from './overlay-template-section';
import { SectionsActionsToolbar } from './section-actions-toolbar';
import { SectionTitle } from './section-title';
import { TemplateSectionActions } from './template-section-actions';
import type { BasicSectionProps } from './types';

interface WorkspaceContentSectionProps {
  index: number;
  templateId?: string;
  show: boolean;
  numberOfComments: number;
}

type SectionWorkspaceEditorModeProps = BasicSectionProps & WorkspaceContentSectionProps;

export const SectionWorkspaceEditorMode = memo((props: SectionWorkspaceEditorModeProps) => {
  const {
    id: sectionId,
    sectionContentId,
    templateId,
    index,
    show,
    numberOfComments,
    updateSectionContent,
    updateSectionTitle,
    sectionContent,
    sectionTitle,
  } = props;

  const { isWorkspace, addSectionRef } = useWorkspaceContext();
  const setContent = useDebouncedUpdateSectionContent({
    id: sectionId,
    updateSectionContent,
  });
  const {
    isOpen: isOpenDesynchronize,
    onOpen: onOpenDesynchronize,
    onClose: onCloseDesynchronize,
  } = useDisclosure();
  const {
    isOpen: isOpenSaveAsTemplate,
    onOpen: onOpenSaveAsTemplate,
    onClose: onCloseSaveAsTemplate,
  } = useDisclosure();

  const sectionRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [shouldFocusContent, setShouldFocusContent] = useState(false);

  useEffect(() => {
    if (sectionRef.current) {
      addSectionRef(sectionId, sectionRef);
    }
  }, [sectionRef, sectionId, addSectionRef]);

  const onClickMenuItemDesynchronize = useCallback(() => {
    onOpenDesynchronize();
  }, [onOpenDesynchronize]);

  const onClickMenuItemSaveAsTemplate = useCallback(() => {
    onOpenSaveAsTemplate();
  }, [onOpenSaveAsTemplate]);

  const showOverlay = () => {
    setIsHovering(true);
  };

  const hideOverlay = () => {
    setIsHovering(false);
  };

  const handleEnterKeyPress = () => {
    // Resets focus immediately to allow jumping to content behavior to be repeated multiple times on hitting Enter
    setShouldFocusContent(true);
    setTimeout(() => {
      setShouldFocusContent(false);
    }, 50);
  };

  const isHiddenSection = !show;
  const isTemplate = isNotNil(templateId);

  return (
    <>
      <Box
        ref={sectionRef}
        aria-label="section-workspace-editor-mode"
        id={`section-${sectionId}`}
        position="relative"
        onMouseEnter={showOverlay}
        onMouseLeave={hideOverlay}
        {...(isHiddenSection && { opacity: '0.5' })}
      >
        <Block my={2}>
          {isTemplate && isHovering && <OverlayTemplateSection />}
          <HStack alignContent="center" mb="4">
            <SectionTitle
              id={sectionId}
              readOnly={isTemplate}
              title={sectionTitle}
              updateSectionTitle={updateSectionTitle}
              onEnterKeyPress={handleEnterKeyPress}
            />
            <Spacer />
            <>
              {isTemplate && (
                <DesynchronizeSectionFromTemplateModal
                  isOpen={isOpenDesynchronize}
                  onClose={onCloseDesynchronize}
                  sectionId={sectionId}
                  sectionTemplateId={templateId}
                />
              )}
              <SaveSectionAsTemplateModal
                defaultTitle={sectionTitle}
                sectionId={sectionId}
                isOpen={isOpenSaveAsTemplate}
                onClose={onCloseSaveAsTemplate}
              />
              {isTemplate && isHovering && (
                <TemplateSectionActions
                  sectionTemplateId={templateId}
                  onClickMenuItemDesynchronize={onClickMenuItemDesynchronize}
                />
              )}
              {isWorkspace && (
                <SectionCtaComment sectionId={sectionId} numberOfComments={numberOfComments} />
              )}
              <SectionsActionsToolbar
                sectionId={sectionId}
                templateId={templateId}
                index={index}
                onClickMenuItemSaveSectionAsTemplate={onClickMenuItemSaveAsTemplate}
                onClickMenuItemDesynchronize={onClickMenuItemDesynchronize}
                show={show}
              />
            </>
          </HStack>
          <SectionEditor
            sectionId={sectionId}
            initialContent={sectionContent}
            setContent={setContent}
            // readOnly false to display variables as Badge
            // For templates, the OverlayTemplateSection will prevent the user from editing the content
            readOnly={false}
            shouldFocusContent={shouldFocusContent}
            sectionContentId={sectionContentId}
          />
        </Block>
      </Box>
    </>
  );
});

SectionWorkspaceEditorMode.displayName = 'SectionWorkspaceEditorMode';
