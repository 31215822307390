import { Trans, t } from '@lingui/macro';
import { Field, FormLayout, useFormContext } from '@saas-ui/forms';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { Box, Button, ModalSection, Text, usePrevious } from '@topo-io/design-system';
import { isEmptyArray, isNil, isNotNil } from '@topo-io/utils';
import type { Contact } from '@/components/common/fields/crm-contacts-field';
import { CRMContactsField } from '@/components/common/fields/crm-contacts-field';
import type { InviteMemberFormValues } from '@/components/workspace/common/share-workspace-modal/invite-members-tab/types';
import { useWorkspace, useWorkspaceContext } from '@/components/workspace/hooks';
import { useSubscription } from '@/hooks/use-subscription';

interface AddEmailStepProps {
  goToNextStep: (email: string | undefined) => void;
  membersEmails: string[];
}

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const AddEmailStep = ({ membersEmails, goToNextStep }: AddEmailStepProps) => {
  const { id, isWorkspace, isGuest, isPreview } = useWorkspaceContext();
  const { workspace } = useWorkspace(id!, { skip: isNil(id) || !isWorkspace });
  const { setValue } = useFormContext<InviteMemberFormValues>();
  const { hasCRMFeatures } = useSubscription();

  // Annoyingly, the combobox used in the CRMContactsField clears the data on blur.
  // In our case, this means that the email value is lost when clicking on the Invite button.
  // To handle this, we cache the typed value and use it instead when clicking on the invite button.
  const [searchTerm, setSearchTerm] = useState('');
  const cachedSearchTerm = usePrevious(searchTerm);

  const handleOnSuggestedInvitationClick = () => {
    setValue('email', cachedSearchTerm);
    goToNextStep(cachedSearchTerm);
  };

  const handleOnSuggestionSelection = (contact: Contact | undefined) => {
    if (isNil(contact)) {
      return;
    }

    setValue('email', contact.email);
    setValue('firstName', contact.firstName);
    setValue('lastName', contact.lastName);
    setValue('CRMId', contact.crmId);
    setValue(
      'CRMAccount',
      contact.account && { crmId: contact.account.crmId, name: contact.account.name }
    );
    goToNextStep(contact.email);
  };

  const handleOnChange = (value: string | null) => {
    setSearchTerm(value ?? '');
  };

  const handleOnChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChange(event.target.value);
  };

  const shouldUseContactSuggestionField =
    isNotNil(workspace?.CRMAssociation?.CRMAccount.id) && !isGuest && !isPreview && hasCRMFeatures;

  return (
    <>
      <ModalSection title={t`Invite members`}>
        {shouldUseContactSuggestionField && (
          <FormLayout templateColumns="1fr auto" gap="2">
            <CRMContactsField
              placeholder="Email"
              name="contact"
              crmAccountId={workspace?.CRMAssociation?.CRMAccount.id}
              onSuggestionSelection={handleOnSuggestionSelection}
              onChange={handleOnChange}
              suggestionPlaceholder={t`CRM Suggestions`}
            />

            <Button type="submit" onClick={handleOnSuggestedInvitationClick}>
              <Trans>Invite</Trans>
            </Button>
          </FormLayout>
        )}

        {!shouldUseContactSuggestionField && (
          <FormLayout templateColumns="1fr auto" gap="2">
            <Field
              type="email"
              name="email"
              placeholder="Email"
              rules={{
                required: true,
                pattern: EMAIL_REGEX,
              }}
              onChange={handleOnChangeEvent}
            />
            <Button type="submit" onClick={() => goToNextStep(searchTerm)}>
              <Trans>Invite</Trans>
            </Button>
          </FormLayout>
        )}
      </ModalSection>

      <ModalSection title={t`Members`}>
        <Box maxHeight="400" overflowY="auto">
          {isEmptyArray(membersEmails) ? (
            <Text color="gray.500">
              <Trans>No members invited yet</Trans>
            </Text>
          ) : (
            membersEmails.map((memberEmail, index) => <Box key={index}>{memberEmail}</Box>)
          )}
        </Box>
      </ModalSection>
    </>
  );
};
