import { Box, Flex } from '@topo-io/design-system';
import { getDisplayName } from '@topo-io/utils';
import { useUser } from '@/hooks';
import { SidebarProfilePicture } from './sidebar-profile-picture';

interface SidebarUserProps {
  isExpanded?: boolean;
  onClick: () => void;
}

export const SidebarUser = ({ isExpanded, onClick }: SidebarUserProps) => {
  const { user, isAnonymousUser } = useUser();

  if (isAnonymousUser(user)) {
    return null;
  }

  const name = getDisplayName(user);

  return (
    <Flex
      height="40px"
      align="center"
      gap="2"
      maxWidth="100%"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      px={isExpanded ? '2' : '0px'}
      onClick={onClick}
    >
      <SidebarProfilePicture user={user} isExpanded={isExpanded} />
      {isExpanded && <Box color="gray.50">{String(name)}</Box>}
    </Flex>
  );
};
