import Link from 'next/link';
import { Box, Flex, H3 } from '@topo-io/design-system';

export const MaintenanceMode = () => {
  return (
    <Flex w="full" h="full" alignItems="center" justifyContent="center">
      <Box overflowY="auto">
        <H3>Topo is under maintenance.</H3>
        <Box color="primary.500">
          <Link href="https://www.topo.io/">Go to the homepage</Link>
        </Box>
      </Box>
    </Flex>
  );
};
