import { t } from '@lingui/macro';
import { useMemo } from 'react';
import type { MultiSelectItem } from '@topo-io/design-system';
import { useCategoriesQuery } from '@topo-io/graphql';
import { hash } from '@topo-io/utils';
import type { MultiSelectFieldProps } from './multi-select-field';
import { MultiSelectField } from './multi-select-field';

type TagsFieldProps = Omit<MultiSelectFieldProps, 'items'>;

export const TagsField = (props: TagsFieldProps) => {
  const { data, loading: isLoading } = useCategoriesQuery();

  const items: MultiSelectItem[] = useMemo(() => {
    const categories = data?.categories ?? [];
    return categories.flatMap((category) => {
      const group = { label: category.name, value: category.id };
      return category.tags.map((tag) => ({
        label: tag.name,
        value: tag.id,
        category: group,
      }));
    });
  }, [data?.categories]);

  return (
    <MultiSelectField
      key={hash(items)}
      {...props}
      placeholder={t`Add tags`}
      emptyStateText={t`No tags yet`}
      items={items}
      isSearchable
      isDisabled={isLoading}
    />
  );
};
