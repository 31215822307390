import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { isEmptyArray, isEmptyObject, throttle } from '@topo-io/utils';
import { ANALYTICS_WAITING_TIME } from '@/config';

type SectionsRefs = Record<string, RefObject<HTMLDivElement>>;

const getVisibleSections = (sectionsRefs: SectionsRefs) => {
  const halfVisibleIds: string[] = [];
  const partiallyVisibleIds: string[] = [];
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  const sections = Object.entries(sectionsRefs).map(([id, ref]) => ({
    id,
    element: ref.current,
  }));

  if (isEmptyArray(sections)) {
    return [];
  }

  sections.forEach((section) => {
    const { element } = section;
    if (!element) {
      return false;
    }
    const rect = element.getBoundingClientRect();
    const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);

    if (visibleHeight >= 0.5 * rect.height) {
      halfVisibleIds.push(section.id);
    } else if (visibleHeight >= 0.1 * rect.height) {
      partiallyVisibleIds.push(section.id);
    }
  });
  if (halfVisibleIds.length > 0) {
    return halfVisibleIds;
  }
  if (partiallyVisibleIds.length > 0) {
    return [partiallyVisibleIds[0]];
  }
  return [];
};

interface UseVisibleSectionsArgs {
  overviewRef: React.RefObject<HTMLDivElement>;
  sectionsRefs: SectionsRefs;
}

export const useVisibleSections = ({ overviewRef, sectionsRefs }: UseVisibleSectionsArgs) => {
  const [visibleSections, setVisibleSections] = useState<string[]>([]);

  const handleScroll = useCallback(() => {
    if (isEmptyObject(sectionsRefs)) {
      return;
    }
    setVisibleSections(getVisibleSections(sectionsRefs));
  }, [sectionsRefs]);

  useEffect(() => {
    const overviewRefComponent = overviewRef?.current;
    // Trigger first scroll event after 1 second to make sure the DOM is ready
    setTimeout(handleScroll, ANALYTICS_WAITING_TIME);

    const throttledHandleScroll = throttle({ interval: 500 }, handleScroll);

    overviewRefComponent?.addEventListener('scroll', throttledHandleScroll);
    return () => {
      overviewRefComponent?.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [overviewRef, handleScroll]);

  return visibleSections;
};
