import { Trans } from '@lingui/macro';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import Link from 'next/link';
import { Box, Flex, Icon } from '@topo-io/design-system';
import type {
  WorkspaceSearchRecord,
  WorkspaceSearchRecordWithEngagementScore,
} from '@topo-io/types';
import { ZERO, formatLocalizedDate } from '@topo-io/utils';
import { EngagementScoreDateRange, EngagementScoreTooltip } from '@/components/engagement-score';
import { Actions } from '@/components/workspace/list/actions';
import { Members } from '@/components/workspace/list/members';
import { WorkspaceTitle } from '@/components/workspace/list/workspace-title';
import { workspaceUrl } from '@/config';

type WorkspaceListColumns = ColumnDef<WorkspaceSearchRecordWithEngagementScore>[];

interface WorkspaceListColumnsOptions {
  isEngagementScoreLoading: boolean;
  onWorkspaceDeletionSuccess: (id: string) => void;
}

export const buildWorkspaceListColumns = ({
  isEngagementScoreLoading,
  onWorkspaceDeletionSuccess,
}: WorkspaceListColumnsOptions): WorkspaceListColumns => {
  const columns: WorkspaceListColumns = [
    {
      id: 'title',
      header: () => <Trans>Workspace</Trans>,
      enableSorting: true,
      cell: (props) => (
        <Link href={workspaceUrl(props.row.original.id)}>
          <WorkspaceTitle title={props.row.original.title} company={props.row.original.company} />
        </Link>
      ),
      meta: {
        isLink: true,
      },
    },
    {
      id: 'engagementScore',
      header: () => <Trans>Scoring</Trans>,
      cell: (props) => (
        <EngagementScoreTooltip
          isScoreLoading={isEngagementScoreLoading}
          score={props.row.original.engagementScore?.score}
          workspaceId={props.row.original.id}
          dateRange={EngagementScoreDateRange.Last30Days}
          withGraph
        />
      ),
    },
    {
      id: 'value',
      header: () => <Trans>Value</Trans>,
      enableSorting: true,
      accessorFn: ({ value }) => (value ? `${value}${'\u00A0'}€` : ''),
    },
    {
      id: 'stage',
      header: () => <Trans>Stage</Trans>,
      enableSorting: true,
      accessorKey: 'stage',
    },
    {
      id: 'closeDate',
      header: () => <Trans>Expected Close Date</Trans>,
      enableSorting: true,
      accessorFn: ({ closeDate }) => (closeDate ? formatLocalizedDate(closeDate) : '-'),
    },
    {
      id: 'views',
      header: () => <Trans>Views</Trans>,
      cell: (cellContext) => {
        const { viewsCount } = cellContext.row.original;
        return (
          <Flex gap="4px" alignItems="center">
            <Icon icon="eye" color="gray.80" />
            {viewsCount ?? ZERO}
          </Flex>
        );
      },
    },
    {
      id: 'lastViewDate',
      header: () => <Trans>Last view</Trans>,
      accessorFn: ({ lastViewDate }) => (lastViewDate ? formatLocalizedDate(lastViewDate) : '-'),
    },
    {
      id: 'owner',
      header: () => <Trans>Owner</Trans>,
      accessorKey: 'owner',
      cell: (cellContext: CellContext<WorkspaceSearchRecord, WorkspaceSearchRecord['owner']>) => (
        <Box>
          {cellContext.getValue().firstName} {cellContext.getValue().lastName}
        </Box>
      ),
    },
    {
      id: 'members',
      header: () => <Trans>Members</Trans>,
      accessorKey: 'members',
      cell: (cell) => {
        const members = cell.getValue<WorkspaceSearchRecord['members']>() ?? [];
        return <Members members={members} size="sm" />;
      },
    },
    {
      id: 'actions',
      meta: { isClickable: false },
      header: undefined,
      cell: (cell) => (
        <Actions
          id={cell.row.original.id}
          onDeleteSuccess={() => onWorkspaceDeletionSuccess(cell.row.original.id)}
        />
      ),
    },
  ];
  return columns;
};
