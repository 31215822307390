import type { FC } from 'react';
import { TaskAction } from '@topo-io/graphql';
import { isEmptyArray } from '@topo-io/utils';
import { TaskProvider } from '@/components/roadmap/contexts/task-context';
import {
  UploadFileActionItem,
  DownloadFilesActionItem,
} from '@/components/roadmap/roadmap-task-drawer/task-action-button';
import type { TaskActionButtonProps } from '@/components/roadmap/roadmap-task-drawer/task-action-button';

const ActionToButtonMap: Record<TaskAction, FC<TaskActionButtonProps>> = {
  [TaskAction.UPLOAD_FILE]: UploadFileActionItem,
  [TaskAction.DOWNLOAD_FILES]: DownloadFilesActionItem,
};

interface TaskActionsProps {
  taskId: string;
  actions: TaskAction[];
  opacity?: string;
}

export const TaskActions: FC<TaskActionsProps> = ({ taskId, actions, opacity }) => {
  if (isEmptyArray(actions)) {
    return null;
  }

  return (
    <TaskProvider taskId={taskId}>
      {actions.map((action) => {
        const ActionComponent = ActionToButtonMap[action];
        return (
          <ActionComponent
            key={action}
            taskId={taskId}
            withIcon={false}
            colorScheme="gray"
            opacity={opacity}
          />
        );
      })}
    </TaskProvider>
  );
};
