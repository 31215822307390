import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { isNil } from '@topo-io/utils';
import { pricingUrl } from '@/config';
import { useUser } from '@/hooks';
import { useSubscription } from '@/hooks/use-subscription';

export const useSubscriptionRedirect = () => {
  const router = useRouter();
  const { selfService } = useFlags();
  const { subscription, isLoading } = useSubscription();
  const { isConnected } = useUser();

  useEffect(() => {
    const location = router.asPath;
    // If we are already on the pricing page, don't redirect
    if (location.includes('/pricing')) {
      return;
    }
    // If the feature flag is loading or not active, don't redirect
    const isFlagLoading = isNil(selfService);
    if (isLoading || isFlagLoading || !selfService) {
      return;
    }
    // If the user is not connected, don't redirect
    if (!isConnected) {
      return;
    }
    if (isNil(subscription) || !subscription.isActive) {
      void router.push(pricingUrl());
    }
  }, [subscription, isLoading, router, selfService, isConnected]);
};
