import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() ?? {};
const all: Record<string, string> = {
  ...publicRuntimeConfig,
  ...serverRuntimeConfig,
};
const server = serverRuntimeConfig ?? {};

export { all, server };
