import type { FC } from 'react';
import { Box, Flex, H5, Icon } from '@topo-io/design-system';
import { CompanyRole } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { useRankedScoreWorkspaces } from '@/components/engagement-score/hooks';
import type { EngagementScoreDateRange } from '@/components/engagement-score/types';
import { useUser } from '@/hooks';
import { WorkspaceRankedByScoreTable } from './workspaces-ranked-by-score-table';

interface WorkspacesRankedByScoreCardProps {
  iconName: string;
  color: string;
  title: string;
  rankedOrder: 'asc' | 'desc';
  dateRange: EngagementScoreDateRange;
}

export const WorkspacesRankedByScoreCard: FC<WorkspacesRankedByScoreCardProps> = ({
  iconName,
  color,
  title,
  rankedOrder,
  dateRange,
}) => {
  const { user, isIdentifiedUser } = useUser();
  const { data, isLoading } = useRankedScoreWorkspaces({
    organizationId: user?.organizationId ?? '',
    order: rankedOrder,
    shouldSkip: isNil(user) || !isIdentifiedUser(user),
    dateRange,
    userProfileId: isIdentifiedUser(user) ? user.id : '',
    isUserManager: isIdentifiedUser(user) ? user.companyRole === CompanyRole.MANAGER : false,
  });

  return (
    <Box borderWidth="1px" borderRadius="md" bg="cards.bg.color" padding="6" flex="1">
      <Flex alignItems="center" gap="2">
        <Flex padding="2" borderRadius="50%" bgColor={`${color}.50`} alignItems="center">
          <Icon icon={iconName} w="20px" h="20px" color={`${color}.700`} data-testid="icon" />
        </Flex>
        <H5>{title}</H5>
      </Flex>
      <WorkspaceRankedByScoreTable data={data} isLoading={isLoading} dateRange={dateRange} />
    </Box>
  );
};
