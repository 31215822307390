import type { FC } from 'react';
import { Box, Flex, Icon, Text } from '@topo-io/design-system';

interface WorkspaceFilterMenuItemProps {
  icon: string;
  text: string;
  onClick: (event: React.MouseEvent) => void;
}

export const WorkspaceFilterMenuItem: FC<WorkspaceFilterMenuItemProps> = ({
  icon,
  text,
  onClick,
}) => {
  return (
    <Box
      onClick={onClick}
      paddingY="2"
      paddingX="4"
      cursor="pointer"
      _hover={{
        bgColor: 'gray.20',
      }}
    >
      <Flex alignItems="center" justifyContent="space-between" width="full">
        <Flex gap="10px" alignItems="center">
          <Icon icon={icon} color="gray.100" w="16px" h="16px" />
          <Text>{text}</Text>
        </Flex>
        <Icon icon="chevron-right" />
      </Flex>
    </Box>
  );
};
