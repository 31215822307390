import { useCallback } from 'react';
import { IndexSortDirection } from '@topo-io/types';
import { useSearchContext } from './use-search-context';

const DEFAULT_DIRECTION = IndexSortDirection.DESC;

interface UseSearchSortArgs {
  attribute: string;
  sortDirection?: IndexSortDirection;
}

interface UseSearchSort {
  sortBy: ({ attribute, direction }: { attribute: string; direction?: IndexSortDirection }) => void;
  sortAttribute: string;
  sortDirection: IndexSortDirection | undefined;
  reset: () => void;
}

export const useSearchSort = ({
  attribute: defaultSortAttribute,
  sortDirection: defaultSortDirection = DEFAULT_DIRECTION,
}: UseSearchSortArgs): UseSearchSort => {
  const { sortAttribute, setSortAttribute, sortDirection, setSortDirection } = useSearchContext();

  const sortBy = useCallback(
    ({
      attribute: newAttribute,
      direction: newDirection,
    }: {
      attribute: string;
      direction?: IndexSortDirection;
    }) => {
      setSortAttribute(newAttribute);
      setSortDirection(newDirection ?? sortDirection);
    },
    [setSortAttribute, setSortDirection, sortDirection]
  );

  const reset = useCallback(() => {
    setSortAttribute(defaultSortAttribute);
    setSortDirection(defaultSortDirection);
  }, [defaultSortAttribute, defaultSortDirection, setSortAttribute, setSortDirection]);

  return {
    sortBy,
    sortAttribute,
    sortDirection,
    reset,
  };
};
