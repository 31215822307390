import { useMemo } from 'react';
import type { OpportunityFiltersInput } from '@topo-io/graphql';
import { useOpportunitiesQuery } from '@topo-io/graphql';
import { useIntegrations } from '@/components/integrations/hooks';

export const useOpportunities = ({
  filters,
  skip = false,
}: {
  filters?: OpportunityFiltersInput;
  skip?: boolean;
}) => {
  const { isConnectedToOneIntegration } = useIntegrations();
  const { data, loading } = useOpportunitiesQuery({
    skip: !isConnectedToOneIntegration || skip,
    variables: { filters },
  });

  const opportunities = useMemo(() => data?.opportunities ?? [], [data?.opportunities]);

  return {
    isFieldDisabled: !isConnectedToOneIntegration,
    opportunities,
    loading,
  };
};
