import type { Roadmap, Step, Task } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import type { MemberWithoutRelations } from '@/components/roadmap/task/assignees';
import type { RoadmapTask } from '@/components/roadmap/types';
import { removeStepFromRoadmap, addStepToRoadmap, updateStepInRoadmap } from './step-adapters';
import {
  addTaskToRoadmap,
  removeTaskFromRoadmap,
  updateTaskAssigneesInRoadmap,
  updateTaskInRoadmap,
} from './task-adapters';

export const getModifiedTaskCache = ({
  stepId,
  updateTask,
}: {
  stepId: string;
  updateTask: RoadmapTask;
}) => {
  return {
    fields: {
      roadmap(roadmap: Roadmap) {
        return {
          id: roadmap.id,
          steps: updateTaskInRoadmap({ state: roadmap.steps, stepId, updateTaskData: updateTask }),
        };
      },
    },
  };
};

export const getDeletedTaskCache = ({
  stepId,
  deleteTask,
}: {
  stepId: string;
  deleteTask: Partial<Task>;
}) => {
  return {
    fields: {
      roadmap(roadmap: Roadmap) {
        if (isNil(deleteTask.id)) {
          return;
        }
        return {
          id: roadmap.id,
          steps: removeTaskFromRoadmap({ state: roadmap.steps, stepId, taskId: deleteTask.id }),
        };
      },
    },
  };
};

export const getCreatedTaskCache = ({ stepId }: { stepId: string }) => {
  return {
    fields: {
      roadmap(roadmap: Roadmap) {
        return { id: roadmap.id, steps: addTaskToRoadmap({ state: roadmap.steps, stepId }) };
      },
    },
  };
};

export const getDeletedStepCache = ({ stepId }: { stepId: string }) => {
  return {
    fields: {
      roadmap(roadmap: Roadmap) {
        return { id: roadmap.id, steps: removeStepFromRoadmap({ state: roadmap.steps, stepId }) };
      },
    },
  };
};

export const getCreatedStepCache = ({ workspaceId }: { workspaceId: string }) => {
  return {
    fields: {
      roadmap(roadmap: Roadmap) {
        return { id: roadmap.id, steps: addStepToRoadmap({ state: roadmap.steps, workspaceId }) };
      },
    },
  };
};

export const getUpdatedAssigneesTaskCache = ({
  stepId,
  updatedTask,
  assignee,
}: {
  stepId: string;
  updatedTask: Partial<Task>;
  assignee: MemberWithoutRelations;
}): { fields: { roadmap: (roadmap: Roadmap) => Roadmap } } => {
  return {
    fields: {
      roadmap(roadmap: Roadmap) {
        return {
          id: roadmap.id,
          steps: updateTaskAssigneesInRoadmap({
            state: roadmap.steps,
            stepId,
            updatedTask,
            assignee,
          }),
        };
      },
    },
  };
};

export const getUpdatedStepCache = ({
  stepId,
  updateStepData,
}: {
  stepId: string;
  updateStepData: Partial<Step>;
}): { fields: { roadmap: (roadmap: Roadmap) => Roadmap } } => {
  return {
    fields: {
      roadmap(roadmap: Roadmap) {
        return {
          id: roadmap.id,
          steps: updateStepInRoadmap({
            state: roadmap.steps,
            stepId,
            updateStepData: updateStepData,
          }),
        };
      },
    },
  };
};
