import type { MembersQuery } from '@topo-io/graphql';
import { useMembersQuery } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';

export const useWorkspaceMembers = (
  workspaceId: string | undefined
): {
  isLoading: boolean;
  members: MembersQuery['members'];
} => {
  const { data, loading: isLoading } = useMembersQuery({
    variables: { workspaceId: workspaceId! },
    skip: isNil(workspaceId),
  });
  const members = data?.members ?? [];

  return {
    members,
    isLoading,
  };
};
