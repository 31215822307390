import { t } from '@lingui/macro';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useToast } from '@topo-io/design-system';
import { useOrganizationBySlugQuery, useApolloClient } from '@topo-io/graphql';
import { useClientMemo } from '@topo-io/hooks';
import { matchRules } from '@topo-io/utils';
import { signInUrl, previewRoutes, getOrganizationSlug, publicRoutes } from '@/config';
import { useUser } from '@/hooks';

const TOAST_DURATION = 10000;
const TOAST_UNAUTHORIZED_ORG = 'unauthorized-org';
const TOAST_EXPIRED_SESSION = 'expired-session';
const TOAST_CANT_REACH_SERVER = 'cant-reach-server';

export const useLoginRedirect = () => {
  const router = useRouter();
  const { toast, errorToast } = useToast();
  const { pathname } = router;
  const { loading, isConnected, isEditor, user, error } = useUser();
  const isPublic = useMemo(() => matchRules(pathname, publicRoutes), [pathname]);
  const isPreview = useMemo(() => matchRules(pathname, previewRoutes), [pathname]);
  const isPageChanging = !router.isReady;
  const slug = useClientMemo(() => getOrganizationSlug(), []);
  const { error: errorOnOrg } = useOrganizationBySlugQuery({
    variables: {
      slug: slug!,
    },
    skip: !slug || isPublic,
  });

  const apolloClient = useApolloClient();
  const unauthorizedOrgMessage = t`Your are not authorized in this organization, please check you are accessing the right URL!`;
  const expiredSession = t`Your session has expired, please reconnect!`;
  const cantReachServerMessage = t`We can't reach our server, please check your internet connection!`;

  const redirectToLogin = useCallback(async () => {
    await apolloClient.clearStore();
    await router.push(signInUrl());
  }, [router, apolloClient]);

  // Disconnect if org doesn't exist
  useEffect(() => {
    if (!errorOnOrg) {
      return;
    }

    if (errorOnOrg?.message === 'Organization not found') {
      void redirectToLogin();
      if (!toast.isActive(TOAST_UNAUTHORIZED_ORG)) {
        errorToast({
          title: unauthorizedOrgMessage,
          duration: TOAST_DURATION,
          id: TOAST_UNAUTHORIZED_ORG,
        });
      }
    } else {
      if (!toast.isActive(TOAST_CANT_REACH_SERVER)) {
        errorToast({
          title: cantReachServerMessage,
          duration: TOAST_DURATION,
          id: TOAST_CANT_REACH_SERVER,
        });
      }
    }
  }, [
    errorOnOrg,
    redirectToLogin,
    unauthorizedOrgMessage,
    cantReachServerMessage,
    toast,
    errorToast,
  ]);

  // Disconnect if user is not connected
  useEffect(() => {
    if (loading || isPublic || isPageChanging || isConnected) {
      return;
    }

    if (!error) {
      return;
    }

    if (error?.message === 'Unauthorized') {
      void redirectToLogin();
      if (!toast.isActive(TOAST_EXPIRED_SESSION)) {
        errorToast({
          title: expiredSession,
          duration: TOAST_DURATION,
          id: TOAST_EXPIRED_SESSION,
        });
      }
    } else {
      if (!toast.isActive(TOAST_CANT_REACH_SERVER)) {
        errorToast({
          title: cantReachServerMessage,
          duration: TOAST_DURATION,
          id: TOAST_CANT_REACH_SERVER,
        });
      }
    }
  }, [
    loading,
    isPublic,
    isPageChanging,
    error,
    isConnected,
    errorOnOrg,
    redirectToLogin,
    toast,
    errorToast,
    expiredSession,
    cantReachServerMessage,
    unauthorizedOrgMessage,
  ]);

  // Disconnect if user is not editor in this org
  useEffect(() => {
    if (loading || isPublic || isPreview || isPageChanging) {
      return;
    }

    if (isConnected && !isEditor(user)) {
      void redirectToLogin();
      if (!toast.isActive(TOAST_UNAUTHORIZED_ORG)) {
        errorToast({
          title: unauthorizedOrgMessage,
          duration: TOAST_DURATION,
          id: TOAST_UNAUTHORIZED_ORG,
        });
      }
    }
  }, [
    user,
    router,
    pathname,
    loading,
    isPublic,
    isPreview,
    isPageChanging,
    isConnected,
    isEditor,
    redirectToLogin,
    toast,
    errorToast,
    unauthorizedOrgMessage,
  ]);
};
