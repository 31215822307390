import { t } from '@lingui/macro';
import type { WorkspaceFiltersItem } from './types';

export const getWorkspaceFilterItems = (): WorkspaceFiltersItem[] => {
  return [
    {
      key: 'value',
      text: t`Value`,
      icon: 'BankNote',
    },
    {
      key: 'stage',
      text: t`Stage`,
      icon: 'List',
    },
    {
      key: 'closeDate',
      text: t`Close date`,
      icon: 'CalendarMinus',
    },
    {
      key: 'ownerId',
      text: t`Owner`,
      icon: 'Users01',
    },
    /*
  TODO: Uncomment these items when the corresponding filters are implemented
  {
    key: 'lastView',
    text: t`Last view`,
    icon: 'eye',
  },
    {
    key: 'views',
    text: t`Views`,
    icon: 'eye',
  },
      {
    key: 'score',
    text: t`Score`,
    icon: 'eye',
  },
, */
  ];
};
