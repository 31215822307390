import { t } from '@lingui/macro';
import { Flex, Box, Text } from '@topo-io/design-system';
import { Action } from '@topo-io/graphql';

export const ActionVerb = ({ action }: { action: Action }) => {
  const iconMapper: { [key in Action]: string } = {
    [Action.OpenWorkspace]: t`has opened`,
    [Action.ShareWorkspace]: t`has shared`,
    [Action.CommentSection]: t`has commented`,
  };

  const verb = iconMapper[action];

  return (
    <Box>
      <Flex>
        <Text>&nbsp;{verb}</Text>
      </Flex>
    </Box>
  );
};
