import { t } from '@lingui/macro';
import { SectionType } from '@topo-io/graphql';

export enum AISectionModalStep {
  SECTION_TYPE = 'sectionType',
  CUSTOM_INSTRUCTIONS = 'customInstructions',
}

export interface AISectionModalValues {
  [AISectionModalStep.SECTION_TYPE]: SectionType;
  [AISectionModalStep.CUSTOM_INSTRUCTIONS]: string;
}

export const AI_SECTION_MODAL_SECTIONS = [
  {
    type: SectionType.INTRODUCTION,
    smiley: '👋',
    title: t`Introduction`,
  },
  {
    type: SectionType.FOLLOW_UP,
    smiley: '📝',
    title: t`Follow-up`,
  },
  {
    type: SectionType.ABOUT_US,
    smiley: '⭐',
    title: t`About us`,
  },
  {
    type: SectionType.CUSTOMER_STORIES,
    smiley: '🤩',
    title: t`Customer story`,
  },
  {
    type: SectionType.CONTACT_PROFILE,
    smiley: '🙂',
    title: t`Contact profile`,
  },
  {
    type: SectionType.PRICING,
    smiley: '💸',
    title: t`Pricing`,
  },
  {
    type: SectionType.MEETING_RECORDING,
    smiley: '▶️',
    title: t`Meeting recording`,
  },
  {
    type: SectionType.FAQ,
    smiley: '❓',
    title: t`FAQ`,
  },
];
