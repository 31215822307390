import type { FC } from 'react';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@topo-io/design-system';
import { EngagementScoreDateRange } from '@/components/engagement-score/types';
import { mapDateRangeToLabel } from '@/components/engagement-score/utils';

const activeItemStyle = {
  bgColor: 'gray.20',
};

interface EngagementScoreDateRangeFilterProps {
  dateRange: EngagementScoreDateRange;
  onChange: (dateRange: EngagementScoreDateRange) => void;
}

export const EngagementScoreDateRangeFilter: FC<EngagementScoreDateRangeFilterProps> = ({
  dateRange,
  onChange,
}) => {
  const label = mapDateRangeToLabel(dateRange);

  const isMenuItemActive = (range: EngagementScoreDateRange) => {
    return dateRange === range;
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Icon icon="chevron-down" />}
        bgColor="white"
        color="gray.700"
        borderColor="gray.300"
        variant="outline"
        _hover={{ bgColor: 'gray.20' }}
        _active={{ bgColor: 'gray.20' }}
      >
        {label}
      </MenuButton>
      <MenuList>
        <MenuItem
          {...(isMenuItemActive(EngagementScoreDateRange.Last7Days) && { ...activeItemStyle })}
          onClick={() => onChange(EngagementScoreDateRange.Last7Days)}
        >
          {mapDateRangeToLabel(EngagementScoreDateRange.Last7Days)}
        </MenuItem>
        <MenuItem
          {...(isMenuItemActive(EngagementScoreDateRange.Last30Days) && { ...activeItemStyle })}
          onClick={() => onChange(EngagementScoreDateRange.Last30Days)}
        >
          {mapDateRangeToLabel(EngagementScoreDateRange.Last30Days)}
        </MenuItem>
        <MenuItem
          {...(isMenuItemActive(EngagementScoreDateRange.Last90Days) && { ...activeItemStyle })}
          onClick={() => onChange(EngagementScoreDateRange.Last90Days)}
        >
          {mapDateRangeToLabel(EngagementScoreDateRange.Last90Days)}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
