import { useCallback } from 'react';
import { SNOWPLOW_SECTION_SCHEMA } from '@/config';
import { useAnalytics } from '@/hooks';

// Persistant args
interface Args {
  skip?: boolean;
  workspaceId: string | undefined;
  workspaceTemplateId: string | undefined;
  workspaceTabId: string | undefined;
  organizationId: string | undefined;
  workspaceTabViewId: string | undefined;
  workspaceViewId: string | undefined;
}

// Args that change on each call
interface TrackSectionArgs {
  sectionId: string;
  sectionTemplateId?: string;
  sectionViewId: string | undefined;
  action: SectionTrackingAction;
}

export type SectionTrackingAction = 'view' | 'ping';

// Wrapper around analytics.track to track section events
export const useTrackSection = ({
  skip = false,
  workspaceId,
  workspaceTemplateId,
  workspaceTabId,
  workspaceTabViewId,
  workspaceViewId,
  organizationId,
}: Args) => {
  const analytics = useAnalytics();

  const trackSection = useCallback(
    ({ sectionId, sectionTemplateId, sectionViewId, action }: TrackSectionArgs) => {
      if (!workspaceId || !organizationId || !analytics || skip) {
        return;
      }
      void analytics.track({
        eventName: 'selfDescribingEvent',
        properties: {
          schema: SNOWPLOW_SECTION_SCHEMA,
          data: {
            workspaceId,
            workspaceTemplateId: workspaceTemplateId ?? null,
            workspaceTabId,
            organizationId,
            sectionId,
            sectionTemplateId: sectionTemplateId ?? null,
            sectionViewId,
            workspaceTabViewId,
            workspaceViewId,
            action,
          },
        },
        plugins: {
          snowplow: true,
          segment: false,
        },
      });
    },
    [
      workspaceId,
      workspaceTemplateId,
      workspaceTabId,
      analytics,
      skip,
      organizationId,
      workspaceTabViewId,
      workspaceViewId,
    ]
  );

  return { trackSection };
};
