import { t } from '@lingui/macro';
import type { MutableRefObject, Ref } from 'react';
import { useMemo } from 'react';
import { HStack, Checkbox, InlineInput, Spacer } from '@topo-io/design-system';
import type { TaskQuery, UpdateTaskInput } from '@topo-io/graphql';
import { formatDate, parseDate } from '@topo-io/utils';
import { EditableDate } from '@/components/common/editable-date';
import type { MemberMenuItem } from '@/components/common/member-menu';
import { MemberMenu } from '@/components/common/member-menu';
import { DueDate } from '@/components/roadmap/due-date';
import { useCreateOnKeypress } from '@/components/roadmap/hooks';
import { useRoadmapContext } from '@/components/roadmap/hooks/use-roadmap-context';
import { useTaskContext } from '@/components/roadmap/hooks/use-task-context';
import { useWorkspaceContext } from '@/components/workspace/hooks/use-workspace-context';
import { useWorkspaceMembers } from '@/components/workspace/hooks/use-workspace-members';
import { SubtaskMenu } from './subtask-menu';

interface TasksubtaskProps {
  subtask: TaskQuery['task']['subtasks'][number];
  inputRef: Ref<HTMLInputElement>;
  onCreate: () => void;
}

export const TaskSubtask = ({ subtask, inputRef, onCreate }: TasksubtaskProps) => {
  const { id: workspaceId, isGuest } = useWorkspaceContext();
  const { canEditRoadmap } = useRoadmapContext();
  const { name, done, assignees } = subtask;
  const { updateSubtask, debouncedUpdateSubtask, updateSubtaskAssignee } = useTaskContext();
  const { members: workspaceMembers } = useWorkspaceMembers(workspaceId);

  const handleTaskUpdate = (taskData: Partial<UpdateTaskInput>) => {
    debouncedUpdateSubtask(subtask.id, {
      ...subtask,
      ...taskData,
    });
  };

  const members = useMemo(
    () => workspaceMembers.map((member) => ({ ...member, id: member.userProfileId })),
    [workspaceMembers]
  );

  const onMemberSelect = (member: MemberMenuItem) => updateSubtaskAssignee(subtask.id, member.id);

  const dueDate = useMemo(
    () => (subtask.dueDate ? parseDate(subtask.dueDate) : undefined),
    [subtask.dueDate]
  );

  useCreateOnKeypress({
    key: 'Enter',
    ref: inputRef as MutableRefObject<unknown>,
    onKeypress: onCreate,
  });

  return (
    <HStack w="full" h={10} position="relative">
      <Checkbox
        disabled={!canEditRoadmap && !isGuest}
        defaultChecked={done}
        onChange={(evt) => handleTaskUpdate({ done: evt.target.checked })}
      />
      <InlineInput
        as="p"
        defaultValue={name ?? ''}
        placeholder={t`Subtask name`}
        readOnly={!canEditRoadmap}
        onChange={(evt) => handleTaskUpdate({ name: evt.target.value })}
        autoComplete="off"
        data-form-type="other"
        data-testid="subtask"
        ref={inputRef}
      />
      <Spacer />
      <MemberMenu
        selectedMembers={assignees}
        allMembers={members}
        onMemberSelect={onMemberSelect}
        readOnly={!canEditRoadmap}
      />
      <EditableDate
        setDate={(date) => updateSubtask(subtask.id, { dueDate: date ? formatDate(date) : null! })}
        date={dueDate}
        readOnly={!canEditRoadmap}
      >
        <DueDate date={dueDate} done={subtask.done} />
      </EditableDate>
      {canEditRoadmap && <SubtaskMenu subtaskId={subtask.id} />}
    </HStack>
  );
};
