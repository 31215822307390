import { useCallback } from 'react';
import { Block, Box } from '@topo-io/design-system';
import type { UpdateDetailsTemplateInput } from '@topo-io/graphql';
import { TemplateDocument, useUpdateDetailsTemplateMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { TemplateTagsForm } from '@/components/common/template-tags-form';
import { useTemplate, useWorkspaceContext } from '@/components/workspace/hooks';
import { TemplateWorkspaceDetailsForm } from './template-workspace-details-form';

export const TemplateEditForm = () => {
  const { id, readOnly } = useWorkspaceContext();
  const { template } = useTemplate(id!, { skip: isNil(id) });
  const [updateTemplate] = useUpdateDetailsTemplateMutation();

  const updateTemplateDetails = useCallback(
    async (data: UpdateDetailsTemplateInput) => {
      await updateTemplate({
        variables: { id: id!, ...data },
        refetchQueries: [
          {
            fetchPolicy: 'network-only',
            query: TemplateDocument,
            variables: { id: id! },
          },
        ],
      });
    },
    [updateTemplate, id]
  );

  if (isNil(template)) {
    return <>No template found</>;
  }

  return (
    <>
      <Block mt="4" data-testid="workspace-details-edit-form">
        <TemplateWorkspaceDetailsForm
          template={template}
          updateTemplateDetails={updateTemplateDetails}
          readOnly={readOnly}
        />
      </Block>
      <Block>
        <Box w="50%">
          <TemplateTagsForm
            template={template}
            updateTemplateDetails={updateTemplateDetails}
            readOnly={readOnly}
          />
        </Box>
      </Block>
    </>
  );
};
