import { Trans } from '@lingui/macro';
import { useState } from 'react';
import { Button, Flex, Badge, Divider, SortableList, SortableItem } from '@topo-io/design-system';
import { useCountWorkspaceLegacyActivitiesQuery } from '@topo-io/graphql';
import { isNil, isNotNil, isPositive } from '@topo-io/utils';
import { useWorkspaceContext, useWorkspaceTabs } from '@/components/workspace/hooks';
import { ManageTabAccessModal } from '@/components/workspace/tabs';
import type { WorkspaceTab } from '@/components/workspace/tabs/workspace-tab-type';
import {
  WORKSPACE_TAB_ANALYTICS,
  WORKSPACE_TAB_DETAILS,
  WorkspaceTabNonEditableEnum,
} from '@/components/workspace/tabs/workspace-tab-type';
import { useUser } from '@/hooks';
import { DeleteWorkspaceTabModal } from './delete-workspace-tab-modal';
import { NavbarAddWorkspaceTab } from './navbar-add-workspace-tab';
import { NavbarWorkspaceTab } from './navbar-workspace-tab';
import { getTabButtonStyle } from './utils';

export const NavbarWorkspaceTabs = () => {
  const {
    isWorkspace,
    isTemplate: isWorkspaceTemplate,
    isGuest,
    isPreview,
  } = useWorkspaceContext();
  const { workspaceTabs, updateTab } = useWorkspaceTabs();
  const [deleteTabModalState, setDeleteTabModalState] = useState<{
    isOpen: boolean;
    tab: WorkspaceTab | null;
  }>({ isOpen: false, tab: null });
  const [manageTabAccessModalState, setManageTabAccessModalState] = useState<{
    isOpen: boolean;
    tab: WorkspaceTab | null;
  }>({ isOpen: false, tab: null });

  const openDeleteTabModal = (workspaceTab: WorkspaceTab) => {
    setDeleteTabModalState({ isOpen: true, tab: workspaceTab });
  };

  const closeDeleteTabModal = () => {
    setDeleteTabModalState({ isOpen: false, tab: null });
  };

  const openManageAccessModal = (workspaceTab: WorkspaceTab) => {
    setManageTabAccessModalState({ isOpen: true, tab: workspaceTab });
  };

  const closeManageAccessModal = () => {
    setManageTabAccessModalState({ isOpen: false, tab: null });
  };

  const handlePositionChange = (
    tabs: WorkspaceTab[],
    updatedTab: WorkspaceTab,
    newPosition: number
  ) => {
    updateTab({
      input: {
        position: newPosition,
      },
      tabId: updatedTab.id,
    });
  };

  if (!isWorkspace && !isWorkspaceTemplate) {
    return null;
  }

  return (
    <>
      <Flex gap="5px" ml="3" flexGrow={2} overflowX="auto" h="full" alignItems="center">
        <Flex
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
          }}
        >
          <Flex gap="2px">
            <SortableList
              items={workspaceTabs}
              onChange={handlePositionChange}
              renderItem={(workspaceTab) => (
                <SortableItem id={workspaceTab.id}>
                  <NavbarWorkspaceTab
                    key={workspaceTab.id}
                    workspaceTab={workspaceTab}
                    openDeleteTabModal={openDeleteTabModal}
                    openManageAccessModal={openManageAccessModal}
                  />
                </SortableItem>
              )}
            />
          </Flex>
        </Flex>
        {!isPreview && !isGuest && (
          <>
            <NavbarAddWorkspaceTab />
            <Divider
              orientation="vertical"
              borderColor="gray.50"
              height="28px"
              alignSelf="center"
              mr="16px"
              borderRadius="40px"
            />
          </>
        )}
        <Flex flexGrow={1} pr="4" gap="4">
          <NonEditableTabs />
        </Flex>
      </Flex>
      <DeleteWorkspaceTabModal
        isOpen={deleteTabModalState.isOpen}
        onClose={closeDeleteTabModal}
        workspaceTab={deleteTabModalState.tab}
      />
      <ManageTabAccessModal
        isOpen={manageTabAccessModalState.isOpen}
        onClose={closeManageAccessModal}
        workspaceTab={manageTabAccessModalState.tab}
      />
    </>
  );
};

const NonEditableTabs: React.FC = () => {
  const { id, isWorkspace, isTemplate: isWorkspaceTemplate } = useWorkspaceContext();
  const { isConnected } = useUser();
  const { isTabShown, onChangeTab, tab } = useWorkspaceTabs();

  const { data: dataCountUnseenActivities } = useCountWorkspaceLegacyActivitiesQuery({
    variables: { workspaceId: id!, filters: { unseen: true } },
    skip: isNil(id) || (!isConnected && !isWorkspace && !isWorkspaceTemplate),
  });

  const isMissingActivities =
    isNotNil(dataCountUnseenActivities) &&
    isNotNil(dataCountUnseenActivities?.workspaceLegacyActivities) &&
    isPositive(dataCountUnseenActivities.workspaceLegacyActivities.count);

  return (
    <>
      {isTabShown({ type: WorkspaceTabNonEditableEnum.ANALYTICS, isDisplayed: true }) && (
        <Button
          {...getTabButtonStyle({
            isActive: tab.type === WorkspaceTabNonEditableEnum.ANALYTICS,
            isDisplayed: true,
          })}
          onClick={() => onChangeTab(WORKSPACE_TAB_ANALYTICS.slug)}
        >
          <Trans>Analytics</Trans>

          {isMissingActivities && <Badge variant="dotAlert" position="relative" top="-8px" />}
        </Button>
      )}
      {isTabShown({ type: WorkspaceTabNonEditableEnum.DETAILS, isDisplayed: true }) && (
        <Button
          {...getTabButtonStyle({
            isActive: tab.type === WorkspaceTabNonEditableEnum.DETAILS,
            isDisplayed: true,
          })}
          onClick={() => onChangeTab(WORKSPACE_TAB_DETAILS.slug)}
        >
          {isWorkspace ? <Trans>Details</Trans> : <Trans>Template details</Trans>}
        </Button>
      )}
    </>
  );
};
