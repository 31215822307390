import { useCallback } from 'react';
import { useWorkspaceTabs } from '@/components/workspace/hooks';
import { SNOWPLOW_WORKSPACE_TAB_SCHEMA } from '@/config';
import { useAnalytics } from '@/hooks';

type WorkspaceTabTrackingAction = 'view' | 'ping';

// Persistant args
interface UseTrackWorkspaceTabArgs {
  skip?: boolean;
  workspaceId: string | undefined;
  workspaceTemplateId: string | undefined;
  organizationId: string | undefined;
  workspaceViewId: string | undefined;
}

// Args that change on each call
interface TrackWorkspaceTabArgs {
  action: WorkspaceTabTrackingAction;
  workspaceTabViewId: string | undefined;
}

// Wrapper around analytics.track to track workspace tab events
export const useTrackWorkspaceTab = ({
  workspaceId,
  workspaceTemplateId,
  workspaceViewId,
  skip = false,
  organizationId,
}: UseTrackWorkspaceTabArgs) => {
  const analytics = useAnalytics();

  const { tabId } = useWorkspaceTabs();

  const trackWorkspaceTab = useCallback(
    ({ action, workspaceTabViewId }: TrackWorkspaceTabArgs) => {
      if (!workspaceId || !tabId || !organizationId || !analytics || skip) {
        return;
      }

      void analytics.track({
        eventName: 'selfDescribingEvent',
        properties: {
          schema: SNOWPLOW_WORKSPACE_TAB_SCHEMA,
          data: {
            workspaceId,
            workspaceTabId: tabId,
            workspaceTemplateId: workspaceTemplateId ?? null,
            organizationId,
            action,
            workspaceViewId,
            workspaceTabViewId,
          },
        },
        plugins: {
          snowplow: true,
          segment: false,
        },
      });
    },
    [workspaceId, analytics, skip, organizationId, tabId, workspaceTemplateId, workspaceViewId]
  );

  return { trackWorkspaceTab };
};
