import { Trans, t } from '@lingui/macro';
import type { FC } from 'react';
import { EventTrackingEvents } from '@topo-io/constants';
import {
  Box,
  Button,
  CustomThemeOverride,
  Flex,
  H6,
  SimpleModal,
  Text,
  useToast,
} from '@topo-io/design-system';
import { ThemeDocument, useCreateThemeMutation, useUpdateThemeMutation } from '@topo-io/graphql';
import { isNil } from '@topo-io/utils';
import { useBrandingColors } from '@/components/branding/hooks';
import { useAnalytics } from '@/hooks';
import { BrandingForm } from './branding-form';
import { BrandingSettingsInfoBox } from './branding-settings-info-box';

interface WorkspaceBrandingModalProps {
  isOpen: boolean;
  onClose: () => void;
  workspaceId: string;
}

export const WorkspaceBrandingModal: FC<WorkspaceBrandingModalProps> = ({
  isOpen,
  onClose,
  workspaceId,
}) => {
  const analytics = useAnalytics();
  const { successToast, errorToast } = useToast();

  const {
    theme,
    primaryColorsPalette,
    setContrastTextColor,
    setPrimaryColor,
    reset,
    FALLBACK_PRIMARY_COLOR,
    FALLBACK_CONTRAST_TEXT_COLOR,
  } = useBrandingColors(workspaceId);

  const [createThemeMutation, { loading: isCreateThemeLoading }] = useCreateThemeMutation({
    refetchQueries: [ThemeDocument],
  });
  const [updateThemeMutation, { loading: isUpdateThemeLoading }] = useUpdateThemeMutation({
    refetchQueries: [ThemeDocument],
  });

  const { primaryColor, contrastTextColor } = theme;

  const handleSubmit = async () => {
    try {
      if (isNil(theme.workspaceId) || isNil(theme.id)) {
        await createThemeMutation({
          variables: {
            input: {
              workspaceId,
              primaryColor,
              contrastTextColor,
            },
          },
        });
      } else {
        await updateThemeMutation({
          variables: {
            input: {
              id: theme.id,
              // Hack, remove this after fixing the generate color palette function
              primaryColor: primaryColor === FALLBACK_PRIMARY_COLOR ? undefined : primaryColor,
              contrastTextColor:
                contrastTextColor === FALLBACK_CONTRAST_TEXT_COLOR ? undefined : contrastTextColor,
            },
          },
        });
      }
      successToast({
        title: t`Brand colors updated successfully`,
      });
      analytics.track({
        eventName: EventTrackingEvents.WORKSPACE_CUSTOM_BRAND_CREATED,
        properties: {
          workspaceId,
          primaryColor,
          contrastTextColor,
        },
      });
      onClose();
    } catch (e) {
      errorToast({
        title: t`An error occurred while updating brand colors`,
      });
    }
  };

  return (
    <SimpleModal isOpen={isOpen} onClose={onClose} p="2" size="5xl" padding="6">
      <CustomThemeOverride colors={primaryColorsPalette} contrastColor={contrastTextColor}>
        <H6>
          <Trans>Customize workspace branding</Trans>
        </H6>
        <Text mb="6" color="gray.500">
          <Trans>You can customize the branding of this specific workspace. </Trans>
        </Text>
        <BrandingSettingsInfoBox>
          <Trans>
            Changes will only impact this specific workspace. You can change your global branding
            settings in Organization.
          </Trans>
        </BrandingSettingsInfoBox>

        <Box mt="6">
          <BrandingForm
            primaryColor={primaryColor}
            contrastTextColor={contrastTextColor}
            onContrastTextColorChange={setContrastTextColor}
            onPrimaryColorChange={setPrimaryColor}
            onReset={() => reset()}
          />
          <Flex justifyContent="flex-end" mt="8">
            <Button
              onClick={() => handleSubmit()}
              isLoading={isCreateThemeLoading || isUpdateThemeLoading}
            >
              <Trans>Save</Trans>
            </Button>
          </Flex>
        </Box>
      </CustomThemeOverride>
    </SimpleModal>
  );
};
