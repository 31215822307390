import { useCallback, useMemo } from 'react';
import type { ChangeEventHandler } from 'react';
import { HStack, ComboboxItem, Text } from '@topo-io/design-system';
import type { ItemType } from '@topo-io/design-system';
import type { CRMContactFiltersInput } from '@topo-io/graphql';
import { useCRMContactsLazyQuery } from '@topo-io/graphql';
import { isBlank, isNil, isNotNil, orderArrayAlphabetically, partition } from '@topo-io/utils';
import { ComboboxField } from './combobox-field';

const DEFAULT_LIMIT = 100;

export interface Contact {
  email: string;
  firstName?: string;
  lastName?: string;
  crmId?: string;
  account?: {
    name: string;
    crmId: string;
    domainUrl?: string;
  };
}

interface CRMContactsFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  onChange?: (value: string | null) => void;
  onSuggestionSelection?: (item: Contact | undefined) => void;
  crmAccountId: string | undefined;
  suggestionPlaceholder?: string;
}

interface ContactItemType extends ItemType, Contact {}

const comboboxItemRender = ({ items }: { items: ContactItemType[] }) =>
  items.map((item) => (
    <ComboboxItem key={item.key} item={item}>
      <HStack fontWeight="400" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        <Text>{item.label}</Text>
        {item.account?.name && <Text color="gray.100">({item.account.name})</Text>}
      </HStack>
    </ComboboxItem>
  ));

export const CRMContactsField = ({
  crmAccountId,
  name,
  label,
  placeholder,
  onChange,
  onSuggestionSelection,
  suggestionPlaceholder,
}: CRMContactsFieldProps) => {
  const [retrieveCRMContacts] = useCRMContactsLazyQuery();
  const defaultContactFilters: CRMContactFiltersInput = useMemo(
    () => ({ CRMAccountId: crmAccountId, limit: DEFAULT_LIMIT }),
    [crmAccountId]
  );

  const optionsFn = useCallback(
    async (value: string | undefined): Promise<ItemType[]> => {
      const { data } = await retrieveCRMContacts({
        variables: {
          filters:
            isNil(value) || isBlank(value) ? defaultContactFilters : { limit: 100, search: value },
        },
      });

      const contacts =
        data?.CRMContacts?.flatMap((contact) =>
          contact.emailAddresses.map((email) => ({
            id: contact.crmId,
            email,
            account: contact.CRMAccount,
            ...contact,
          }))
        ) ?? [];

      const options: ContactItemType[] = contacts.map((contact) => ({
        label: contact.email,
        key: contact.id,
        ...contact,
      }));

      const orderedOptions = orderArrayAlphabetically(options, (option) => option.email);
      const [accountOptions, otherOptions] = partition(
        orderedOptions,
        (option) => option.account?.crmId === defaultContactFilters.CRMAccountId
      );

      return accountOptions.concat(otherOptions);
    },
    [defaultContactFilters, retrieveCRMContacts]
  );

  const handleOnItemSelection = ((event: { target: { value: ContactItemType | ItemType } }) => {
    const isCrmContact = isNotNil(event.target.value.crmId);
    const contact: Contact = isCrmContact
      ? (event.target.value as Contact)
      : { email: event.target.value.label };

    onSuggestionSelection?.(contact);
  }) as unknown as ChangeEventHandler<HTMLDivElement>;

  return (
    <ComboboxField
      showNewItem
      options={optionsFn}
      render={comboboxItemRender}
      name={name}
      label={label}
      placeholder={placeholder}
      isDisabled={isNil(crmAccountId)}
      onChange={handleOnItemSelection}
      onTextChange={onChange}
      suggestionPlaceholder={suggestionPlaceholder}
    />
  );
};
