import type { FC, ReactNode } from 'react';
import { Box } from '@topo-io/design-system';

interface MainContainerProps {
  children: ReactNode;
}

const MainContainer: FC<MainContainerProps> = ({ children }) => {
  return (
    <Box as="main" p="8">
      {children}
    </Box>
  );
};

export { MainContainer };
