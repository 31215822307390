import { useMemo } from 'react';
import { isNotNil } from '@topo-io/utils';
import { useSearchContext } from './use-search-context';

export const useSearchResults = <T>() => {
  const { results: preResult, refresh, isLoading, searchQuery } = useSearchContext();

  const isSearching = isNotNil(searchQuery);

  const results = useMemo(
    () =>
      preResult.filter((hit) => {
        if ('archivedAt' in hit) {
          return !hit.archivedAt;
        }

        return true;
      }),
    [preResult]
  );

  return {
    results: results as T[],
    refresh,
    isLoading,
    isSearching,
    query: searchQuery,
  };
};
