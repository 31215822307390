import { t } from '@lingui/macro';
import type { FC, PropsWithChildren } from 'react';
import { useCallback, createContext, useMemo } from 'react';
import type { CreateFileFn } from '@topo-io/design-system';
import { useToast } from '@topo-io/design-system';
import type {
  CreateTaskInput,
  CreateTaskMutationOptions,
  DeleteTaskMutationOptions,
  TaskQuery,
  UpdateTaskAssigneesMutationOptions,
  UpdateTaskInput,
  UpdateTaskMutationOptions,
} from '@topo-io/graphql';
import {
  useDeleteFileUsageMutation,
  FileUsageUsage,
  FILES_USAGES,
  useCreateFileMutation,
  useCreateFileUsageMutation,
  useCreateTaskMutation,
  TaskDocument,
  useUpdateTaskAssigneesMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from '@topo-io/graphql';
import { useDebouncedCallback } from '@topo-io/hooks';
import { isNil, isNotNil, omit } from '@topo-io/utils';
import { useUser } from '@/hooks';

export interface TaskContextProps {
  taskId?: string;
  updateTask: (data: UpdateTaskInput) => void;
  updateTaskAssignee: (assigneeUserId: string) => void;
  debouncedUpdateTask: (data: UpdateTaskInput) => void;
  createSubtask: (data: CreateTaskInput) => void;
  updateSubtask: (subtaskId: string, data: UpdateTaskInput) => void;
  updateSubtaskAssignee: (subtaskId: string, assigneeUserId: string) => void;
  debouncedUpdateSubtask: (parentTaskId: string, data: UpdateTaskInput) => void;
  deleteSubtask: (parentTaskId: string) => void;
  createFile: CreateFileFn;
  createFileUsage: (fileId: string) => void;
  deleteFile: (fileId: string) => Promise<void>;
}

export const TaskContext = createContext<TaskContextProps>({
  taskId: undefined,
  // Disable the rule for the default value. The actual function is set in the provider below.
  /* eslint-disable @typescript-eslint/no-empty-function */
  updateTask: () => {},
  updateTaskAssignee: () => {},
  debouncedUpdateTask: () => {},

  createSubtask: () => {},
  updateSubtask: () => {},
  updateSubtaskAssignee: () => {},
  debouncedUpdateSubtask: () => {},
  deleteSubtask: () => {},

  createFile: () => Promise.resolve(undefined),
  createFileUsage: () => {},
  deleteFile: () => Promise.resolve(undefined),
  /* eslint-enable */
});

export interface TaskProviderProps {
  taskId: string;
}

const DEBOUNCE_THROTTLE_TIME = 500;

// TODO: refactor to use function builder (aka: makeTaskUpdate etc).

export const TaskProvider: FC<PropsWithChildren<TaskProviderProps>> = ({ taskId, children }) => {
  const { user, isAnonymousUser } = useUser();
  const { errorToast } = useToast();
  const mutationOptions = useMemo(
    () => ({
      refetchQueries: [
        {
          fetchPolicy: 'network-only',
          query: TaskDocument,
          variables: { id: taskId },
        },
      ],
    }),
    [taskId]
  );
  const [updateTaskAssigneesMutation] = useUpdateTaskAssigneesMutation(
    mutationOptions as UpdateTaskAssigneesMutationOptions
  );
  const [updateTaskMutation] = useUpdateTaskMutation(mutationOptions as UpdateTaskMutationOptions);
  const [createTask] = useCreateTaskMutation(mutationOptions as CreateTaskMutationOptions);
  const [deleteTaskMutation] = useDeleteTaskMutation(mutationOptions as DeleteTaskMutationOptions);
  const [createFileMutation] = useCreateFileMutation();
  const [createFileUsageMutation] = useCreateFileUsageMutation({
    refetchQueries: [
      {
        query: FILES_USAGES,
        variables: {
          input: {
            taskId,
          },
        },
      },
    ],
  });
  const [deleteFileUsageMutation] = useDeleteFileUsageMutation();

  const updateTask = useCallback(
    async (task: TaskQuery['task']) => {
      const payload = omit(task, [
        'id',
        'assignees',
        'subtasks',
        'subtasksCount',
        'completedSubtasksCount',
      ]);
      await updateTaskMutation({
        variables: {
          taskId,
          taskData: payload,
        },
      });
    },
    [taskId, updateTaskMutation]
  );

  const updateTaskAssignee = useCallback(
    async (assigneeUserProfileId: string) =>
      await updateTaskAssigneesMutation({
        variables: { taskId, userProfileId: assigneeUserProfileId },
      }),
    [taskId, updateTaskAssigneesMutation]
  );

  const debouncedUpdateTask = useDebouncedCallback(updateTask, DEBOUNCE_THROTTLE_TIME);

  const createSubtask = useCallback(
    async (data: CreateTaskInput) => {
      await createTask({ variables: { taskData: { ...data, parentTaskId: taskId } } });
    },
    [taskId, createTask]
  );

  const updateSubtask = useCallback(
    async (
      subtaskId: string,
      { id: _, assignees: __, parentTaskId: ___, ...payload }: TaskQuery['task']['subtasks'][number]
    ) => {
      await updateTaskMutation({
        variables: {
          taskId: subtaskId,
          taskData: payload,
        },
      });
    },
    [updateTaskMutation]
  );
  const debouncedUpdateSubtask = useDebouncedCallback(updateSubtask, DEBOUNCE_THROTTLE_TIME);

  const updateSubtaskAssignee = useCallback(
    async (subtaskId: string, assigneeUserProfileId: string) =>
      await updateTaskAssigneesMutation({
        variables: { taskId: subtaskId, userProfileId: assigneeUserProfileId },
      }),
    [updateTaskAssigneesMutation]
  );

  const deleteSubtask = useCallback(
    async (subtaskId: string) => {
      // TODO: cache update to refresh roadmap.
      await deleteTaskMutation({ variables: { taskId: subtaskId } });
    },
    [deleteTaskMutation]
  );

  const createFile = useCallback(
    async ({
      ext,
      type,
      checksum,
      name,
      size,
    }: {
      ext: string;
      type: string;
      checksum: string;
      name: string;
      size: number;
    }) => {
      const { data, errors } = await createFileMutation({
        variables: {
          input: {
            type,
            checksum,
            ext,
            name,
            size,
          },
        },
      });
      if (isNotNil(errors) || isNil(data)) {
        errorToast({
          title: t`An error occurred while uploading your file`,
        });
        return;
      }
      return data.createFile;
    },
    [createFileMutation, errorToast]
  );

  const createFileUsage = useCallback(
    async (fileId: string) => {
      if (isNil(taskId) || isAnonymousUser(user)) {
        return;
      }
      const { data, errors } = await createFileUsageMutation({
        variables: {
          input: {
            fileId,
            usage: FileUsageUsage.TASK,
            relatedType: 'task',
            relatedId: taskId,
            ownerId: user?.id,
          },
        },
      });
      if (isNotNil(errors) || isNil(data)) {
        errorToast({
          title: t`An error occurred while uploading your file`,
        });
        return;
      }
      return data.createFileUsage.id;
    },
    [createFileUsageMutation, errorToast, taskId, user, isAnonymousUser]
  );

  const deleteFile = useCallback(
    async (fileId: string) => {
      await deleteFileUsageMutation({
        variables: {
          id: fileId,
        },
        refetchQueries: [
          {
            query: FILES_USAGES,
            variables: {
              input: {
                ...(taskId ? { taskId: taskId } : {}),
              },
            },
          },
        ],
      });
    },
    [deleteFileUsageMutation, taskId]
  );

  const value = useMemo(
    () => ({
      taskId,
      updateTask,
      updateTaskAssignee,
      debouncedUpdateTask,
      createSubtask,
      updateSubtask,
      updateSubtaskAssignee,
      debouncedUpdateSubtask,
      deleteSubtask,
      createFile,
      createFileUsage,
      deleteFile,
    }),
    [
      taskId,
      updateTask,
      updateTaskAssignee,
      debouncedUpdateTask,
      createSubtask,
      updateSubtask,
      updateSubtaskAssignee,
      debouncedUpdateSubtask,
      deleteSubtask,
      createFile,
      createFileUsage,
      deleteFile,
    ]
  );

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};
