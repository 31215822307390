const CUBE_CONSTANTS = {
    WORKSPACE_TITLE: 'workspace.title',
    WORKSPACE_OWNER_ID: 'workspace.ownerid',
    WORKSPACE_ORGANIZATION_ID: 'workspace.organizationid',
    WORKSPACE_TOTAL_SHARED_COUNT: 'workspace.total_shared_count',
    WORKSPACE_TOTAL_ACTIVE_COUNT: 'workspace.total_active_count',
    WORKSPACE_TOTAL_GUESTS_SUM: 'workspace.total_guests_sum',
    WORKSPACE_TOTAL_VIEWS_SUM: 'workspace.total_views_sum',
    WORKSPACE_TOTAL_TIME_SPENT_SUM: 'workspace.total_time_spent_sum',
    COMPANY_NAME: 'company.name',
    ACTIVITY_CREATED_AT: 'activity.createdat',
    INVITATION_CREATED_AT: 'invitation.createdat',
    WORKSPACE_VIEWS_COUNT: 'workspace_views.count',
    WORKSPACE_VIEWS_START_TIMESTAMP: 'workspace_views.start_tstamp',
    WORKSPACE_VIEWS_ENGAGED_TIME: 'workspace_views.engaged_time_in_s',
    WORKSPACE_VIEWS_WORKSPACE_ID: 'workspace_views.workspace_id',
    WORKSPACE_VIEWS_USER_ID: 'workspace_views.user_id',
    SECTION_VIEWS_COUNT: 'section_views.count',
    SECTION_VIEWS_WORKSPACE_ID: 'section_views.workspace_id',
    SECTION_VIEWS_ENGAGED_TIME: 'section_views.engaged_time_in_s',
    SECTION_VIEWS_SECTION_ID: 'section_views.section_id',
    SECTION_VIEWS_WORKSPACE_TAB_ID: 'section_views.workspace_tab_id',
    SECTION_VIEWS_USER_ID: 'section_views.user_id',
    WEIGHTED_VALUES_COMMENT_SUM: 'workspace_weighted_values.comments_sum',
    WEIGHTED_VALUES_ENGAGED_TIME_SUM: 'workspace_weighted_values.engaged_time_sum',
    WEIGHTED_VALUES_SHARES_SUM: 'workspace_weighted_values.shares_sum',
    WEIGHTED_VALUES_VIEWS_SUM: 'workspace_weighted_values.views_sum',
    WEIGHTED_VALUES_WORKSPACE_ID: 'workspace_weighted_values.workspaceid',
    WEIGHTED_VALUES_INTERACTION_DATE: 'workspace_weighted_values.interaction_date',
    DAILY_SCORE_SCORE: 'workspace_daily_scores.daily_score',
    DAILY_SCORE_SCORE_DATE: 'workspace_daily_scores.score_date',
    DAILY_SCORE_WORKSPACE_ID: 'workspace_daily_scores.workspaceid',
    WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_DIFF: 'workspaces_ranked_by_score_diff.score_difference',
    WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_ONE_FILTER: 'workspaces_ranked_by_score_diff.score_at_date_one_date_filter',
    WORKSPACES_RANKED_BY_SCORE_DIFF_DATE_TWO_FILTER: 'workspaces_ranked_by_score_diff.score_at_date_two_date_filter',
    WORKSPACES_RANKED_BY_SCORE_DIFF_WORKSPACE_ID: 'workspaces_ranked_by_score_diff.workspaceid',
    WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_AT_DATE_ONE: 'workspaces_ranked_by_score_diff.score_at_date_one',
    WORKSPACES_RANKED_BY_SCORE_DIFF_SCORE_AT_DATE_TWO: 'workspaces_ranked_by_score_diff.score_at_date_two'
};

/*
 * WARNING: event names should be chosen very carefully !!!
 *
 * Please read: https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 */ var EventTrackingEvents;
(function(EventTrackingEvents) {
    EventTrackingEvents["ACTIVITIES_MARKED_AS_SEEN"] = "Activities Marked As Seen";
    EventTrackingEvents["INTEGRATION_SET_UP"] = "Integration Set Up";
    EventTrackingEvents["MAP_TASK_COMPLETED"] = "Mutual Action Plan Task Completed";
    EventTrackingEvents["MAP_TASK_ASSIGNED"] = "Mutual Action Plan Task Assigned";
    EventTrackingEvents["MAP_TASK_DUE_DATE_SET"] = "Mutual Action Plan Task Due Date Set";
    EventTrackingEvents["NOTIFICATION_SENT"] = "Notification Sent";
    EventTrackingEvents["PAGE_LOAD"] = "Page Loaded";
    EventTrackingEvents["SECTION_TEMPLATE_CREATED"] = "Section Template Created";
    EventTrackingEvents["SIGN_IN"] = "User Signed In";
    EventTrackingEvents["SIGN_UP"] = "User Signed Up";
    EventTrackingEvents["SIGN_OUT"] = "User Signed Out";
    EventTrackingEvents["VARIABLE_ADDED"] = "Variable Added";
    EventTrackingEvents["WORKSPACE_CREATED"] = "Workspace Created";
    EventTrackingEvents["WORKSPACE_COMMENT_CREATED"] = "Workspace Comment Created";
    EventTrackingEvents["WORKSPACE_OPENED"] = "Workspace Opened";
    EventTrackingEvents["WORKSPACE_SHARED"] = "Workspace Shared";
    EventTrackingEvents["WORKSPACE_TEMPLATE_CREATED"] = "Workspace Template Created";
    EventTrackingEvents["WORKSPACE_SYNCED_TO_CRM_ACCOUNT"] = "Workspace Synced To CRM Account";
    EventTrackingEvents["WORKSPACE_SYNCED_TO_CRM_OPPORTUNITY"] = "Workspace Synced To CRM Opportunity";
    EventTrackingEvents["TAB_LIMIT_ACCESS_CREATED"] = "Tab Limit Access Created";
    EventTrackingEvents["WORKSPACE_CUSTOM_BRAND_CREATED"] = "Workspace Custom Brand Created";
    EventTrackingEvents["REFERALL_PROGRAM_CLICKED"] = "Referral Program Clicked";
    EventTrackingEvents["CREATED_FROM_DIRECTORY_SYNC"] = "User Created From Directory Sync";
    EventTrackingEvents["UPDATED_FROM_DIRECTORY_SYNC"] = "User Updated From Directory Sync";
    EventTrackingEvents["DISABLED_FROM_DIRECTORY_SYNC"] = "User Disabled From Directory Sync";
})(EventTrackingEvents || (EventTrackingEvents = {}));

const OPEN_GRAPH_CDN_URL = 'https://rooms.topo.io/og';
var OpenGraphParams;
(function(OpenGraphParams) {
    OpenGraphParams["BUYER_DOMAIN"] = "buyer";
    OpenGraphParams["SELLER_DOMAIN"] = "seller";
    OpenGraphParams["BUYER_COMPANY_ID"] = "buyerCompanyId";
    OpenGraphParams["SELLER_COMPANY_ID"] = "sellerCompanyId";
    OpenGraphParams["WIDTH"] = "width";
    OpenGraphParams["HEIGHT"] = "height";
    OpenGraphParams["BG_COLOR"] = "bgColor";
    OpenGraphParams["TEXT_COLOR"] = "textColor";
    OpenGraphParams["TITLE_COLOR"] = "titleColor";
    OpenGraphParams["ACCENT_COLOR"] = "accentColor";
    OpenGraphParams["BORDER_RADIUS"] = "borderRadius";
    OpenGraphParams["EXTERNAL_BORDER_RADIUS"] = "externalBorderRadius";
    OpenGraphParams["DEBUG"] = "debug";
})(OpenGraphParams || (OpenGraphParams = {}));

const SECRET_42_HASH = '$2b$10$EpRnTzVlqHNP0.fUbXUwSOyuiXe/QLSUG6xNekdHgTGmrpHEfIoxm';
const SECRET_42_PASSWORD = 'secret42';

var VariableType;
(function(VariableType) {
    VariableType["GuestFirstName"] = "GuestFirstName";
    VariableType["GuestLastName"] = "GuestLastName";
    VariableType["GuestCompany"] = "GuestCompany";
    VariableType["OwnerFirstName"] = "OwnerFirstName";
    VariableType["OwnerLastName"] = "OwnerLastName";
    VariableType["OwnerCompany"] = "OwnerCompany";
    VariableType["OwnerEmail"] = "OwnerEmail";
    VariableType["OwnerJobTitle"] = "OwnerJobTitle";
})(VariableType || (VariableType = {}));

const OVERLAY_SECTION_Z_INDEX = 1;
const TOOLBAR_SECTION_Z_INDEX = 2;
const TOOLBAR_TEMPLATE_SECTION_Z_INDEX = 3;
const TAB_LIST_Z_INDEX = 4;
const TEMPLATE_LIBRARY_Z_INDEX = 5;
const MENU_BUTTON_WORKSPACE_Z_INDEX = 6;
const OVERLAY_OVERVIEW_Z_INDEX = 7;
const SECTIONS_COMMENTS_DRAWER_Z_INDEX = 8;

export { CUBE_CONSTANTS, EventTrackingEvents, MENU_BUTTON_WORKSPACE_Z_INDEX, OPEN_GRAPH_CDN_URL, OVERLAY_OVERVIEW_Z_INDEX, OVERLAY_SECTION_Z_INDEX, OpenGraphParams, SECRET_42_HASH, SECRET_42_PASSWORD, SECTIONS_COMMENTS_DRAWER_Z_INDEX, TAB_LIST_Z_INDEX, TEMPLATE_LIBRARY_Z_INDEX, TOOLBAR_SECTION_Z_INDEX, TOOLBAR_TEMPLATE_SECTION_Z_INDEX, VariableType };
