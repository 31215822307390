import { ErrorBoundary } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import type { FC, PropsWithChildren } from 'react';
import { DesignSystemProvider } from '@topo-io/design-system';
import { HttpProvider, CookiesProvider, Cookies } from '@topo-io/hooks';
import { isBrowser } from '@topo-io/utils';
import { LocaleProvider } from '@/components/i18n';
import { all } from '@/config';
import { AnalyticsProvider, GraphqlProvider, StateProvider } from '@/contexts';

const { ENV } = all;

interface SetupProps extends PropsWithChildren {
  cookies: string;
}

const Setup: FC<SetupProps> = ({ children, cookies }) => {
  const { locale } = useRouter();
  const [shortLocale] = locale ? locale.split('-') : ['en'];
  const cookiesForProvider = isBrowser() ? undefined : new Cookies(cookies);

  return (
    <ErrorBoundary
      fallback={
        <>
          <p>An error has occurred. You need to reload this page.</p>
          <p>If this error happens again try removing your cookies.</p>
        </>
      }
    >
      <CookiesProvider cookies={cookiesForProvider}>
        <GraphqlProvider>
          <HttpProvider env={ENV}>
            <LocaleProvider locale={shortLocale}>
              <AnalyticsProvider>
                <StateProvider>
                  <DesignSystemProvider>{children}</DesignSystemProvider>
                </StateProvider>
              </AnalyticsProvider>
            </LocaleProvider>
          </HttpProvider>
        </GraphqlProvider>
      </CookiesProvider>
    </ErrorBoundary>
  );
};

export default Setup;
