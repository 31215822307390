import { CUBE_CONSTANTS } from '@topo-io/constants';
import type {
  EngagementScoreDateRange,
  EngagementScoreForPeriod,
} from '@/components/engagement-score/types';
import { mapDateRangeToDates } from '@/components/engagement-score/utils';
import { useAnalyticsQuery } from '@/hooks';

interface UseWorkspacesDailyEngagementScoreArgs {
  workspaceId: string;
  dateRange: EngagementScoreDateRange;
  shouldSkip?: boolean;
}

const SCORE_DATE_BY_DAY = `${CUBE_CONSTANTS.DAILY_SCORE_SCORE_DATE}.day` as const;

export interface ScoreForPeriodRawData {
  [SCORE_DATE_BY_DAY]: string;
  [CUBE_CONSTANTS.DAILY_SCORE_SCORE]: string;
  [CUBE_CONSTANTS.DAILY_SCORE_WORKSPACE_ID]: string;
}

interface UseWorkspacesScoresForPeriodReturnValue {
  data: EngagementScoreForPeriod[];
  isLoading: boolean;
}

export const useWorkspaceScoresForPeriod = ({
  workspaceId,
  dateRange,
  shouldSkip,
}: UseWorkspacesDailyEngagementScoreArgs): UseWorkspacesScoresForPeriodReturnValue => {
  const [startDate, endDate] = mapDateRangeToDates(dateRange).currentRange;

  const { tableData, isLoading } = useAnalyticsQuery<ScoreForPeriodRawData[]>({
    query: {
      dimensions: [CUBE_CONSTANTS.DAILY_SCORE_SCORE, CUBE_CONSTANTS.DAILY_SCORE_WORKSPACE_ID],
      filters: [
        {
          member: CUBE_CONSTANTS.DAILY_SCORE_WORKSPACE_ID,
          operator: 'equals',
          values: [workspaceId],
        },
      ],
      timeDimensions: [
        {
          dimension: CUBE_CONSTANTS.DAILY_SCORE_SCORE_DATE,
          granularity: 'day',
          dateRange: [startDate, endDate],
        },
      ],
    },
    options: {
      skip: shouldSkip,
    },
  });

  return {
    data: tableData.map((row) => ({
      date: row[SCORE_DATE_BY_DAY],
      score: Math.trunc(Number(row[CUBE_CONSTANTS.DAILY_SCORE_SCORE])),
    })),
    isLoading,
  };
};
