import { Chance } from 'chance';
import type { GuestsMapping } from '@/components/analytics/types';

export const MOST_VIEWED_SECTION_BASE_HEIGHT = 300;
export const DEFAULT_SECTION_COUNT = 7;
export const SECTION_HEIGHT = 20;

const chance = new Chance();

const sectionTitle = 'Section title';
const sectionId1 = 'sectionId-1';
const sectionId2 = 'sectionId-2';
const sectionId3 = 'sectionId-3';
const sectionId4 = 'sectionId-4';
const sectionId5 = 'sectionId-5';
const userId1 = 'userId-1';
const userId2 = 'userId-2';
const userId3 = 'userId-3';
const userId4 = 'userId-4';

const generateNumber = () => chance.integer({ min: 10, max: 100 });

const GUESTS_MAPPING: GuestsMapping = {
  [userId1]: {
    id: userId1,
    displayName: chance.first(),
    picture: '',
  },
  [userId2]: {
    id: userId2,
    displayName: chance.first(),
    picture: '',
  },
  [userId3]: {
    id: userId3,
    displayName: chance.first(),
    picture: '',
  },
  [userId4]: {
    id: userId4,
    displayName: chance.first(),
    picture: '',
  },
};

const SECTIONS_MAPPING = {
  [sectionId1]: {
    id: sectionId1,
    title: sectionTitle,
  },
  [sectionId2]: {
    id: sectionId2,
    title: sectionTitle,
  },
  [sectionId3]: {
    id: sectionId3,
    title: sectionTitle,
  },
  [sectionId4]: {
    id: sectionId4,
    title: sectionTitle,
  },
  [sectionId5]: {
    id: sectionId5,
    title: sectionTitle,
  },
};

const DATA = [
  {
    sectionId: sectionId1,
    sectionTitle: sectionTitle,
    [userId1]: generateNumber(),
    [userId2]: generateNumber(),
    [userId3]: generateNumber(),
    [userId4]: generateNumber(),
  },
  {
    sectionId: sectionId2,
    sectionTitle: sectionTitle,
    [userId1]: generateNumber(),
    [userId2]: generateNumber(),
    [userId3]: generateNumber(),
    [userId4]: generateNumber(),
  },
  {
    sectionId: sectionId3,
    sectionTitle: sectionTitle,
    [userId1]: generateNumber(),
    [userId2]: generateNumber(),
    [userId3]: generateNumber(),
    [userId4]: generateNumber(),
  },
  {
    sectionId: sectionId4,
    sectionTitle: sectionTitle,
    [userId1]: generateNumber(),
    [userId2]: generateNumber(),
    [userId3]: generateNumber(),
    [userId4]: generateNumber(),
  },
  {
    sectionId: sectionId5,
    sectionTitle: sectionTitle,
    [userId1]: generateNumber(),
    [userId2]: generateNumber(),
    [userId3]: generateNumber(),
    [userId4]: generateNumber(),
  },
];

const KEYS = [userId1, userId2, userId3, userId4];

export const MOST_VIEWED_SECTION_FAKE_DATA = {
  guestsMapping: GUESTS_MAPPING,
  sectionsMapping: SECTIONS_MAPPING,
  data: DATA,
  keys: KEYS,
};
