import { t } from '@lingui/macro';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { InlineInput } from '@topo-io/design-system';
import { useTypingDetector } from '@topo-io/hooks';
import { useSavingStatus } from '@/state/hooks/use-saving-status';
import { useDebouncedUpdateSectionTitle } from './hooks/use-debounced-update-section-title';
import type { BasicSectionProps } from './types';

type SectionTitleProps = {
  title: string;
  readOnly: boolean;
  onEnterKeyPress?: () => void;
} & Pick<BasicSectionProps, 'id' | 'updateSectionTitle'>;

export const SectionTitle: FC<SectionTitleProps> = ({
  readOnly,
  title,
  id,
  updateSectionTitle,
  onEnterKeyPress,
}) => {
  const { showNotSavedStatus } = useSavingStatus();
  const { isTyping, handleTyping } = useTypingDetector(700);
  const previousTitle = useRef(title);

  const ref = useRef<HTMLInputElement | null>(null);
  const titleProps = {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    height: '32px',
  };
  const updateSectionTitleDebounced = useDebouncedUpdateSectionTitle({ id, updateSectionTitle });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onEnterKeyPress) {
      ref.current?.blur();
      onEnterKeyPress();
    }
  };

  /*
   * If the user currently editing the title, we don't want to update the input value
   * If the user is not editing the title, we want to update the input value
   * Useful when multiple tabs are open, because workspace content is refreshed every 10 seconds
   */
  useEffect(() => {
    if (!isTyping && title !== previousTitle.current) {
      previousTitle.current = title;
      if (ref.current) {
        ref.current.value = title;
      }
    }
  }, [title, isTyping]);

  return (
    <InlineInput
      ref={ref}
      {...titleProps}
      aria-label="section-title-input"
      as="h4"
      defaultValue={title}
      readOnly={readOnly}
      placeholder={t`Untitled section`}
      onKeyDown={handleKeyDown}
      onChange={(event) => {
        handleTyping();
        showNotSavedStatus();
        updateSectionTitleDebounced(event.target.value);
      }}
      color="gray.900"
      data-testid="section-title"
    />
  );
};
